import { IncomingMessage } from 'http';

import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import Cookies from 'universal-cookie';

import { isBrowser } from '../constants/general';

interface IEnvironmentConfig {
  cognitoRegion: string;
  cognitoUserPoolId: string;
  cognitoClientId: string;
}

type Store = Record<string, string>;

export const getAuthConfig = (environmentConfig: IEnvironmentConfig) => {
  return {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: environmentConfig.cognitoRegion,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: environmentConfig.cognitoUserPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: environmentConfig.cognitoClientId,

      // OPTIONAL - Enforce auth authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //   domain: 'localhost',
      //   // OPTIONAL - Cookie path
      //   path: '/',
      //   // OPTIONAL - Cookie expiration in days
      //   expires: 365,
      //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //   sameSite: 'strict',
      //   // OPTIONAL - Cookie secure flag
      //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //   secure: false,
      // },
      oauth: {},

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      storage: ClientAmplifyStorage,
    },
  };
};

export class AmplifyStorage implements Storage {
  static PREFIX = '@ss/';
  cookies = new Cookies();
  store: Store = {};

  constructor(context?: {
    req: IncomingMessage & {
      cookies: NextApiRequestCookies;
    };
  }) {
    if (!isBrowser && context && context?.req) {
      this.cookies = new Cookies(context?.req.headers.cookie);
      Object.assign(this.store, this.cookies.getAll());
    }
  }

  get length() {
    return Object.entries(this.store).length;
  }

  clear() {
    Array.from(new Array(this.length))
      .map((_, i) => this.key(i))
      .forEach((key) => this.removeItem(key));
  }

  getItem(key: keyof Store) {
    return this.getLocalItem(key);
  }

  protected getLocalItem(key: keyof Store) {
    return Object.prototype.hasOwnProperty.call(this.store, this.getKey(key))
      ? this.store[this.getKey(key)]
      : null;
  }

  protected getKey(key: string) {
    if (key.startsWith(AmplifyStorage.PREFIX)) {
      return key;
    }

    return AmplifyStorage.PREFIX + key;
  }

  key(index: number) {
    return Object.keys(this.store)[index];
  }

  removeItem(key: string) {
    this.removeLocalItem(key);

    if (!isBrowser && this.cookies) {
      this.removeServerItem(key);
    }
  }

  protected removeLocalItem(key: keyof Store) {
    delete this.store[this.getKey(key)];
  }

  protected removeServerItem(key: keyof Store) {
    this.cookies.remove(this.getKey(key));
  }

  setItem(key: keyof Store, value: string) {
    this.setLocalItem(key, value);

    // keys take the shape:
    //  1. `${ProviderPrefix}.${userPoolClientId}.${username}.${tokenType}
    //  2. `${ProviderPrefix}.${userPoolClientId}.LastAuthUser
    const tokenType = key.split('.').pop();

    switch (tokenType) {
      case 'LastAuthUser':
      case 'accessToken':
      case 'refreshToken':
      case 'idToken':
        isBrowser
          ? this.setLocalItem(key, value)
          : this.setServerItem(key, value);
        break;
    }
  }

  protected setLocalItem(key: keyof Store, value: string) {
    this.store[this.getKey(key)] = value;
  }

  protected setServerItem(key: keyof Store, value: string) {
    this.cookies.set(this.getKey(key), value, {
      httpOnly: true,
    });
  }

  public resetStore(store: Store) {
    this.clear();

    this.store = { ...store };
  }
}

export const ClientAmplifyStorage = new AmplifyStorage();
