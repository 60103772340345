import React from 'react';

const BillingHistoryIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      opacity="0.3"
      d="M28.9177 11.2142V35.3123H3.14746V3.14868H28.9177V11.2142Z"
      fill="currentColor"
    />
    <path
      d="M24.2939 20.9514H7.76953V24.2956H24.2939V20.9514Z"
      fill="currentColor"
    />
    <path
      d="M24.2939 14.5579H7.76953V17.9021H24.2939V14.5579Z"
      fill="currentColor"
    />
    <path
      d="M15.5399 27.3447H7.76953V30.6889H15.5399V27.3447Z"
      fill="currentColor"
    />
    <path
      d="M26.2625 14.7544L28.4264 12.0987L33.3444 15.0495L36.0001 0.885742L19.8691 7.37747L24.6888 9.93482L32.8526 4.22997L26.6559 11.2135L26.2625 14.7544Z"
      fill="currentColor"
    />
    <path
      d="M19.2785 3.14751L16.131 0H0V32.1636H3.14751V3.14751H19.2785Z"
      fill="currentColor"
    />
  </svg>
);

export { BillingHistoryIcon };
