import React from 'react';

const TickIcon = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.3 25.0999L0 13.4999L5.1 8.7999L10.3 14.5999L22.4 0.899902L27.5 5.4999L10.3 25.0999Z"
      fill="#36C6C8"
    />
  </svg>
);

export { TickIcon };
