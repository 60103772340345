import React from 'react';

const SummaryCheckIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M143.958 155.878C120.093 171.788 86.3722 175.519 61.1471 158.662C48.8415 150.444 39.4424 138.553 34.2881 124.682C29.1338 110.811 28.4871 95.6672 32.4401 81.4074C36.3931 67.1476 44.7442 54.4984 56.3044 45.2609C67.8646 36.0234 82.0445 30.6686 96.825 29.9589C127.149 28.4952 155.891 48.486 167.823 76.3283"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M144.079 118.348L154.226 128.733L178.579 101.733"
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1045 121.287L74.2269 118.165C75.3239 117.068 75.4927 115.316 74.2269 110.549C72.961 105.781 74.3534 99.8312 76.864 97.3207L81.0834 93.1013"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M126.211 112.975L107.223 131.962C100.894 138.291 96.6748 134.072 92.4554 138.291L90.3457 140.401"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M67.144 117.201L57.6562 126.689L82.9719 152.004L92.4596 142.516L67.144 117.201Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M109.333 89.7679L105.113 93.9873"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M115.662 96.097L111.442 100.316"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M121.991 102.426L117.771 106.646"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M126.211 112.975C129.649 109.557 126.907 103.523 121.991 102.426C123.025 100.127 119.882 95.443 115.662 96.097C116.717 92.9325 112.709 89.2405 109.333 89.7679C109.294 88.5635 108.904 87.3966 108.212 86.4098C107.521 85.4231 106.556 84.6593 105.437 84.2117C104.318 83.7642 103.093 83.6522 101.912 83.8896C100.73 84.127 99.6437 84.7035 98.7845 85.5485L83.0039 101.413"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M86.0623 98.27L77.7923 89.8312C77.5039 89.5397 77.1367 89.3386 76.7358 89.2524C76.3349 89.1662 75.9176 89.1987 75.5349 89.346C72.7284 90.454 69.6592 90.7168 66.7053 90.102C63.7513 89.4872 61.0418 88.0217 58.9104 85.8861C56.9917 83.9621 55.5658 81.6033 54.7541 79.0101C53.9424 76.417 53.7687 73.6662 54.248 70.9916C54.3226 70.6163 54.4978 70.2684 54.7549 69.9851C55.0121 69.7018 55.3414 69.4938 55.7076 69.3832C56.0739 69.2727 56.4634 69.2638 56.8343 69.3576C57.2052 69.4514 57.5436 69.6443 57.8134 69.9156L64.8809 76.962C65.077 77.1598 65.3103 77.3167 65.5674 77.4238C65.8245 77.5309 66.1002 77.5861 66.3788 77.5861C66.6573 77.5861 66.933 77.5309 67.1901 77.4238C67.4472 77.3167 67.6805 77.1598 67.8766 76.962L73.826 71.0127C74.0238 70.8165 74.1807 70.5832 74.2878 70.3261C74.3949 70.069 74.4501 69.7933 74.4501 69.5148C74.4501 69.2363 74.3949 68.9605 74.2878 68.7034C74.1807 68.4463 74.0238 68.213 73.826 68.0169L66.7796 60.9494C66.5083 60.6796 66.3154 60.3412 66.2216 59.9703C66.1278 59.5994 66.1367 59.2099 66.2472 58.8437C66.3578 58.4774 66.5658 58.1481 66.8491 57.8909C67.1324 57.6338 67.4803 57.4586 67.8555 57.384C70.5367 56.8976 73.2957 57.0677 75.8969 57.8796C78.498 58.6916 80.8639 60.1212 82.7923 62.0464C84.9279 64.1778 86.3934 66.8873 87.0082 69.8413C87.6229 72.7952 87.3602 75.8645 86.2522 78.6709C86.1049 79.0536 86.0724 79.4709 86.1586 79.8718C86.2447 80.2728 86.4459 80.6399 86.7374 80.9283L95.0496 89.3671"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M122.476 116.73L135.557 129.726C136.675 130.926 137.283 132.512 137.254 134.152C137.225 135.792 136.561 137.356 135.401 138.516C134.242 139.675 132.677 140.34 131.038 140.369C129.398 140.397 127.811 139.789 126.611 138.671L113.531 125.675"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export { SummaryCheckIcon };
