import Head from 'next/head';

import { useBrand } from '@client/hooks/useBrand';
export const ProfileHead = () => {
  const { brand } = useBrand();
  const title = `My Account - ${brand.name}`;
  return (
    <Head key={'profileHead'}>
      <title>{title}</title>
    </Head>
  );
};

export default ProfileHead;
