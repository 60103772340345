import Amplify from 'aws-amplify';

import { getAuthConfig } from '../configs/amplify';

export function initAmplify() {
  const authConfig = getAuthConfig({
    cognitoRegion: process.env.NEXT_PUBLIC_COGNITO_REGION as string,
    cognitoUserPoolId: process.env.NEXT_PUBLIC_COGNITO_USERPOOL_ID as string,
    cognitoClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string,
  });

  Amplify.configure({ ...authConfig, ssr: true });
}
