import { useCallback } from 'react';
import { toast } from 'react-toastify';

const toastOptions = {
  autoClose: 4000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  closeButton: false,
};

const toastSuccessOptions = {
  style: {
    backgroundColor: '#F4F9F6',
    border: '2px solid #258244',
    borderRadius: '4px',
    color: '#444444',
    fontSize: '13px',
  },
};

const toastErrorOptions = {
  style: {
    backgroundColor: '#FCF3F5',
    border: '2px solid #D40C3C',
    borderRadius: '4px',
    color: '#444444',
    fontSize: '13px',
  },
};

export function useToast() {
  const handleShowSuccess = useCallback((message: string) => {
    toast.success(message, { ...toastOptions, ...toastSuccessOptions });
  }, []);

  const handleShowError = useCallback((message: string) => {
    toast.error(message, { ...toastOptions, ...toastErrorOptions });
  }, []);

  const handleShowInfo = useCallback((message: string) => {
    toast.info(message, toastOptions);
  }, []);

  const handleShowWarn = useCallback((message: string) => {
    toast.warn(message, toastOptions);
  }, []);

  return {
    showSuccess: handleShowSuccess,
    showError: handleShowError,
    showInfo: handleShowInfo,
    showWarn: handleShowWarn,
  };
}
