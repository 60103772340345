import { ITopNavbarSectionProps } from '../interfaces';

import { StyledTopNavbarSection } from './styles';

export const TopNavbarSection: React.FC<ITopNavbarSectionProps> = ({
  children,
  ...rest
}) => {
  return <StyledTopNavbarSection {...rest}>{children}</StyledTopNavbarSection>;
};
