import React from 'react';

const style: React.CSSProperties = {
  fill: 'none',
  stroke: '#000000',
  strokeWidth: 15,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 10,
  strokeDasharray: 'none',
  strokeOpacity: 1,
};

const RadiatorIcon = (props: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="682.66669"
    height="682.66669"
    viewBox="0 0 682.66669 682.66669"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs3307">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath3349">
        <path d="M 0,512 H 512 V 0 H 0 Z" id="path3347" />
      </clipPath>
    </defs>
    <g id="g3309" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
      <g id="g3311" transform="translate(122,106.998)">
        <path d="M 0,0 H 29" id="path3313" style={style} />
      </g>
      <g id="g3315" transform="translate(152,72.1924)">
        <path d="M 0,0 H -30" id="path3317" style={style} />
      </g>
      <g id="g3319" transform="translate(230,72.1924)">
        <path d="M 0,0 H -26" id="path3321" style={style} />
      </g>
      <g id="g3323" transform="translate(310,72.1924)">
        <path d="M 0,0 H -28" id="path3325" style={style} />
      </g>
      <g id="g3327" transform="translate(389,72.1924)">
        <path d="M 0,0 H -29" id="path3329" style={style} />
      </g>
      <g id="g3331" transform="translate(360,106.998)">
        <path d="M 0,0 H 29" id="path3333" style={style} />
      </g>
      <g id="g3335" transform="translate(281,106.998)">
        <path d="M 0,0 H 29" id="path3337" style={style} />
      </g>
      <g id="g3339" transform="translate(203,106.998)">
        <path d="M 0,0 H 29" id="path3341" style={style} />
      </g>
      <g id="g3343">
        <g id="g3345" clipPath="url(#clipPath3349)">
          <g id="g3351" transform="translate(466,433.8936)">
            <path d="M 0,0 H 31.5" id="path3353" style={style} />
          </g>
          <g id="g3355" transform="translate(72,416.4902)">
            <path
              d="m 0,0 h -21.744 c -2.086,0 -3.777,1.691 -3.777,3.777 v 27.252 c 0,2.086 1.691,3.777 3.777,3.777 H 0"
              id="path3357"
              style={style}
            />
          </g>
          <g id="g3359" transform="translate(152,416.4902)">
            <path d="M 0,0 H -28" id="path3361" style={style} />
          </g>
          <g id="g3363" transform="translate(231,416.4902)">
            <path d="M 0,0 H -29" id="path3365" style={style} />
          </g>
          <g id="g3367" transform="translate(311,416.4902)">
            <path d="M 0,0 H -30" id="path3369" style={style} />
          </g>
          <g id="g3371" transform="translate(389,416.4902)">
            <path d="M 0,0 H -29" id="path3373" style={style} />
          </g>
          <g id="g3375" transform="translate(440,451.2959)">
            <path
              d="M 0,0 H 25.521 V -34.806 H 0"
              id="path3377"
              style={style}
            />
          </g>
          <g id="g3379" transform="translate(361,451.2959)">
            <path d="M 0,0 H 29" id="path3381" style={style} />
          </g>
          <g id="g3383" transform="translate(281,451.2959)">
            <path d="M 0,0 H 29" id="path3385" style={style} />
          </g>
          <g id="g3387" transform="translate(202,451.2959)">
            <path d="M 0,0 H 29" id="path3389" style={style} />
          </g>
          <g id="g3391" transform="translate(124,451.2959)">
            <path d="M 0,0 H 28" id="path3393" style={style} />
          </g>
          <g id="g3395" transform="translate(121.1782,339)">
            <path
              d="m 0,0 v -282.36 c 0,-9.066 -7.349,-16.414 -16.415,-16.414 h -15.116 c -9.065,0 -16.414,7.348 -16.414,16.414 v 431.011 c 0,9.066 7.349,16.415 16.414,16.415 h 15.116 C -7.349,165.066 0,157.717 0,148.651 V 44"
              id="path3397"
              style={style}
            />
          </g>
          <g id="g3399" transform="translate(184.1611,40.2256)">
            <path
              d="m 0,0 h -15.117 c -9.065,0 -16.414,7.349 -16.414,16.414 v 431.012 c 0,9.065 7.349,16.415 16.414,16.415 H 0 c 9.065,0 16.415,-7.35 16.415,-16.415 V 16.414 C 16.415,7.349 9.065,0 0,0 Z"
              id="path3401"
              style={style}
            />
          </g>
          <g id="g3403" transform="translate(232.0273,196)">
            <path
              d="m 0,0 v 291.651 c 0,9.066 7.349,16.415 16.415,16.415 h 15.116 c 9.066,0 16.414,-7.349 16.414,-16.415 V -139.36 c 0,-9.066 -7.348,-16.414 -16.414,-16.414 H 16.415 C 7.349,-155.774 0,-148.426 0,-139.36 V -41"
              id="path3405"
              style={style}
            />
          </g>
          <g id="g3407" transform="translate(342.9551,40.2256)">
            <path
              d="M 0,0 H -15.116 C -24.182,0 -31.53,7.349 -31.53,16.414 v 431.012 c 0,9.065 7.348,16.415 16.414,16.415 H 0 c 9.065,0 16.415,-7.35 16.415,-16.415 V 16.414 C 16.415,7.349 9.065,0 0,0 Z"
              id="path3409"
              style={style}
            />
          </g>
          <g id="g3411" transform="translate(438.7676,254)">
            <path
              d="m 0,0 v -196.927 c 0,-9.064 -7.35,-16.414 -16.415,-16.414 h -15.116 c -9.066,0 -16.415,7.35 -16.415,16.414 v 431.013 c 0,9.065 7.349,16.414 16.415,16.414 h 15.116 C -7.35,250.5 0,243.151 0,234.086 V 46"
              id="path3413"
              style={style}
            />
          </g>
          <g id="g3415" transform="translate(504.5,451.2959)">
            <path d="M 0,0 V -34.806" id="path3417" style={style} />
          </g>
          <g id="g3419" transform="translate(176.5,34.5)">
            <path d="M 0,0 V -27" id="path3421" style={style} />
          </g>
          <g id="g3423" transform="translate(335.5,34.5)">
            <path d="M 0,0 V -27" id="path3425" style={style} />
          </g>
          <g id="g3427" transform="translate(20.0811,48.5)">
            <path d="M 0,0 V -41" style={style} id="path3429" />
          </g>
          <g id="g3431" transform="translate(67.9189,92.5)">
            <path d="M 0,0 H -26.405" style={style} id="path3433" />
          </g>
          <g id="g3435" transform="translate(491.9189,46.9785)">
            <path d="M 0,0 V -39.479" style={style} id="path3437" />
          </g>
          <g id="g3439" transform="translate(444.0811,92.5)">
            <path d="M 0,0 H 33.315" style={style} id="path3441" />
          </g>
          <g id="g3443" transform="translate(21,49.5)">
            <path
              d="M 0,0 V 0 C -7.456,0 -13.5,6.044 -13.5,13.5 V 58 h 27 V 13.5 C 13.5,6.044 7.456,0 0,0 Z"
              style={style}
              id="path3445"
            />
          </g>
          <g id="g3447" transform="translate(21,107.5)">
            <path d="M 0,0 V 31" style={style} id="path3449" />
          </g>
          <g id="g3451" transform="translate(23.5,139.5)">
            <path
              d="m 0,0 h -5 c -5.799,0 -10.5,4.701 -10.5,10.5 0,5.799 4.701,10.5 10.5,10.5 H 0 C 5.799,21 10.5,16.299 10.5,10.5 10.5,4.701 5.799,0 0,0 Z"
              style={style}
              id="path3453"
            />
          </g>
          <g id="g3455" transform="translate(491,49.5)">
            <path
              d="M 0,0 V 0 C -7.456,0 -13.5,6.044 -13.5,13.5 V 58 h 27 V 13.5 C 13.5,6.044 7.456,0 0,0 Z"
              style={style}
              id="path3457"
            />
          </g>
          <g id="g3459" transform="translate(491,107.5)">
            <path d="M 0,0 V 31" style={style} id="path3461" />
          </g>
          <g id="g3463" transform="translate(493.5,139.5)">
            <path
              d="m 0,0 h -5 c -5.799,0 -10.5,4.701 -10.5,10.5 0,5.799 4.701,10.5 10.5,10.5 H 0 C 5.799,21 10.5,16.299 10.5,10.5 10.5,4.701 5.799,0 0,0 Z"
              style={style}
              id="path3465"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export { RadiatorIcon };
