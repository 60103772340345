import { useMemo } from 'react';

import { useRouter } from 'next/router';

import { format } from 'date-fns';

import {
  ContractRenewalInteraction,
  ContractStatus,
  RenewalStatus,
} from '@client/types/__generated__/globalTypes';

import { getIsInRenewalPeriod } from '@client/utils/renewal';

import { useOptimizely } from '@client/modules/UserAnalytics/hooks';

import { CommonRoutes } from '@client/constants/routes';

import { getValueByKeyForNumberRanking } from '@client/utils/mtaQuotes';

import { GetCustomerByIdDetails } from '../../Customer/gql/fragments/__generated__/GetCustomerByIdDetails';
import { IPlan } from '../Plan/interfaces';

export function useRenewalNotifications({
  customer,
  isDirectDebitActive,
  planDetails,
  lastRenewalInteraction,
  pendingRenewalContractProductId,
  pendingRenewalContractProductName,
  isRenewalDiscountApplied,
}: {
  customer?: GetCustomerByIdDetails;
  isDirectDebitActive?: boolean;
  planDetails?: IPlan;
  lastRenewalInteraction?: ContractRenewalInteraction;
  pendingRenewalContractProductName?: string;
  pendingRenewalContractProductId?: string;
  isRenewalDiscountApplied?: boolean;
}) {
  const allowRenewalsDecision = useOptimizely('renewal-discount', true);
  const router = useRouter();

  const activeContract = useMemo(
    () =>
      customer?.contracts?.find(
        (contract) => contract?.contractStatus === ContractStatus.Active,
      ),
    [customer?.contracts],
  );

  const renewalDate =
    activeContract?.endDate &&
    format(new Date(activeContract.endDate), 'LLLL do');

  const isOnPlanPage = router.pathname === CommonRoutes.getPlanRoute();
  const isOnProfilePage = router.pathname === CommonRoutes.getProfileRoute();

  const displayRenewPlanCta = Boolean(
    lastRenewalInteraction?.renewalStatus != RenewalStatus.Accept &&
      allowRenewalsDecision.enabled &&
      (isOnPlanPage || isOnProfilePage) &&
      getIsInRenewalPeriod(activeContract?.endDate) &&
      isDirectDebitActive &&
      !isRenewalDiscountApplied &&
      planDetails?.autoRenew !== true,
  );

  const currentPlanId = planDetails?.planId;
  const currentPlanName = planDetails?.planName;

  const hasCoverChanged = currentPlanId !== pendingRenewalContractProductId;

  const getRenewalInterationText = () => {
    const currentPlanRankingValue =
      getValueByKeyForNumberRanking(currentPlanId) ?? 0;
    const selectedPlanRankingValue =
      getValueByKeyForNumberRanking(pendingRenewalContractProductId) ?? 0;

    return selectedPlanRankingValue > currentPlanRankingValue
      ? 'upgraded'
      : selectedPlanRankingValue < currentPlanRankingValue
      ? 'switched'
      : 'renewed';
  };

  const displayRenewalInteractionNotification = Boolean(
    lastRenewalInteraction?.renewalStatus == RenewalStatus.Accept &&
      allowRenewalsDecision.enabled &&
      isOnPlanPage &&
      getIsInRenewalPeriod(activeContract?.endDate) &&
      isDirectDebitActive,
  );

  const renewalInteractionNotificationText = hasCoverChanged
    ? `You've ${getRenewalInterationText()} to the ${pendingRenewalContractProductName} plan`
    : `You've ${getRenewalInterationText()} your ${currentPlanName} plan`;

  return {
    renewalDate,
    displayRenewPlanCta,
    displayRenewalInteractionNotification,
    renewalInteractionNotificationText,
  };
}
