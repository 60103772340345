import React from 'react';

const ProfileIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M36 36H0C0 26.1 8.1 18 18 18C27.9 18 36 26.1 36 36Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M18 15C22.1421 15 25.5 11.6421 25.5 7.5C25.5 3.35786 22.1421 0 18 0C13.8579 0 10.5 3.35786 10.5 7.5C10.5 11.6421 13.8579 15 18 15Z"
      fill="currentColor"
    />
  </svg>
);

export { ProfileIcon };
