import React from 'react';

const PlanIcon = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      opacity="0.3"
      d="M19.6 3.3L16.4 0H0V32.7H3.3V3.3H19.6Z"
      fill="currentColor"
    />
    <path
      d="M21.3 3.2998H3.3V35.9998H26.5C23.4 35.9998 20.7 34.6998 18.7 32.6998C16.7 30.6998 15.5 27.9998 15.5 24.9998C15.5 23.6998 15.7 22.3998 16.2 21.2998H8.3V17.9998H18C20 15.5998 22.9 14.0998 26.2 13.9998C26.3 13.9998 26.4 13.9998 26.5 13.9998C27.5 13.9998 28.5 14.1998 29.5 14.3998V11.3998L21.3 3.2998ZM14.7 24.4998V27.7998H8.2V24.4998H14.7Z"
      fill="currentColor"
    />
    <path
      d="M26 32L20 25.3L23 22.6L26 25.9L33 18L36 20.7L26 32Z"
      fill="currentColor"
    />
  </svg>
);

export { PlanIcon };
