import React from 'react';

const PoundIcon = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="23"
    height="32"
    viewBox="0 0 23 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M0.27265 14.6727H3.45356C2.54473 12.4847 2.09032 10.5862 2.09032 8.97738C2.09032 6.40322 2.95371 4.26345 4.68049 2.55807C6.40727 0.852691 8.54303 0 11.0878 0C14.2687 0 16.5862 0.852691 18.0403 2.55807C19.5248 4.26345 20.3276 6.27451 20.4487 8.59125L15.9955 9.41177C15.9652 7.67421 15.5107 6.37104 14.6322 5.50226C13.784 4.60131 12.6328 4.15083 11.1786 4.15083C9.90628 4.15083 8.81568 4.58522 7.90685 5.454C6.99801 6.2906 6.5436 7.46506 6.5436 8.97738C6.5436 10.3288 7.04345 12.2273 8.04317 14.6727H16.8588V18.8235H9.22465C9.28524 19.4027 9.31554 19.8371 9.31554 20.1267C9.31554 21.7999 8.93686 23.3122 8.17949 24.6637C7.42213 26.0151 6.36183 27.0608 4.99858 27.8009H14.2232C15.6774 27.8009 16.768 27.3183 17.495 26.3529C18.2221 25.3555 18.5856 24.1327 18.5856 22.6848L22.8571 23.3605C22.8571 25.9025 22.1755 27.9779 20.8123 29.5867C19.4793 31.1956 17.6465 32 15.3138 32H0V27.4148C1.51472 26.7391 2.7265 25.7898 3.63533 24.5671C4.54416 23.3122 4.99858 22.0091 4.99858 20.6576C4.99858 19.9497 4.93799 19.3384 4.81681 18.8235H0.27265V14.6727Z"
      fill="#B6D727"
    />
  </svg>
);

export { PoundIcon };
