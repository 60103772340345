import React from 'react';

const ElectricsIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g
        id="019---Electricity-Meter"
        fill="rgb(0,0,0)"
        fillRule="nonzero"
        transform="translate(-1)"
      >
        <path
          id="Shape"
          d="m16.692 56.379c.4854583.263716 1.092784.0839583 1.3565-.4015s.0839583-1.092784-.4015-1.3565c-13.56837399-7.3633116-18.61716581-24.319404-11.28662344-37.9055101 7.33054234-13.58610617 24.27439984-18.67580751 37.87815904-11.37807712 13.6037591 7.29773042 18.7343403 24.22925432 11.4694644 37.85058722-.1686294.3151083-.1563411.696243.032236.9998344s.5247936.4835168.882.472.6811346-.2127261.849764-.5278344c7.7848791-14.5937948 2.2890384-32.7351927-12.2859764-40.55517575-14.5750148-7.81998302-32.7295883-2.36782357-40.58462998 12.18832675-7.85504164 14.5561502-2.44659507 32.7237942 12.09060638 40.613849z"
        />
        <path
          id="Shape"
          d="m53.651 40.651c.2445097.1033632.5200844.1052652.7659976.0052868.2459132-.0999785.4419828-.2936321.5450024-.5382868 5.4578371-12.9209325-.3143204-27.8421279-13.0467692-33.72627872-12.7324487-5.88415078-27.8371981-.61097119-34.14116433 11.91893272-6.30396624 12.5299039-1.53558802 27.8015261 10.77793353 34.518346.3138578.1709516.695076.1614762 1.0000533-.0248568.3049773-.1863331.4873805-.5212154.4785-.8785-.0088805-.3572847-.2076955-.6826916-.5215533-.8536432-11.3445169-6.2035108-15.72959244-20.2831033-9.91379012-31.8311791 5.81580232-11.54807573 19.73883172-16.40740314 31.47722092-10.98597996 11.7383892 5.42142316 17.066395 19.17192016 12.0455692 31.08715906-.2139236.5086564.0246039 1.0944585.533 1.309z"
        />
        <path
          id="Shape"
          d="m44 25c1.6568542 0 3-1.3431458 3-3v-2c0-1.6568542-1.3431458-3-3-3h-26c-1.6568542 0-3 1.3431458-3 3v2c0 1.6568542 1.3431458 3 3 3zm1-5v2c0 .5522847-.4477153 1-1 1h-3v-4h3c.5522847 0 1 .4477153 1 1zm-28 2v-2c0-.5522847.4477153-1 1-1h3v4h-3c-.5522847 0-1-.4477153-1-1zm6-3h4v4h-4zm6 0h4v4h-4zm6 0h4v4h-4z"
        />
        <path
          id="Shape"
          d="m47.72 31h3.28c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-3.28c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1z"
        />
        <path
          id="Shape"
          d="m34.28 30c0-.5522847-.4477153-1-1-1h-22.28c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h22.28c.5522847 0 1-.4477153 1-1z"
        />
        <path
          id="Shape"
          d="m16 34c-1.1045695 0-2 .8954305-2 2v6c0 1.1045695.8954305 2 2 2h3c1.1045695 0 2-.8954305 2-2v-6c0-1.1045695-.8954305-2-2-2zm0 8v-6h3v6z"
        />
        <path
          id="Shape"
          d="m29 37.59c.5522847 0 1-.4477153 1-1v-.59c0-1.1045695-.8954305-2-2-2h-3c-1.1045695 0-2 .8954305-2 2v7c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-7h3v.59c0 .5522847.4477153 1 1 1z"
        />
        <path
          id="Shape"
          d="m43.127 28.424c-.5783098-.8883618-1.5664864-1.424189-2.6265-1.424189s-2.0481902.5358272-2.6265 1.424189l-17.369 26.77c-.629106.9523788-.676888 2.1754438-.124 3.174.5476641 1.0074887 1.6032805 1.633949 2.75 1.632h34.738c1.1471117.0014613 2.2029401-.6252809 2.751-1.633.552938-.9981928.5051509-2.2210029-.124-3.173zm15.735 28.988c-.1954309.3658584-.578241.5925375-.993.588h-34.738c-.4141851.004359-.7964661-.2218497-.992-.587-.1994665-.3548578-.1824557-.7917268.044-1.13l17.369-26.77c.208992-.3215756.5664788-.5155949.95-.5155949s.741008.1940193.95.5155949l17.369 26.77c.2255622.3384625.2414177.7750679.041 1.129z"
        />
        <path
          id="Shape"
          d="m45.447 45.105-3.282-1.641 1.8-7.221c.1113688-.4454354-.0949046-.9094769-.5001912-1.1252512-.4052865-.2157742-.9054358-.1278318-1.2128088.2132512l-9 10c-.2088092.2325932-.2968503.5494433-.2379677.8564183.0588827.3069751.2579152.5687599.5379677.7075817l3.282 1.641-1.8 7.221c-.1113688.4454354.0949046.9094769.5001912 1.1252512.4052865.2157742.9054358.1278318 1.2128088-.2132512l9-10c.2088092-.2325932.2968503-.5494433.2379677-.8564183-.0588827-.3069751-.2579152-.5687599-.5379677-.7075817zm-7.5 7.239 1.026-4.1c.114207-.4550528-.1032966-.9283208-.523-1.138l-2.825-1.412 5.434-6.037-1.026 4.1c-.114207.4550528.1032966.9283208.523 1.138l2.825 1.412z"
        />
      </g>
    </g>
  </svg>
);

export { ElectricsIcon };
