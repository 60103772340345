import React from 'react';

const BackChevronIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.579177 8L8.57918 16L9.99989 14.5793L3.42066 8.00005L10 1.42071L8.57928 -3.8147e-06L1.99994 6.57933L1.99989 6.57928L0.579177 8Z"
      fill="#555C6B"
    />
  </svg>
);

export { BackChevronIcon };
