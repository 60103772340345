import React from 'react';

const CorgiLogo = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="69"
    height="48"
    viewBox="0 0 69 48"
    fill="none"
    data-test="CORGI Logo"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M0 32.9035H18.7178V0H0V32.9035Z" fill="white" />
    <path
      d="M0.345703 26.5868H18.3759V0.345215H0.345703V26.5868Z"
      fill="#F09003"
    />
    <path
      d="M0.345703 32.5582H18.3759V26.9199H0.345703V32.5582Z"
      fill="#010101"
    />
    <path
      d="M4.7058 23.7692L4.66913 24.5546L4.64468 24.5852C4.36502 24.7103 4.06175 24.7738 3.75539 24.7716C2.48411 24.7716 1.87598 23.8792 1.87598 22.8677C1.87598 21.9509 2.34659 20.8875 3.69122 20.8875C4.02191 20.8879 4.34918 20.9544 4.65385 21.083L4.67524 21.1075L4.62634 21.8379H4.5805C4.3744 21.668 4.11407 21.578 3.84707 21.5842C3.00057 21.5842 2.84472 22.3818 2.84472 22.8372C2.84472 23.5859 3.26033 24.0351 3.85318 24.0351C4.15287 24.0336 4.44218 23.9252 4.66913 23.7295"
      fill="white"
    />
    <path
      d="M8.01313 21.4528C7.92878 21.3348 7.82553 21.2315 7.70753 21.1472C7.40962 20.963 7.0645 20.8695 6.71434 20.8782C6.39452 20.8714 6.07846 20.9483 5.79756 21.1013C5.57925 21.2275 5.39258 21.4019 5.25172 21.611C5.11087 21.8202 5.01954 22.0587 4.98467 22.3084C4.95438 22.4861 4.93905 22.666 4.93883 22.8463C4.9211 23.1182 4.95883 23.3909 5.04971 23.6478C5.14059 23.9046 5.28273 24.1404 5.46751 24.3407C5.80201 24.6275 6.23107 24.78 6.67156 24.7685C7.07255 24.7852 7.46653 24.6596 7.78393 24.414C7.99247 24.2056 8.1547 23.9555 8.26002 23.6801C8.36534 23.4047 8.41137 23.1101 8.39512 22.8157C8.43313 22.3286 8.29545 21.844 8.00702 21.4497L8.01313 21.4528ZM7.18496 21.7859C7.28078 21.9106 7.34287 22.058 7.36526 22.2137C7.399 22.386 7.41843 22.5608 7.42333 22.7363C7.44472 23.5278 7.27664 24.0962 6.67462 24.0962C5.95035 24.0962 5.90451 23.2436 5.90451 22.7668C5.90451 22.1342 6.05426 21.8073 6.29873 21.6483C6.41461 21.5805 6.54644 21.5446 6.68073 21.5444C6.77743 21.5447 6.87286 21.5665 6.96008 21.6083C7.0473 21.65 7.12413 21.7107 7.18496 21.7859Z"
      fill="white"
    />
    <path
      d="M10.0847 23.2405C10.4331 23.8517 10.5095 24.0015 10.8457 24.6921L10.8854 24.7196C11.0474 24.7196 11.191 24.6982 11.3652 24.6921C11.5394 24.686 11.6922 24.6921 11.8511 24.6921L11.8847 24.6279C11.6544 24.3273 11.4441 24.0119 11.2552 23.6837C11.0871 23.4147 11.0046 23.2711 10.8762 23.0388C11.0866 22.9698 11.2689 22.8345 11.3962 22.6534C11.5234 22.4722 11.5886 22.2547 11.5822 22.0334C11.5938 21.8461 11.5545 21.6591 11.4685 21.4923C11.3824 21.3255 11.2527 21.1851 11.0932 21.0861C10.8212 20.9455 10.5431 20.9394 9.93501 20.9486H9.57135H8.93571L8.9082 20.9822C8.92959 21.5139 8.95099 22.0609 8.95099 23.4972C8.95099 23.9893 8.95099 24.2246 8.92959 24.6646L8.96015 24.6952C9.34215 24.6952 9.42466 24.6738 9.84944 24.6952L9.88306 24.6646C9.84027 24.0534 9.83416 23.8823 9.8311 23.2528L10.0847 23.2405ZM9.84027 21.5995C9.91362 21.5995 9.98696 21.5995 10.0572 21.5995C10.262 21.5995 10.6684 21.5995 10.6684 22.064C10.6748 22.1548 10.657 22.2457 10.6167 22.3273C10.5764 22.4089 10.5152 22.4783 10.4392 22.5285C10.2544 22.6155 10.05 22.6524 9.84638 22.6355"
      fill="white"
    />
    <path
      d="M15.2008 23.7694C15.2008 24.0108 15.2008 24.2553 15.2008 24.4967L15.1764 24.5395C14.7859 24.689 14.372 24.7676 13.954 24.7717C13.7186 24.7742 13.484 24.7433 13.2572 24.68C12.9036 24.5909 12.5904 24.3853 12.3679 24.0963C12.1161 23.7397 11.9883 23.3103 12.0043 22.874C12.0006 22.487 12.0919 22.105 12.2701 21.7616C12.4359 21.4752 12.6788 21.2412 12.9711 21.0861C13.2634 20.931 13.5933 20.8612 13.9234 20.8845C14.3059 20.881 14.6842 20.9646 15.0297 21.129L15.0572 21.1596C15.0144 21.4652 15.0052 21.5599 14.9777 21.8869L14.938 21.9022C14.6898 21.7021 14.3826 21.5894 14.064 21.5813C13.407 21.5813 12.9669 22.0366 12.9669 22.8678C12.9669 23.2651 13.1105 24.0444 13.9173 24.0444C14.0496 24.0421 14.1811 24.0225 14.3085 23.9863C14.3225 23.6119 14.3082 23.237 14.2657 22.8648L14.2932 22.8312H15.1764L15.2008 22.8617C15.2008 23.1307 15.2008 23.3965 15.2008 23.6593"
      fill="white"
    />
    <path
      d="M16.8293 20.9822C16.808 21.6973 16.808 22.4399 16.808 23.1672C16.808 23.8946 16.808 24.1757 16.8446 24.6585L16.8141 24.6922C16.3832 24.6922 16.2793 24.6922 15.8973 24.6922L15.8728 24.6616C15.8942 24.1115 15.9034 23.7906 15.9034 23.1672C15.9034 21.8226 15.8881 21.5537 15.8545 20.9822L15.8759 20.9517C16.2518 20.9517 16.3709 20.9517 16.8171 20.9517"
      fill="white"
    />
    <path
      d="M3.4193 30.8345V28.3898H2.8081V29.3371H2.17858V28.3867H1.56738V30.8315H2.17858V29.8658H2.81727V30.8345H3.42846"
      fill="white"
    />
    <path
      d="M5.32325 29.9118C5.34011 29.7859 5.33061 29.6578 5.29536 29.5358C5.26012 29.4137 5.19989 29.3003 5.1185 29.2028C5.04191 29.1237 4.94955 29.0617 4.84741 29.0206C4.74528 28.9795 4.63565 28.9604 4.52565 28.9644C4.41263 28.958 4.29955 28.9759 4.19408 29.017C4.08862 29.0582 3.99325 29.1215 3.91445 29.2028C3.78269 29.4158 3.71289 29.6613 3.71289 29.9118C3.71289 30.1622 3.78269 30.4078 3.91445 30.6208C3.9934 30.7016 4.08887 30.7645 4.19435 30.8052C4.29983 30.8458 4.41284 30.8631 4.52565 30.8561C4.63543 30.8605 4.74492 30.8418 4.84704 30.8013C4.94916 30.7608 5.04164 30.6993 5.1185 30.6208C5.19989 30.5232 5.26012 30.4098 5.29536 30.2878C5.33061 30.1657 5.34011 30.0377 5.32325 29.9118ZM4.7579 29.9118C4.7579 30.0921 4.7579 30.2174 4.67539 30.2968C4.65499 30.3146 4.63121 30.3281 4.60549 30.3365C4.57976 30.3449 4.55261 30.348 4.52565 30.3457C4.4982 30.3483 4.47051 30.3453 4.44426 30.3369C4.418 30.3285 4.39371 30.3148 4.37285 30.2968C4.29607 30.184 4.26245 30.0473 4.27811 29.9118C4.25802 29.7777 4.28735 29.6408 4.36062 29.5267C4.38082 29.5075 4.40494 29.4929 4.43132 29.484C4.4577 29.475 4.48571 29.4719 4.51342 29.4748C4.54065 29.4721 4.56814 29.4754 4.59399 29.4843C4.61984 29.4933 4.64344 29.5078 4.66316 29.5267C4.73651 29.597 4.74568 29.7284 4.74568 29.9118"
      fill="white"
    />
    <path
      d="M8.25089 30.8346V29.6428C8.2565 29.5454 8.24143 29.448 8.20667 29.3569C8.17191 29.2657 8.11824 29.183 8.0492 29.1141C7.92713 29.007 7.76836 28.9511 7.60608 28.9583C7.51028 28.9538 7.41457 28.9689 7.32475 29.0025C7.23492 29.0361 7.15286 29.0876 7.08351 29.1539C7.02884 29.0888 6.95982 29.0372 6.88188 29.0033C6.80393 28.9694 6.71918 28.954 6.63428 28.9583C6.47375 28.9565 6.31897 29.018 6.20339 29.1294V28.9766H5.65332V30.8255H6.21867V29.7284C6.21867 29.6684 6.2425 29.6109 6.28491 29.5685C6.32732 29.5261 6.38484 29.5022 6.44481 29.5022C6.50479 29.5022 6.56231 29.5261 6.60472 29.5685C6.64713 29.6109 6.67096 29.6684 6.67096 29.7284V30.8346H7.23631V29.7284C7.23631 29.6684 7.26013 29.6109 7.30254 29.5685C7.34495 29.5261 7.40247 29.5022 7.46245 29.5022C7.52243 29.5022 7.57995 29.5261 7.62236 29.5685C7.66477 29.6109 7.68859 29.6684 7.68859 29.7284V30.8346H8.25089Z"
      fill="white"
    />
    <path
      d="M10.1486 30.0921V29.8415C10.1582 29.728 10.1436 29.6137 10.1058 29.5062C10.068 29.3987 10.0079 29.3004 9.92932 29.2178C9.85079 29.1353 9.75566 29.0703 9.65019 29.0271C9.54472 28.984 9.43131 28.9636 9.31742 28.9675C8.83153 28.9675 8.48926 29.3067 8.48926 29.9118C8.48926 30.7094 8.95988 30.8561 9.36937 30.8561C9.50298 30.8618 9.63621 30.8383 9.75974 30.787C9.88327 30.7358 9.99411 30.6582 10.0845 30.5597L9.74831 30.2235C9.7011 30.2773 9.642 30.3194 9.5757 30.3465C9.5094 30.3735 9.43772 30.3848 9.36632 30.3794C9.32442 30.3823 9.28237 30.3766 9.24279 30.3625C9.20321 30.3485 9.16695 30.3265 9.13626 30.2978C9.10558 30.2691 9.08114 30.2344 9.06446 30.1959C9.04778 30.1573 9.03923 30.1158 9.03933 30.0738L10.1486 30.0921ZM9.59246 29.7193H9.04239C9.04097 29.6687 9.05144 29.6184 9.07295 29.5726C9.09408 29.5265 9.12886 29.488 9.17258 29.4623C9.21631 29.4366 9.26687 29.425 9.31742 29.429C9.36791 29.4254 9.41829 29.4372 9.46193 29.4629C9.50557 29.4885 9.54042 29.5268 9.5619 29.5726C9.58341 29.6184 9.59388 29.6687 9.59246 29.7193Z"
      fill="white"
    />
    <path
      d="M12.267 29.1752C12.2677 29.0673 12.2461 28.9604 12.2036 28.8612C12.1611 28.762 12.0986 28.6726 12.02 28.5986C11.9415 28.5246 11.8485 28.4676 11.7469 28.4312C11.6454 28.3947 11.5374 28.3796 11.4297 28.3867H10.4609V30.8315H11.0721V29.9483H11.4388C11.5464 29.9559 11.6543 29.9412 11.7559 29.9051C11.8574 29.869 11.9505 29.8123 12.0291 29.7385C12.1077 29.6648 12.1702 29.5756 12.2128 29.4766C12.2553 29.3775 12.2769 29.2707 12.2762 29.1629L12.267 29.1752ZM11.6558 29.1752C11.6554 29.2089 11.6483 29.2422 11.6347 29.273C11.6212 29.3039 11.6016 29.3317 11.5771 29.3548C11.5525 29.3779 11.5236 29.3959 11.4919 29.4075C11.4603 29.4192 11.4267 29.4243 11.393 29.4227H11.063V28.9276H11.4022C11.4358 28.926 11.4695 28.9312 11.5011 28.9428C11.5327 28.9545 11.5617 28.9724 11.5862 28.9955C11.6108 29.0186 11.6304 29.0464 11.6439 29.0773C11.6574 29.1082 11.6646 29.1415 11.665 29.1752"
      fill="white"
    />
    <path
      d="M13.3635 30.8345V30.3609H13.168C13.1526 30.3629 13.1371 30.3613 13.1224 30.3564C13.1078 30.3514 13.0945 30.3431 13.0836 30.3322C13.0726 30.3213 13.0644 30.308 13.0594 30.2933C13.0544 30.2787 13.0529 30.2631 13.0549 30.2478V28.3867H12.4895V30.2875C12.4871 30.3624 12.5006 30.4369 12.529 30.5062C12.5574 30.5755 12.6001 30.638 12.6543 30.6897C12.7086 30.7413 12.7731 30.7809 12.8437 30.8059C12.9143 30.8308 12.9894 30.8406 13.0641 30.8345H13.3696"
      fill="white"
    />
    <path
      d="M15.0778 30.8347V29.6428C15.0778 29.1906 14.7936 28.9705 14.2618 28.9705C14.1383 28.9579 14.0136 28.9737 13.8971 29.0166C13.7806 29.0595 13.6754 29.1284 13.5895 29.2181L13.941 29.5695C13.9779 29.5255 14.0251 29.4913 14.0784 29.47C14.1317 29.4487 14.1895 29.4409 14.2466 29.4473C14.4421 29.4473 14.5246 29.5084 14.5246 29.6765V29.7223H14.1396C13.6995 29.7223 13.5101 29.982 13.5101 30.2601C13.505 30.4204 13.5632 30.5763 13.672 30.6941C13.7927 30.8046 13.9516 30.8638 14.1151 30.8591C14.1921 30.8641 14.2693 30.8516 14.3407 30.8225C14.4122 30.7934 14.4761 30.7484 14.5277 30.691V30.8408L15.0778 30.8347ZM14.5155 30.141C14.5194 30.1741 14.5165 30.2078 14.507 30.2398C14.4976 30.2718 14.4817 30.3016 14.4605 30.3274C14.4305 30.3542 14.3949 30.3742 14.3564 30.3858C14.3179 30.3974 14.2772 30.4004 14.2374 30.3946C14.1121 30.3946 14.051 30.3243 14.051 30.2418C14.051 30.1593 14.1182 30.0921 14.2282 30.0921H14.5155V30.141Z"
      fill="white"
    />
    <path
      d="M17.0127 30.8346V29.6428C17.0213 29.5465 17.0091 29.4494 16.9769 29.3582C16.9448 29.267 16.8934 29.1838 16.8263 29.1141C16.771 29.0616 16.7057 29.0207 16.6343 28.994C16.5629 28.9672 16.4868 28.955 16.4107 28.9583C16.2501 28.9565 16.0953 29.018 15.9798 29.1294V28.9766H15.4297V30.8255H15.995V29.7284C15.995 29.6684 16.0189 29.6109 16.0613 29.5685C16.1037 29.5261 16.1612 29.5022 16.2212 29.5022C16.2812 29.5022 16.3387 29.5261 16.3811 29.5685C16.4235 29.6109 16.4473 29.6684 16.4473 29.7284V30.8346H17.0127Z"
      fill="white"
    />
    <path
      d="M18.5591 1.99866C17.8827 1.91708 17.202 1.87728 16.5208 1.87948C12.7008 1.87948 10.5525 3.16299 10.1216 3.40746C10.0543 3.44413 9.99629 3.48081 9.94434 3.51442C10.3905 3.26689 12.5297 2.05673 16.3405 2.05673C17.0196 2.05613 17.6982 2.09593 18.3727 2.17591"
      fill="white"
    />
    <path
      d="M16.3401 19.0049C16.3401 19.0049 10.2679 15.4203 9.47333 8.60853C9.31426 7.26383 9.2805 5.90726 9.37248 4.55632C9.40609 3.9054 9.49472 3.75566 9.84615 3.54785C9.44277 3.76788 9.33886 3.89623 9.2808 4.56243C9.13442 5.89616 9.11702 7.2409 9.22885 8.57797C9.77892 15.5364 16.01 19.4083 16.01 19.4083C16.9008 18.8083 17.7281 18.1192 18.4792 17.3516C17.8205 17.9706 17.1042 18.5252 16.3401 19.008"
      fill="#010101"
    />
    <path
      d="M16.5301 2.04443C12.4687 2.04443 10.4182 3.28821 9.95671 3.52352C9.49526 3.75883 9.40053 3.82912 9.34552 4.53504C9.23195 5.88665 9.25447 7.24625 9.41275 8.59336C10.2409 15.4387 16.2703 19.0448 16.2703 19.0448C16.9971 18.5807 17.6933 18.0703 18.3545 17.5168V2.16973C17.7433 2.11166 17.1168 2.04443 16.5209 2.04443H16.5301Z"
      fill="url(#paint0_radial_3315_144582)"
    />
    <path
      d="M44.2752 10.7417C45.6953 10.7193 47.086 11.1472 48.2479 11.9641L47.8415 10.9159H52.1535L53.6631 16.0897L53.9931 17.254C53.9931 17.254 54.1612 16.6428 54.3262 16.0897L55.8359 10.9159H60.1478L59.7353 11.9794C60.8997 11.1512 62.2976 10.7156 63.7264 10.7356C63.9372 10.7356 64.145 10.7356 64.3376 10.7601L62.122 2.47534L39.8135 8.45586L40.6875 11.7196C41.77 11.0684 43.0119 10.7299 44.2752 10.7417Z"
      fill="#109939"
    />
    <path
      d="M63.7232 14.4822C62.4122 14.4822 61.584 15.4906 61.584 16.6977C61.584 17.9965 62.458 18.8858 63.7232 18.8858C64.9883 18.8858 65.8623 17.9965 65.8623 16.6977C65.8623 16.5253 65.8449 16.3533 65.8104 16.1843L65.734 15.9032C65.6001 15.4792 65.331 15.1107 64.9678 14.8541C64.6047 14.5975 64.1675 14.4669 63.7232 14.4822Z"
      fill="#109939"
    />
    <path
      d="M46.3985 16.6978C46.3985 15.4754 45.5856 14.4822 44.2593 14.4822C42.933 14.4822 42.1201 15.4906 42.1201 16.6978C42.1201 17.9965 42.9941 18.8858 44.2593 18.8858C45.5245 18.8858 46.3985 17.9965 46.3985 16.6978Z"
      fill="#109939"
    />
    <path
      d="M63.7226 22.4735C60.6055 22.4735 58.011 20.7071 57.522 17.697L55.7404 22.3085H52.2566L50.4872 17.7245C49.983 20.7163 47.3976 22.4735 44.2897 22.4735C44.0452 22.4735 43.8038 22.4735 43.5654 22.4399L45.7993 30.7735L68.1079 24.793L67.2308 21.5873C66.1584 22.1829 64.9492 22.4883 63.7226 22.4735Z"
      fill="#109939"
    />
    <path
      d="M30.0279 2.47534H29.2578V30.7766H30.0279V2.47534Z"
      fill="#303031"
    />
    <path
      d="M3.05596 44.2075C2.93484 44.4179 2.75549 44.5887 2.53951 44.6995C2.2696 44.8311 1.97174 44.8951 1.67161 44.886H1.02986V46.4139H0V42.1723H1.66856C1.96341 42.1634 2.25631 42.223 2.52423 42.3464C2.74712 42.45 2.93276 42.6197 3.05596 42.8323C3.17454 43.0474 3.23461 43.2897 3.23015 43.5352C3.2314 43.7706 3.17137 44.0023 3.05596 44.2075ZM2.03222 43.9264C2.12369 43.8166 2.17378 43.6781 2.17378 43.5352C2.17378 43.3923 2.12369 43.2539 2.03222 43.144C1.90911 43.0425 1.75117 42.9932 1.59216 43.0065H1.02986V44.067H1.5891C1.74853 44.0795 1.90651 44.029 2.02916 43.9264H2.03222Z"
      fill="#1D1D1B"
    />
    <path
      d="M3.74953 43.8073C3.86561 43.5575 4.04994 43.3456 4.28127 43.1961C4.50766 43.0553 4.76954 42.9822 5.03609 42.9852C5.24841 42.9832 5.45807 43.0325 5.64728 43.1288C5.8152 43.2174 5.95732 43.348 6.05984 43.5078V43.0341H7.0897V46.3957H6.07206V45.922C5.9664 46.0813 5.82245 46.2116 5.6534 46.3009C5.46418 46.3972 5.25452 46.4465 5.0422 46.4445C4.77714 46.4476 4.51695 46.3732 4.29349 46.2306C4.06217 46.0811 3.87783 45.8692 3.76176 45.6194C3.63108 45.3315 3.56348 45.0189 3.56348 44.7026C3.56348 44.3864 3.63108 44.0738 3.76176 43.7859L3.74953 43.8073ZM5.85815 44.1128C5.79016 44.0436 5.70908 43.9887 5.61962 43.9511C5.53016 43.9136 5.43412 43.8943 5.3371 43.8943C5.24009 43.8943 5.14405 43.9136 5.05459 43.9511C4.96513 43.9887 4.88405 44.0436 4.81606 44.1128C4.74118 44.1951 4.68365 44.2917 4.64689 44.3967C4.61014 44.5017 4.59492 44.613 4.60215 44.724C4.5903 44.948 4.66716 45.1676 4.81606 45.3352C4.88177 45.4073 4.96212 45.4644 5.05171 45.5029C5.14131 45.5414 5.23809 45.5603 5.33558 45.5583C5.43318 45.5599 5.53006 45.5412 5.62003 45.5033C5.71 45.4655 5.7911 45.4092 5.85815 45.3383C5.99754 45.1651 6.07354 44.9494 6.07354 44.7271C6.07354 44.5048 5.99754 44.2891 5.85815 44.1159V44.1128Z"
      fill="#1D1D1B"
    />
    <path
      d="M9.3298 43.1655C9.51488 43.057 9.72647 43.002 9.94099 43.0066V44.0976H9.65679C9.45076 44.0821 9.24595 44.1406 9.07921 44.2626C9.00722 44.3398 8.95275 44.4317 8.91952 44.5319C8.88629 44.6321 8.87509 44.7383 8.88668 44.8433V46.4018H7.84766V43.0402H8.87752V43.5995C8.99278 43.4219 9.14758 43.2733 9.3298 43.1655Z"
      fill="#1D1D1B"
    />
    <path
      d="M12.2975 45.5338V46.4048H11.7749C11.4602 46.4235 11.1494 46.3268 10.9009 46.1328C10.7888 46.0142 10.7035 45.8728 10.6508 45.7183C10.5981 45.5638 10.5792 45.3998 10.5953 45.2374V43.902H10.1797V43.0463H10.5892V42.2273H11.6129V43.0463H12.2852V43.902H11.6129V45.2496C11.6088 45.2887 11.613 45.3282 11.6251 45.3656C11.6372 45.4029 11.657 45.4374 11.6832 45.4666C11.7514 45.5158 11.8347 45.5396 11.9185 45.5338H12.2975Z"
      fill="#1D1D1B"
    />
    <path
      d="M14.7879 46.2399C14.5302 46.0984 14.3182 45.8863 14.1767 45.6287C14.0261 45.3465 13.9473 45.0317 13.9473 44.7119C13.9473 44.3921 14.0261 44.0772 14.1767 43.7951C14.3224 43.5354 14.5399 43.3232 14.8031 43.1839C15.0801 43.0451 15.3857 42.9729 15.6955 42.9729C16.0053 42.9729 16.3108 43.0451 16.5878 43.1839C16.8461 43.3245 17.0584 43.5368 17.199 43.7951C17.3563 44.0746 17.4354 44.3912 17.4282 44.7119C17.4365 45.033 17.3561 45.3502 17.196 45.6287C17.049 45.8885 16.8305 46.1006 16.5664 46.2399C16.2898 46.3825 15.9823 46.4549 15.671 46.4507C15.3637 46.4547 15.0602 46.3822 14.7879 46.2399ZM16.1783 45.3414C16.2514 45.2582 16.3074 45.1613 16.343 45.0565C16.3787 44.9516 16.3933 44.8407 16.3861 44.7302C16.3982 44.5084 16.3261 44.2901 16.1844 44.119C16.1202 44.0515 16.0429 43.9978 15.9573 43.9611C15.8717 43.9243 15.7795 43.9054 15.6863 43.9054C15.5931 43.9054 15.5009 43.9243 15.4153 43.9611C15.3297 43.9978 15.2524 44.0515 15.1882 44.119C15.0476 44.2906 14.9767 44.5088 14.9896 44.7302C14.977 44.9511 15.0467 45.1688 15.1851 45.3414C15.2459 45.4118 15.3216 45.4678 15.4067 45.5054C15.4918 45.5429 15.5842 45.561 15.6771 45.5584C15.7713 45.5601 15.8648 45.5416 15.9512 45.5041C16.0377 45.4667 16.1151 45.4112 16.1783 45.3414Z"
      fill="#1D1D1B"
    />
    <path
      d="M19.6682 43.8989H19.1151V46.4017H18.0852V43.8989H17.7246V43.0432H18.0974V42.9454C18.0864 42.7724 18.1123 42.599 18.1734 42.4367C18.2345 42.2744 18.3295 42.127 18.4519 42.0042C18.7463 41.7693 19.1183 41.6536 19.494 41.6803H19.6651V42.5543C19.5208 42.5287 19.3721 42.5593 19.2495 42.6398C19.2033 42.6881 19.1684 42.7461 19.1473 42.8095C19.1261 42.8729 19.1193 42.9402 19.1273 43.0066V43.0371H19.6804L19.6682 43.8989Z"
      fill="#1D1D1B"
    />
    <path
      d="M23.3015 45.5338V46.4048H22.7759C22.4621 46.4236 22.1523 46.3268 21.9049 46.1328C21.7923 46.0145 21.7067 45.8732 21.654 45.7186C21.6012 45.564 21.5826 45.3999 21.5993 45.2374V43.902H21.1807V43.0463H21.5902V42.2273H22.6139V43.0463H23.2893V43.902H22.6139V45.2496C22.6102 45.2889 22.6149 45.3286 22.6275 45.3659C22.6401 45.4033 22.6605 45.4376 22.6873 45.4666C22.7571 45.5172 22.8428 45.541 22.9287 45.5338H23.3015Z"
      fill="#1D1D1B"
    />
    <path
      d="M26.8679 43.3946C27.1138 43.6877 27.2384 44.0638 27.2163 44.4458V46.4078H26.1925V44.5742C26.2046 44.384 26.1421 44.1965 26.0183 44.0516C25.8909 43.9319 25.7226 43.8653 25.5477 43.8653C25.3729 43.8653 25.2046 43.9319 25.0771 44.0516C24.9533 44.1965 24.8909 44.384 24.9029 44.5742V46.4078H23.873V41.946H24.9029V43.4924C25.0134 43.3401 25.1606 43.2182 25.3307 43.1379C25.5215 43.0471 25.7308 43.0021 25.9419 43.0065C26.115 43.0019 26.287 43.0342 26.4466 43.1011C26.6062 43.168 26.7498 43.268 26.8679 43.3946Z"
      fill="#1D1D1B"
    />
    <path
      d="M31.0821 44.9684H28.7504C28.7552 45.148 28.8272 45.3191 28.952 45.4482C29.0701 45.5589 29.2273 45.6183 29.389 45.6133C29.5012 45.6215 29.6131 45.5947 29.7094 45.5367C29.8057 45.4787 29.8817 45.3923 29.9269 45.2893H31.0209C30.9677 45.514 30.8631 45.7232 30.7153 45.9005C30.566 46.0791 30.3779 46.2213 30.1653 46.3161C29.9313 46.4187 29.6781 46.4698 29.4227 46.4659C29.1161 46.4715 28.8132 46.399 28.5425 46.255C28.2842 46.1143 28.072 45.9021 27.9314 45.6438C27.7816 45.3619 27.7069 45.0462 27.7144 44.727C27.7076 44.4083 27.7811 44.093 27.9283 43.8102C28.0674 43.5507 28.28 43.3381 28.5395 43.199C28.8125 43.0553 29.1173 42.9828 29.4257 42.9882C29.7277 42.9819 30.0263 43.0523 30.2936 43.1929C30.5432 43.3265 30.749 43.5291 30.8865 43.7766C31.0342 44.0482 31.108 44.3538 31.1004 44.6628C31.1003 44.765 31.0942 44.867 31.0821 44.9684ZM30.0461 44.397C30.0494 44.3177 30.0349 44.2387 30.0037 44.1657C29.9725 44.0927 29.9254 44.0276 29.8658 43.9753C29.7395 43.8691 29.5784 43.8136 29.4135 43.8194C29.2545 43.8145 29.0991 43.8677 28.9765 43.9691C28.8547 44.0811 28.7767 44.2328 28.7565 44.397H30.0461Z"
      fill="#1D1D1B"
    />
    <path
      d="M33.8269 46.1664C33.4973 45.987 33.2242 45.7192 33.0384 45.3932C32.8462 45.0529 32.7451 44.6686 32.7451 44.2778C32.7451 43.8869 32.8462 43.5027 33.0384 43.1623C33.2253 42.8379 33.4981 42.5714 33.8269 42.3922C34.1621 42.2098 34.5377 42.1143 34.9194 42.1143C35.301 42.1143 35.6766 42.2098 36.0119 42.3922C36.3395 42.5708 36.6106 42.8376 36.7942 43.1623C36.9837 43.5036 37.0831 43.8875 37.0831 44.2778C37.0831 44.6681 36.9837 45.0519 36.7942 45.3932C36.6098 45.7182 36.3391 45.9858 36.0119 46.1664C35.6772 46.3488 35.3021 46.4443 34.9209 46.4443C34.5397 46.4443 34.1646 46.3488 33.8299 46.1664H33.8269ZM35.7307 45.1671C35.927 44.911 36.0334 44.5973 36.0334 44.2747C36.0334 43.9521 35.927 43.6384 35.7307 43.3824C35.6275 43.2712 35.5014 43.1838 35.361 43.1263C35.2206 43.0687 35.0694 43.0425 34.9179 43.0493C34.7661 43.0427 34.6148 43.0687 34.474 43.1256C34.3333 43.1826 34.2064 43.2691 34.1019 43.3793C33.9056 43.6365 33.7993 43.9511 33.7993 44.2747C33.7993 44.5983 33.9056 44.9129 34.1019 45.1701C34.2056 45.2818 34.3322 45.3696 34.473 45.4277C34.6139 45.4857 34.7656 45.5125 34.9179 45.5063C35.0699 45.5125 35.2215 45.4854 35.3619 45.4268C35.5023 45.3682 35.6282 45.2795 35.7307 45.1671Z"
      fill="#1D1D1B"
    />
    <path
      d="M41.6225 42.1724L40.122 46.3988H38.8324L37.335 42.1724H38.429L39.4772 45.3628L40.5315 42.1724H41.6225Z"
      fill="#1D1D1B"
    />
    <path
      d="M42.9546 46.1663C42.6263 45.9852 42.3537 45.7179 42.1662 45.3932C41.9759 45.0522 41.876 44.6682 41.876 44.2777C41.876 43.8873 41.9759 43.5033 42.1662 43.1623C42.3546 42.8392 42.6271 42.573 42.9546 42.3922C43.2904 42.2098 43.6665 42.1143 44.0486 42.1143C44.4308 42.1143 44.8069 42.2098 45.1427 42.3922C45.4696 42.5717 45.7404 42.8382 45.925 43.1623C46.1144 43.5036 46.2138 43.8874 46.2138 44.2777C46.2138 44.6681 46.1144 45.0519 45.925 45.3932C45.7406 45.7182 45.4698 45.9858 45.1427 46.1663C44.8074 46.3487 44.4318 46.4443 44.0502 46.4443C43.6685 46.4443 43.2929 46.3487 42.9576 46.1663H42.9546ZM44.8585 45.167C45.0567 44.9118 45.1643 44.5979 45.1643 44.2747C45.1643 43.9515 45.0567 43.6376 44.8585 43.3824C44.756 43.2711 44.6304 43.1836 44.4905 43.126C44.3506 43.0685 44.1997 43.0423 44.0486 43.0493C43.8969 43.042 43.7453 43.0678 43.6044 43.1248C43.4636 43.1817 43.3368 43.2686 43.2327 43.3793C43.0364 43.6365 42.9301 43.9511 42.9301 44.2747C42.9301 44.5983 43.0364 44.9129 43.2327 45.1701C43.3359 45.2822 43.4625 45.3704 43.6034 45.4285C43.7444 45.4866 43.8963 45.5131 44.0486 45.5062C44.2003 45.5129 44.3515 45.4859 44.4915 45.4273C44.6315 45.3686 44.7568 45.2798 44.8585 45.167Z"
      fill="#1D1D1B"
    />
    <path
      d="M50.8119 42.1724V42.9975H49.0914V43.8898H50.3779V44.6905H49.0914V46.3988H48.0615V42.1724H50.8119Z"
      fill="#1D1D1B"
    />
    <path
      d="M51.3398 43.8073C51.4551 43.5569 51.6396 43.3448 51.8716 43.1961C52.097 43.0557 52.3578 42.9826 52.6234 42.9852C52.8357 42.9826 53.0455 43.0319 53.2345 43.1288C53.4036 43.2158 53.5462 43.3467 53.6471 43.5078V43.0341H54.7014V46.3957H53.6715V45.922C53.5674 46.0827 53.4231 46.2133 53.2529 46.3009C53.0639 46.3978 52.8541 46.4471 52.6417 46.4446C52.3776 46.4472 52.1185 46.3728 51.896 46.2306C51.6639 46.082 51.4794 45.8699 51.3643 45.6194C51.2336 45.3315 51.166 45.0189 51.166 44.7027C51.166 44.3864 51.2336 44.0738 51.3643 43.7859L51.3398 43.8073ZM53.4454 44.1129C53.3791 44.0418 53.2985 43.9855 53.209 43.9476C53.1195 43.9097 53.0231 43.8911 52.9259 43.8928C52.829 43.891 52.7328 43.9094 52.6434 43.9468C52.5539 43.9841 52.4732 44.0396 52.4064 44.1098C52.3315 44.1921 52.274 44.2886 52.2372 44.3936C52.2005 44.4986 52.1852 44.61 52.1925 44.721C52.1806 44.9449 52.2575 45.1645 52.4064 45.3322C52.4721 45.4042 52.5524 45.4614 52.642 45.4999C52.7316 45.5383 52.8284 45.5572 52.9259 45.5553C53.0231 45.557 53.1195 45.5384 53.209 45.5005C53.2985 45.4625 53.3791 45.4063 53.4454 45.3352C53.5848 45.162 53.6608 44.9464 53.6608 44.724C53.6608 44.5017 53.5848 44.2861 53.4454 44.1129Z"
      fill="#1D1D1B"
    />
    <path
      d="M60.7283 43.3946C60.8569 43.537 60.9557 43.7036 61.0192 43.8846C61.0827 44.0656 61.1095 44.2574 61.0981 44.4489V46.4108H60.0744V44.5773C60.0809 44.4865 60.0691 44.3954 60.0397 44.3093C60.0103 44.2232 59.9639 44.1439 59.9032 44.0761C59.7724 43.962 59.6047 43.8991 59.4311 43.8991C59.2575 43.8991 59.0898 43.962 58.9589 44.0761C58.8983 44.1439 58.8519 44.2232 58.8225 44.3093C58.7931 44.3954 58.7813 44.4865 58.7878 44.5773V46.4108H57.758V44.5773C57.7639 44.4863 57.7516 44.3951 57.7216 44.309C57.6917 44.2229 57.6448 44.1437 57.5838 44.0761C57.4529 43.962 57.2852 43.8991 57.1116 43.8991C56.938 43.8991 56.7703 43.962 56.6395 44.0761C56.5794 44.1443 56.5333 44.2236 56.504 44.3096C56.4746 44.3956 56.4625 44.4866 56.4683 44.5773V46.4108H55.4385V43.0493H56.4683V43.471C56.5757 43.3275 56.7162 43.2122 56.8778 43.1349C57.0563 43.0505 57.2519 43.0087 57.4493 43.0126C57.6854 43.0089 57.9187 43.0646 58.1277 43.1746C58.3245 43.2818 58.4867 43.443 58.5953 43.6391C58.7127 43.4509 58.8756 43.2954 59.069 43.1868C59.2686 43.0713 59.4954 43.0112 59.726 43.0126C59.9105 43.0028 60.095 43.0317 60.2676 43.0975C60.4402 43.1633 60.5972 43.2645 60.7283 43.3946Z"
      fill="#1D1D1B"
    />
    <path
      d="M61.8956 42.5329C61.8416 42.4817 61.7986 42.4202 61.7692 42.3518C61.7398 42.2835 61.7246 42.21 61.7246 42.1356C61.7246 42.0612 61.7398 41.9876 61.7692 41.9193C61.7986 41.851 61.8416 41.7894 61.8956 41.7383C61.9553 41.6839 62.0252 41.6418 62.1012 41.6145C62.1773 41.5872 62.258 41.5753 62.3387 41.5794C62.4996 41.5727 62.6567 41.6298 62.7757 41.7383C62.8297 41.7894 62.8727 41.851 62.9021 41.9193C62.9315 41.9876 62.9467 42.0612 62.9467 42.1356C62.9467 42.21 62.9315 42.2835 62.9021 42.3518C62.8727 42.4202 62.8297 42.4817 62.7757 42.5329C62.6567 42.6413 62.4996 42.6985 62.3387 42.6918C62.258 42.6959 62.1773 42.684 62.1012 42.6567C62.0252 42.6294 61.9553 42.5873 61.8956 42.5329ZM62.8521 43.0432V46.4048H61.8223V43.0432H62.8521Z"
      fill="#1D1D1B"
    />
    <path d="M64.6275 41.946V46.4016H63.5977V41.946H64.6275Z" fill="#1D1D1B" />
    <path
      d="M68.808 43.0432L66.6994 48H65.5931L66.3632 46.2886L64.9941 43.0432H66.1462L66.9225 45.1427L67.6926 43.0432H68.808Z"
      fill="#1D1D1B"
    />
    <defs>
      <radialGradient
        id="paint0_radial_3315_144582"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.0396 1.58534) scale(15.3938 15.392)"
      >
        <stop stopColor="#FBC98C" />
        <stop offset="1" stopColor="#F09003" />
      </radialGradient>
    </defs>
  </svg>
);

export { CorgiLogo };
