export const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

export const CORGI_PHONE_NUMBER = '0330 102 8256';
export const OVO_PHONE_NUMBER = '0333 344 1417';

// taken from Aurora repo
export enum BRAND {
  CORGI = 1,
  // SSE = 2,
  OVO = 3,
  OVOEnergy = 4,
}
