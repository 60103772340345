import { CTAButton, Card, Heading1, Stack } from '@ovotech/element';

import React from 'react';

import { ICardLayoutProps } from './interfaces';

const CardLayout: React.FC<ICardLayoutProps> = ({
  heading,
  subtitle,
  children,
  afterCard,
  beforeCard,
  onNext,
  onBack,
  nextButtonText,
  className,
  buttonsDisabled = false,
}: ICardLayoutProps) => (
  <>
    <Stack spaceBetween={4}>
      <Heading1>{heading}</Heading1>
      {subtitle}
      {beforeCard}
      <Card className={className}>{children}</Card>
      {afterCard}
      {onBack && (
        <>
          <CTAButton
            fullWidth="always"
            onClick={onNext}
            disabled={buttonsDisabled}
          >
            {nextButtonText ?? 'Next'}
          </CTAButton>
          <CTAButton
            variant="secondary"
            fullWidth="always"
            onClick={onBack}
            disabled={buttonsDisabled}
          >
            Back
          </CTAButton>
        </>
      )}
      {!onBack && onNext && (
        <CTAButton
          fullWidth="always"
          onClick={onNext}
          disabled={buttonsDisabled}
        >
          {nextButtonText ?? 'Next'}
        </CTAButton>
      )}
    </Stack>
  </>
);

export { CardLayout };
