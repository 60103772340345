import React from 'react';

import { StyledMenuList } from '../../components/Navbar/MobileMenu/styles';

import { IProfileMenuListProps } from './interfaces';

import { ProfileMenuItem } from './ProfileMenuItem';

export const ProfileMenuList: React.FC<IProfileMenuListProps> = ({ links }) => {
  return (
    <div>
      <StyledMenuList showBullets={false}>
        {links.map((props, idx) => (
          <ProfileMenuItem key={`profile-menu-link-${idx}`} {...props} />
        ))}
      </StyledMenuList>
    </div>
  );
};
