import { IMobileMenuButton } from '../interfaces';

import {
  StyledMobileMenuButtonBottom,
  StyledMobileMenuButtonMiddle,
  StyledMobileMenuButtonTop,
  StyledMobileMenuButtonWrapper,
} from './styles';

export const MobileMenuButton: React.FC<IMobileMenuButton> = ({
  isOpen,
  onClick,
  ...rest
}) => {
  return (
    <StyledMobileMenuButtonWrapper onClick={onClick} {...rest}>
      <StyledMobileMenuButtonTop isOpen={isOpen} />
      <StyledMobileMenuButtonMiddle isOpen={isOpen} />
      <StyledMobileMenuButtonBottom isOpen={isOpen} />
    </StyledMobileMenuButtonWrapper>
  );
};
