import { CalloutType } from '../../../../src/client/types/__generated__/globalTypes';

import { GeneralProblemsType, IGeneralProblem } from './interfaces';

export enum CustomerPlanTypes {
  ESSENTIALS = 'ESSENTIALS',
  STARTER = 'STARTER',
  ADVANCED = 'ADVANCED',
  COMPLETE = 'COMPLETE',
}
export const enum SUB_TITLE {
  TELL_US_ABOUT_YOUR_ISSUE = 'Tell us about your issue',
  CHOOSE_ONE_OF_OPTIONS = 'Choose one from the list below',
}

export const ADDITIONAL_INFORMATION_LABEL = 'Additional information';

export const GENERAL_PROBLEMS_CORE = <Array<IGeneralProblem>>[
  {
    key: GeneralProblemsType.WATER_LEAKING,
    icon: 'waterLeaking',
    title: 'Water Leaking',
    coveredBySections: [2, 4],
    excessAmount: 0,
    emailLabel: 'water leak',
  },
  {
    key: GeneralProblemsType.INTERNAL_DRAINS,
    icon: 'internalDrains',
    title: 'Internal Drains',
    coveredBySections: [5],
    excessAmount: 0,
  },
  {
    key: GeneralProblemsType.EXTERNAL_DRAINS,
    icon: 'externalDrains',
    title: 'External Drains',
    coveredBySections: [6],
    excessAmount: 0,
  },
  {
    key: GeneralProblemsType.ELECTRICS,
    icon: 'electrics',
    title: 'Electrics',
    coveredBySections: [4],
    excessAmount: 0,
  },
  {
    key: GeneralProblemsType.TAPS_AND_TOILETS,
    icon: 'tapsAndToilets',
    title: 'Taps & Toilets',
    coveredBySections: [9],
    excessAmount: 0,
  },
  {
    key: GeneralProblemsType.SOMETHING_ELSE,
    icon: 'questionMark',
    title: 'Something else',
    excessAmount: 0,
    emailLabel: 'boiler',
  },
];

// used as a backup incase a Variation of the something else AB Test cannot be determined
export const GENERAL_PROBLEMS = <Array<IGeneralProblem>>[
  {
    key: GeneralProblemsType.BOILER,
    icon: 'boiler',
    title: 'Boiler & Central Heating',
    coveredBySections: [1, 2],
    excessAmount: 0,
    emailLabel: 'boiler',
  },
  {
    key: GeneralProblemsType.WATER_LEAKING,
    icon: 'waterLeaking',
    title: 'Water Leaking',
    coveredBySections: [2, 4],
    excessAmount: 0,
    emailLabel: 'water leak',
  },
  {
    key: GeneralProblemsType.INTERNAL_DRAINS,
    icon: 'internalDrains',
    title: 'Internal Drains',
    coveredBySections: [5],
    excessAmount: 0,
    emailLabel: 'internal drains',
  },
  {
    key: GeneralProblemsType.EXTERNAL_DRAINS,
    icon: 'externalDrains',
    title: 'External Drains',
    coveredBySections: [6],
    excessAmount: 0,
    emailLabel: 'external drains',
  },
  {
    key: GeneralProblemsType.ELECTRICS,
    icon: 'electrics',
    title: 'Electrics',
    coveredBySections: [4],
    excessAmount: 0,
  },
  {
    key: GeneralProblemsType.TAPS_AND_TOILETS,
    icon: 'tapsAndToilets',
    title: 'Taps & Toilets',
    coveredBySections: [9],
    excessAmount: 0,
  },
  {
    key: GeneralProblemsType.SOMETHING_ELSE,
    icon: 'questionMark',
    title: 'Something else',
    excessAmount: 0,
    emailLabel: 'boiler',
  },
];

export const GENERAL_PROBLEM_SESSION_STORAGE_KEY = 'generalProblem';
export const CUSTOMER_HAS_EXCESS_SESSION_STORAGE_KEY = 'isCustomerHasExcess';
export const EXCESS_AMOUNT_SESSION_STORAGE_KEY = 'excessAmount';

export const GENERAL_PROBLEM_MIXPANEL_EVENTS = {
  GENERAL_PROBLEM_SUBMIT_ERROR: 'categorise.problem.error.page.load',
  GENERAL_PROBLEM_SUBMIT: 'categorise.problem.next.select',
};

export const WATER_LEAK_MODAL_YES_EVENT =
  'categorise.problem.popup.leak.yes.select';

export enum PROBLEM_MODALS_EVENTS {
  MODAL_PHONE_NUMBER_CLICK_EVENT = 'categorise.problem.popup.number.select',
  WATER_LEAK_MODAL_PHONE_NUMBER_CLICK_EVENT = 'categorise.problem.popup.leak.number.select',
}

export const SESSION_EXPIRED_STORAGE_KEY = 'sessionExpired';

export const GeneralProblemCalloutTypeEnumMap: Record<
  GeneralProblemsType,
  CalloutType
> = {
  [GeneralProblemsType.BOILER]: CalloutType.Heating,
  [GeneralProblemsType.WATER_LEAKING]: CalloutType.Plumbing,
  [GeneralProblemsType.INTERNAL_DRAINS]: CalloutType.Drainage,
  [GeneralProblemsType.EXTERNAL_DRAINS]: CalloutType.Drainage,
  [GeneralProblemsType.ELECTRICS]: CalloutType.Electrical,
  [GeneralProblemsType.TAPS_AND_TOILETS]: CalloutType.TapsAndToilets,
  [GeneralProblemsType.SOMETHING_ELSE]: CalloutType.Unknown,
};
