import React from 'react';

const OvoLogo = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 534 534"
    data-test="OVOEnergy Logo"
    {...rest}
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          style={{ fill: '#109939' }}
          d="M120,177.8c22.84,0,43.9,6.69,60.42,18.71l-6.18-15.95H239.9l23,78.71,5,17.75s2.56-9.25,5.08-17.75l22.95-78.71h65.64l-6.27,16.17C371.84,184.57,393,177.8,416,177.8c3.19,0,6.34.14,9.46.4L391.65,52,52,143l13.31,49.68C80.86,183.09,99.73,177.8,120,177.8Z"
        />
        <path
          className="cls-1"
          style={{ fill: '#109939' }}
          d="M416,234.72c-20,0-32.35,15.38-32.35,33.73,0,19.74,13.31,33.28,32.35,33.28s32.36-13.54,32.36-33.28a38.72,38.72,0,0,0-.82-7.83l-1.14-4.26C442.21,243.82,431.36,234.72,416,234.72Z"
        />
        <path
          className="cls-1"
          style={{ fill: '#109939' }}
          d="M152.38,268.45c0-18.35-12.39-33.73-32.35-33.73S87.66,250.1,87.66,268.45c0,19.74,13.32,33.28,32.37,33.28S152.38,288.19,152.38,268.45Z"
        />
        <path
          className="cls-1"
          style={{ fill: '#109939' }}
          d="M416,356.35c-47.46,0-86.91-26.91-94.39-72.71l-27.1,69.95H241.28l-26.93-69.54c-7.65,45.54-47,72.3-94.32,72.3-3.73,0-7.39-.18-11-.51l34,126.82,339.66-91-13.08-48.82C454.29,351.65,435.85,356.35,416,356.35Z"
        />
        <rect
          className="cls-2"
          style={{ fill: 'none' }}
          width="534.67"
          height="534.67"
        />
      </g>
    </g>
  </svg>
);

export { OvoLogo };
