import { IMobileMenuContainer } from '../interfaces';

import { StyledMobileMenuContainer } from './styles';

export const MobileMenuContainer: React.FC<IMobileMenuContainer> = ({
  children,
  ...rest
}) => {
  return (
    <StyledMobileMenuContainer {...rest}>{children}</StyledMobileMenuContainer>
  );
};
