export const WELCOME_TITLE = 'Let’s try these quick fixes?';
export const WELCOME_SUBTITLE =
  'Lots of problems can be sorted quickly without needing an engineer.';
export const WELCOME_ADVICE_TITLE = 'Find a quick fix';
export const WELCOME_ADVICE_SUBTITLE =
  'Our handy videos and step-by-step guides could help you sort this right now.';

export const WELCOME_ADVICE_LINKS: Record<string, string> = {
  CORGI: `https://www.corgihomeplan.co.uk/advice`,
  // SSE: ``,
  OVO: `https://www.ovoenergy.com/guides/energy-guides/what-to-do-if-your-boiler-breaks-down`,
  OVOEnergy: `https://www.ovoenergy.com/guides/energy-guides/what-to-do-if-your-boiler-breaks-down`,
};

export const WELCOME_RAISE_CALLOUT_TITLE = 'Still need an engineer?';
export const WELCOME_RAISE_CALLOUT_SUBTITLE =
  'Click on the link below and continue to raise your claim.';

export enum WELCOME_MIXPANEL_EVENTS {
  ADIVCE_LINK = 'quick.fixes.advice.link.click',
  NEXT_BUTTON = 'self.help.page.next.btn.select',
}

export const LOGIN_TOAST_DISPLAYED_KEY = 'loginToastDisplayed';
