import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  BRAND,
  CORGI_PHONE_NUMBER,
  OVO_PHONE_NUMBER,
} from '../../constants/general';

import { LOGIN_SESSION_STORAGE_KEYS } from '../../modules/Login/constants';

import { selectCurrentUser } from '../../redux/auth/selectors';
import { useAppSelector } from '../useAppSelector';

import { BrandInfo } from './interfaces';

export const brandInfo: Record<string, BrandInfo> = {
  OVO: {
    id: BRAND.OVO,
    templateCollection: 'ohp',
    contactUsLink: `https://www.ovoenergy.com/help`,
    brand: {
      name: 'OVO HomePlan',
      phone: OVO_PHONE_NUMBER,
      email: 'no.reply@ovoenergy.com',
      legalentity: {
        name: 'OVO Energy Ltd',
        fcareferenceno: '824122',
        companyno: '06890795',
        address: '1 Rivergate, Temple Quay, Bristol, BS1 6ED',
        registrationcountry: 'England and Wales',
      },
    },
  },
  OVOEnergy: {
    id: BRAND.OVOEnergy,
    templateCollection: 'ohs',
    contactUsLink: `https://www.ovoenergy.com/help`,
    brand: {
      name: 'OVO Energy',
      phone: OVO_PHONE_NUMBER,
      email: 'no.reply@ovoenergy.com',
      legalentity: {
        name: 'CORGI HomePlan Ltd',
        fcareferenceno: '824122',
        companyno: 'SC358475',
        address: 'Cadworks, 41 West Campbell Street, Glasgow, G2 6SE',
        registrationcountry: 'Scotland',
      },
    },
  },
  CORGI: {
    id: BRAND.CORGI,
    templateCollection: 'corgi',
    contactUsLink: `https://www.corgihomeplan.co.uk/contact-us`,
    brand: {
      name: 'CORGI HomePlan',
      phone: CORGI_PHONE_NUMBER,
      email: 'noreply@corgihomeplan.co.uk',
      legalentity: {
        name: 'CORGI HomePlan Ltd',
        fcareferenceno: '824122',
        companyno: 'SC358475',
        address: 'Cadworks, 41 West Campbell Street, Glasgow, G2 6SE',
        registrationcountry: 'Scotland',
      },
    },
  },
};

const getBrandFromHostname = (): number => {
  const hostname = window.location.hostname;

  if (hostname.includes('corgihomeplan')) {
    return BRAND.CORGI;
  }

  return BRAND.OVOEnergy;
};

const getBrandFromSessionStorage = (): number | null => {
  const brand = sessionStorage.getItem(LOGIN_SESSION_STORAGE_KEYS.BRAND);

  if (brand) {
    return Number(brand);
  }
  return null;
};

export const useBrand = (): {
  setBrandId: Dispatch<SetStateAction<number>>;
} & BrandInfo => {
  const [isActualBrandUploaded, setIsActualBrandUploaded] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const defaultBrandId = BRAND.CORGI;
  const [activeBrandId, setActiveBrandId] = useState(defaultBrandId);
  const brandName = BRAND[activeBrandId];
  const { id, brand, templateCollection, contactUsLink } = brandInfo[brandName];
  const cutomerBrandId = currentUser?.attributes['custom:brandId'];

  useEffect(() => {
    const sessionBrand = getBrandFromSessionStorage();

    if (sessionBrand) {
      setActiveBrandId(sessionBrand);
      setIsActualBrandUploaded(true);
      return;
    }

    if (cutomerBrandId) {
      setActiveBrandId(cutomerBrandId);
    } else {
      setActiveBrandId(getBrandFromHostname());
    }

    setIsActualBrandUploaded(true);
  }, [cutomerBrandId]);

  return {
    id,
    brand,
    templateCollection,
    contactUsLink,
    setBrandId: setActiveBrandId,
    isActualBrandUploaded,
  };
};
