import React, { Children, ReactElement, cloneElement } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { INavbarLinkProps } from './interfaces';
import { SetRouteParams } from './constant';

export const NavbarLink: React.FC<INavbarLinkProps> = ({
  children,
  href,
  onClick,
  className,
  ...rest
}) => {
  const { route, query } = useRouter();

  const child = Children.only(children) as ReactElement;

  const url = SetRouteParams(route, query);

  const isActive = url === href;

  const childClassName = `${className}${isActive ? ` active` : ``}`;

  return (
    <Link {...rest} href={href} passHref legacyBehavior>
      {cloneElement(child, {
        onClick,
        className: childClassName,
        href,
      })}
    </Link>
  );
};
