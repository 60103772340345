import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { AppProps } from 'next/app';
import 'react-toastify/dist/ReactToastify.css';
import '@ovotech/element/dist/reset.css';

import { Layout } from '../../components';
import { store } from '../../redux/config';

import Auth from '../Auth';
import { UserAnalytics } from '../UserAnalytics';

import BrandThemeProvider from '../BrandThemeProvider';

import { GlobalStyles } from './styles';

const Application: React.FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <ReduxProvider store={store}>
      <BrandThemeProvider>
        <Auth>
          <UserAnalytics>
            <GlobalStyles />
            <Layout {...pageProps}>
              <Component {...pageProps} />
            </Layout>
            <ToastContainer />
          </UserAnalytics>
        </Auth>
      </BrandThemeProvider>
    </ReduxProvider>
  );
};

export { Application };
