import React from 'react';

export const HeaderTickIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M32.0897 0L0.5 8.41026L9.01282 40L40.6026 31.4872L32.0897 0ZM17.9359 29.641L10.141 20.9231L13.9359 17.4359L17.8333 21.8462L26.859 11.5897L30.7564 15.0769L17.9359 29.641Z"
      fill="currentColor"
    />
  </svg>
);
