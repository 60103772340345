import React from 'react';

import { Card, Heading3, Heading4 } from '@ovotech/element';

import {
  EnergisedIcon,
  EnergisedIconBackground,
  StyledCol,
  StyledCtaCard,
} from './styles';
import { IProfileCardLayoutProps } from './interfaces';

const ProfileCardLayout: React.FC<IProfileCardLayoutProps> = ({
  heading,
  icon,
  isCta = false,
  children,
  className,
}) => {
  return (
    <StyledCol data-testid={`profile-card-layout-${heading}`}>
      {isCta ? (
        <StyledCtaCard>
          {heading && <Heading4 className="mb-6">{heading}</Heading4>}
          {children}
        </StyledCtaCard>
      ) : (
        <Card className={`overflow-hidden h-full ${className || ''}`}>
          {icon && (
            <EnergisedIconBackground>
              <EnergisedIcon name={icon} width={50} height={50} />
            </EnergisedIconBackground>
          )}
          {heading && <Heading3 className="mb-6 sm:mb-10">{heading}</Heading3>}
          {children}
        </Card>
      )}
    </StyledCol>
  );
};

export { ProfileCardLayout };
