import React, { useState } from 'react';

import { filterLinksByPosition } from '../constant';

import { IMobileMenuLinkProps, MobileMenuProps } from '../interfaces';

import { MobileMenuBottomBarSection } from './MobileMenuBottomBarSection';
import { MobileMenuButton } from './MobileMenuButton';
import { MobileMenuContainer } from './MobileMenuContainer';

import { MobileMenuList } from './MobileMenuList';
import { MobileMenuScroll } from './MobileMenuScroll';

export const MobileMenu: React.FC<MobileMenuProps> = ({ links }) => {
  const [open, setOpen] = useState<boolean>(false);

  const { topLinks, bottomLinks } = filterLinksByPosition(links);

  const closeMenu = () => setOpen(false);

  const handleCloseMenuWrapper = (link: IMobileMenuLinkProps) => ({
    ...link,
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      closeMenu();
      link.onClick && link.onClick(e);
    },
  });

  const topLinksWithHandleSelect = topLinks.map(handleCloseMenuWrapper);

  const bottomLinksWithHandleSelect = bottomLinks.map(handleCloseMenuWrapper);

  return (
    <>
      <MobileMenuButton
        onClick={() => setOpen(!open)}
        isOpen={open}
        aria-expanded={open}
      />
      <MobileMenuContainer isOpen={open}>
        <MobileMenuScroll>
          <MobileMenuList
            data-test-id="mobile-navbar-top-content"
            links={topLinksWithHandleSelect}
          />
        </MobileMenuScroll>
        <MobileMenuBottomBarSection>
          <MobileMenuList
            data-test-id="mobile-navbar-bottom-content"
            links={bottomLinksWithHandleSelect}
          />
        </MobileMenuBottomBarSection>
      </MobileMenuContainer>
    </>
  );
};
