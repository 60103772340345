import styled, { css } from 'styled-components';
import { Icon, Notification, P } from '@ovotech/element';

export const StyledErrorNotification = styled(Notification)`
  ${({ theme: { core } }) => css`
    margin-top: ${core.space[4]};
  `}

  div {
    display: flex;
    width: 100%;
  }
`;

export const ErrorText = styled(P)`
  margin-top: 0;
`;

export const WarningIcon = styled(Icon)(
  ({ theme: { core } }) => css`
    margin-right: ${core.space[3]};
  `,
);
