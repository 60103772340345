import React from 'react';

const ResolvedIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="10" cy="10" r="10" fill="#1BBD5A" />
    <path
      d="M8.375 16L3.5 10.7357L5.9375 8.61429L8.375 11.2071L14.0625 5L16.5 7.12143L8.375 16Z"
      fill="white"
    />
  </svg>
);

export { ResolvedIcon };
