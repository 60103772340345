import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { PROFILE_MIXPANEL_EVENTS } from '@client/modules/Profile/constants';

import { useGetVersionQuery } from '@client/services/self-serve';

import { useMixpanel } from '../UserAnalytics/hooks';

import DatadogProvider from './DatadogProvider';
import MixpanelProvider from './MixpanelProvider';
import OptimizelyProvider from './OptimizelyProvider';
import QualtricsProvider from './QualtricsProvider';

import { initDatadog, initMixpanel, initOptimizely } from './utils';

initMixpanel();
initOptimizely();

const UserAnalytics: React.FC = ({ children }) => {
  const mixpanel = useMixpanel();
  const router = useRouter();
  const { data, isSuccess, isError } = useGetVersionQuery();

  useEffect(() => {
    if (isSuccess || isError) {
      initDatadog(data?.dataDogVersion as string);
    }
  }, [isError, isSuccess, data]);

  //reffererButton param is to aid reporting on AB test, which test the effectiveness of using a login button vs a banner when redirecting customer from corgihomeplan.co.uk
  // To be refactored once the AB test is over

  useEffect(() => {
    const { reffererButton, ...updatedQuery } = router.query;
    if (reffererButton) {
      mixpanel?.track(PROFILE_MIXPANEL_EVENTS.VIEW, {
        pageName: router.pathname,
        ...(reffererButton && { reffererButton }),
      });

      const newPathObject = {
        pathname: router.pathname,
        query: updatedQuery,
      };

      router.push(newPathObject, undefined, { shallow: true });
    } else {
      mixpanel?.track(PROFILE_MIXPANEL_EVENTS.VIEW, {
        pageName: router.pathname,
      });
    }
  }, [mixpanel, router]);

  return (
    <QualtricsProvider>
      <OptimizelyProvider>
        <MixpanelProvider>
          <DatadogProvider>{children}</DatadogProvider>
        </MixpanelProvider>
      </OptimizelyProvider>
    </QualtricsProvider>
  );
};

export { UserAnalytics };
