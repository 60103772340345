import React, { ComponentType, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Loading } from '../../components';

import { useAppSelector } from '../../hooks/useAppSelector';
import {
  selectCurrentUser,
  selectIsUserLoaded,
} from '../../redux/auth/selectors';

export function withAuth<T>(WrappedComponent: ComponentType<T>) {
  return function WithAuthHOC(hocProps: never) {
    const router = useRouter();
    const currentUser = useAppSelector(selectCurrentUser);
    const isUserLoaded = useAppSelector(selectIsUserLoaded);

    useEffect(() => {
      if (currentUser == null && isUserLoaded) {
        router.push(
          '/login?redirect=' +
            window.location.pathname +
            window.location.search,
        );
      }
    });

    // Don't show the content until we make sure the user is authenticated and loaded
    if (!isUserLoaded || (isUserLoaded && currentUser === null))
      return <Loading />;

    return <WrappedComponent {...hocProps} />;
  };
}
