import { IPolicyExclusions, IPolicyInclusions } from './interfaces';

export const PROFILE_RAISE_CALLOUT_CTA_TEXT = 'Get started';

export const PROFILE_MIXPANEL_EVENTS = {
  VIEW: 'pageview',
  MENU_SELECT: 'profile.menu.select',
  BUTTON_CLICK: 'button.click',
  BACK_BUTTON_CLICK: 'back.select',
  LINK_CLICK: 'link.click',
};

export const PROFILE_MIXPANEL_EVENT_PROPERTIES = {
  PROFILE_LINK: 'Profile',
  PLAN_LINK: 'Plan',
  PAYMENT_DETAILS_LINK: 'Payment details',
  BILLING_HISTORY_LINK: 'Billing history',
  RAISE_CALLOUT_BUTTON: 'Raise callout',
  RETURN_TO_PLAN_BUTTON: 'Raised callout - Return to plan',
  RENEW_PLAN_BUTTON: 'Renew my plan',
};

export const PROFILE_PATHNAME = {
  PROFILE: '/profile',
  PLAN: '/profile/plan',
  PAYMENT_DETAILS: '/profile/payment-details',
  BILLING_HISTORY: '/profile/billing-history',
};

export const ProductIds = {
  Essentials: '1a6f596a-9d71-4711-b6dd-9b25841ee001',
  Starter: '1a6f596a-9d71-4711-b6dd-9b25841ee002',
  Advanced: '1a6f596a-9d71-4711-b6dd-9b25841ee003',
  Complete: '1a6f596a-9d71-4711-b6dd-9b25841ee004',
  AnnualBoilerService: '1a6f596a-9d71-4711-b6dd-9b25841ee005',
};

export const ProductComponents = {
  CentralHeatingSystem: 'Central Heating System',
  CentralHeatingBoiler: 'Central Heating Boiler',
  AnnualBoilerService: 'Annual Boiler Service',
  PlumbingAndElectrics: 'Plumbing & Electrics',
  BoilerReplacement: 'Boiler Replacement',
  GasSupplyPipe: 'Gas Supply Pipe',
  WaterSupplyPipe: 'Water Supply Pipe',
  InternalDrainsAndWastepipes: 'Internal Drains & Wastepipes',
  ExternalDrains: 'External Drains',
  TapsAndToilets: 'Taps & Toilets',
};

export const policyExclusionsByComponent: IPolicyExclusions = {
  [ProductComponents.CentralHeatingSystem]: [
    'Repair or replacement of the boiler should the engineer determine that it is beyond economical repair or if new manufacturer approved spare parts are not available for boilers 7 years old or over',
  ],
  [ProductComponents.CentralHeatingBoiler]: [
    'Repair or replacement of the boiler should the engineer determine that it is beyond economical repair or if new manufacturer approved spare parts are not available for boilers 7 years old or over',
  ],
  [ProductComponents.PlumbingAndElectrics]: [
    'Electrical faults that require a complete system rewire',
    'Fluorescent light starters, decorative or low voltage lightning',
  ],
  [ProductComponents.BoilerReplacement]: [
    'Boiler replacement of boilers 10 years old or over at the initial policy start date or at policy renewal date',
    'The boiler will not be replaced during the first 6 months following the initial policy start date',
  ],
  [ProductComponents.WaterSupplyPipe]: [
    'Any water supply pipe outside the boundary of your home or for which you are not responsible, or fresh water pipes beneath or inside any building or outbuilding',
  ],
  [ProductComponents.InternalDrainsAndWastepipes]: [
    'Shared drains',
    'Damage, or blockages caused by degradation, to pitch fibre drains',
  ],
  [ProductComponents.ExternalDrains]: [
    'Shared drains',
    'Damage, or blockages caused by degradation, to pitch fibre drains',
  ],
  [ProductComponents.GasSupplyPipe]: [],
  [ProductComponents.TapsAndToilets]: [],
  [ProductComponents.AnnualBoilerService]: [
    'Repair of any faults. This is not an insurance policy.',
    'Testing the system’s inhibitor levels for the presence of sludge.',
    'Servicing of warm air boilers, back boilers or dual purpose boilers such as AGA or Rayburn.',
  ],
};

export const policyExclusionsByPlan: IPolicyExclusions = {
  [ProductIds.Essentials]: [
    'Repair or replacement of the boiler should the engineer determine that it is beyond economical repair or if new manufacturer approved spare parts are not available for boilers 7 years old or over',
  ],
  [ProductIds.Starter]: [
    'Repair or replacement of the boiler should the engineer determine that it is beyond economical repair or if new manufacturer approved spare parts are not available for boilers 7 years old or over',
    'Electrical faults that require a complete system rewire',
    'Fluorescent light starters, decorative or low voltage lightning',
  ],
  [ProductIds.Advanced]: [
    'Repair or replacement of the boiler should the engineer determine that it is beyond economical repair or if new manufacturer approved spare parts are not available for boilers 7 years old or over',
    'Electrical faults that require a complete system rewire',
    'Fluorescent light starters, decorative or low voltage lightning',
    'Any water supply pipe outside the boundary of your home or for which you are not responsible, or fresh water pipes beneath or inside any building or outbuilding',
    'Shared drains',
  ],
  [ProductIds.Complete]: [
    'Boiler replacement of boilers 10 years old or over at the initial policy start date or at policy renewal date',
    'Repair or replacement of the boiler should the engineer determine that it is beyond economical repair or if new manufacturer approved spare parts are not available for boilers 7 years old or over',
    'Electrical faults that require a complete system rewire',
    'Fluorescent light starters, decorative or low voltage lightning',
    'Any water supply pipe outside the boundary of your home or for which you are not responsible, or fresh water pipes beneath or inside any building or outbuilding',
    'Shared drains',
    'The boiler will not be replaced during the first 6 months following the initial policy start date',
    'Damage, or blockages caused by degradation, to pitch fibre drains',
  ],
};

export const policyExclusionsForAllPlans = [
  'Pre-existing, system design or installation faults',
  'Underfloor heating and controls',
  'Damage caused by weather or freezing',
  'The first £60 of any claim, if you have chosen a policy with a £60 excess',
  'Damage caused by sludge, scale or other debris in the central heating system or boiler',
  'Damage arising as a result of disconnection from, re-connection to or interruption of the gas, electricity or water mains services to your home',
  'Devices that can be plugged into or operate using the electricity supply e.g. light bulbs, extractor fans, electric showers and domestic appliances',
  'Landlords or Guest Houses/B&Bs',
  'Boilers over 45kW or deemed commercial boilers by their manufacturers',
  'Steel or iron pipes linked to the central heating system',
];

export const policyInclusions: IPolicyInclusions = {
  [ProductComponents.CentralHeatingSystem]: [
    'Time clock or programmer',
    'Hot water, feeds, expansion tanks, cylinders and immersions',
    'Radiator and valves',
    'Pipes and fittings',
  ],
  [ProductComponents.CentralHeatingBoiler]: [
    'Gas fired central heating boiler, flue and controls',
    'Thermostats, heating controls, circulating pumps and motorised valves',
  ],
  [ProductComponents.AnnualBoilerService]: [
    'A visual check of the boiler',
    'The boiler being fired safely to identify any working faults',
    'A flue gas analysis efficiency test',
    'Opening up the boiler and inspecting it, if necessary',
    'Checking the flue and ventilation are in accordance with Gas Safety (Installation and Use) Regulations',
    'Checking and adjusting the system pressure',
    'Cleaning the condensate trap',
    'Visually checking and bleeding radiators, if necessary',
    'A visual check of the hot water cylinder',
    'Confirmation in writing that the service has been carried out',
    'Cleaning of the boiler’s filter (if you ask us to)',
  ],
  [ProductComponents.PlumbingAndElectrics]: [
    'The hot and cold water pipes internal to your home between the stopcock and your taps or appliances',
    'Electrical circuits',
    'Fuse box',
    'Circuit breakers',
    'Sockets and light fittings',
    'Up to £2,000 claim limit',
  ],
  [ProductComponents.BoilerReplacement]: [
    'If your boiler is less than 7 years old at the initial policy start date and less than 10 years old at renewal, we will pay up to £2,500 for the boiler replacement',
    'If your boiler is 7 years old or more but less than 10 years old at the initial policy start date and less than 10 years old at renewal, we will pay a contribution of £400 towards the boiler replacement',
  ],
  [ProductComponents.GasSupplyPipe]: [
    'Gas supply pipes located in your home, which supply the central heating boiler and other gas appliances, that connect your home to the gas mains supply.',
    'Up to £2,000 claim limit',
  ],
  [ProductComponents.WaterSupplyPipe]: [
    'Water supply pipes within your boundary between your home and the mains supply pipes',
    'Excavation of underground pipes',
    'Up to £2,000 claim limit',
  ],
  [ProductComponents.InternalDrainsAndWastepipes]: [
    'Blocked or leaking internal drains and waste pipes',
    'Up to £2,000 claim limit',
  ],
  [ProductComponents.ExternalDrains]: [
    'Blocked or leaking external drains and waste pipes within your boundary',
    'Excavation of underground drains',
    'Up to £2,000 claim limit',
  ],
  [ProductComponents.TapsAndToilets]: [
    'Leaking taps and running toilets',
    'Ballcocks, syphons and valves',
    'Replacement of non-ceramic tap washers',
    'Up to £2,000 claim limit',
  ],
};
