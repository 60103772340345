import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { CTAButton } from '@ovotech/element';

import { CommonRoutes } from '../../constants/routes';

import useNavigate from '../../hooks/useNavigate';

import { useMixpanel } from '../UserAnalytics/hooks';

import { ProfileCardLayout } from './ProfileCardLayout';

import {
  PROFILE_MIXPANEL_EVENTS,
  PROFILE_MIXPANEL_EVENT_PROPERTIES,
  PROFILE_RAISE_CALLOUT_CTA_TEXT,
} from './constants';

const ProfileCalloutCTA: React.FC<{ isAbsOnly?: boolean }> = ({
  isAbsOnly,
}) => {
  const mixpanel = useMixpanel();
  const { navigate } = useNavigate();
  const { getLoginRoute } = CommonRoutes;
  const router = useRouter();

  const handleRaiseCalloutClick = useCallback(() => {
    mixpanel?.track(PROFILE_MIXPANEL_EVENTS.BUTTON_CLICK, {
      buttonId: PROFILE_MIXPANEL_EVENT_PROPERTIES.RAISE_CALLOUT_BUTTON,
      pageName: router.pathname,
    });
    navigate(getLoginRoute());
  }, [navigate, getLoginRoute, mixpanel, router.pathname]);

  if (isAbsOnly) return null;

  return (
    <ProfileCardLayout heading="Book an engineer" isCta={true}>
      <CTAButton
        onClick={handleRaiseCalloutClick}
        variant="primary"
        data-testid="raise-callout-button"
        fullWidth="always"
      >
        {PROFILE_RAISE_CALLOUT_CTA_TEXT}
      </CTAButton>
    </ProfileCardLayout>
  );
};

export { ProfileCalloutCTA };
