import { useEffect, useMemo } from 'react';

import { ContractStatus } from '@client/types/__generated__/globalTypes';

import { useOptimizely } from '@client/modules/UserAnalytics/hooks';

import { selectIsUserConfirmed } from '@client/redux/auth/selectors';

import { useAppSelector } from '@client/hooks/useAppSelector';

import { CommonRoutes } from '../../../constants/routes';
import useNavigate from '../../../hooks/useNavigate';
import { GetCustomerByIdDetails } from '../../Customer/gql/fragments/__generated__/GetCustomerByIdDetails';

export function useProfile(
  customer?: GetCustomerByIdDetails | undefined | null,
) {
  const enableAccountDashboardDecision = useOptimizely(
    'enable-account-dashboard',
    true,
  );
  const isLoggedIn = useAppSelector(selectIsUserConfirmed);
  const isEnableAccountDashboard =
    enableAccountDashboardDecision.enabled && isLoggedIn;
  const { getWelcomeRoute } = CommonRoutes;
  const { navigate } = useNavigate();

  useEffect(() => {
    const redirect = async () => {
      if (!isEnableAccountDashboard) {
        await navigate(getWelcomeRoute());
      }
    };
    redirect();
  }, [isEnableAccountDashboard, getWelcomeRoute, navigate]);

  const activeContract = useMemo(
    () =>
      customer?.contracts?.find(
        (contract) => contract?.contractStatus === ContractStatus.Active,
      ),
    [customer?.contracts],
  );

  return {
    activeContract,
  };
}
