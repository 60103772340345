import React from 'react';

import { LoadingTestIds } from './constants';

import { StyledLoading, StyledLoadingOverlay } from './styles';

const Loading = () => {
  return (
    <StyledLoadingOverlay data-testid={LoadingTestIds.overlay}>
      <StyledLoading data-testid={LoadingTestIds.spinner} />
    </StyledLoadingOverlay>
  );
};

export { Loading };
