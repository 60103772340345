export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: any;
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  Uuid: any;
  /** The _Any scalar is used to pass representations of entities from external services into the root _entities field for execution. Validation of the _Any scalar is done by matching the __typename and @external fields defined in the schema. */
  _Any: any;
  /** Scalar representing a set of fields. */
  _FieldSet: any;
};

export enum AccountDirection {
  PAYABLE = 'PAYABLE',
  PAYABLE_RECEIVABLE = 'PAYABLE_RECEIVABLE',
  RECEIVABLE = 'RECEIVABLE'
}

export type AccountInformation = {
  __typename?: 'AccountInformation';
  accountNumber: Scalars['String'];
  bankAccountCustomerName: Scalars['String'];
  sortCode: Scalars['String'];
};

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum AccountType {
  INSURANCE = 'INSURANCE',
  NON_INSURANCE = 'NON_INSURANCE'
}

export type Action = {
  __typename?: 'Action';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  details: Scalars['String'];
  dueDate: Scalars['DateTime'];
  friendlyActionId: Scalars['Int'];
  id: Scalars['Uuid'];
  modifiedAt: Scalars['DateTime'];
  responsibilities: Array<Responsibility>;
  setBy: Scalars['String'];
  status: ActionsStatus;
  tags: Array<Tag>;
};

export type ActionFilterInput = {
  and?: InputMaybe<Array<ActionFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  date?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  details?: InputMaybe<StringOperationFilterInput>;
  dueDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  friendlyActionId?: InputMaybe<ComparableInt32OperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ActionFilterInput>>;
  responsibilities?: InputMaybe<ListFilterInputTypeOfResponsibilityFilterInput>;
  setBy?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<StatusOperationFilterInput>;
  tags?: InputMaybe<ListFilterInputTypeOfTagFilterInput>;
};

export type ActionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  details?: InputMaybe<SortEnumType>;
  dueDate?: InputMaybe<SortEnumType>;
  friendlyActionId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  setBy?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export enum ActionType {
  CALLBACK_MANAGEMENT = 'CALLBACK_MANAGEMENT',
  CASE_MANAGEMENT = 'CASE_MANAGEMENT',
  CLAIM_SUPPORT = 'CLAIM_SUPPORT',
  CUSTOMER_CARE_MANAGEMENT = 'CUSTOMER_CARE_MANAGEMENT',
  CUSTOMER_RETENTION = 'CUSTOMER_RETENTION',
  DD_CANCELLATIONS = 'DD_CANCELLATIONS',
  GENERAL = 'GENERAL',
  SERVICE_MANAGEMENT = 'SERVICE_MANAGEMENT',
  TASK_MANAGEMENT = 'TASK_MANAGEMENT'
}

/** A connection to a list of items. */
export type ActionsByDomainConnection = {
  __typename?: 'ActionsByDomainConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ActionsByDomainEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Action>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ActionsByDomainEdge = {
  __typename?: 'ActionsByDomainEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Action>;
};

/** A connection to a list of items. */
export type ActionsByResponsibleEmailConnection = {
  __typename?: 'ActionsByResponsibleEmailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ActionsByResponsibleEmailEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Action>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ActionsByResponsibleEmailEdge = {
  __typename?: 'ActionsByResponsibleEmailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Action>;
};

/** A connection to a list of items. */
export type ActionsConnection = {
  __typename?: 'ActionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ActionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Action>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum ActionsDomainType {
  COMPANY = 'COMPANY',
  COMPLAINT = 'COMPLAINT',
  CONTRACT = 'CONTRACT',
  CUSTOMER = 'CUSTOMER',
  JOB = 'JOB',
  TASK = 'TASK'
}

/** An edge in a connection. */
export type ActionsEdge = {
  __typename?: 'ActionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Action>;
};

export enum ActionsStatus {
  COMPLETE = 'COMPLETE',
  OPEN = 'OPEN'
}

export type AddActionInput = {
  action?: InputMaybe<Scalars['String']>;
  actionType: ActionType;
  customerId: Scalars['Int'];
  dueDate: Scalars['DateTime'];
  email?: InputMaybe<Scalars['String']>;
};

export type AddActionPayload = {
  __typename?: 'AddActionPayload';
  actionId: Scalars['Int'];
  actionName?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
};

export type AddAddressInput = {
  addressId: Scalars['Uuid'];
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  latitude: Scalars['Decimal'];
  longitude: Scalars['Decimal'];
  postcode: Scalars['String'];
};

export type AddAddressPayload = {
  __typename?: 'AddAddressPayload';
  address: Address;
};

export type AddAuroraActionInput = {
  date: Scalars['DateTime'];
  details?: InputMaybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  setBy?: InputMaybe<Scalars['String']>;
  status: ActionsStatus;
};

export type AddAuroraActionPayload = {
  __typename?: 'AddAuroraActionPayload';
  date: Scalars['DateTime'];
  details: Scalars['String'];
  dueDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  setBy: Scalars['String'];
  status: ActionsStatus;
};

export type AddBankDetailsCommandInput = {
  accountNumber: Scalars['String'];
  bankAccountCustomerName: Scalars['String'];
  contractId: Scalars['Uuid'];
  skipSupportDirectDebitCheck?: InputMaybe<Scalars['Boolean']>;
  sortCode: Scalars['String'];
};

export type AddComplaintPaymentLinkCommandInput = {
  complaintId: Scalars['Int'];
  paymentId: Scalars['Uuid'];
};

export type AddContractBoilerInput = {
  applianceType?: InputMaybe<ApplianceType>;
  boilerManufacturerId?: InputMaybe<Scalars['Uuid']>;
  contractBoilerId: Scalars['Uuid'];
  fuelType: FuelType;
  installedOn?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<Scalars['String']>;
  sludge?: InputMaybe<SludgeWarning>;
  sludgeWarning?: InputMaybe<Scalars['DateTime']>;
  warrantyDurationYears?: InputMaybe<Scalars['Int']>;
};

export type AddContractBoilerPayload = {
  __typename?: 'AddContractBoilerPayload';
  contractBoiler: ContractBoiler;
};

export type AddContractCancelNotificationInput = {
  cancelDate: Scalars['DateTime'];
  cancellationCategory: CancellationCategory;
  cancellationFeePaymentMethod: CancellationFeePaymentMethod;
  cancellationFeePreviewId: Scalars['Uuid'];
  cancellationReason: CancellationReason;
  contractId: Scalars['Uuid'];
  legacyLinkId: Scalars['Int'];
  originalEndDate: Scalars['DateTime'];
};

export type AddContractInput = {
  addressId: Scalars['Uuid'];
  brandId: Scalars['Int'];
  claimOffDate?: InputMaybe<Scalars['DateTime']>;
  contractBoilerId?: InputMaybe<Scalars['Uuid']>;
  contractDurationMonth: Scalars['Int'];
  contractId: Scalars['Uuid'];
  contractPremiumInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  contractPremiumNonInsurance?: InputMaybe<Scalars['Decimal']>;
  contractStatus: ContractStatus;
  contractYear: Scalars['Int'];
  coolingOffEndDate: Scalars['DateTime'];
  customerId: Scalars['Uuid'];
  endDate: Scalars['DateTime'];
  enquiryValidationStatus?: InputMaybe<EnquiryValidationStatusType>;
  initialProductVersionId?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['Uuid']>;
  ownershipType: OwnershipType;
  preferenceId: Scalars['Uuid'];
  previousContractId?: InputMaybe<Scalars['Uuid']>;
  previousProvider?: InputMaybe<Scalars['String']>;
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promocodeId?: InputMaybe<Scalars['Uuid']>;
  promotionDurationMonth?: InputMaybe<Scalars['Int']>;
  promotionInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  promotionNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  signUpId?: InputMaybe<Scalars['Uuid']>;
  signUpMethod: Scalars['String'];
  startDate: Scalars['DateTime'];
  subBrand?: InputMaybe<Scalars['String']>;
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
  underwriterStatus?: InputMaybe<UnderwriterStatusType>;
};

export type AddContractPreferencePayload = {
  __typename?: 'AddContractPreferencePayload';
  preference: ContractPreference;
};

export type AddCustomerBankAccountInput = {
  accountNumber: Scalars['String'];
  bankAccountCustomerName: Scalars['String'];
  customerBankAccountId: Scalars['Uuid'];
  customerId: Scalars['Uuid'];
  sortCode: Scalars['String'];
};

export type AddCustomerBankAccountPayload = {
  __typename?: 'AddCustomerBankAccountPayload';
  customerBankAccount: CustomerBankAccount;
};

export type AddCustomerConsentInput = {
  companyId: Scalars['Uuid'];
  customerConsentId: Scalars['Uuid'];
  customerId: Scalars['Uuid'];
  marketingPreference: MarketingPreference;
};

export type AddCustomerConsentPayload = {
  __typename?: 'AddCustomerConsentPayload';
  customerConsent: CustomerConsent;
};

export type AddCustomerDefaultPreferencePayload = {
  __typename?: 'AddCustomerDefaultPreferencePayload';
  preference: CustomerDefaultPreference;
};

export type AddCustomerInput = {
  addressId: Scalars['Uuid'];
  authorisedPersonFullName?: InputMaybe<Scalars['String']>;
  customerId: Scalars['Uuid'];
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  externalCustomerId?: InputMaybe<Scalars['String']>;
  forename: Scalars['String'];
  isActive: Scalars['Boolean'];
  ovoCustomerId?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  secondaryPhone?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  thirdPhone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AddCustomerPayload = {
  __typename?: 'AddCustomerPayload';
  customer: Customer;
};

export type AddExcessPaymentCommandInput = {
  calloutId: Scalars['Int'];
  contractId: Scalars['Uuid'];
  excessAmount: Scalars['Decimal'];
  paymentId?: InputMaybe<Scalars['Uuid']>;
  paymentMethod: CustomerPaymentMethod;
};

export type AddMtaInlifeAuditLogCommandInput = {
  contractId: Scalars['Uuid'];
  currentContract: MtaInlifeContractInfoInput;
  previousContract: MtaInlifeContractInfoInput;
};

export type AddNewEnquiryCommandInput = {
  address?: InputMaybe<AddressInput>;
  authorisedPerson?: InputMaybe<Scalars['String']>;
  autoSignUpStatus?: InputMaybe<AutoSignUpStatus>;
  bankDetails?: InputMaybe<NewEnquiryBankDetailsInput>;
  boiler?: InputMaybe<newEnquiries_BoilerInput>;
  brandName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<ContactInput>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  coverTypeId?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  excess?: InputMaybe<Scalars['String']>;
  forename?: InputMaybe<Scalars['String']>;
  hasAgreedContact: Scalars['Boolean'];
  newEnquiryId: Scalars['Uuid'];
  ovoCustomerId?: InputMaybe<Scalars['String']>;
  paymentDay?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  signUpType: SignUpType;
  sourceOfSale?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AddNoteInput = {
  callOutId?: InputMaybe<Scalars['Int']>;
  communicationCategory?: InputMaybe<CommunicationCategory>;
  communicationReason?: InputMaybe<CommunicationReason>;
  communicationType?: InputMaybe<CommunicationType>;
  companyId?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  isBulletNote?: InputMaybe<Scalars['Boolean']>;
};

export type AddNotePayload = {
  __typename?: 'AddNotePayload';
  communicationType?: Maybe<CommunicationType>;
  customerId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isBulletNote?: Maybe<Scalars['Boolean']>;
};

export type AddRenewalInteractionInput = {
  contractId: Scalars['Uuid'];
  newContractPremiumInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  newProductInsuranceAmount: Scalars['Decimal'];
  newProductNonInsuranceAmount: Scalars['Decimal'];
  newProductVersionId: Scalars['Uuid'];
  newPromotionInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  newPromotionNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  newTotalInsuranceCost?: InputMaybe<Scalars['Decimal']>;
  newTotalNonInsuranceCost?: InputMaybe<Scalars['Decimal']>;
  prevContractPremiumInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  prevProductInsuranceAmount: Scalars['Decimal'];
  prevProductNonInsuranceAmount: Scalars['Decimal'];
  prevPromotionInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  prevPromotionNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  prevTotalInsuranceCost?: InputMaybe<Scalars['Decimal']>;
  prevTotalNonInsuranceCost?: InputMaybe<Scalars['Decimal']>;
  previousProductVersionId: Scalars['Uuid'];
  renewalDeclineReason?: InputMaybe<RenewalDeclineReason>;
  renewalDiscount: Scalars['Int'];
  renewalInteractionId: Scalars['Uuid'];
  renewalStatus: RenewalStatus;
};

export type AddRenewalInteractionPayload = {
  __typename?: 'AddRenewalInteractionPayload';
  renewalInteraction: ContractRenewalInteraction;
};

export type AddRepairContributionCommandInput = {
  calloutId: Scalars['Int'];
  contractId: Scalars['Uuid'];
  paymentId?: InputMaybe<Scalars['Uuid']>;
  paymentMethod?: InputMaybe<CustomerPaymentMethod>;
  paymentNote: Scalars['String'];
  repairContributionAmount: Scalars['Decimal'];
};

export type AddSSECustomerInput = {
  addressId: Scalars['Uuid'];
  authorisedPersonFullName?: InputMaybe<Scalars['String']>;
  customerFriendlyId: Scalars['String'];
  customerId: Scalars['Uuid'];
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  externalCustomerId?: InputMaybe<Scalars['String']>;
  forename: Scalars['String'];
  isActive: Scalars['Boolean'];
  ovoCustomerId?: InputMaybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  secondaryPhone?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  thirdPhone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AddServiceAuditInput = {
  description?: InputMaybe<Scalars['String']>;
  emailsSent?: Scalars['Int'];
  lettersSent?: Scalars['Int'];
  serviceMonth: Month;
  type: CalloutSourceType;
};

export type AddServiceAuditPayload = {
  __typename?: 'AddServiceAuditPayload';
  operationSucceeded: Scalars['Boolean'];
};

export type AddServicesStatisticsInput = {
  allocated: Scalars['Int'];
  completed: Scalars['Int'];
  due: Scalars['Int'];
  noAccess: Scalars['Int'];
  pendingNoAccess: Scalars['Int'];
  scheduled: Scalars['Int'];
  scheduledPast: Scalars['Int'];
  serviceMonth: Month;
  unscheduled: Scalars['Int'];
};

export type AddWorkingDayEventCommandInput = {
  eventDescription?: InputMaybe<Scalars['String']>;
  eventEndDateTime: Scalars['DateTime'];
  eventStartDateTime: Scalars['DateTime'];
  eventTitle?: InputMaybe<Scalars['String']>;
  eventType: EventType;
  id: Scalars['Uuid'];
};

export type AdditionalAbilities = {
  __typename?: 'AdditionalAbilities';
  offRoad?: Maybe<Scalars['Boolean']>;
  unventedTicket?: Maybe<Scalars['Boolean']>;
};

export type AdditionalAbilitiesFilterInput = {
  and?: InputMaybe<Array<AdditionalAbilitiesFilterInput>>;
  offRoad?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AdditionalAbilitiesFilterInput>>;
  unventedTicket?: InputMaybe<BooleanOperationFilterInput>;
};

export type AdditionalAbilitiesSortInput = {
  offRoad?: InputMaybe<SortEnumType>;
  unventedTicket?: InputMaybe<SortEnumType>;
};

export type Address = {
  __typename?: 'Address';
  addressId: Scalars['Uuid'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  modifiedAt: Scalars['DateTime'];
  postcode: Scalars['String'];
};

export type AddressFilterInput = {
  addressId?: InputMaybe<ComparableGuidOperationFilterInput>;
  addressLine1?: InputMaybe<StringOperationFilterInput>;
  addressLine2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AddressFilterInput>>;
  city?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  latitude?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  longitude?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AddressFilterInput>>;
  postcode?: InputMaybe<StringOperationFilterInput>;
};

export type AddressInput = {
  isCommercial: Scalars['Boolean'];
  isGuestHouse: Scalars['Boolean'];
  isLiving: Scalars['Boolean'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  line3?: InputMaybe<Scalars['String']>;
  ownership: Ownership;
  postcode: Scalars['String'];
};

export type AddressSortInput = {
  addressId?: InputMaybe<SortEnumType>;
  addressLine1?: InputMaybe<SortEnumType>;
  addressLine2?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  latitude?: InputMaybe<SortEnumType>;
  longitude?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  postcode?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AddressesConnection = {
  __typename?: 'AddressesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AddressesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Address>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AddressesEdge = {
  __typename?: 'AddressesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Address;
};

export type Admin = {
  __typename?: 'Admin';
  hasCommercialEngineers?: Maybe<Scalars['Boolean']>;
  hasDedicatedCallHandlers?: Maybe<Scalars['Boolean']>;
  hasPriority?: Maybe<Scalars['Boolean']>;
  isOem?: Maybe<Scalars['Boolean']>;
  isPreviouslySse?: Maybe<Scalars['Boolean']>;
  numberOfEngineers?: Maybe<Scalars['Int']>;
};

export type AdminFilterInput = {
  and?: InputMaybe<Array<AdminFilterInput>>;
  hasCommercialEngineers?: InputMaybe<BooleanOperationFilterInput>;
  hasDedicatedCallHandlers?: InputMaybe<BooleanOperationFilterInput>;
  hasPriority?: InputMaybe<BooleanOperationFilterInput>;
  isOem?: InputMaybe<BooleanOperationFilterInput>;
  isPreviouslySse?: InputMaybe<BooleanOperationFilterInput>;
  numberOfEngineers?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  or?: InputMaybe<Array<AdminFilterInput>>;
};

export type AdminSortInput = {
  hasCommercialEngineers?: InputMaybe<SortEnumType>;
  hasDedicatedCallHandlers?: InputMaybe<SortEnumType>;
  hasPriority?: InputMaybe<SortEnumType>;
  isOem?: InputMaybe<SortEnumType>;
  isPreviouslySse?: InputMaybe<SortEnumType>;
  numberOfEngineers?: InputMaybe<SortEnumType>;
};

export type AggregatedManufacturer = {
  __typename?: 'AggregatedManufacturer';
  exclusionModels: Array<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
};

export type AggregatedManufacturerFilterInput = {
  and?: InputMaybe<Array<AggregatedManufacturerFilterInput>>;
  exclusionModels?: InputMaybe<ListStringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AggregatedManufacturerFilterInput>>;
};

export type AggregatedManufacturerSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type AllocationRecord = {
  __typename?: 'AllocationRecord';
  calloutId: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  contractId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Uuid'];
  isCompanyNotified: Scalars['Boolean'];
  serviceMonth: Scalars['Int'];
};

export type AlternativeContactDetails = {
  __typename?: 'AlternativeContactDetails';
  contactName?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
};

export type AmendBankDetailsCommandInput = {
  accountNumber: Scalars['String'];
  bankAccountCustomerName: Scalars['String'];
  contractId: Scalars['Uuid'];
  sortCode: Scalars['String'];
};

export type ApplianceLocations = {
  __typename?: 'ApplianceLocations';
  boilerLocation?: Maybe<Scalars['String']>;
  fuseboardLocation?: Maybe<Scalars['String']>;
  gasMeterLocation?: Maybe<Scalars['String']>;
  stopCockLocation?: Maybe<Scalars['String']>;
};

export enum ApplianceType {
  CombiBoiler = 'CombiBoiler',
  GasFire = 'GasFire',
  NonCombiBoiler = 'NonCombiBoiler',
  SystemBoiler = 'SystemBoiler'
}

export enum ApplyPolicy {
  AFTER_RESOLVER = 'AFTER_RESOLVER',
  BEFORE_RESOLVER = 'BEFORE_RESOLVER'
}

export type AuditLogEntity = {
  __typename?: 'AuditLogEntity';
  agent: Scalars['String'];
  description: Scalars['String'];
  domain: DomainType;
  eventType: audit_EventType;
  id: Scalars['Uuid'];
  loggedAt: Scalars['DateTime'];
  referenceId: Scalars['Uuid'];
};

export type AuditLogEntityFilterInput = {
  agent?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AuditLogEntityFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  domain?: InputMaybe<audit_DomainTypeOperationFilterInput>;
  eventType?: InputMaybe<EventTypeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  loggedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AuditLogEntityFilterInput>>;
  referenceId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type AuditLogEntitySortInput = {
  agent?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  domain?: InputMaybe<SortEnumType>;
  eventType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  loggedAt?: InputMaybe<SortEnumType>;
  referenceId?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AuditLogsConnection = {
  __typename?: 'AuditLogsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AuditLogsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AuditLogEntity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AuditLogsEdge = {
  __typename?: 'AuditLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AuditLogEntity;
};

export type AuroraToGiLinkFilterInput = {
  and?: InputMaybe<Array<AuroraToGiLinkFilterInput>>;
  auroraToGiLinkId?: InputMaybe<ComparableGuidOperationFilterInput>;
  contract?: InputMaybe<ContractFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  giCustomerId?: InputMaybe<ComparableInt32OperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AuroraToGiLinkFilterInput>>;
};

export type AuroraToGiLinkSortInput = {
  auroraToGiLinkId?: InputMaybe<SortEnumType>;
  contract?: InputMaybe<ContractSortInput>;
  createdAt?: InputMaybe<SortEnumType>;
  giCustomerId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
};

export type AuthorizeDirective = {
  __typename?: 'AuthorizeDirective';
  apply: ApplyPolicy;
  policy?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
};

export enum AutoSignUpStatus {
  Fail = 'Fail',
  Success = 'Success'
}

export enum AutoSignUpValidationError {
  BoilerAgeExceededFail = 'BoilerAgeExceededFail',
  DuplicationCheckFail = 'DuplicationCheckFail',
  IncompleteDataFail = 'IncompleteDataFail',
  ModulusCheckFail = 'ModulusCheckFail',
  UnderwriterCheckFail = 'UnderwriterCheckFail'
}

export type BERContributionPaymentAuthorisation = {
  __typename?: 'BERContributionPaymentAuthorisation';
  additionalInformation?: Maybe<Scalars['String']>;
  bERContributionAmount: Scalars['Decimal'];
  calloutId: Scalars['Int'];
  complaintId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['DateTime'];
  customerFriendlyId?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  paymentProposal?: Maybe<PaymentProposal>;
  status: PaymentAuthorisationStatus;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export type BacsReportHistory = {
  __typename?: 'BacsReportHistory';
  bacsReference: Scalars['String'];
  contractId: Scalars['Uuid'];
  dateReceived: Scalars['DateTime'];
  id: Scalars['Uuid'];
  reasonCode: Scalars['String'];
  reasonDescription: Scalars['String'];
  reportType: ReportType;
};

export type BankAccountModel = {
  __typename?: 'BankAccountModel';
  accountDirection: AccountDirection;
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountStatus: AccountStatus;
  accountType: AccountType;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  legalEntity?: Maybe<LegalEntityModel>;
  serviceUserNumber?: Maybe<Scalars['String']>;
  serviceUserNumberName?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
};

export type BankAccountResponse = {
  __typename?: 'BankAccountResponse';
  data?: Maybe<BankAccountResponseData>;
};

export type BankAccountResponseData = {
  __typename?: 'BankAccountResponseData';
  accountNumber?: Maybe<Scalars['String']>;
  accountNumberExistsAtSortCode?: Maybe<Scalars['Boolean']>;
  sortCode?: Maybe<Scalars['String']>;
  supportsDirectCredits?: Maybe<Scalars['Boolean']>;
  supportsDirectDebits?: Maybe<Scalars['Boolean']>;
  supportsFasterPayments?: Maybe<Scalars['Boolean']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  billingStatus?: Maybe<GiBillingStatus>;
  selfBillingStatus?: Maybe<SelfBillingStatus>;
};

export type BankDetailsFilterInput = {
  and?: InputMaybe<Array<BankDetailsFilterInput>>;
  billingStatus?: InputMaybe<NullableOfGiBillingStatusOperationFilterInput>;
  or?: InputMaybe<Array<BankDetailsFilterInput>>;
  selfBillingStatus?: InputMaybe<NullableOfSelfBillingStatusOperationFilterInput>;
};

export type BankDetailsInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  sortCode?: InputMaybe<Scalars['String']>;
};

export type BankDetailsSortInput = {
  billingStatus?: InputMaybe<SortEnumType>;
  selfBillingStatus?: InputMaybe<SortEnumType>;
};

export type BaseCommunicationEvent = {
  __typename?: 'BaseCommunicationEvent';
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type BaseDocumentEvent = {
  __typename?: 'BaseDocumentEvent';
  timeStampUtc: Scalars['DateTime'];
};

export type BeyondEconomicalRepairContributionAuthorisationInputModelInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  bERContributionAmount: Scalars['Decimal'];
  calloutId: Scalars['Int'];
  complaintId?: InputMaybe<Scalars['Int']>;
  customerFriendlyId?: InputMaybe<Scalars['String']>;
  paymentProposal?: InputMaybe<PaymentProposalInputModelInput>;
};

export type Boiler = {
  __typename?: 'Boiler';
  boilerQuantity: Scalars['Int'];
  fuelType: NewEnquiryFuelType;
  installDate?: Maybe<Scalars['DateTime']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  needsRepair: Scalars['Boolean'];
};

export enum BoilerCondition {
  ANY = 'ANY',
  BROKEN = 'BROKEN',
  WORKING = 'WORKING'
}

export type BoilerConditionOperationFilterInput = {
  eq?: InputMaybe<BoilerCondition>;
  in?: InputMaybe<Array<BoilerCondition>>;
  neq?: InputMaybe<BoilerCondition>;
  nin?: InputMaybe<Array<BoilerCondition>>;
};

export type BoilerEligibility = {
  __typename?: 'BoilerEligibility';
  error?: Maybe<Scalars['String']>;
  isEligible: Scalars['Boolean'];
};

export type BoilerEligibilityInput = {
  boilerModel?: InputMaybe<Scalars['String']>;
  installationDate: Scalars['DateTime'];
  manufacturerId: Scalars['Uuid'];
};

export type BoilerInput = {
  boilerQuantity: Scalars['Int'];
  fuelType: CustomerSignupFuelType;
  installDate?: InputMaybe<Scalars['DateTime']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  needsRepair: Scalars['Boolean'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['Uuid'];
  name: Scalars['String'];
};

export type BrandFilterInput = {
  and?: InputMaybe<Array<BrandFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BrandFilterInput>>;
};

export type BrandModel = {
  __typename?: 'BrandModel';
  description?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  legacyId: Scalars['Int'];
  legalEntity?: Maybe<LegalEntityModel>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type BrandSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BrandsConnection = {
  __typename?: 'BrandsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BrandsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Brand>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BrandsEdge = {
  __typename?: 'BrandsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Brand;
};

export type Callout = {
  __typename?: 'Callout';
  alternativeContactDetails?: Maybe<AlternativeContactDetails>;
  applianceId?: Maybe<Scalars['Int']>;
  attendanceDate?: Maybe<Scalars['DateTime']>;
  calloutId: Scalars['Int'];
  calloutPayment?: Maybe<CalloutPayment>;
  calloutSourceType?: Maybe<callout_CalloutSourceType>;
  calloutType?: Maybe<CalloutType>;
  checklistComplete?: Maybe<Scalars['Boolean']>;
  class?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  complaint?: Maybe<Complaint>;
  confimedInHour?: Maybe<Scalars['Boolean']>;
  contactDate?: Maybe<Scalars['DateTime']>;
  costOverride?: Maybe<Scalars['Boolean']>;
  customerDelay?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['Int']>;
  dateCompleted?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  excess?: Maybe<Scalars['Decimal']>;
  excludeFromAverages?: Maybe<Scalars['Boolean']>;
  excludedBy?: Maybe<Scalars['Int']>;
  externalCustomerRef?: Maybe<Scalars['Uuid']>;
  externalRef?: Maybe<Scalars['String']>;
  flagsSet?: Maybe<Scalars['Boolean']>;
  highlight?: Maybe<Scalars['Boolean']>;
  hoursCompromise?: Maybe<Scalars['Int']>;
  invoiceDispute?: Maybe<InvoiceDispute>;
  invoiceOk?: Maybe<Scalars['Int']>;
  irmAuthorized?: Maybe<Scalars['Boolean']>;
  jobsheet?: Maybe<Jobsheet>;
  landlord: Scalars['Boolean'];
  lastModified: Scalars['DateTime'];
  linkedCallout?: Maybe<Scalars['Int']>;
  nextActionId?: Maybe<Scalars['Int']>;
  notRecallReason?: Maybe<Scalars['String']>;
  notification?: Maybe<Notification>;
  numberOfVisits?: Maybe<Scalars['Int']>;
  originalAttendDate?: Maybe<Scalars['DateTime']>;
  outOfHours?: Maybe<OutOfHours>;
  problemType?: Maybe<ProblemType>;
  repairNotCovered?: Maybe<RepairNotCovered>;
  resolvedByPhone?: Maybe<Scalars['Boolean']>;
  resolvedFlag?: Maybe<Scalars['Boolean']>;
  responsible?: Maybe<Scalars['Int']>;
  scConfirmed?: Maybe<ServiceCompanyConfirmed>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  scheduledTime?: Maybe<Scalars['String']>;
  searchableCustomerId: Scalars['String'];
  serviceRun?: Maybe<Scalars['Boolean']>;
  source?: Maybe<CalloutSource>;
  specialInstructions?: Maybe<Scalars['String']>;
  status?: Maybe<CalloutStatus>;
  tagUserId: Scalars['Int'];
  travelHours?: Maybe<Scalars['Decimal']>;
  travelTimeAllowed?: Maybe<Scalars['Boolean']>;
  yearOfAccount?: Maybe<Scalars['Decimal']>;
};

export type CalloutAllocation = {
  __typename?: 'CalloutAllocation';
  acceptCallouts?: Maybe<Scalars['Boolean']>;
  notifyMethod?: Maybe<CompanyCalloutNotifyMethod>;
};

export type CalloutAllocationFilterInput = {
  acceptCallouts?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<CalloutAllocationFilterInput>>;
  notifyMethod?: InputMaybe<NullableOfCompanyCalloutNotifyMethodOperationFilterInput>;
  or?: InputMaybe<Array<CalloutAllocationFilterInput>>;
};

export type CalloutAllocationSortInput = {
  acceptCallouts?: InputMaybe<SortEnumType>;
  notifyMethod?: InputMaybe<SortEnumType>;
};

export type CalloutExcessLinkModel = {
  __typename?: 'CalloutExcessLinkModel';
  calloutId: Scalars['Int'];
  id: Scalars['Uuid'];
  payment: customerPayments_Payment;
};

export type CalloutFilterInput = {
  and?: InputMaybe<Array<CalloutFilterInput>>;
  applianceId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  attendanceDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  calloutId?: InputMaybe<ComparableInt32OperationFilterInput>;
  calloutType?: InputMaybe<NullableOfCalloutTypeOperationFilterInput>;
  checklistComplete?: InputMaybe<BooleanOperationFilterInput>;
  class?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  companyId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  confimedInHour?: InputMaybe<BooleanOperationFilterInput>;
  contactDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  costOverride?: InputMaybe<BooleanOperationFilterInput>;
  customerDelay?: InputMaybe<BooleanOperationFilterInput>;
  customerId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  dateCompleted?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  details?: InputMaybe<StringOperationFilterInput>;
  dueDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  excess?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  excludeFromAverages?: InputMaybe<BooleanOperationFilterInput>;
  excludedBy?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  externalRef?: InputMaybe<StringOperationFilterInput>;
  flagsSet?: InputMaybe<BooleanOperationFilterInput>;
  highlight?: InputMaybe<BooleanOperationFilterInput>;
  hoursCompromise?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  invoiceOk?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  irmAuthorized?: InputMaybe<BooleanOperationFilterInput>;
  landlord?: InputMaybe<BooleanOperationFilterInput>;
  lastModified?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  linkedCallout?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  nextActionId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  notRecallReason?: InputMaybe<StringOperationFilterInput>;
  numberOfVisits?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  or?: InputMaybe<Array<CalloutFilterInput>>;
  originalAttendDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  problemType?: InputMaybe<NullableOfProblemTypeOperationFilterInput>;
  resolvedByPhone?: InputMaybe<BooleanOperationFilterInput>;
  resolvedFlag?: InputMaybe<BooleanOperationFilterInput>;
  responsible?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  scConfirmed?: InputMaybe<NullableOfServiceCompanyConfirmedOperationFilterInput>;
  scheduledDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  scheduledTime?: InputMaybe<StringOperationFilterInput>;
  serviceRun?: InputMaybe<BooleanOperationFilterInput>;
  source?: InputMaybe<CalloutSourceFilterInput>;
  specialInstructions?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<NullableOfCalloutStatusOperationFilterInput>;
  tagUserId?: InputMaybe<ComparableInt32OperationFilterInput>;
  travelHours?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  travelTimeAllowed?: InputMaybe<BooleanOperationFilterInput>;
  yearOfAccount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
};

export type CalloutFlagsInput = {
  vulnerable?: InputMaybe<Scalars['Boolean']>;
};

export type CalloutPayment = {
  __typename?: 'CalloutPayment';
  amount?: Maybe<Scalars['Decimal']>;
  netAmount?: Maybe<Scalars['Decimal']>;
  payAuthBy?: Maybe<Scalars['Int']>;
  payAuthDate?: Maybe<Scalars['DateTime']>;
  statusId?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['Decimal']>;
  vatRate?: Maybe<Scalars['Float']>;
};

export type CalloutPaymentLinkModel = {
  __typename?: 'CalloutPaymentLinkModel';
  calloutId: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  paymentId: Scalars['Uuid'];
};

export type CalloutSortInput = {
  applianceId?: InputMaybe<SortEnumType>;
  attendanceDate?: InputMaybe<SortEnumType>;
  calloutId?: InputMaybe<SortEnumType>;
  calloutSourceType?: InputMaybe<SortEnumType>;
  calloutType?: InputMaybe<SortEnumType>;
  checklistComplete?: InputMaybe<SortEnumType>;
  class?: InputMaybe<SortEnumType>;
  companyId?: InputMaybe<SortEnumType>;
  confimedInHour?: InputMaybe<SortEnumType>;
  contactDate?: InputMaybe<SortEnumType>;
  costOverride?: InputMaybe<SortEnumType>;
  customerDelay?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  dateCompleted?: InputMaybe<SortEnumType>;
  details?: InputMaybe<SortEnumType>;
  dueDate?: InputMaybe<SortEnumType>;
  excess?: InputMaybe<SortEnumType>;
  excludeFromAverages?: InputMaybe<SortEnumType>;
  excludedBy?: InputMaybe<SortEnumType>;
  externalCustomerRef?: InputMaybe<SortEnumType>;
  externalRef?: InputMaybe<SortEnumType>;
  flagsSet?: InputMaybe<SortEnumType>;
  highlight?: InputMaybe<SortEnumType>;
  hoursCompromise?: InputMaybe<SortEnumType>;
  invoiceOk?: InputMaybe<SortEnumType>;
  irmAuthorized?: InputMaybe<SortEnumType>;
  landlord?: InputMaybe<SortEnumType>;
  lastModified?: InputMaybe<SortEnumType>;
  linkedCallout?: InputMaybe<SortEnumType>;
  nextActionId?: InputMaybe<SortEnumType>;
  notRecallReason?: InputMaybe<SortEnumType>;
  numberOfVisits?: InputMaybe<SortEnumType>;
  originalAttendDate?: InputMaybe<SortEnumType>;
  problemType?: InputMaybe<SortEnumType>;
  resolvedByPhone?: InputMaybe<SortEnumType>;
  resolvedFlag?: InputMaybe<SortEnumType>;
  responsible?: InputMaybe<SortEnumType>;
  scConfirmed?: InputMaybe<SortEnumType>;
  scheduledDate?: InputMaybe<SortEnumType>;
  scheduledTime?: InputMaybe<SortEnumType>;
  searchableCustomerId?: InputMaybe<SortEnumType>;
  serviceRun?: InputMaybe<SortEnumType>;
  source?: InputMaybe<CalloutSourceSortInput>;
  specialInstructions?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  tagUserId?: InputMaybe<SortEnumType>;
  travelHours?: InputMaybe<SortEnumType>;
  travelTimeAllowed?: InputMaybe<SortEnumType>;
  yearOfAccount?: InputMaybe<SortEnumType>;
};

export type CalloutSource = {
  __typename?: 'CalloutSource';
  calloutId: Scalars['Int'];
  calloutSourceId: Scalars['Int'];
  calloutSourceTypeId: Scalars['Int'];
  externalJobId?: Maybe<Scalars['Int']>;
  externalJobsheetId?: Maybe<Scalars['Uuid']>;
  externaljob?: Maybe<Externaljob>;
  jobsheetSubmittedBy?: Maybe<Scalars['String']>;
  origin: Origin;
};

export type CalloutSourceFilterInput = {
  and?: InputMaybe<Array<CalloutSourceFilterInput>>;
  calloutId?: InputMaybe<ComparableInt32OperationFilterInput>;
  calloutSourceId?: InputMaybe<ComparableInt32OperationFilterInput>;
  calloutSourceTypeId?: InputMaybe<ComparableInt32OperationFilterInput>;
  externalJobId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  externalJobsheetId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  externaljob?: InputMaybe<ExternaljobFilterInput>;
  jobsheetSubmittedBy?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CalloutSourceFilterInput>>;
  origin?: InputMaybe<OriginOperationFilterInput>;
};

export type CalloutSourceSortInput = {
  calloutId?: InputMaybe<SortEnumType>;
  calloutSourceId?: InputMaybe<SortEnumType>;
  calloutSourceTypeId?: InputMaybe<SortEnumType>;
  externalJobId?: InputMaybe<SortEnumType>;
  externalJobsheetId?: InputMaybe<SortEnumType>;
  externaljob?: InputMaybe<ExternaljobSortInput>;
  jobsheetSubmittedBy?: InputMaybe<SortEnumType>;
  origin?: InputMaybe<SortEnumType>;
};

export enum CalloutSourceType {
  External = 'External',
  Horizon = 'Horizon',
  Internal = 'Internal',
  OvoBoilerRepair = 'OvoBoilerRepair',
  OvoEnergy = 'OvoEnergy',
  OvoHomePlan = 'OvoHomePlan'
}

export enum CalloutStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  NoAccess = 'NoAccess',
  NoRepair = 'NoRepair',
  PendingNoAccess = 'PendingNoAccess',
  PhoneFix = 'PhoneFix',
  PhoneFixFailed = 'PhoneFixFailed',
  Resolved = 'Resolved',
  Unknown = 'Unknown'
}

export type CalloutStatusDto = {
  __typename?: 'CalloutStatusDto';
  calloutId: Scalars['Int'];
  scheduledDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CalloutStatus>;
};

export type CalloutStatusDtoFilterInput = {
  and?: InputMaybe<Array<CalloutStatusDtoFilterInput>>;
  calloutId?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CalloutStatusDtoFilterInput>>;
  scheduledDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  status?: InputMaybe<NullableOfCalloutStatusOperationFilterInput>;
};

export type CalloutStatusDtoSortInput = {
  calloutId?: InputMaybe<SortEnumType>;
  scheduledDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type CalloutStatusesQueryInput = {
  calloutIds?: InputMaybe<Array<Scalars['Int']>>;
};

export enum CalloutType {
  Audit = 'Audit',
  Chargeable = 'Chargeable',
  Drainage = 'Drainage',
  Electrical = 'Electrical',
  Estimate = 'Estimate',
  FiveYearElectricalInspection = 'FiveYearElectricalInspection',
  Heating = 'Heating',
  Plumbing = 'Plumbing',
  Recall = 'Recall',
  RecallService = 'RecallService',
  Service = 'Service',
  TapsAndToilets = 'TapsAndToilets',
  Unknown = 'Unknown'
}

export type Campaign = {
  __typename?: 'Campaign';
  brand: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CampaignFilterInput = {
  and?: InputMaybe<Array<CampaignFilterInput>>;
  brand?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CampaignFilterInput>>;
  startDate?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type CampaignInput = {
  brand: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CampaignSortInput = {
  brand?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type CampaignsConnection = {
  __typename?: 'CampaignsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CampaignsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Campaign>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CampaignsEdge = {
  __typename?: 'CampaignsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Campaign;
};

export type CancelAuroraContractEventCommandInput = {
  contractId: Scalars['Uuid'];
  legacyLinkId: Scalars['Int'];
};

export type CancelAuroraContractEventPayload = {
  __typename?: 'CancelAuroraContractEventPayload';
  contractId: Scalars['Uuid'];
  legacyLinkId: Scalars['Int'];
};

export type CancelCalloutInput = {
  calloutId: Scalars['Int'];
};

export type CancelCalloutPayload = {
  __typename?: 'CancelCalloutPayload';
  calloutId: Scalars['Int'];
};

export type CancelContractCommandInput = {
  cancelDate: Scalars['DateTime'];
  cancellationCategory: CancellationCategory;
  cancellationFeePaymentMethod: CancellationFeePaymentMethod;
  cancellationFeePreviewId: Scalars['Uuid'];
  cancellationReason: CancellationReason;
  contractId: Scalars['Uuid'];
  legacyLinkId: Scalars['Int'];
};

export type CancellationAuthorisationInputModelInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  customerFriendlyId?: InputMaybe<Scalars['String']>;
  parentPaymentId: Scalars['Uuid'];
};

export enum CancellationCategory {
  Cost = 'Cost',
  CustomerDeceased = 'CustomerDeceased',
  CustomerIsStayingWithExistingProvider = 'CustomerIsStayingWithExistingProvider',
  CustomerRaisedComplaint = 'CustomerRaisedComplaint',
  Dissatisfaction = 'Dissatisfaction',
  InvalidContract = 'InvalidContract',
  MidTermChange = 'MidTermChange',
  MovingHouse = 'MovingHouse',
  MovingToOtherProvider = 'MovingToOtherProvider',
  NewBoilerInstalled = 'NewBoilerInstalled',
  NoPaymentsReceived = 'NoPaymentsReceived',
  RenewalAborted = 'RenewalAborted',
  RenewalDeclined = 'RenewalDeclined',
  TermsAndConditions = 'TermsAndConditions'
}

export type CancellationFeeBreakdown = {
  __typename?: 'CancellationFeeBreakdown';
  adminFees: SplitAmounts;
  calculationDetails: CancellationFeeCalculatorDetails;
  cancellationDate: Scalars['DateTime'];
  cancellationFees: SplitAmounts;
  contractId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  id: Scalars['Uuid'];
  insuranceFeeBreakdown: InsuranceCancellationFeeComponent;
  nonInsuranceFeeBreakdown: NonInsuranceCancellationFeeComponent;
  otherPaymentsToBeCollected: Array<Payment>;
  paymentId?: Maybe<Scalars['Uuid']>;
  totalCancellationFee: Scalars['Decimal'];
  totalFeeIncludingOtherPaymentsToBeCollected: TotalFeeIncludingOtherPaymentsToBeCollectedModel;
  upcomingPaymentId?: Maybe<Scalars['Uuid']>;
};

export type CancellationFeeCalculatorDetails = {
  __typename?: 'CancellationFeeCalculatorDetails';
  cancellationDate: Scalars['DateTime'];
  daysOnCover: Scalars['Int'];
  paid: SplitAmounts;
  premium: SplitAmounts;
  startDate: Scalars['DateTime'];
};

export enum CancellationFeePaymentMethod {
  Card = 'Card',
  Cheque = 'Cheque',
  DirectCredit = 'DirectCredit',
  DirectDebit = 'DirectDebit'
}

export type CancellationFeePreviewInput = {
  cancellationDate: Scalars['DateTime'];
  contractId: Scalars['Uuid'];
  paymentId?: InputMaybe<Scalars['Uuid']>;
};

export type CancellationPaymentAuthorisation = {
  __typename?: 'CancellationPaymentAuthorisation';
  additionalInformation?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['DateTime'];
  customerFriendlyId?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  parentPaymentId: Scalars['Uuid'];
  paymentProposal?: Maybe<PaymentProposal>;
  status: PaymentAuthorisationStatus;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export enum CancellationReason {
  AlternativeArrangements = 'AlternativeArrangements',
  BankOrEmergencyCover = 'BankOrEmergencyCover',
  BgOrScottishGas = 'BgOrScottishGas',
  BoilerMakeNotCovered = 'BoilerMakeNotCovered',
  BoilerService = 'BoilerService',
  CallCentreStaff = 'CallCentreStaff',
  CareHomeOrShelteredHousing = 'CareHomeOrShelteredHousing',
  CustomerDeceased = 'CustomerDeceased',
  DamageToProperty = 'DamageToProperty',
  DdiCancelledByCustomer = 'DdiCancelledByCustomer',
  DelayInSourcingParts = 'DelayInSourcingParts',
  DelayWithAnnualService = 'DelayWithAnnualService',
  EnergyOrUtilitySupplier = 'EnergyOrUtilitySupplier',
  EngineerAttitude = 'EngineerAttitude',
  ExcessOnDrainage = 'ExcessOnDrainage',
  FaultNotCoveredOther = 'FaultNotCoveredOther',
  FaultNotCoveredSludge = 'FaultNotCoveredSludge',
  HomeInsurance = 'HomeInsurance',
  HomeServe = 'HomeServe',
  LivingWithFamily = 'LivingWithFamily',
  LocalEngineer = 'LocalEngineer',
  MisdiagnosedFault = 'MisdiagnosedFault',
  MovingAbroad = 'MovingAbroad',
  MovingIntoRentalProperty = 'MovingIntoRentalProperty',
  MultipleBoilersOnCover = 'MultipleBoilersOnCover',
  NewBuild = 'NewBuild',
  NoAccessToFault = 'NoAccessToFault',
  NoGasOrLPG = 'NoGasOrLPG',
  NotNaturalGas = 'NotNaturalGas',
  PoorCommunication = 'PoorCommunication',
  PostcodeNotCovered = 'PostcodeNotCovered',
  PriceIncreased = 'PriceIncreased',
  PriceIsTooExpensive = 'PriceIsTooExpensive',
  ProviderMatchedPrice = 'ProviderMatchedPrice',
  ProviderOfferedADiscount = 'ProviderOfferedADiscount',
  RenewalAborted = 'RenewalAborted',
  RenewalDeclined = 'RenewalDeclined',
  RepairTime = 'RepairTime',
  ResponseTime = 'ResponseTime',
  Retain = 'Retain',
  StandardOfAnnualService = 'StandardOfAnnualService',
  StandardOfWork = 'StandardOfWork',
  TenantOrLandlord = 'TenantOrLandlord',
  TermsAndConditions = 'TermsAndConditions',
  TheBoilerHasAnInstallationIssue = 'TheBoilerHasAnInstallationIssue',
  TheBoilerIsBeyondEconomicalRepairLessThen6MonthsOrNoServiceHistory = 'TheBoilerIsBeyondEconomicalRepairLessThen6MonthsOrNoServiceHistory',
  TheBoilerIsUnderWarranty = 'TheBoilerIsUnderWarranty',
  TheBoilerMakeOrFuelIsNotCovered = 'TheBoilerMakeOrFuelIsNotCovered',
  TheCoverIsADuplicate = 'TheCoverIsADuplicate',
  TheCustomerCanNoLongerAfford = 'TheCustomerCanNoLongerAfford',
  TheCustomerChangedTheirMind = 'TheCustomerChangedTheirMind',
  TheCustomerHasACommercialProperty = 'TheCustomerHasACommercialProperty',
  TheCustomerHasAPreExistingFault = 'TheCustomerHasAPreExistingFault',
  TheCustomerHasAnnualServiceConcerns = 'TheCustomerHasAnnualServiceConcerns',
  ThirdPartyInterference = 'ThirdPartyInterference',
  ThirtyDayExclusionPeriod = 'ThirtyDayExclusionPeriod',
  ThreatOfViolenceOrAggressiveBehaviour = 'ThreatOfViolenceOrAggressiveBehaviour',
  UnableToChargeCustomer = 'UnableToChargeCustomer',
  UpgradeOrDowngradeLevelOfCover = 'UpgradeOrDowngradeLevelOfCover'
}

export type CardProcessingInput = {
  contractId: Scalars['Uuid'];
  paymentId: Scalars['Uuid'];
};

export type ChangePaymentDayCommandInput = {
  contractId: Scalars['Uuid'];
  paymentDay: Scalars['Int'];
};

export type Checks = {
  __typename?: 'Checks';
  condensateOk?: Maybe<Scalars['Int']>;
  fuseOk?: Maybe<Scalars['Int']>;
  headerTankOk?: Maybe<Scalars['Int']>;
  hwTankOk?: Maybe<Scalars['Int']>;
  pressureOk?: Maybe<Scalars['Int']>;
  sludgeOk?: Maybe<Scalars['Int']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type Communication = {
  __typename?: 'Communication';
  commId: Scalars['Uuid'];
  creationDate: Scalars['DateTime'];
  currentStatus?: Maybe<CommunicationStatus>;
  deliverTo: Scalars['String'];
  events: Array<ICommunicationEvent>;
  staticDocuments?: Maybe<Array<Document>>;
  storageKey?: Maybe<Scalars['String']>;
  tags: Array<KeyValuePairOfStringAndString>;
  template: Scalars['String'];
  templateCollection: Scalars['String'];
  templateInputJson: Scalars['String'];
  templateKey: TemplateKey;
  type: CommunicationRequestType;
};

export enum CommunicationCategory {
  CALLOUTS = 'CALLOUTS',
  COMPLAINTS = 'COMPLAINTS',
  EXTRA = 'EXTRA',
  FINANCE = 'FINANCE',
  NETWORK = 'NETWORK',
  UNSET = 'UNSET',
  UPDATE = 'UPDATE'
}

export type CommunicationComposeDeferredEvent = {
  __typename?: 'CommunicationComposeDeferredEvent';
  message: Scalars['String'];
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationComposeFailedEvent = {
  __typename?: 'CommunicationComposeFailedEvent';
  message: Scalars['String'];
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationComposedEvent = {
  __typename?: 'CommunicationComposedEvent';
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationConsumedByPrintProviderEvent = {
  __typename?: 'CommunicationConsumedByPrintProviderEvent';
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationDeliverFailedEvent = {
  __typename?: 'CommunicationDeliverFailedEvent';
  message: Scalars['String'];
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationDeliveredEvent = {
  __typename?: 'CommunicationDeliveredEvent';
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationNotFoundError = {
  __typename?: 'CommunicationNotFoundError';
  id: Scalars['Uuid'];
};

export enum CommunicationReason {
  AUTO_NOTE = 'AUTO_NOTE',
  CASE_MANAGEMENT = 'CASE_MANAGEMENT',
  CLAIMS_SUPPORT = 'CLAIMS_SUPPORT',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  DECLINED_CLAIM = 'DECLINED_CLAIM',
  EXISTING_CLAIM = 'EXISTING_CLAIM',
  HISTORICAL = 'HISTORICAL',
  MANAGER_CALLBACK = 'MANAGER_CALLBACK',
  NETWORK_ENGINEER = 'NETWORK_ENGINEER',
  NEW_CLAIM = 'NEW_CLAIM',
  RETENTION = 'RETENTION',
  SALES = 'SALES',
  SERVICE_MANAGEMENT = 'SERVICE_MANAGEMENT',
  TASK_MANAGEMENT = 'TASK_MANAGEMENT',
  UNSET = 'UNSET'
}

export type CommunicationRequestFailedEvent = {
  __typename?: 'CommunicationRequestFailedEvent';
  message: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export enum CommunicationRequestType {
  EMAIL = 'EMAIL',
  MAIL = 'MAIL',
  NONE = 'NONE',
  SMS = 'SMS'
}

export type CommunicationRequestedEvent = {
  __typename?: 'CommunicationRequestedEvent';
  requestedBy?: Maybe<Scalars['String']>;
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationSmtpEvent = {
  __typename?: 'CommunicationSmtpEvent';
  message: Scalars['String'];
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationSmtpFailureEvent = {
  __typename?: 'CommunicationSmtpFailureEvent';
  message: Scalars['String'];
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export enum CommunicationStatus {
  COMPOSED = 'COMPOSED',
  COMPOSE_DEFERRED = 'COMPOSE_DEFERRED',
  COMPOSE_FAILURE = 'COMPOSE_FAILURE',
  DELIVERED = 'DELIVERED',
  DELIVERY_FAILURE = 'DELIVERY_FAILURE',
  REQUESTED = 'REQUESTED',
  REQUEST_FAILURE = 'REQUEST_FAILURE',
  SEND_FAILURE = 'SEND_FAILURE',
  SENT = 'SENT',
  UPLOAD_FAILURE = 'UPLOAD_FAILURE'
}

export enum CommunicationType {
  CALL_IN = 'CALL_IN',
  CALL_OUT = 'CALL_OUT',
  COMPANY_SUSPENDED = 'COMPANY_SUSPENDED',
  EMAIL_IN = 'EMAIL_IN',
  EMAIL_OUT = 'EMAIL_OUT',
  MISC = 'MISC',
  UNSET = 'UNSET'
}

export type CommunicationUploadFailedEvent = {
  __typename?: 'CommunicationUploadFailedEvent';
  message: Scalars['String'];
  status: CommunicationStatus;
  timeStampUtc: Scalars['DateTime'];
};

export type CommunicationWithAttachments = {
  __typename?: 'CommunicationWithAttachments';
  attachments: Array<DocumentAttachment>;
  communication: Communication;
  restrictedChannels: Array<CommunicationRequestType>;
};

export type CommunicationWithPreSignedUrl = {
  __typename?: 'CommunicationWithPreSignedUrl';
  commId: Scalars['Uuid'];
  creationDate: Scalars['DateTime'];
  currentStatus?: Maybe<CommunicationStatus>;
  deliverTo: Scalars['String'];
  events: Array<ICommunicationEvent>;
  preSignedUrl?: Maybe<Scalars['String']>;
  staticDocuments?: Maybe<Array<Document>>;
  storageKey?: Maybe<Scalars['String']>;
  tags: Array<KeyValuePairOfStringAndString>;
  template: Scalars['String'];
  templateCollection: Scalars['String'];
  templateInputJson: Scalars['String'];
  templateKey: TemplateKey;
  type: CommunicationRequestType;
};

export type CommunicationsQueryInput = {
  filters: Array<KeyValuePairOfStringAndStringInput>;
};

export type CommunicationsSearchResults = {
  __typename?: 'CommunicationsSearchResults';
  communication?: Maybe<Array<CommunicationWithPreSignedUrl>>;
  error?: Maybe<CreateCommunicationError>;
};

export type Company = {
  __typename?: 'Company';
  additionalAbilities?: Maybe<AdditionalAbilities>;
  address?: Maybe<company_Address>;
  admin?: Maybe<Admin>;
  availability?: Maybe<CompanyAvailability>;
  bankDetails?: Maybe<BankDetails>;
  calloutAllocation?: Maybe<CalloutAllocation>;
  calloutDistance?: Maybe<Scalars['Int']>;
  calloutInstructions?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['Int']>;
  contactDetails?: Maybe<ContactDetails>;
  corgiRegNo?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Maybe<CompanyDocument>>>;
  emergencyCover?: Maybe<EmergencyCover>;
  financialInformation?: Maybe<FinancialInformation>;
  installation?: Maybe<Installation>;
  isKantan: Scalars['Boolean'];
  owner?: Maybe<Owner>;
  prohibition?: Maybe<Scalars['Boolean']>;
  prosecuted?: Maybe<Scalars['Boolean']>;
  publicLiabilityInsurance?: Maybe<PublicLiabilityInsurance>;
  rates?: Maybe<Rates>;
  servicing?: Maybe<Servicing>;
  statistics?: Maybe<CompanyStatistics>;
  status?: Maybe<Status>;
  users: Array<CompanyUser>;
  vatStatus?: Maybe<VatStatus>;
};

export type CompanyAdditionalAbilitiesPatchInput = {
  offRoad?: InputMaybe<Scalars['Boolean']>;
  unventedTicket?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyAddressPatchInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address3?: InputMaybe<Scalars['String']>;
  address4?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
};

export type CompanyAdminPatchInput = {
  hasCommercialEngineers?: InputMaybe<Scalars['Boolean']>;
  hasDedicatedCallHandlers?: InputMaybe<Scalars['Boolean']>;
  hasPriority?: InputMaybe<Scalars['Boolean']>;
  isOem?: InputMaybe<Scalars['Boolean']>;
  numberOfEngineers?: InputMaybe<Scalars['Int']>;
};

export type CompanyAvailability = {
  __typename?: 'CompanyAvailability';
  companyId: Scalars['Int'];
  isAvailable: Scalars['Boolean'];
  unavailableDates?: Maybe<Array<Maybe<CompanyUnavailableDates>>>;
};

export type CompanyBankDetailsPatchInput = {
  billingStatus?: InputMaybe<GiBillingStatus>;
  selfBillingStatus?: InputMaybe<SelfBillingStatus>;
};

export type CompanyByPostcodeDistance = {
  __typename?: 'CompanyByPostcodeDistance';
  companyId: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  contactForename?: Maybe<Scalars['String']>;
  contactSurname?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<CompanyContractStatus>;
  distance: Scalars['Float'];
  elecRate?: Maybe<Scalars['Decimal']>;
  elecRateOoh?: Maybe<Scalars['Decimal']>;
  electrical?: Maybe<Scalars['Boolean']>;
  hourlyRate?: Maybe<Scalars['Decimal']>;
  hourlyRateOoh?: Maybe<Scalars['Decimal']>;
  postcode?: Maybe<Scalars['String']>;
  serviceAndRepair?: Maybe<Scalars['Boolean']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  serviceOnly?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CompanyStatus>;
};

export type CompanyByPostcodeDistanceCollectionSegment = {
  __typename?: 'CompanyByPostcodeDistanceCollectionSegment';
  items?: Maybe<Array<Maybe<CompanyByPostcodeDistance>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CompanyByPostcodeDistanceFilterInput = {
  and?: InputMaybe<Array<CompanyByPostcodeDistanceFilterInput>>;
  companyId?: InputMaybe<ComparableInt32OperationFilterInput>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  contactForename?: InputMaybe<StringOperationFilterInput>;
  contactSurname?: InputMaybe<StringOperationFilterInput>;
  contractStatus?: InputMaybe<NullableOfContractStatusOperationFilterInput>;
  distance?: InputMaybe<ComparableDoubleOperationFilterInput>;
  elecRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  elecRateOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  electrical?: InputMaybe<BooleanOperationFilterInput>;
  hourlyRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  hourlyRateOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  or?: InputMaybe<Array<CompanyByPostcodeDistanceFilterInput>>;
  postcode?: InputMaybe<StringOperationFilterInput>;
  serviceAndRepair?: InputMaybe<BooleanOperationFilterInput>;
  serviceCharge?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  serviceOnly?: InputMaybe<BooleanOperationFilterInput>;
  status?: InputMaybe<NullableOfCompanyStatusOperationFilterInput>;
};

export type CompanyByPostcodeDistanceSortInput = {
  companyId?: InputMaybe<SortEnumType>;
  companyName?: InputMaybe<SortEnumType>;
  contactForename?: InputMaybe<SortEnumType>;
  contactSurname?: InputMaybe<SortEnumType>;
  contractStatus?: InputMaybe<SortEnumType>;
  distance?: InputMaybe<SortEnumType>;
  elecRate?: InputMaybe<SortEnumType>;
  elecRateOoh?: InputMaybe<SortEnumType>;
  electrical?: InputMaybe<SortEnumType>;
  hourlyRate?: InputMaybe<SortEnumType>;
  hourlyRateOoh?: InputMaybe<SortEnumType>;
  postcode?: InputMaybe<SortEnumType>;
  serviceAndRepair?: InputMaybe<SortEnumType>;
  serviceCharge?: InputMaybe<SortEnumType>;
  serviceOnly?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type CompanyCalloutAllocationPatchInput = {
  acceptCallouts?: InputMaybe<Scalars['Boolean']>;
  notifyMethod?: InputMaybe<CompanyCalloutNotifyMethod>;
};

export enum CompanyCalloutNotifyMethod {
  Email = 'Email',
  EmailAndSms = 'EmailAndSms'
}

export type CompanyCollectionSegment = {
  __typename?: 'CompanyCollectionSegment';
  items?: Maybe<Array<Maybe<Company>>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type CompanyContactDetailsPatchInput = {
  calloutEmail?: InputMaybe<Scalars['String']>;
  calloutMobile?: InputMaybe<Scalars['String']>;
  calloutTelNumber?: InputMaybe<Scalars['String']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  primaryContact?: InputMaybe<Scalars['String']>;
  telNumber?: InputMaybe<Scalars['String']>;
};

export enum CompanyContractStatus {
  Approved = 'Approved',
  ApprovedHolding = 'ApprovedHolding',
  DeadNetwork = 'DeadNetwork',
  DeadWarrantyPlus = 'DeadWarrantyPlus',
  Declined = 'Declined',
  Duplicate = 'Duplicate',
  LastChance = 'LastChance',
  Live = 'Live',
  None = 'None',
  PlExpired = 'PlExpired',
  Quarantine = 'Quarantine',
  Remail = 'Remail',
  Submitted = 'Submitted',
  Suspended = 'Suspended',
  WpNetworkUpgrade = 'WpNetworkUpgrade'
}

export type CompanyDocument = {
  __typename?: 'CompanyDocument';
  companyId: Scalars['Int'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  uploadDate: Scalars['DateTime'];
  uri?: Maybe<Scalars['String']>;
};

export type CompanyEmergencyCoverPatchInput = {
  emergency2Hr?: InputMaybe<Scalars['Boolean']>;
  emergency24Hr?: InputMaybe<Scalars['Boolean']>;
  evenings?: InputMaybe<Scalars['Boolean']>;
  weekends?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyFilterInput = {
  additionalAbilities?: InputMaybe<AdditionalAbilitiesFilterInput>;
  address?: InputMaybe<company_AddressFilterInput>;
  admin?: InputMaybe<AdminFilterInput>;
  and?: InputMaybe<Array<CompanyFilterInput>>;
  bankDetails?: InputMaybe<BankDetailsFilterInput>;
  calloutAllocation?: InputMaybe<CalloutAllocationFilterInput>;
  calloutDistance?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  calloutInstructions?: InputMaybe<StringOperationFilterInput>;
  companyId?: InputMaybe<ComparableInt32OperationFilterInput>;
  companyName?: InputMaybe<StringOperationFilterInput>;
  companyNumber?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  contactDetails?: InputMaybe<ContactDetailsFilterInput>;
  corgiRegNo?: InputMaybe<StringOperationFilterInput>;
  emergencyCover?: InputMaybe<EmergencyCoverFilterInput>;
  financialInformation?: InputMaybe<FinancialInformationFilterInput>;
  installation?: InputMaybe<InstallationFilterInput>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
  owner?: InputMaybe<OwnerFilterInput>;
  prohibition?: InputMaybe<BooleanOperationFilterInput>;
  prosecuted?: InputMaybe<BooleanOperationFilterInput>;
  publicLiabilityInsurance?: InputMaybe<PublicLiabilityInsuranceFilterInput>;
  rates?: InputMaybe<RatesFilterInput>;
  servicing?: InputMaybe<ServicingFilterInput>;
  status?: InputMaybe<StatusFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
  vatStatus?: InputMaybe<VatStatusFilterInput>;
};

export type CompanyFinancialInformationPatchInput = {
  priceBasis?: InputMaybe<PriceBasis>;
};

export type CompanyInstallationPatchInput = {
  bathrooms?: InputMaybe<Scalars['Boolean']>;
  conventional?: InputMaybe<Scalars['Boolean']>;
  fireplaces?: InputMaybe<Scalars['Boolean']>;
  homeHeat?: InputMaybe<Scalars['Boolean']>;
  numBoilers?: InputMaybe<Scalars['Int']>;
  solar?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyOwnerPatchInput = {
  forename?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CompanyPatchInput = {
  additionalAbilities?: InputMaybe<CompanyAdditionalAbilitiesPatchInput>;
  address?: InputMaybe<CompanyAddressPatchInput>;
  admin?: InputMaybe<CompanyAdminPatchInput>;
  bankDetails?: InputMaybe<CompanyBankDetailsPatchInput>;
  calloutAllocation?: InputMaybe<CompanyCalloutAllocationPatchInput>;
  calloutDistance?: InputMaybe<Scalars['Int']>;
  calloutInstructions?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyNumber?: InputMaybe<Scalars['Int']>;
  contactDetails?: InputMaybe<CompanyContactDetailsPatchInput>;
  corgiRegNo?: InputMaybe<Scalars['String']>;
  emergencyCover?: InputMaybe<CompanyEmergencyCoverPatchInput>;
  financialInformation?: InputMaybe<CompanyFinancialInformationPatchInput>;
  installation?: InputMaybe<CompanyInstallationPatchInput>;
  isKantan?: InputMaybe<Scalars['Boolean']>;
  owner?: InputMaybe<CompanyOwnerPatchInput>;
  prohibition?: InputMaybe<Scalars['Boolean']>;
  prosecuted?: InputMaybe<Scalars['Boolean']>;
  publicLiabilityInsurance?: InputMaybe<CompanyPublicLiabilityInsurancePatchInput>;
  rates?: InputMaybe<CompanyRatesPatchInput>;
  servicing?: InputMaybe<CompanyServicingPatchInput>;
  status?: InputMaybe<CompanyStatusPatchInput>;
  vatStatus?: InputMaybe<CompanyVatStatusPatchInput>;
};

export type CompanyPublicLiabilityInsurancePatchInput = {
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  hasCover?: InputMaybe<Scalars['Boolean']>;
  insurer?: InputMaybe<Scalars['String']>;
  policyLimit?: InputMaybe<Scalars['Decimal']>;
  policyRef?: InputMaybe<Scalars['String']>;
};

export type CompanyRatesPatchInput = {
  elecCharge?: InputMaybe<Scalars['Decimal']>;
  elecChargeOoh?: InputMaybe<Scalars['Decimal']>;
  elecRate?: InputMaybe<Scalars['Decimal']>;
  elecRateOoh?: InputMaybe<Scalars['Decimal']>;
  fixedRepairCharge?: InputMaybe<Scalars['Decimal']>;
  hourlyRate?: InputMaybe<Scalars['Decimal']>;
  hourlyRateOoh?: InputMaybe<Scalars['Decimal']>;
  plumbCharge?: InputMaybe<Scalars['Decimal']>;
  plumbChargeOoh?: InputMaybe<Scalars['Decimal']>;
  plumbRate?: InputMaybe<Scalars['Decimal']>;
  plumbRateOoh?: InputMaybe<Scalars['Decimal']>;
  repairChargeOoh?: InputMaybe<Scalars['Decimal']>;
  serviceCharge?: InputMaybe<Scalars['Decimal']>;
  serviceRate?: InputMaybe<Scalars['Decimal']>;
  sparesMarkup?: InputMaybe<Scalars['Float']>;
};

export type CompanyServicingPatchInput = {
  electrical?: InputMaybe<Scalars['Boolean']>;
  plumbing?: InputMaybe<Scalars['Boolean']>;
  powerflush?: InputMaybe<Scalars['Boolean']>;
  serviceAndRepair?: InputMaybe<Scalars['Boolean']>;
  serviceOnly?: InputMaybe<Scalars['Boolean']>;
  tapsAndToilets?: InputMaybe<Scalars['Boolean']>;
};

export type CompanySortInput = {
  additionalAbilities?: InputMaybe<AdditionalAbilitiesSortInput>;
  address?: InputMaybe<company_AddressSortInput>;
  admin?: InputMaybe<AdminSortInput>;
  bankDetails?: InputMaybe<BankDetailsSortInput>;
  calloutAllocation?: InputMaybe<CalloutAllocationSortInput>;
  calloutDistance?: InputMaybe<SortEnumType>;
  calloutInstructions?: InputMaybe<SortEnumType>;
  companyId?: InputMaybe<SortEnumType>;
  companyName?: InputMaybe<SortEnumType>;
  companyNumber?: InputMaybe<SortEnumType>;
  contactDetails?: InputMaybe<ContactDetailsSortInput>;
  corgiRegNo?: InputMaybe<SortEnumType>;
  emergencyCover?: InputMaybe<EmergencyCoverSortInput>;
  financialInformation?: InputMaybe<FinancialInformationSortInput>;
  installation?: InputMaybe<InstallationSortInput>;
  owner?: InputMaybe<OwnerSortInput>;
  prohibition?: InputMaybe<SortEnumType>;
  prosecuted?: InputMaybe<SortEnumType>;
  publicLiabilityInsurance?: InputMaybe<PublicLiabilityInsuranceSortInput>;
  rates?: InputMaybe<RatesSortInput>;
  servicing?: InputMaybe<ServicingSortInput>;
  status?: InputMaybe<StatusSortInput>;
  vatStatus?: InputMaybe<VatStatusSortInput>;
};

export type CompanyStatistics = {
  __typename?: 'CompanyStatistics';
  companyId: Scalars['Int'];
  pastSixMonths?: Maybe<CompanyStatisticsDetail>;
  pastThousandDays?: Maybe<CompanyStatisticsDetail>;
};

export type CompanyStatisticsDetail = {
  __typename?: 'CompanyStatisticsDetail';
  averageCost: Scalars['Float'];
  averageCostWarningsSent: Scalars['Int'];
  averageHours: Scalars['Float'];
  calloutsAttended: Scalars['Int'];
  calloutsConfirmedWithinHour: Scalars['Int'];
  calloutsNotified: Scalars['Int'];
  calloutsPaid: Scalars['Int'];
  complaints: Scalars['Int'];
  customerRating: Scalars['Float'];
  editRequestsSent: Scalars['Int'];
  hoursCompromisesAccepted: Scalars['Int'];
  hoursCompromisesRejected: Scalars['Int'];
  hoursCompromisesTotal: Scalars['Int'];
  invoiceProblems: Scalars['Int'];
  invoicesDisputed: Scalars['Int'];
  invoicesExcluded: Scalars['Int'];
  invoicesFlagged: Scalars['Int'];
  invoicesTotal: Scalars['Int'];
  networkAverageCost: Scalars['Float'];
  networkAverageFaultFoundAtServiceRatio: Scalars['Float'];
  networkAverageHours: Scalars['Float'];
  outOfHoursIssues: Scalars['Int'];
  outOfHoursTotal: Scalars['Int'];
  performanceBand: Scalars['Int'];
  priceBand: Scalars['Float'];
  recentCalloutsAverageCost: Scalars['Float'];
  serviceRecallsAttended: Scalars['Int'];
  servicesAttended: Scalars['Int'];
  suspiciousInvoices: Scalars['Int'];
};

export enum CompanyStatus {
  Electrician = 'Electrician',
  Network = 'Network',
  Specify = 'Specify',
  WarrantyPlus = 'WarrantyPlus'
}

export type CompanyStatusPatchInput = {
  contractStatus?: InputMaybe<CompanyContractStatus>;
  rating?: InputMaybe<Rating>;
  scStatus?: InputMaybe<CompanyStatus>;
};

export type CompanyUnavailableDates = {
  __typename?: 'CompanyUnavailableDates';
  from?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  to?: Maybe<Scalars['DateTime']>;
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  companyId: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type CompanyVatStatusPatchInput = {
  isRegistered?: InputMaybe<Scalars['Boolean']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
};

export type ComparableDateTimeOffsetOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<Scalars['Decimal']>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['Uuid']>;
  gt?: InputMaybe<Scalars['Uuid']>;
  gte?: InputMaybe<Scalars['Uuid']>;
  in?: InputMaybe<Array<Scalars['Uuid']>>;
  lt?: InputMaybe<Scalars['Uuid']>;
  lte?: InputMaybe<Scalars['Uuid']>;
  neq?: InputMaybe<Scalars['Uuid']>;
  ngt?: InputMaybe<Scalars['Uuid']>;
  ngte?: InputMaybe<Scalars['Uuid']>;
  nin?: InputMaybe<Array<Scalars['Uuid']>>;
  nlt?: InputMaybe<Scalars['Uuid']>;
  nlte?: InputMaybe<Scalars['Uuid']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOffsetOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableNullableOfGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['Uuid']>;
  gt?: InputMaybe<Scalars['Uuid']>;
  gte?: InputMaybe<Scalars['Uuid']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  lt?: InputMaybe<Scalars['Uuid']>;
  lte?: InputMaybe<Scalars['Uuid']>;
  neq?: InputMaybe<Scalars['Uuid']>;
  ngt?: InputMaybe<Scalars['Uuid']>;
  ngte?: InputMaybe<Scalars['Uuid']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  nlt?: InputMaybe<Scalars['Uuid']>;
  nlte?: InputMaybe<Scalars['Uuid']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type Complaint = {
  __typename?: 'Complaint';
  complaintId?: Maybe<Scalars['Int']>;
  complaintStage?: Maybe<Scalars['Int']>;
  fromComplaint?: Maybe<Scalars['Boolean']>;
};

export type ComplaintPaymentLink = {
  __typename?: 'ComplaintPaymentLink';
  complaintId: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  paymentId: Scalars['Uuid'];
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type Contact = {
  __typename?: 'Contact';
  homePlanContact?: Maybe<Scalars['Boolean']>;
  homePlanOptions?: Maybe<PlanOptions>;
  isMailOnly: Scalars['Boolean'];
  otherOrgContact: Array<Organization>;
  otherOrgOptions?: Maybe<PlanOptions>;
};

export type ContactDetails = {
  __typename?: 'ContactDetails';
  calloutEmail?: Maybe<Scalars['String']>;
  calloutMobile?: Maybe<Scalars['String']>;
  calloutTelNumber?: Maybe<Scalars['String']>;
  faxNumber?: Maybe<Scalars['String']>;
  primaryContact?: Maybe<Scalars['String']>;
  telNumber?: Maybe<Scalars['String']>;
};

export type ContactDetailsFilterInput = {
  and?: InputMaybe<Array<ContactDetailsFilterInput>>;
  calloutEmail?: InputMaybe<StringOperationFilterInput>;
  calloutMobile?: InputMaybe<StringOperationFilterInput>;
  calloutTelNumber?: InputMaybe<StringOperationFilterInput>;
  faxNumber?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ContactDetailsFilterInput>>;
  primaryContact?: InputMaybe<StringOperationFilterInput>;
  telNumber?: InputMaybe<StringOperationFilterInput>;
};

export type ContactDetailsSortInput = {
  calloutEmail?: InputMaybe<SortEnumType>;
  calloutMobile?: InputMaybe<SortEnumType>;
  calloutTelNumber?: InputMaybe<SortEnumType>;
  faxNumber?: InputMaybe<SortEnumType>;
  primaryContact?: InputMaybe<SortEnumType>;
  telNumber?: InputMaybe<SortEnumType>;
};

export type ContactInput = {
  homePlanContact?: InputMaybe<Scalars['Boolean']>;
  homePlanOptions?: InputMaybe<PlanOptionsInput>;
  isMailOnly: Scalars['Boolean'];
  otherOrgContact: Array<Organization>;
  otherOrgOptions?: InputMaybe<PlanOptionsInput>;
};

export type Contract = {
  __typename?: 'Contract';
  address: Address;
  addressId: Scalars['Uuid'];
  brandId: Scalars['Int'];
  callouts?: Maybe<Array<Maybe<Callout>>>;
  claimOffDate?: Maybe<Scalars['DateTime']>;
  contractBoiler?: Maybe<ContractBoiler>;
  contractBoilerId?: Maybe<Scalars['Uuid']>;
  contractDurationMonth: Scalars['Int'];
  contractFriendlyId: Scalars['String'];
  contractId: Scalars['Uuid'];
  contractPreference: ContractPreference;
  contractPremiumAmount: Scalars['Decimal'];
  contractPremiumInsuranceAmount?: Maybe<Scalars['Decimal']>;
  contractPremiumNonInsurance?: Maybe<Scalars['Decimal']>;
  contractStatus: ContractStatus;
  contractYear: Scalars['Int'];
  coolingOffEndDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['Uuid'];
  declinedCallouts?: Maybe<Array<Maybe<DeclinedCallout>>>;
  endDate: Scalars['DateTime'];
  enquiryValidationStatus?: Maybe<EnquiryValidationStatusType>;
  externalContractId?: Maybe<Scalars['String']>;
  initialProductVersionId?: Maybe<Scalars['String']>;
  legacyLinkId: Scalars['Int'];
  legalEntityId: Scalars['Uuid'];
  modifiedAt: Scalars['DateTime'];
  offerId?: Maybe<Scalars['Uuid']>;
  ownershipType: OwnershipType;
  preferenceId: Scalars['Uuid'];
  previousContract?: Maybe<Contract>;
  previousContractId?: Maybe<Scalars['Uuid']>;
  previousProvider?: Maybe<Scalars['String']>;
  productAmount: Scalars['Decimal'];
  productInsuranceAmount: Scalars['Decimal'];
  productName?: Maybe<Scalars['String']>;
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersion?: Maybe<ProductVersion>;
  productVersionId: Scalars['Uuid'];
  promocodeId?: Maybe<Scalars['Uuid']>;
  promotionAmount: Scalars['Decimal'];
  promotionDurationMonth?: Maybe<Scalars['Int']>;
  promotionInsuranceAmount?: Maybe<Scalars['Decimal']>;
  promotionNonInsuranceAmount?: Maybe<Scalars['Decimal']>;
  renewalInteractions: Array<ContractRenewalInteraction>;
  serviceMonth?: Maybe<Scalars['Int']>;
  signUpId?: Maybe<Scalars['Uuid']>;
  signUpMethod: Scalars['String'];
  startDate: Scalars['DateTime'];
  subBrand?: Maybe<Scalars['String']>;
  totalCost: Scalars['Decimal'];
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
  underwriterId: Scalars['Uuid'];
  underwriterName?: Maybe<Scalars['String']>;
  underwriterStatus?: Maybe<UnderwriterStatusType>;
};

export type ContractBoiler = {
  __typename?: 'ContractBoiler';
  applianceType?: Maybe<ApplianceType>;
  boilerManufacturerId?: Maybe<Scalars['Uuid']>;
  boilerManufacturerName?: Maybe<Scalars['String']>;
  contractBoilerId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  fuelType: FuelType;
  installedOn?: Maybe<Scalars['DateTime']>;
  model?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  sludge?: Maybe<SludgeWarning>;
  sludgeWarning?: Maybe<Scalars['DateTime']>;
  warrantyDurationYears?: Maybe<Scalars['Int']>;
};

export type ContractBoilerFilterInput = {
  and?: InputMaybe<Array<ContractBoilerFilterInput>>;
  applianceType?: InputMaybe<NullableOfApplianceTypeOperationFilterInput>;
  boilerManufacturerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  contractBoilerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  fuelType?: InputMaybe<FuelTypeOperationFilterInput>;
  installedOn?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  model?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ContractBoilerFilterInput>>;
  sludge?: InputMaybe<NullableOfSludgeWarningOperationFilterInput>;
  sludgeWarning?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  warrantyDurationYears?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type ContractBoilerSortInput = {
  applianceType?: InputMaybe<SortEnumType>;
  boilerManufacturerId?: InputMaybe<SortEnumType>;
  contractBoilerId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  fuelType?: InputMaybe<SortEnumType>;
  installedOn?: InputMaybe<SortEnumType>;
  model?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  sludge?: InputMaybe<SortEnumType>;
  sludgeWarning?: InputMaybe<SortEnumType>;
  warrantyDurationYears?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ContractBoilersConnection = {
  __typename?: 'ContractBoilersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContractBoilersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ContractBoiler>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContractBoilersEdge = {
  __typename?: 'ContractBoilersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ContractBoiler;
};

export type ContractCancelNotification = {
  __typename?: 'ContractCancelNotification';
  agent: Scalars['String'];
  cancellationCategoryId: Scalars['Int'];
  cancellationDate: Scalars['DateTime'];
  cancellationFeePreviewId: Scalars['Uuid'];
  cancellationReasonId: Scalars['Int'];
  contract: Contract;
  contractCancelNotificationId: Scalars['Uuid'];
  contractId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  originalEndDate: Scalars['DateTime'];
  undo: Scalars['Boolean'];
};

export type ContractCancelNotificationFilterInput = {
  agent?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ContractCancelNotificationFilterInput>>;
  cancellationCategoryId?: InputMaybe<ComparableInt32OperationFilterInput>;
  cancellationDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  cancellationFeePreviewId?: InputMaybe<ComparableGuidOperationFilterInput>;
  cancellationReasonId?: InputMaybe<ComparableInt32OperationFilterInput>;
  contract?: InputMaybe<ContractFilterInput>;
  contractCancelNotificationId?: InputMaybe<ComparableGuidOperationFilterInput>;
  contractId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ContractCancelNotificationFilterInput>>;
  originalEndDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  undo?: InputMaybe<BooleanOperationFilterInput>;
};

export type ContractCancelNotificationPayload = {
  __typename?: 'ContractCancelNotificationPayload';
  contractCancelNotification: ContractCancelNotification;
};

export type ContractCancelNotificationSortInput = {
  agent?: InputMaybe<SortEnumType>;
  cancellationCategoryId?: InputMaybe<SortEnumType>;
  cancellationDate?: InputMaybe<SortEnumType>;
  cancellationFeePreviewId?: InputMaybe<SortEnumType>;
  cancellationReasonId?: InputMaybe<SortEnumType>;
  contract?: InputMaybe<ContractSortInput>;
  contractCancelNotificationId?: InputMaybe<SortEnumType>;
  contractId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  originalEndDate?: InputMaybe<SortEnumType>;
  undo?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ContractCancelNotificationsConnection = {
  __typename?: 'ContractCancelNotificationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContractCancelNotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ContractCancelNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContractCancelNotificationsEdge = {
  __typename?: 'ContractCancelNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ContractCancelNotification;
};

export type ContractFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<ComparableGuidOperationFilterInput>;
  and?: InputMaybe<Array<ContractFilterInput>>;
  auroraToGiLink?: InputMaybe<AuroraToGiLinkFilterInput>;
  auroraToGiLinkId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  brandId?: InputMaybe<ComparableInt32OperationFilterInput>;
  claimOffDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  contractBoiler?: InputMaybe<ContractBoilerFilterInput>;
  contractBoilerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  contractDurationMonth?: InputMaybe<ComparableInt32OperationFilterInput>;
  contractFriendlyId?: InputMaybe<StringOperationFilterInput>;
  contractId?: InputMaybe<ComparableGuidOperationFilterInput>;
  contractPreference?: InputMaybe<ContractPreferenceFilterInput>;
  contractPremiumInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  contractPremiumNonInsurance?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  contractStatus?: InputMaybe<ContractStatusOperationFilterInput>;
  contractYear?: InputMaybe<ComparableInt32OperationFilterInput>;
  coolingOffEndDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  enquiryValidationStatus?: InputMaybe<NullableOfEnquiryValidationStatusTypeOperationFilterInput>;
  externalContractId?: InputMaybe<StringOperationFilterInput>;
  initialProductVersionId?: InputMaybe<StringOperationFilterInput>;
  legacyLinkId?: InputMaybe<ComparableInt32OperationFilterInput>;
  legalEntityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  offerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  or?: InputMaybe<Array<ContractFilterInput>>;
  ownershipType?: InputMaybe<OwnershipTypeOperationFilterInput>;
  preferenceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  previousContract?: InputMaybe<ContractFilterInput>;
  previousContractId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  previousProvider?: InputMaybe<StringOperationFilterInput>;
  productInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  productNonInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  productVersionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  promocodeId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  promotionDurationMonth?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  promotionInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  promotionNonInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  renewalInteractions?: InputMaybe<ListFilterInputTypeOfContractRenewalInteractionFilterInput>;
  signUpId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  signUpMethod?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  subBrand?: InputMaybe<StringOperationFilterInput>;
  totalInsuranceCost?: InputMaybe<ComparableDecimalOperationFilterInput>;
  totalNonInsuranceCost?: InputMaybe<ComparableDecimalOperationFilterInput>;
  underwriterId?: InputMaybe<ComparableGuidOperationFilterInput>;
  underwriterStatus?: InputMaybe<NullableOfUnderwriterStatusTypeOperationFilterInput>;
};

export type ContractPayload = {
  __typename?: 'ContractPayload';
  contract: Contract;
};

export type ContractPreference = {
  __typename?: 'ContractPreference';
  auroraBilling: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  paymentDay: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  paymentSchedule: PaymentScheduleType;
  preferenceId: Scalars['Uuid'];
  toRenew: Scalars['Int'];
};

export type ContractPreferenceFilterInput = {
  and?: InputMaybe<Array<ContractPreferenceFilterInput>>;
  auroraBilling?: InputMaybe<BooleanOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ContractPreferenceFilterInput>>;
  paymentDay?: InputMaybe<ComparableInt32OperationFilterInput>;
  paymentMethod?: InputMaybe<ComparableInt32OperationFilterInput>;
  paymentSchedule?: InputMaybe<PaymentScheduleTypeOperationFilterInput>;
  preferenceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  toRenew?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ContractPreferenceInput = {
  auroraBilling: Scalars['Boolean'];
  paymentDay: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  paymentSchedule?: InputMaybe<PaymentScheduleType>;
  preferenceId?: InputMaybe<Scalars['Uuid']>;
  toRenew: Scalars['Int'];
};

export type ContractPreferencePayload = {
  __typename?: 'ContractPreferencePayload';
  contractPreference: ContractPreference;
};

export type ContractPreferenceSortInput = {
  auroraBilling?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  paymentDay?: InputMaybe<SortEnumType>;
  paymentMethod?: InputMaybe<SortEnumType>;
  paymentSchedule?: InputMaybe<SortEnumType>;
  preferenceId?: InputMaybe<SortEnumType>;
  toRenew?: InputMaybe<SortEnumType>;
};

export type ContractRenewalInteraction = {
  __typename?: 'ContractRenewalInteraction';
  contract: Contract;
  contractId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  modifiedAt: Scalars['DateTime'];
  prevContractPremiumInsuranceAmount?: Maybe<Scalars['Decimal']>;
  prevProductInsuranceAmount: Scalars['Decimal'];
  prevProductNonInsuranceAmount: Scalars['Decimal'];
  prevPromotionInsuranceAmount?: Maybe<Scalars['Decimal']>;
  prevPromotionNonInsuranceAmount?: Maybe<Scalars['Decimal']>;
  prevTotalInsuranceCost?: Maybe<Scalars['Decimal']>;
  prevTotalNonInsuranceCost?: Maybe<Scalars['Decimal']>;
  previousProductVersionId: Scalars['Uuid'];
  renewalDeclineReason?: Maybe<RenewalDeclineReason>;
  renewalDiscount: Scalars['Int'];
  renewalInteractionId: Scalars['Uuid'];
  renewalStatus: RenewalStatus;
};

export type ContractRenewalInteractionFilterInput = {
  and?: InputMaybe<Array<ContractRenewalInteractionFilterInput>>;
  contract?: InputMaybe<ContractFilterInput>;
  contractId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ContractRenewalInteractionFilterInput>>;
  prevContractPremiumInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  prevProductInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  prevProductNonInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  prevPromotionInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  prevPromotionNonInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  prevTotalInsuranceCost?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  prevTotalNonInsuranceCost?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  previousProductVersionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  renewalDeclineReason?: InputMaybe<NullableOfRenewalDeclineReasonOperationFilterInput>;
  renewalDiscount?: InputMaybe<ComparableInt32OperationFilterInput>;
  renewalInteractionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  renewalStatus?: InputMaybe<RenewalStatusOperationFilterInput>;
};

export type ContractSortInput = {
  address?: InputMaybe<AddressSortInput>;
  addressId?: InputMaybe<SortEnumType>;
  auroraToGiLink?: InputMaybe<AuroraToGiLinkSortInput>;
  auroraToGiLinkId?: InputMaybe<SortEnumType>;
  brandId?: InputMaybe<SortEnumType>;
  claimOffDate?: InputMaybe<SortEnumType>;
  contractBoiler?: InputMaybe<ContractBoilerSortInput>;
  contractBoilerId?: InputMaybe<SortEnumType>;
  contractDurationMonth?: InputMaybe<SortEnumType>;
  contractFriendlyId?: InputMaybe<SortEnumType>;
  contractId?: InputMaybe<SortEnumType>;
  contractPreference?: InputMaybe<ContractPreferenceSortInput>;
  contractPremiumInsuranceAmount?: InputMaybe<SortEnumType>;
  contractPremiumNonInsurance?: InputMaybe<SortEnumType>;
  contractStatus?: InputMaybe<SortEnumType>;
  contractYear?: InputMaybe<SortEnumType>;
  coolingOffEndDate?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerId?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  enquiryValidationStatus?: InputMaybe<SortEnumType>;
  externalContractId?: InputMaybe<SortEnumType>;
  initialProductVersionId?: InputMaybe<SortEnumType>;
  legacyLinkId?: InputMaybe<SortEnumType>;
  legalEntityId?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  offerId?: InputMaybe<SortEnumType>;
  ownershipType?: InputMaybe<SortEnumType>;
  preferenceId?: InputMaybe<SortEnumType>;
  previousContract?: InputMaybe<ContractSortInput>;
  previousContractId?: InputMaybe<SortEnumType>;
  previousProvider?: InputMaybe<SortEnumType>;
  productInsuranceAmount?: InputMaybe<SortEnumType>;
  productNonInsuranceAmount?: InputMaybe<SortEnumType>;
  productVersionId?: InputMaybe<SortEnumType>;
  promocodeId?: InputMaybe<SortEnumType>;
  promotionDurationMonth?: InputMaybe<SortEnumType>;
  promotionInsuranceAmount?: InputMaybe<SortEnumType>;
  promotionNonInsuranceAmount?: InputMaybe<SortEnumType>;
  signUpId?: InputMaybe<SortEnumType>;
  signUpMethod?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  subBrand?: InputMaybe<SortEnumType>;
  totalInsuranceCost?: InputMaybe<SortEnumType>;
  totalNonInsuranceCost?: InputMaybe<SortEnumType>;
  underwriterId?: InputMaybe<SortEnumType>;
  underwriterStatus?: InputMaybe<SortEnumType>;
};

export enum ContractStatus {
  Aborted = 'Aborted',
  Active = 'Active',
  CancelRequest = 'CancelRequest',
  Cancelled = 'Cancelled',
  Considering = 'Considering',
  Declined = 'Declined',
  Expired = 'Expired',
  Incomplete = 'Incomplete',
  NewEnquiry = 'NewEnquiry',
  PendingActivation = 'PendingActivation',
  PendingRenewal = 'PendingRenewal',
  Renewal = 'Renewal',
  RenewalDeclined = 'RenewalDeclined',
  Suspended = 'Suspended'
}

export type ContractStatusOperationFilterInput = {
  eq?: InputMaybe<ContractStatus>;
  in?: InputMaybe<Array<ContractStatus>>;
  neq?: InputMaybe<ContractStatus>;
  nin?: InputMaybe<Array<ContractStatus>>;
};

/** A connection to a list of items. */
export type ContractsConnection = {
  __typename?: 'ContractsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContractsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Contract>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContractsEdge = {
  __typename?: 'ContractsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Contract;
};

/** A connection to a list of items. */
export type ContractsForRenewalConnection = {
  __typename?: 'ContractsForRenewalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ContractsForRenewalEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Contract>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ContractsForRenewalEdge = {
  __typename?: 'ContractsForRenewalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Contract;
};

export type CreateCommunicationError = CommunicationNotFoundError | FailedRequestDuplicationError | FailedToGetDocumentError | FailedToRequestError | FailedValidationError | TemplateNotFoundError;

export type CreateContractRenewalInput = {
  autoRenewal: Scalars['Int'];
  brandId: Scalars['Int'];
  contractEnd: Scalars['DateTime'];
  contractPremiumInsuranceAmount: Scalars['Decimal'];
  contractPremiumNonInsurance: Scalars['Decimal'];
  contractStart: Scalars['DateTime'];
  contractYear: Scalars['Int'];
  newContractId: Scalars['Uuid'];
  offerId?: InputMaybe<Scalars['Uuid']>;
  previousContractId: Scalars['Uuid'];
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promotionDurationMonth?: InputMaybe<Scalars['Int']>;
  promotionInsuranceAmount: Scalars['Decimal'];
  promotionNonInsuranceAmount: Scalars['Decimal'];
  totalInsuranceAmount: Scalars['Decimal'];
  totalNonInsuranceAmount: Scalars['Decimal'];
};

export type CreateDocumentError = DocumentNotFoundError | FailedToRequestError | FailedToUploadError | TemplateNotFoundError | ValidationFailedError;

export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload';
  documentId?: Maybe<Scalars['UUID']>;
  errors?: Maybe<Array<CreateDocumentError>>;
};

export type CreateLandlordRecordPayload = {
  __typename?: 'CreateLandlordRecordPayload';
  recordId?: Maybe<Scalars['Uuid']>;
};

export type CreateReconciliationSettlementCommandInput = {
  amount: Scalars['Decimal'];
  contractId?: InputMaybe<Scalars['Uuid']>;
  effectiveDate: Scalars['DateTime'];
  insuranceAmount: Scalars['Decimal'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentCategory: PaymentCategory;
  paymentId?: InputMaybe<Scalars['Uuid']>;
  paymentSubCategory: PaymentSubCategory;
  reconciliationSummaryId: Scalars['Uuid'];
};

export type CreateSSERenewalInput = {
  accountName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  addressId: Scalars['Uuid'];
  auroraBilling: Scalars['Boolean'];
  brandId: Scalars['Int'];
  claimOffDate?: InputMaybe<Scalars['DateTime']>;
  contractBoilerId?: InputMaybe<Scalars['Uuid']>;
  contractDurationMonth: Scalars['Int'];
  contractFriendlyId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['Uuid'];
  contractPremiumInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  contractPremiumNonInsurance?: InputMaybe<Scalars['Decimal']>;
  contractStatus: ContractStatus;
  contractYear: Scalars['Int'];
  coolingOffEndDate: Scalars['DateTime'];
  customerId: Scalars['Uuid'];
  documentCommunicationPreference?: InputMaybe<Scalars['Boolean']>;
  endDate: Scalars['DateTime'];
  enquiryValidationStatus?: InputMaybe<EnquiryValidationStatusType>;
  initialProductVersionId?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['Uuid']>;
  ownershipType: OwnershipType;
  paymentDay: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  paymentSchedule?: InputMaybe<PaymentScheduleType>;
  preferenceId?: InputMaybe<Scalars['Uuid']>;
  previousContractId?: InputMaybe<Scalars['Uuid']>;
  previousProvider?: InputMaybe<Scalars['String']>;
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promocodeId?: InputMaybe<Scalars['Uuid']>;
  promotionDurationMonth?: InputMaybe<Scalars['Int']>;
  promotionInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  promotionNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  serviceDueDate?: InputMaybe<Scalars['DateTime']>;
  serviceMonth?: InputMaybe<Scalars['Int']>;
  signUpId?: InputMaybe<Scalars['Uuid']>;
  signUpMethod: Scalars['String'];
  sortCode?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  subBrand?: InputMaybe<Scalars['String']>;
  toRenew: Scalars['Int'];
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
  underwriterStatus?: InputMaybe<UnderwriterStatusType>;
};

export type CreateServiceRecordInput = {
  contractId: Scalars['Uuid'];
  month: Month;
};

export type CreateServiceRecordPayload = {
  __typename?: 'CreateServiceRecordPayload';
  recordId: Scalars['Uuid'];
};

export type Customer = {
  __typename?: 'Customer';
  address: Address;
  addressId: Scalars['Uuid'];
  authorisedPersonFullName?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  createdAt: Scalars['DateTime'];
  createdOn: Scalars['DateTime'];
  customerDefaultPreference?: Maybe<CustomerDefaultPreference>;
  customerFriendlyId: Scalars['String'];
  customerId: Scalars['Uuid'];
  dob?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  externalCustomerId?: Maybe<Scalars['String']>;
  forename: Scalars['String'];
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  marketingPermissions?: Maybe<MarketingPermissions>;
  modifiedAt: Scalars['DateTime'];
  ovoCustomerId?: Maybe<Scalars['String']>;
  primaryPhone: Scalars['String'];
  secondaryPhone?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  thirdPhone?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CustomerBankAccount = {
  __typename?: 'CustomerBankAccount';
  accountNumber: Scalars['String'];
  bankAccountCustomerName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerBankAccountId: Scalars['Uuid'];
  customerId: Scalars['Uuid'];
  modifiedAt: Scalars['DateTime'];
  sortCode: Scalars['String'];
};

export type CustomerBankDetails = {
  __typename?: 'CustomerBankDetails';
  accountNumber: Scalars['String'];
  bankAccountCustomerName: Scalars['String'];
  contractId: Scalars['Uuid'];
  sortCode: Scalars['String'];
};

export type CustomerConsent = {
  __typename?: 'CustomerConsent';
  companyId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerConsentId: Scalars['Uuid'];
  customerId: Scalars['Uuid'];
  marketingPreference: MarketingPreference;
  modifiedAt: Scalars['DateTime'];
};

export type CustomerCreationResult = {
  __typename?: 'CustomerCreationResult';
  contractFriendlyId: Scalars['String'];
  contractId: Scalars['Uuid'];
  customerId: Scalars['Uuid'];
  legacyLinkId: Scalars['Int'];
};

export type CustomerDefaultPreference = {
  __typename?: 'CustomerDefaultPreference';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['Uuid'];
  documentCommunication: DocumentCommunicationPreference;
  modifiedAt: Scalars['DateTime'];
  paymentDay: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  paymentSchedule: PaymentScheduleType;
  preferenceId: Scalars['Uuid'];
  toRenew: Scalars['Int'];
};

export type CustomerDefaultPreferenceFilterInput = {
  and?: InputMaybe<Array<CustomerDefaultPreferenceFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  documentCommunication?: InputMaybe<DocumentCommunicationPreferenceOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<CustomerDefaultPreferenceFilterInput>>;
  paymentDay?: InputMaybe<ComparableInt32OperationFilterInput>;
  paymentMethod?: InputMaybe<ComparableInt32OperationFilterInput>;
  paymentSchedule?: InputMaybe<PaymentScheduleTypeOperationFilterInput>;
  preferenceId?: InputMaybe<ComparableGuidOperationFilterInput>;
  toRenew?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type CustomerDefaultPreferenceInput = {
  customerId: Scalars['Uuid'];
  documentCommunication: DocumentCommunicationPreference;
  paymentDay: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  paymentSchedule?: InputMaybe<PaymentScheduleType>;
  preferenceId?: InputMaybe<Scalars['Uuid']>;
  toRenew: Scalars['Int'];
};

export type CustomerDefaultPreferencePayload = {
  __typename?: 'CustomerDefaultPreferencePayload';
  customerDefaultPreference: CustomerDefaultPreference;
};

export type CustomerDefaultPreferenceSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerId?: InputMaybe<SortEnumType>;
  documentCommunication?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  paymentDay?: InputMaybe<SortEnumType>;
  paymentMethod?: InputMaybe<SortEnumType>;
  paymentSchedule?: InputMaybe<SortEnumType>;
  preferenceId?: InputMaybe<SortEnumType>;
  toRenew?: InputMaybe<SortEnumType>;
};

export type CustomerFilterInput = {
  address?: InputMaybe<AddressFilterInput>;
  addressId?: InputMaybe<ComparableGuidOperationFilterInput>;
  and?: InputMaybe<Array<CustomerFilterInput>>;
  authorisedPersonFullName?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  createdOn?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerDefaultPreference?: InputMaybe<CustomerDefaultPreferenceFilterInput>;
  customerFriendlyId?: InputMaybe<StringOperationFilterInput>;
  customerFriendlyIdLink?: InputMaybe<CustomerFriendlyIdLinkFilterInput>;
  customerFriendlyIdLinkId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  dob?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  employeeId?: InputMaybe<StringOperationFilterInput>;
  externalCustomerId?: InputMaybe<StringOperationFilterInput>;
  forename?: InputMaybe<StringOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  marketingPermissions?: InputMaybe<MarketingPermissionsFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  oVOCustomerId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFilterInput>>;
  primaryPhone?: InputMaybe<StringOperationFilterInput>;
  secondaryPhone?: InputMaybe<StringOperationFilterInput>;
  surname?: InputMaybe<StringOperationFilterInput>;
  thirdPhone?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type CustomerFriendlyIdLinkFilterInput = {
  and?: InputMaybe<Array<CustomerFriendlyIdLinkFilterInput>>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerFriendlyId?: InputMaybe<ComparableInt32OperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<CustomerFriendlyIdLinkFilterInput>>;
};

export type CustomerFriendlyIdLinkSortInput = {
  customer?: InputMaybe<CustomerSortInput>;
  customerFriendlyId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type CustomerPayload = {
  __typename?: 'CustomerPayload';
  customer: Customer;
};

export enum CustomerPaymentMethod {
  Card = 'Card',
  Cheque = 'Cheque',
  DirectCredit = 'DirectCredit',
  DirectDebit = 'DirectDebit'
}

export type CustomerSignUp = {
  __typename?: 'CustomerSignUp';
  activatedAt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<NewEnquiryAddress>;
  authorisedPerson?: Maybe<Scalars['String']>;
  autoSignUpStatus?: Maybe<AutoSignUpStatus>;
  autoSignUpValidationErrors?: Maybe<Array<AutoSignUpValidationError>>;
  bankDetails?: Maybe<NewEnquiryBankDetails>;
  boiler?: Maybe<Boiler>;
  brandName?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  contractStartDate?: Maybe<Scalars['DateTime']>;
  coverTypeId?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  excess?: Maybe<Scalars['String']>;
  forename: Scalars['String'];
  hasAgreedContact: Scalars['Boolean'];
  isOnboarded: Scalars['Boolean'];
  lastUpdatedAt: Scalars['DateTime'];
  ovoCustomerId?: Maybe<Scalars['String']>;
  paymentDay?: Maybe<Scalars['Int']>;
  phone: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  signUpId: Scalars['Uuid'];
  signUpType?: Maybe<SignUpType>;
  sourceOfSale?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type CustomerSignupAddressInput = {
  isCommercial: Scalars['Boolean'];
  isGuestHouse: Scalars['Boolean'];
  isLiving: Scalars['Boolean'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  line3?: InputMaybe<Scalars['String']>;
  ownership: CustomerSignupOwnership;
  postcode: Scalars['String'];
};

export enum CustomerSignupAutoSignUpStatus {
  Fail = 'Fail',
  Success = 'Success'
}

export enum CustomerSignupAutoSignUpValidationError {
  BoilerAgeExceededFail = 'BoilerAgeExceededFail',
  DuplicationCheckFail = 'DuplicationCheckFail',
  IncompleteDataFail = 'IncompleteDataFail',
  ModulusCheckFail = 'ModulusCheckFail',
  UnderwriterCheckFail = 'UnderwriterCheckFail'
}

export type CustomerSignupBankDetailsInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  autoRenewalOptOut?: InputMaybe<Scalars['Boolean']>;
  isAccountHolder: Scalars['Boolean'];
  sortCode: Scalars['String'];
};

export type CustomerSignupContactInput = {
  homePlanContact?: InputMaybe<Scalars['Boolean']>;
  homePlanOptions?: InputMaybe<CustomerSignupPlanOptionsInput>;
  isMailOnly: Scalars['Boolean'];
  otherOrgContact: Array<CustomerSignupOrganization>;
  otherOrgOptions?: InputMaybe<CustomerSignupPlanOptionsInput>;
};

export enum CustomerSignupFuelType {
  Gas = 'Gas'
}

export type CustomerSignupInput = {
  activatedAt?: InputMaybe<Scalars['DateTime']>;
  address?: InputMaybe<CustomerSignupAddressInput>;
  authorisedPerson?: InputMaybe<Scalars['String']>;
  autoSignUpStatus?: InputMaybe<CustomerSignupAutoSignUpStatus>;
  autoSignUpValidationErrors?: InputMaybe<Array<CustomerSignupAutoSignUpValidationError>>;
  bankDetails?: InputMaybe<CustomerSignupBankDetailsInput>;
  boiler?: InputMaybe<BoilerInput>;
  brandName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<CustomerSignupContactInput>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  coverTypeId?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  excess?: InputMaybe<Scalars['String']>;
  forename: Scalars['String'];
  hasAgreedContact: Scalars['Boolean'];
  isOnboarded: Scalars['Boolean'];
  lastUpdatedAt: Scalars['DateTime'];
  ovoCustomerId?: InputMaybe<Scalars['String']>;
  paymentDay?: InputMaybe<Scalars['Int']>;
  phone: Scalars['String'];
  productName?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  signUpId: Scalars['Uuid'];
  signUpType?: InputMaybe<CustomerSignupSignUpType>;
  sourceOfSale?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type CustomerSignupNonNullChecksInput = {
  checkAddressNotNull: Scalars['Boolean'];
  checkBankDetailsNotNull: Scalars['Boolean'];
  checkBoilerNotNull: Scalars['Boolean'];
  checkContactNotNull: Scalars['Boolean'];
};

export enum CustomerSignupOrganization {
  CorgiHomeHeat = 'CorgiHomeHeat',
  Ovo = 'Ovo',
  Sse = 'Sse'
}

export enum CustomerSignupOwnership {
  Landlord = 'Landlord',
  Owner = 'Owner',
  Tenant = 'Tenant'
}

export type CustomerSignupPlanOptionsInput = {
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export enum CustomerSignupSignUpType {
  TeleCan = 'TeleCan',
  Website = 'Website'
}

export type CustomerSortInput = {
  address?: InputMaybe<AddressSortInput>;
  addressId?: InputMaybe<SortEnumType>;
  authorisedPersonFullName?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  customerDefaultPreference?: InputMaybe<CustomerDefaultPreferenceSortInput>;
  customerFriendlyId?: InputMaybe<SortEnumType>;
  customerFriendlyIdLink?: InputMaybe<CustomerFriendlyIdLinkSortInput>;
  customerFriendlyIdLinkId?: InputMaybe<SortEnumType>;
  customerId?: InputMaybe<SortEnumType>;
  dob?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  employeeId?: InputMaybe<SortEnumType>;
  externalCustomerId?: InputMaybe<SortEnumType>;
  forename?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  marketingPermissions?: InputMaybe<MarketingPermissionsSortInput>;
  modifiedAt?: InputMaybe<SortEnumType>;
  oVOCustomerId?: InputMaybe<SortEnumType>;
  primaryPhone?: InputMaybe<SortEnumType>;
  secondaryPhone?: InputMaybe<SortEnumType>;
  surname?: InputMaybe<SortEnumType>;
  thirdPhone?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export enum CustomerType {
  ANY = 'ANY',
  HOMEOWNER = 'HOMEOWNER',
  HOMEOWNER_NON_RESIDENT = 'HOMEOWNER_NON_RESIDENT',
  LANDLORD = 'LANDLORD'
}

export type CustomerTypeOperationFilterInput = {
  eq?: InputMaybe<CustomerType>;
  in?: InputMaybe<Array<CustomerType>>;
  neq?: InputMaybe<CustomerType>;
  nin?: InputMaybe<Array<CustomerType>>;
};

/** A connection to a list of items. */
export type CustomersConnection = {
  __typename?: 'CustomersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CustomersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Customer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CustomersEdge = {
  __typename?: 'CustomersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Customer;
};

export type DataShadowingContractUpsertInput = {
  addressId: Scalars['Uuid'];
  boilerManufacturerId: Scalars['Uuid'];
  boilerModel: Scalars['String'];
  cancelDate?: InputMaybe<Scalars['DateTime']>;
  claimOffDate?: InputMaybe<Scalars['DateTime']>;
  contractDurationMonth: Scalars['Int'];
  contractFriendlyId: Scalars['String'];
  contractPremiumAmount?: InputMaybe<Scalars['Decimal']>;
  contractPremiumInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  contractPremiumNonInsurance?: InputMaybe<Scalars['Decimal']>;
  contractStatus: ContractStatus;
  contractYear: Scalars['Int'];
  coolingOffEndDate: Scalars['DateTime'];
  customerClass: Scalars['Int'];
  customerId: Scalars['Uuid'];
  endDate: Scalars['DateTime'];
  enquiryValidationStatus?: InputMaybe<EnquiryValidationStatusType>;
  installDate: Scalars['DateTime'];
  legacyLinkId: Scalars['Int'];
  offerId?: InputMaybe<Scalars['Uuid']>;
  ownershipType: OwnershipType;
  paymentDay: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  previousProvider?: InputMaybe<Scalars['String']>;
  productAmount: Scalars['Decimal'];
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promocodeId?: InputMaybe<Scalars['Uuid']>;
  promotionAmount?: InputMaybe<Scalars['Decimal']>;
  promotionDurationMonth?: InputMaybe<Scalars['Int']>;
  promotionInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  promotionNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  signUpId?: InputMaybe<Scalars['Uuid']>;
  signUpMethod: Scalars['String'];
  sludge?: InputMaybe<SludgeWarning>;
  sludgeWarning?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  toRenew: Scalars['Int'];
  totalCost: Scalars['Decimal'];
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
  underwriterId: Scalars['Uuid'];
  underwriterStatus?: InputMaybe<UnderwriterStatusType>;
  yoa: Scalars['Decimal'];
};

export type DataShadowingContractUpsertPayload = {
  __typename?: 'DataShadowingContractUpsertPayload';
  contract: Contract;
};

export type DataShadowingCustomerUpsertInput = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  address3: Scalars['String'];
  createdOn?: InputMaybe<Scalars['DateTime']>;
  customerFriendlyId: Scalars['String'];
  documentCommunication: DocumentCommunicationPreference;
  email?: InputMaybe<Scalars['String']>;
  forename: Scalars['String'];
  homeTelNo?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Decimal']>;
  longitude?: InputMaybe<Scalars['Decimal']>;
  mobileTelNo?: InputMaybe<Scalars['String']>;
  ovoCustomerId?: InputMaybe<Scalars['String']>;
  paymentDay: Scalars['Int'];
  paymentMethod: Scalars['Int'];
  postCode: Scalars['String'];
  preferredContact: PreferredContactInput;
  statusId: Scalars['Int'];
  surname: Scalars['String'];
  title: Scalars['String'];
  toRenew: Scalars['Int'];
  workTelNo?: InputMaybe<Scalars['String']>;
};

export type DataShadowingCustomerUpsertPayload = {
  __typename?: 'DataShadowingCustomerUpsertPayload';
  customer: Customer;
};

export type DeclineExcessInput = {
  calloutId: Scalars['Int'];
};

export type DeclineExcessPayload = {
  __typename?: 'DeclineExcessPayload';
  declinedCalloutId: Scalars['Int'];
};

export type DeclinedCallout = {
  __typename?: 'DeclinedCallout';
  additionalInfo?: Maybe<Scalars['String']>;
  calloutType?: Maybe<CalloutType>;
  class?: Maybe<Scalars['Int']>;
  contactDate?: Maybe<Scalars['DateTime']>;
  customerId?: Maybe<Scalars['Int']>;
  declinedCalloutId: Scalars['Int'];
  description?: Maybe<DeclinedCalloutDescription>;
  descriptionId?: Maybe<Scalars['Int']>;
  originalCalloutId?: Maybe<Scalars['Int']>;
  problemType?: Maybe<ProblemType>;
  reason?: Maybe<DeclinedCalloutReason>;
  reasonId?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  repairNotCovered?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export type DeclinedCalloutDescription = {
  __typename?: 'DeclinedCalloutDescription';
  declinedDescription?: Maybe<Scalars['String']>;
  declinedDescriptionId: Scalars['Int'];
};

export type DeclinedCalloutReason = {
  __typename?: 'DeclinedCalloutReason';
  declinedReason?: Maybe<Scalars['String']>;
  declinedReasonId: Scalars['Int'];
};

export type DeleteCustomerCommandInput = {
  customerId: Scalars['Uuid'];
};

export type DeleteCustomerPayload = {
  __typename?: 'DeleteCustomerPayload';
  customer: Customer;
};

export type DeleteNewEnquiryCommandInput = {
  newEnquiryId: Scalars['Uuid'];
};

export enum DirectDebitInstructionStatus {
  Active = 'Active',
  CancelledByCustomer = 'CancelledByCustomer',
  CancelledBySystem = 'CancelledBySystem',
  Inactive = 'Inactive',
  InactivePayerDeceased = 'InactivePayerDeceased'
}

export type Document = {
  __typename?: 'Document';
  clone: Document;
  collection: Scalars['String'];
  documentCollection: Scalars['String'];
  documentId: Scalars['String'];
  documentKey?: Maybe<Scalars['String']>;
  documentType: Scalars['String'];
  events: Array<IDocumentEvent>;
  fileChecksum?: Maybe<Array<Scalars['Byte']>>;
  id: Scalars['UUID'];
  isReady: Scalars['Boolean'];
  name: Scalars['String'];
  tags: Array<KeyValuePairOfStringAndString>;
  templateKey: TemplateKey;
};

export type DocumentAttachment = {
  __typename?: 'DocumentAttachment';
  attachmentType: Scalars['String'];
  document?: Maybe<DocumentWithPreSignedUrl>;
  documentId: Scalars['String'];
};

export enum DocumentCommunicationPreference {
  EmailAndPost = 'EmailAndPost',
  EmailOnly = 'EmailOnly'
}

export type DocumentCommunicationPreferenceOperationFilterInput = {
  eq?: InputMaybe<DocumentCommunicationPreference>;
  in?: InputMaybe<Array<DocumentCommunicationPreference>>;
  neq?: InputMaybe<DocumentCommunicationPreference>;
  nin?: InputMaybe<Array<DocumentCommunicationPreference>>;
};

export type DocumentComposeFailedEvent = {
  __typename?: 'DocumentComposeFailedEvent';
  message: Scalars['String'];
  timeStampUtc: Scalars['DateTime'];
};

export type DocumentComposedEvent = {
  __typename?: 'DocumentComposedEvent';
  timeStampUtc: Scalars['DateTime'];
};

export type DocumentMissingKeyError = {
  __typename?: 'DocumentMissingKeyError';
  message: Scalars['String'];
};

export type DocumentNotFoundError = {
  __typename?: 'DocumentNotFoundError';
  id: Scalars['UUID'];
};

export type DocumentRenderFailedEvent = {
  __typename?: 'DocumentRenderFailedEvent';
  message: Scalars['String'];
  timeStampUtc: Scalars['DateTime'];
};

export type DocumentRenderedEvent = {
  __typename?: 'DocumentRenderedEvent';
  timeStampUtc: Scalars['DateTime'];
};

export type DocumentRequestFailedEvent = {
  __typename?: 'DocumentRequestFailedEvent';
  message: Scalars['String'];
  requestedBy?: Maybe<Scalars['String']>;
  timeStampUtc: Scalars['DateTime'];
};

export type DocumentRequestedEvent = {
  __typename?: 'DocumentRequestedEvent';
  inputJson?: Maybe<Scalars['String']>;
  requestedBy?: Maybe<Scalars['String']>;
  template: Scalars['String'];
  templateCollection: Scalars['String'];
  timeStampUtc: Scalars['DateTime'];
};

export type DocumentSearchNoResultsError = {
  __typename?: 'DocumentSearchNoResultsError';
  message: Scalars['String'];
};

export type DocumentUploadedEvent = {
  __typename?: 'DocumentUploadedEvent';
  requestedBy?: Maybe<Scalars['String']>;
  timeStampUtc: Scalars['DateTime'];
};

export type DocumentWithPreSignedURL = {
  __typename?: 'DocumentWithPreSignedURL';
  document: Document;
  error?: Maybe<DocumentMissingKeyError>;
  preSignedURL?: Maybe<Scalars['String']>;
};

export type DocumentWithPreSignedUrl = {
  __typename?: 'DocumentWithPreSignedUrl';
  clone: Document;
  /** @deprecated Use Document.Collection */
  collection: Scalars['String'];
  document: Document;
  /** @deprecated Use Document.DocumentId */
  documentId: Scalars['String'];
  /** @deprecated Use Document.DocumentKey */
  documentKey?: Maybe<Scalars['String']>;
  events: Array<Scalars['String']>;
  /** @deprecated Use Document.Name */
  name: Scalars['String'];
  preSignedUrl?: Maybe<Scalars['String']>;
  printablePreSignedUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use Document.Tags */
  tags: Array<KeyValuePairOfStringAndString>;
};

export type DocumentsSearchResult = {
  __typename?: 'DocumentsSearchResult';
  document?: Maybe<DocumentWithPreSignedUrl>;
  error?: Maybe<CreateCommunicationError>;
};

export enum DomainType {
  ADDRESS = 'ADDRESS',
  APPLIANCE = 'APPLIANCE',
  CONTRACT = 'CONTRACT',
  CUSTOMER = 'CUSTOMER'
}

export type DomainTypeOperationFilterInput = {
  eq?: InputMaybe<ActionsDomainType>;
  in?: InputMaybe<Array<ActionsDomainType>>;
  neq?: InputMaybe<ActionsDomainType>;
  nin?: InputMaybe<Array<ActionsDomainType>>;
};

export type EligibilityCriteria = {
  __typename?: 'EligibilityCriteria';
  boilerCondition: BoilerCondition;
  customerTypes: Array<CustomerType>;
  id: Scalars['Uuid'];
  maximumBoilerAge?: Maybe<Scalars['Int']>;
  minimumBoilerAge?: Maybe<Scalars['Int']>;
};

export type EligibilityCriteriaFilterInput = {
  and?: InputMaybe<Array<EligibilityCriteriaFilterInput>>;
  boilerCondition?: InputMaybe<BoilerConditionOperationFilterInput>;
  customerTypes?: InputMaybe<ListCustomerTypeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  maximumBoilerAge?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  minimumBoilerAge?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  or?: InputMaybe<Array<EligibilityCriteriaFilterInput>>;
};

export type EligibilityCriteriaSortInput = {
  boilerCondition?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  maximumBoilerAge?: InputMaybe<SortEnumType>;
  minimumBoilerAge?: InputMaybe<SortEnumType>;
};

export type EmergencyCover = {
  __typename?: 'EmergencyCover';
  emergency2Hr?: Maybe<Scalars['Boolean']>;
  emergency24Hr?: Maybe<Scalars['Boolean']>;
  evenings?: Maybe<Scalars['Boolean']>;
  weekends?: Maybe<Scalars['Boolean']>;
};

export type EmergencyCoverFilterInput = {
  and?: InputMaybe<Array<EmergencyCoverFilterInput>>;
  emergency2Hr?: InputMaybe<BooleanOperationFilterInput>;
  emergency24Hr?: InputMaybe<BooleanOperationFilterInput>;
  evenings?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<EmergencyCoverFilterInput>>;
  weekends?: InputMaybe<BooleanOperationFilterInput>;
};

export type EmergencyCoverSortInput = {
  emergency2Hr?: InputMaybe<SortEnumType>;
  emergency24Hr?: InputMaybe<SortEnumType>;
  evenings?: InputMaybe<SortEnumType>;
  weekends?: InputMaybe<SortEnumType>;
};

export enum EnquiryValidationStatusType {
  BoilerAgeExceededFail = 'BoilerAgeExceededFail',
  DuplicationCheckFail = 'DuplicationCheckFail',
  IncompleteDataFail = 'IncompleteDataFail',
  InvalidAccountNameFail = 'InvalidAccountNameFail',
  Success = 'Success',
  UnderwriterCheckFail = 'UnderwriterCheckFail'
}

export enum EventType {
  NonWorkingDay = 'NonWorkingDay',
  WorkingDay = 'WorkingDay'
}

export type EventTypeOperationFilterInput = {
  eq?: InputMaybe<audit_EventType>;
  in?: InputMaybe<Array<audit_EventType>>;
  neq?: InputMaybe<audit_EventType>;
  nin?: InputMaybe<Array<audit_EventType>>;
};

export enum ExcessType {
  FIFTY = 'FIFTY',
  NINETY = 'NINETY',
  NINETY_NINE = 'NINETY_NINE',
  SIXTY = 'SIXTY',
  ZERO = 'ZERO'
}

export type ExcessTypeOperationFilterInput = {
  eq?: InputMaybe<ExcessType>;
  in?: InputMaybe<Array<ExcessType>>;
  neq?: InputMaybe<ExcessType>;
  nin?: InputMaybe<Array<ExcessType>>;
};

export type Externaljob = {
  __typename?: 'Externaljob';
  externalCustomerRef: Scalars['Uuid'];
  externalJobId: Scalars['Int'];
};

export type ExternaljobFilterInput = {
  and?: InputMaybe<Array<ExternaljobFilterInput>>;
  externalCustomerRef?: InputMaybe<ComparableGuidOperationFilterInput>;
  externalJobId?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<ExternaljobFilterInput>>;
};

export type ExternaljobSortInput = {
  externalCustomerRef?: InputMaybe<SortEnumType>;
  externalJobId?: InputMaybe<SortEnumType>;
};

export type FailedRequestDuplicationError = {
  __typename?: 'FailedRequestDuplicationError';
  message: Scalars['String'];
};

export type FailedToGetDocumentError = {
  __typename?: 'FailedToGetDocumentError';
  error: Scalars['String'];
  identifier: Scalars['String'];
};

export type FailedToRequestError = {
  __typename?: 'FailedToRequestError';
  message: Scalars['String'];
};

export type FailedToUploadError = {
  __typename?: 'FailedToUploadError';
  message: Scalars['String'];
};

export type FailedValidationError = {
  __typename?: 'FailedValidationError';
  validationFailures: Array<ValidationFailure>;
};

export type FakeEntity = {
  __typename?: 'FakeEntity';
  id: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type FilteredCalloutsConnection = {
  __typename?: 'FilteredCalloutsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FilteredCalloutsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Callout>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FilteredCalloutsEdge = {
  __typename?: 'FilteredCalloutsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Callout>;
};

export type FinancialInformation = {
  __typename?: 'FinancialInformation';
  priceBasis?: Maybe<PriceBasis>;
};

export type FinancialInformationFilterInput = {
  and?: InputMaybe<Array<FinancialInformationFilterInput>>;
  or?: InputMaybe<Array<FinancialInformationFilterInput>>;
  priceBasis?: InputMaybe<NullableOfPriceBasisOperationFilterInput>;
};

export type FinancialInformationSortInput = {
  priceBasis?: InputMaybe<SortEnumType>;
};

export enum FlowType {
  InlifeMta = 'InlifeMta',
  RenewalMta = 'RenewalMta'
}

export enum FuelType {
  Lpg = 'Lpg',
  NaturalGas = 'NaturalGas',
  Oil = 'Oil'
}

export type FuelTypeOperationFilterInput = {
  eq?: InputMaybe<FuelType>;
  in?: InputMaybe<Array<FuelType>>;
  neq?: InputMaybe<FuelType>;
  nin?: InputMaybe<Array<FuelType>>;
};

export type GenerateMidTermAdjustmentPaymentsPreviewInput = {
  contractId: Scalars['Uuid'];
  isCurrentProduct: Scalars['Boolean'];
  newInsuranceAmount: Scalars['Decimal'];
  newNonInsuranceAmount: Scalars['Decimal'];
};

export type GenerateMidTermAdjustmentPaymentsPreviewPayload = {
  __typename?: 'GenerateMidTermAdjustmentPaymentsPreviewPayload';
  payments: Array<customerPayments_Payment>;
  summary: MidTermAdjustmentDetailsModel;
};

export type GetAuditInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GetChangePaymentDayPreviewQueryInput = {
  contractId: Scalars['Uuid'];
  paymentDay: Scalars['Int'];
};

export type GetCommunicationResult = {
  __typename?: 'GetCommunicationResult';
  communication?: Maybe<CommunicationWithAttachments>;
  error?: Maybe<CreateCommunicationError>;
};

export type GetContractByBacsReferenceQueryItem = {
  __typename?: 'GetContractByBacsReferenceQueryItem';
  bacsReference: Scalars['String'];
  contractId?: Maybe<Scalars['Uuid']>;
};

export type GetContractsByBacsReferencesQueryResponse = {
  __typename?: 'GetContractsByBacsReferencesQueryResponse';
  items: Array<GetContractByBacsReferenceQueryItem>;
};

export type GetDocumentError = DocumentMissingKeyError | DocumentNotFoundError | DocumentSearchNoResultsError;

export type GetDocumentResult = {
  __typename?: 'GetDocumentResult';
  document?: Maybe<DocumentWithPreSignedURL>;
  error?: Maybe<GetDocumentError>;
};

export type GetLandordRecordsDueServiceInput = {
  daysUntilDue: Scalars['Int'];
};

export type GetLatestPaymentQueryInput = {
  contractId: Scalars['Uuid'];
};

export type GetMtaQuotesInput = {
  boilerInWorkingCondition: Scalars['Boolean'];
  boilerInstallationDate?: InputMaybe<Scalars['DateTime']>;
  boilerUnder45kw: Scalars['Boolean'];
  boilerUsesNaturalMainsGasBoiler: Scalars['Boolean'];
  brandName: Scalars['String'];
  currentPremiumAmount: Scalars['Decimal'];
  customerType: CustomerType;
  onlySingleBoiler: Scalars['Boolean'];
  propertyIsNotGuestHouse: Scalars['Boolean'];
};

export type GetNearestPaymentQueryInput = {
  contractId: Scalars['Uuid'];
};

export type GetNoAccessScheduledDateInput = {
  month: Month;
  type: NoAccessType;
};

export type GetQuotesInput = {
  boilerInWorkingCondition: Scalars['Boolean'];
  boilerInstallationDate?: InputMaybe<Scalars['DateTime']>;
  boilerManufacturerId?: InputMaybe<Scalars['Uuid']>;
  boilerModel?: InputMaybe<Scalars['String']>;
  boilerUnder45kw: Scalars['Boolean'];
  boilerUsesNaturalMainsGasBoiler: Scalars['Boolean'];
  brandName: Scalars['String'];
  customerType: CustomerType;
  offerSaleDate?: InputMaybe<Scalars['DateTime']>;
  onlySingleBoiler: Scalars['Boolean'];
  promocode?: InputMaybe<Scalars['String']>;
  propertyIsNotGuestHouse: Scalars['Boolean'];
};

export type GetSalesProductVersionsResult = {
  __typename?: 'GetSalesProductVersionsResult';
  data?: Maybe<Array<ProductVersion>>;
  error?: Maybe<IProductError>;
};

export type GetServiceDetailsForCustomersInContractYearInput = {
  legacyLinkIdContractStartDates?: InputMaybe<Array<InputMaybe<LegacyLinkIdContractStartDateInput>>>;
};

export type GetUnprocessedServiceRecordsInput = {
  serviceMonth: Scalars['Int'];
  serviceYear?: InputMaybe<Scalars['Int']>;
};

export type GetWorkingDayDateGivenNumberOfWorkingDaysQueryInput = {
  numberOfWorkingDays: Scalars['Int'];
  workingDayDateStartCheck?: InputMaybe<Scalars['DateTime']>;
};

export type GetWorkingDayDetailsBetweenDatesQueryInput = {
  workingDayDateEndCheck: Scalars['DateTime'];
  workingDayDateStartCheck: Scalars['DateTime'];
};

export enum GiBillingStatus {
  AmendRequired = 'AmendRequired',
  Amended = 'Amended',
  New = 'New',
  NotEnteredOrInvalid = 'NotEnteredOrInvalid',
  Ok = 'Ok'
}

export type GoodwillAuthorisationInputModelInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  complaintId: Scalars['Int'];
  customerFriendlyId?: InputMaybe<Scalars['String']>;
  goodwillAmount: Scalars['Decimal'];
  paymentProposal?: InputMaybe<PaymentProposalInputModelInput>;
};

export type GoodwillPaymentAuthorisation = {
  __typename?: 'GoodwillPaymentAuthorisation';
  additionalInformation?: Maybe<Scalars['String']>;
  complaintId: Scalars['Int'];
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['DateTime'];
  customerFriendlyId?: Maybe<Scalars['String']>;
  goodwillAmount: Scalars['Decimal'];
  id: Scalars['Uuid'];
  paymentProposal?: Maybe<PaymentProposal>;
  status: PaymentAuthorisationStatus;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export type ICommunicationEvent = BaseCommunicationEvent | CommunicationComposeDeferredEvent | CommunicationComposeFailedEvent | CommunicationComposedEvent | CommunicationConsumedByPrintProviderEvent | CommunicationDeliverFailedEvent | CommunicationDeliveredEvent | CommunicationRequestFailedEvent | CommunicationRequestedEvent | CommunicationSmtpEvent | CommunicationSmtpFailureEvent | CommunicationUploadFailedEvent;

export type IDocumentEvent = BaseDocumentEvent | DocumentComposeFailedEvent | DocumentComposedEvent | DocumentRenderFailedEvent | DocumentRenderedEvent | DocumentRequestFailedEvent | DocumentRequestedEvent | DocumentUploadedEvent;

export type IProductError = {
  message: Scalars['String'];
};

export type IProductVersionProductComponentsBreakdown = {
  basePrice: Scalars['Decimal'];
  commission: Scalars['Decimal'];
  contractPrice: Scalars['Decimal'];
  id: Scalars['Uuid'];
  productComponent: SaleProductComponent;
  productComponentId: Scalars['Uuid'];
  promocodeId?: Maybe<Scalars['Uuid']>;
  standardPrice: Scalars['Decimal'];
};

export type Installation = {
  __typename?: 'Installation';
  bathrooms?: Maybe<Scalars['Boolean']>;
  conventional?: Maybe<Scalars['Boolean']>;
  fireplaces?: Maybe<Scalars['Boolean']>;
  homeHeat?: Maybe<Scalars['Boolean']>;
  numBoilers?: Maybe<Scalars['Int']>;
  solar?: Maybe<Scalars['Boolean']>;
};

export type InstallationFilterInput = {
  and?: InputMaybe<Array<InstallationFilterInput>>;
  bathrooms?: InputMaybe<BooleanOperationFilterInput>;
  conventional?: InputMaybe<BooleanOperationFilterInput>;
  fireplaces?: InputMaybe<BooleanOperationFilterInput>;
  homeHeat?: InputMaybe<BooleanOperationFilterInput>;
  numBoilers?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  or?: InputMaybe<Array<InstallationFilterInput>>;
  solar?: InputMaybe<BooleanOperationFilterInput>;
};

export type InstallationSortInput = {
  bathrooms?: InputMaybe<SortEnumType>;
  conventional?: InputMaybe<SortEnumType>;
  fireplaces?: InputMaybe<SortEnumType>;
  homeHeat?: InputMaybe<SortEnumType>;
  numBoilers?: InputMaybe<SortEnumType>;
  solar?: InputMaybe<SortEnumType>;
};

export type InstructionStatus = {
  __typename?: 'InstructionStatus';
  contractId: Scalars['Uuid'];
  instructionStatusType: DirectDebitInstructionStatus;
};

export type InsuranceCancellationFeeComponent = {
  __typename?: 'InsuranceCancellationFeeComponent';
  adminFee: Scalars['Decimal'];
  boilerOrHeatingBreakdownFees: Scalars['Decimal'];
  claims?: Maybe<Array<InsuranceClaimFee>>;
  insuranceCancellationCharge: Scalars['Decimal'];
  otherBreakdownFees: Scalars['Decimal'];
  outstandingInsurancePremium: Scalars['Decimal'];
  totalInsuranceCancellationAmount: Scalars['Decimal'];
};

export type InsuranceClaimFee = {
  __typename?: 'InsuranceClaimFee';
  amount: Scalars['Decimal'];
  claimType: InsuranceClaimType;
  contactDate?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
};

export enum InsuranceClaimType {
  HeatingOrBoilerRepair = 'HeatingOrBoilerRepair',
  Other = 'Other',
  Service = 'Service'
}

export type InvoiceDispute = {
  __typename?: 'InvoiceDispute';
  disputeHours?: Maybe<Scalars['Boolean']>;
  disputeParts?: Maybe<Scalars['Boolean']>;
  disputeTechnical?: Maybe<Scalars['Boolean']>;
  disputed?: Maybe<Scalars['Int']>;
  supplierInvoice?: Maybe<Scalars['Boolean']>;
  targetHours?: Maybe<Scalars['Float']>;
  targetParts?: Maybe<Scalars['Float']>;
  technicalNarrative?: Maybe<Scalars['String']>;
};

export type InvoiceFlagsInput = {
  condensateOk?: InputMaybe<Scalars['Boolean']>;
  fuseOk?: InputMaybe<Scalars['Boolean']>;
  hWTankOk?: InputMaybe<Scalars['Boolean']>;
  noLeaks?: InputMaybe<Scalars['Boolean']>;
  sludgeOk?: InputMaybe<Scalars['Boolean']>;
};

export type IsNewCalloutRecallDto = {
  __typename?: 'IsNewCalloutRecallDto';
  isRecall: Scalars['Boolean'];
  previousDeclined: Scalars['Boolean'];
};

export type IsNewCalloutRecallInput = {
  calloutType: CalloutType;
  legacyLinkId: Scalars['Int'];
  previousLegacyLinkId?: InputMaybe<Scalars['Int']>;
};

export type Jobsheet = {
  __typename?: 'Jobsheet';
  actionRequired?: Maybe<Scalars['String']>;
  actionTaken?: Maybe<Scalars['String']>;
  applianceAccessible?: Maybe<Scalars['Int']>;
  applianceChecks: Checks;
  applianceGood?: Maybe<Scalars['Int']>;
  applianceLocations: ApplianceLocations;
  boilerShutDown?: Maybe<Scalars['Boolean']>;
  copper?: Maybe<Scalars['Int']>;
  customerAdvice?: Maybe<Scalars['String']>;
  customerInformed?: Maybe<Scalars['Boolean']>;
  faultFound?: Maybe<Scalars['String']>;
  followUpAction?: Maybe<Scalars['String']>;
  fuseboardCondition?: Maybe<Scalars['String']>;
  lackOfServicing?: Maybe<Scalars['Boolean']>;
  noLeaks?: Maybe<Scalars['Int']>;
  ntsProblem?: Maybe<Scalars['String']>;
  repairComplete?: Maybe<Scalars['Boolean']>;
  systemComments?: Maybe<Scalars['String']>;
  systemStatus?: Maybe<Scalars['Int']>;
};

export type KeyValuePairOfStringAndObject = {
  __typename?: 'KeyValuePairOfStringAndObject';
  key: Scalars['String'];
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LandlordRecord = {
  __typename?: 'LandlordRecord';
  active: Scalars['Boolean'];
  contractId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Uuid'];
  processedAt?: Maybe<Scalars['DateTime']>;
  serviceDueDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LatestAttachmentQueryInput = {
  attachmentType: Scalars['String'];
  contractId: Scalars['String'];
};

export type LegacyComplaint = {
  __typename?: 'LegacyComplaint';
  action?: Maybe<Scalars['String']>;
  addedBy?: Maybe<Scalars['Int']>;
  additionalDetails?: Maybe<Scalars['String']>;
  calloutId?: Maybe<Scalars['Int']>;
  calloutRealated?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['Int']>;
  chpCauseId: Scalars['Int'];
  closedDate?: Maybe<Scalars['DateTime']>;
  commActionId: Scalars['Int'];
  commDate?: Maybe<Scalars['DateTime']>;
  commDecisionId: Scalars['Int'];
  commJustificationId: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  complaint?: Maybe<Scalars['Boolean']>;
  complaintDate?: Maybe<Scalars['DateTime']>;
  complaintDetails?: Maybe<Scalars['String']>;
  complaintHandlerId?: Maybe<Scalars['Int']>;
  complaintId: Scalars['Int'];
  complaintTypeHandler?: Maybe<Scalars['Int']>;
  customerClass: Scalars['Int'];
  customerId?: Maybe<Scalars['Int']>;
  declinedCallout?: Maybe<Scalars['Boolean']>;
  directorComplaint?: Maybe<Scalars['Boolean']>;
  externalCustomerRef?: Maybe<Scalars['Uuid']>;
  fOSFee?: Maybe<Scalars['Int']>;
  fOSRefNo?: Maybe<Scalars['String']>;
  finalClosedDate?: Maybe<Scalars['DateTime']>;
  inHouse?: Maybe<Scalars['Boolean']>;
  isClaimsRelated: Scalars['Boolean'];
  isInformal: Scalars['Boolean'];
  length?: Maybe<Scalars['Int']>;
  lloydsCauseId: Scalars['Int'];
  lloydsComplaintCode: LloydsComplaintCodes;
  managementReviewId?: Maybe<Scalars['Int']>;
  newComplaintType?: Maybe<Scalars['Boolean']>;
  newPaymentType?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  publicLiability?: Maybe<Scalars['Boolean']>;
  qAReviewId?: Maybe<Scalars['Int']>;
  resolvedBy?: Maybe<Scalars['Int']>;
  sRCLetterSentDate?: Maybe<Scalars['DateTime']>;
  source: Scalars['Int'];
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  version: Scalars['Int'];
};

export type LegacyComplaintFinalStagesAudit = {
  __typename?: 'LegacyComplaintFinalStagesAudit';
  accepted?: Maybe<Scalars['Int']>;
  amendmentId?: Maybe<Scalars['Int']>;
  auditId: Scalars['Int'];
  complaintId?: Maybe<Scalars['Int']>;
  complaintStageId?: Maybe<Scalars['Int']>;
  finalStageId?: Maybe<Scalars['Int']>;
  offeredAmount?: Maybe<Scalars['Decimal']>;
  oldComplaint?: Maybe<Scalars['Boolean']>;
  paid?: Maybe<Scalars['Decimal']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  paymentType?: Maybe<Scalars['Int']>;
  refundId?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type LegacyContract = {
  __typename?: 'LegacyContract';
  address: legacyCustomer_Address;
  brandId: Scalars['Int'];
  contractBoiler?: Maybe<ContractBoiler>;
  contractFriendlyId: Scalars['String'];
  customer?: Maybe<LegacyCustomer>;
  ownershipType: OwnershipType;
  startDate: Scalars['DateTime'];
};

export type LegacyCustomer = {
  __typename?: 'LegacyCustomer';
  address?: Maybe<legacyCustomer_Address>;
  addressId: Scalars['Uuid'];
  authorisedPersonFullName?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<LegacyContract>>>;
  createdOn: Scalars['DateTime'];
  customerFriendlyId: Scalars['String'];
  customerId: Scalars['Uuid'];
  email?: Maybe<Scalars['String']>;
  forename: Scalars['String'];
  primaryPhone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  thirdPhone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LegacyLinkIdContractStartDateInput = {
  contractStartDate: Scalars['DateTime'];
  legacyLinkId: Scalars['Int'];
};

export type LegalEntityModel = {
  __typename?: 'LegalEntityModel';
  bankAccounts?: Maybe<Array<Maybe<BankAccountModel>>>;
  brands?: Maybe<Array<Maybe<BrandModel>>>;
  companyNo?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  financialRegulatedRefNo?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name?: Maybe<Scalars['String']>;
  registeredCountry?: Maybe<Scalars['String']>;
  registrationOffice?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type LinkExistingExcessToCalloutCommandInput = {
  calloutId: Scalars['Int'];
  contractId: Scalars['Uuid'];
  paymentId: Scalars['Uuid'];
};

export type ListCustomerTypeOperationFilterInput = {
  all?: InputMaybe<CustomerTypeOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CustomerTypeOperationFilterInput>;
  some?: InputMaybe<CustomerTypeOperationFilterInput>;
};

export type ListFilterInputTypeOfContractRenewalInteractionFilterInput = {
  all?: InputMaybe<ContractRenewalInteractionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ContractRenewalInteractionFilterInput>;
  some?: InputMaybe<ContractRenewalInteractionFilterInput>;
};

export type ListFilterInputTypeOfProductComponentProductComponentItemsFilterInput = {
  all?: InputMaybe<ProductComponentProductComponentItemsFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductComponentProductComponentItemsFilterInput>;
  some?: InputMaybe<ProductComponentProductComponentItemsFilterInput>;
};

export type ListFilterInputTypeOfProductVersionFilterInput = {
  all?: InputMaybe<ProductVersionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductVersionFilterInput>;
  some?: InputMaybe<ProductVersionFilterInput>;
};

export type ListFilterInputTypeOfProductVersionProductComponentsFilterInput = {
  all?: InputMaybe<ProductVersionProductComponentsFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ProductVersionProductComponentsFilterInput>;
  some?: InputMaybe<ProductVersionProductComponentsFilterInput>;
};

export type ListFilterInputTypeOfResponsibilityFilterInput = {
  all?: InputMaybe<ResponsibilityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResponsibilityFilterInput>;
  some?: InputMaybe<ResponsibilityFilterInput>;
};

export type ListFilterInputTypeOfTagFilterInput = {
  all?: InputMaybe<TagFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TagFilterInput>;
  some?: InputMaybe<TagFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export enum LloydsComplaintCodes {
  Arrears = 'Arrears',
  Delays = 'Delays',
  Disputes = 'Disputes',
  Errors = 'Errors',
  NotInsuranceRelated = 'NotInsuranceRelated',
  NotSet = 'NotSet',
  Other = 'Other',
  OtherGeneralAdmin = 'OtherGeneralAdmin',
  ProductDisclosureInformation = 'ProductDisclosureInformation',
  ProductPerformance = 'ProductPerformance',
  UnclearGuidance = 'UnclearGuidance',
  UnsuitableAdvice = 'UnsuitableAdvice'
}

export type Manufacturer = {
  __typename?: 'Manufacturer';
  id: Scalars['Uuid'];
  name: Scalars['String'];
};

/** A connection to a list of items. */
export type ManufacturersConnection = {
  __typename?: 'ManufacturersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ManufacturersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AggregatedManufacturer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ManufacturersEdge = {
  __typename?: 'ManufacturersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AggregatedManufacturer;
};

export type MarkCalloutsNotifiedInput = {
  calloutIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type MarkCalloutsNotifiedPayload = {
  __typename?: 'MarkCalloutsNotifiedPayload';
  calloutIds?: Maybe<Array<Scalars['Int']>>;
};

export type MarkLandlordRecordAsProcessedInput = {
  contractId: Scalars['Uuid'];
};

export type MarkLandlordRecordAsProcessedPayload = {
  __typename?: 'MarkLandlordRecordAsProcessedPayload';
  recordId?: Maybe<Scalars['Uuid']>;
};

export type MarketingPermissions = {
  __typename?: 'MarketingPermissions';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['Uuid'];
  email: Scalars['Boolean'];
  modifiedAt: Scalars['DateTime'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  sMS: Scalars['Boolean'];
};

export type MarketingPermissionsFilterInput = {
  and?: InputMaybe<Array<MarketingPermissionsFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customer?: InputMaybe<CustomerFilterInput>;
  customerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  email?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<MarketingPermissionsFilterInput>>;
  phone?: InputMaybe<BooleanOperationFilterInput>;
  post?: InputMaybe<BooleanOperationFilterInput>;
  sMS?: InputMaybe<BooleanOperationFilterInput>;
};

export type MarketingPermissionsSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  customer?: InputMaybe<CustomerSortInput>;
  customerId?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  modifiedAt?: InputMaybe<SortEnumType>;
  phone?: InputMaybe<SortEnumType>;
  post?: InputMaybe<SortEnumType>;
  sMS?: InputMaybe<SortEnumType>;
};

export enum MarketingPreference {
  Email = 'Email',
  Mail = 'Mail',
  Telephone = 'Telephone'
}

export type MidTermAdjustmentDetailsModel = {
  __typename?: 'MidTermAdjustmentDetailsModel';
  currentPrice: Scalars['Decimal'];
  difference: Scalars['Decimal'];
  newPrice: Scalars['Decimal'];
  numberOfRemainingPayments: Scalars['Int'];
};

export enum Month {
  April = 'April',
  August = 'August',
  December = 'December',
  February = 'February',
  January = 'January',
  July = 'July',
  June = 'June',
  March = 'March',
  May = 'May',
  November = 'November',
  October = 'October',
  September = 'September'
}

export type MtaInlifeAuditLogEntity = {
  __typename?: 'MtaInlifeAuditLogEntity';
  contractId: Scalars['Uuid'];
  createdAt: Scalars['DateTime'];
  currentContract: MtaInlifeContractInfo;
  id: Scalars['Uuid'];
  previousContract: MtaInlifeContractInfo;
};

export type MtaInlifeAuditLogEntityFilterInput = {
  and?: InputMaybe<Array<MtaInlifeAuditLogEntityFilterInput>>;
  contractId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  currentContract?: InputMaybe<MtaInlifeContractInfoFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<MtaInlifeAuditLogEntityFilterInput>>;
  previousContract?: InputMaybe<MtaInlifeContractInfoFilterInput>;
};

export type MtaInlifeAuditLogEntitySortInput = {
  contractId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  currentContract?: InputMaybe<MtaInlifeContractInfoSortInput>;
  id?: InputMaybe<SortEnumType>;
  previousContract?: InputMaybe<MtaInlifeContractInfoSortInput>;
};

/** A connection to a list of items. */
export type MtaInlifeAuditLogsConnection = {
  __typename?: 'MtaInlifeAuditLogsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<MtaInlifeAuditLogsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<MtaInlifeAuditLogEntity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MtaInlifeAuditLogsEdge = {
  __typename?: 'MtaInlifeAuditLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MtaInlifeAuditLogEntity;
};

export type MtaInlifeContractInfo = {
  __typename?: 'MtaInlifeContractInfo';
  contractPremiumInsuranceAmount: Scalars['Decimal'];
  offerId?: Maybe<Scalars['Uuid']>;
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promoCodeId?: Maybe<Scalars['Uuid']>;
  promotionDurationMonth?: Maybe<Scalars['Int']>;
  promotionInsuranceAmount?: Maybe<Scalars['Decimal']>;
  promotionNonInsuranceAmount?: Maybe<Scalars['Decimal']>;
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
};

export type MtaInlifeContractInfoFilterInput = {
  and?: InputMaybe<Array<MtaInlifeContractInfoFilterInput>>;
  contractPremiumInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  offerId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  or?: InputMaybe<Array<MtaInlifeContractInfoFilterInput>>;
  productInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  productNonInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  productVersionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  promoCodeId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  promotionDurationMonth?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  promotionInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  promotionNonInsuranceAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  totalInsuranceCost?: InputMaybe<ComparableDecimalOperationFilterInput>;
  totalNonInsuranceCost?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type MtaInlifeContractInfoInput = {
  contractPremiumInsuranceAmount: Scalars['Decimal'];
  offerId?: InputMaybe<Scalars['Uuid']>;
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promoCodeId?: InputMaybe<Scalars['Uuid']>;
  promotionDurationMonth?: InputMaybe<Scalars['Int']>;
  promotionInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  promotionNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
};

export type MtaInlifeContractInfoSortInput = {
  contractPremiumInsuranceAmount?: InputMaybe<SortEnumType>;
  offerId?: InputMaybe<SortEnumType>;
  productInsuranceAmount?: InputMaybe<SortEnumType>;
  productNonInsuranceAmount?: InputMaybe<SortEnumType>;
  productVersionId?: InputMaybe<SortEnumType>;
  promoCodeId?: InputMaybe<SortEnumType>;
  promotionDurationMonth?: InputMaybe<SortEnumType>;
  promotionInsuranceAmount?: InputMaybe<SortEnumType>;
  promotionNonInsuranceAmount?: InputMaybe<SortEnumType>;
  totalInsuranceCost?: InputMaybe<SortEnumType>;
  totalNonInsuranceCost?: InputMaybe<SortEnumType>;
};

export type MtaPrice = {
  __typename?: 'MtaPrice';
  additionalContractPremiumAdded: Scalars['Boolean'];
  additionalInsuranceContractPremium: Scalars['Decimal'];
  contractFirstMonthPrice: Scalars['Decimal'];
  contractPrice: Scalars['Decimal'];
  standardMonthlyPrice: Scalars['Decimal'];
  standardPrice: Scalars['Decimal'];
};

export type MtaQuote = {
  __typename?: 'MtaQuote';
  brand: SaleBrand;
  brandId: Scalars['Uuid'];
  claimOffPeriodDays?: Maybe<Scalars['Int']>;
  coolingOffDurationDays?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  excessType: ExcessType;
  product: SaleProduct;
  productId: Scalars['Uuid'];
  productVersionId: Scalars['Uuid'];
  productVersionName: Scalars['String'];
  productVersionProductComponents: Array<IProductVersionProductComponentsBreakdown>;
  salePrice: MtaPrice;
  termsAndConditionsRef: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAcceleratedMigrationContract: ContractPayload;
  addAction?: Maybe<AddActionPayload>;
  addAddress: AddAddressPayload;
  addAuroraAction?: Maybe<AddAuroraActionPayload>;
  addBERContributionAuthorisation: BERContributionPaymentAuthorisation;
  addBankDetails: CustomerBankDetails;
  addCancellationFeePreview?: Maybe<CancellationFeeBreakdown>;
  addContract: ContractPayload;
  addContractBoiler: AddContractBoilerPayload;
  addContractCancelNotification: ContractCancelNotificationPayload;
  addContractPreference: AddContractPreferencePayload;
  addCustomer: CustomerPayload;
  addCustomerDefaultPreference: AddCustomerDefaultPreferencePayload;
  addExcessPayment?: Maybe<Payment>;
  addGoodwillAuthorisation: GoodwillPaymentAuthorisation;
  addNewEnquiry?: Maybe<NewEnquiry>;
  addNote: AddNotePayload;
  addPaymentAuthorisation: PaymentAuthorisation;
  addPaymentCancellationAuthorisation: CancellationPaymentAuthorisation;
  addPaymentRefundAuthorisation: RefundPaymentAuthorisation;
  addPaymentsSchedule?: Maybe<Scalars['Boolean']>;
  addRenewalInteraction: AddRenewalInteractionPayload;
  addRepairContributionPayment?: Maybe<Payment>;
  addRepairReimbursementAuthorisation: RepairReimbursementPaymentAuthorisation;
  addSSECustomer: CustomerPayload;
  addSSEDummyContract: ContractPayload;
  amendBankDetails: Scalars['Boolean'];
  cancelCallout?: Maybe<CancelCalloutPayload>;
  cancelContract: UpdateContractPayload;
  changePaymentDay?: Maybe<Array<Maybe<Payment>>>;
  createContractRenewal: ContractPayload;
  createDocument: CreateDocumentPayload;
  createLandlordRecord?: Maybe<CreateLandlordRecordPayload>;
  createSSERenewal: ContractPayload;
  createServiceRecord?: Maybe<CreateServiceRecordPayload>;
  createSettlement: PaymentSettlement;
  dataShadowingContractUpsert: DataShadowingContractUpsertPayload;
  dataShadowingCustomerUpsert: DataShadowingCustomerUpsertPayload;
  declineExcess?: Maybe<DeclineExcessPayload>;
  deleteCustomer: DeleteCustomerPayload;
  deleteNewEnquiry: Scalars['Uuid'];
  generateMidTermAdjustmentPaymentsPreview: GenerateMidTermAdjustmentPaymentsPreviewPayload;
  linkExistingExcessToCallout: CalloutPaymentLinkModel;
  patchCompany: PatchCompanyPayload;
  produceTaskCsatSubmittedEvent?: Maybe<ProduceTaskCsatSubmittedEventPayload>;
  raiseCallout?: Maybe<RaiseCalloutPayload>;
  reconcileFile: ReconciliationResult;
  recreateDocument: CreateDocumentPayload;
  resendCommunication: SendPayload;
  resolveCallout?: Maybe<ResolveCalloutPayload>;
  scheduleManualRetry: Scalars['DateTime'];
  sendCommunication: SendPayload;
  setNoAccessDate?: Maybe<ScheduledNoAccess>;
  setReconciliationStatus: SetReconciliationStatusPayload;
  signupCustomer: CustomerCreationResult;
  undoContractCancelNotification: ContractCancelNotificationPayload;
  updateAddress: AddAddressPayload;
  updateContract: UpdateContractPayload;
  updateContractBoiler: AddContractBoilerPayload;
  updateContractPreference: ContractPreferencePayload;
  updateCustomer: AddCustomerPayload;
  updateDocumentTags: UpdateDocumentTagsPayload;
  updateMtaInlifeContract: Contract;
  updateNewEnquiry?: Maybe<NewEnquiry>;
  updatePaymentAuthorisationStatus: PaymentAuthorisation;
  updateScheduledDate: ServiceSchedule;
  updateServiceMonth: UpdateServiceMonthPayload;
  uploadDocument: UploadDocumentPayload;
  upsertCustomerDefaultPreference: CustomerDefaultPreferencePayload;
  upsertMarketingPermissions: UpsertMarketingPermissionsPayload;
  upsertUser: CompanyUser;
};


export type MutationaddAcceleratedMigrationContractArgs = {
  input: AddContractInput;
};


export type MutationaddActionArgs = {
  action?: InputMaybe<AddActionInput>;
};


export type MutationaddAddressArgs = {
  input: AddAddressInput;
};


export type MutationaddAuroraActionArgs = {
  input?: InputMaybe<AddAuroraActionInput>;
};


export type MutationaddBERContributionAuthorisationArgs = {
  input: BeyondEconomicalRepairContributionAuthorisationInputModelInput;
};


export type MutationaddBankDetailsArgs = {
  input: AddBankDetailsCommandInput;
};


export type MutationaddCancellationFeePreviewArgs = {
  input: CancellationFeePreviewInput;
};


export type MutationaddContractArgs = {
  input: AddContractInput;
};


export type MutationaddContractBoilerArgs = {
  input: AddContractBoilerInput;
};


export type MutationaddContractCancelNotificationArgs = {
  input: AddContractCancelNotificationInput;
};


export type MutationaddContractPreferenceArgs = {
  input: ContractPreferenceInput;
};


export type MutationaddCustomerArgs = {
  input: AddCustomerInput;
};


export type MutationaddCustomerDefaultPreferenceArgs = {
  input: CustomerDefaultPreferenceInput;
};


export type MutationaddExcessPaymentArgs = {
  input: AddExcessPaymentCommandInput;
};


export type MutationaddGoodwillAuthorisationArgs = {
  input: GoodwillAuthorisationInputModelInput;
};


export type MutationaddNewEnquiryArgs = {
  input?: InputMaybe<AddNewEnquiryCommandInput>;
};


export type MutationaddNoteArgs = {
  input: AddNoteInput;
};


export type MutationaddPaymentAuthorisationArgs = {
  input: PaymentAuthorisationInputModelInput;
};


export type MutationaddPaymentCancellationAuthorisationArgs = {
  input: CancellationAuthorisationInputModelInput;
};


export type MutationaddPaymentRefundAuthorisationArgs = {
  input: RefundAuthorisationInputModelInput;
};


export type MutationaddPaymentsScheduleArgs = {
  input: PaymentScheduleInput;
};


export type MutationaddRenewalInteractionArgs = {
  input: AddRenewalInteractionInput;
};


export type MutationaddRepairContributionPaymentArgs = {
  input: AddRepairContributionCommandInput;
};


export type MutationaddRepairReimbursementAuthorisationArgs = {
  input: RepairReimbursementAuthorisationInputModelInput;
};


export type MutationaddSSECustomerArgs = {
  input: AddSSECustomerInput;
};


export type MutationaddSSEDummyContractArgs = {
  input: AddContractInput;
};


export type MutationamendBankDetailsArgs = {
  input: AmendBankDetailsCommandInput;
};


export type MutationcancelCalloutArgs = {
  input?: InputMaybe<CancelCalloutInput>;
};


export type MutationcancelContractArgs = {
  input: CancelContractCommandInput;
};


export type MutationchangePaymentDayArgs = {
  input: ChangePaymentDayCommandInput;
};


export type MutationcreateContractRenewalArgs = {
  input: CreateContractRenewalInput;
};


export type MutationcreateDocumentArgs = {
  input: RequestDocumentCommandInput;
};


export type MutationcreateLandlordRecordArgs = {
  input?: InputMaybe<ProcessContractCreatedEventInput>;
};


export type MutationcreateSSERenewalArgs = {
  input: CreateSSERenewalInput;
};


export type MutationcreateServiceRecordArgs = {
  input?: InputMaybe<CreateServiceRecordInput>;
};


export type MutationcreateSettlementArgs = {
  settlement: CreateReconciliationSettlementCommandInput;
};


export type MutationdataShadowingContractUpsertArgs = {
  input: DataShadowingContractUpsertInput;
};


export type MutationdataShadowingCustomerUpsertArgs = {
  input: DataShadowingCustomerUpsertInput;
};


export type MutationdeclineExcessArgs = {
  input?: InputMaybe<DeclineExcessInput>;
};


export type MutationdeleteCustomerArgs = {
  input: DeleteCustomerCommandInput;
};


export type MutationdeleteNewEnquiryArgs = {
  input?: InputMaybe<DeleteNewEnquiryCommandInput>;
};


export type MutationgenerateMidTermAdjustmentPaymentsPreviewArgs = {
  input: GenerateMidTermAdjustmentPaymentsPreviewInput;
};


export type MutationlinkExistingExcessToCalloutArgs = {
  input: LinkExistingExcessToCalloutCommandInput;
};


export type MutationpatchCompanyArgs = {
  input: PatchCompanyInput;
};


export type MutationproduceTaskCsatSubmittedEventArgs = {
  input?: InputMaybe<ProduceTaskCsatSubmittedEventInput>;
};


export type MutationraiseCalloutArgs = {
  input?: InputMaybe<RaiseCalloutInput>;
};


export type MutationreconcileFileArgs = {
  confirmOverride?: Scalars['Boolean'];
  fileName: Scalars['String'];
};


export type MutationrecreateDocumentArgs = {
  input: RecreateDocumentInput;
};


export type MutationresendCommunicationArgs = {
  input: ResendCommunicationInput;
};


export type MutationresolveCalloutArgs = {
  input?: InputMaybe<ResolveCalloutInput>;
};


export type MutationscheduleManualRetryArgs = {
  paymentId: Scalars['Uuid'];
};


export type MutationsendCommunicationArgs = {
  input: SendCommunicationInput;
};


export type MutationsetNoAccessDateArgs = {
  input?: InputMaybe<SetNoAccessDateInput>;
};


export type MutationsetReconciliationStatusArgs = {
  command: SetReconciliationStatusCommandInput;
};


export type MutationsignupCustomerArgs = {
  input: CustomerSignupInput;
};


export type MutationundoContractCancelNotificationArgs = {
  input: UndoContractCancelNotificationInput;
};


export type MutationupdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationupdateContractArgs = {
  input: UpdateContractCommandInput;
};


export type MutationupdateContractBoilerArgs = {
  input: UpdateContractBoilerInput;
};


export type MutationupdateContractPreferenceArgs = {
  input: UpdateContractPreferenceCommandInput;
};


export type MutationupdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationupdateDocumentTagsArgs = {
  input: UpdateDocumentTagsInput;
};


export type MutationupdateMtaInlifeContractArgs = {
  input: UpdateMtaInlifeContractInput;
};


export type MutationupdateNewEnquiryArgs = {
  input?: InputMaybe<UpdateNewEnquiryCommandInput>;
};


export type MutationupdatePaymentAuthorisationStatusArgs = {
  input: PaymentAuthorisationStatusInputModelInput;
};


export type MutationupdateScheduledDateArgs = {
  input: UpdateScheduledDateInput;
};


export type MutationupdateServiceMonthArgs = {
  input: UpdateServiceMonthInput;
};


export type MutationuploadDocumentArgs = {
  input: UploadDocumentInput;
};


export type MutationupsertCustomerDefaultPreferenceArgs = {
  input: UpsertCustomerDefaultPreferenceCommandInput;
};


export type MutationupsertMarketingPermissionsArgs = {
  input: UpsertMarketingPermissionsInput;
};


export type MutationupsertUserArgs = {
  input: UpdatedUserInput;
};

export type NewEnquiry = {
  __typename?: 'NewEnquiry';
  activatedAt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<NewEnquiryAddress>;
  authorisedPerson?: Maybe<Scalars['String']>;
  autoSignUpStatus?: Maybe<AutoSignUpStatus>;
  autoSignUpValidationErrors?: Maybe<Array<AutoSignUpValidationError>>;
  bankDetails?: Maybe<NewEnquiryBankDetails>;
  boiler?: Maybe<Boiler>;
  brandName?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  contractStartDate?: Maybe<Scalars['DateTime']>;
  coverTypeId?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  excess?: Maybe<Scalars['String']>;
  forename: Scalars['String'];
  hasAgreedContact: Scalars['Boolean'];
  isOnboarded: Scalars['Boolean'];
  lastUpdatedAt: Scalars['DateTime'];
  ovoCustomerId?: Maybe<Scalars['String']>;
  paymentDay?: Maybe<Scalars['Int']>;
  phone: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  signUpId: Scalars['Uuid'];
  signUpType?: Maybe<SignUpType>;
  sourceOfSale?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type NewEnquiryAddress = {
  __typename?: 'NewEnquiryAddress';
  isCommercial: Scalars['Boolean'];
  isGuestHouse: Scalars['Boolean'];
  isLiving: Scalars['Boolean'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  ownership: Ownership;
  postcode: Scalars['String'];
};

export type NewEnquiryBankDetails = {
  __typename?: 'NewEnquiryBankDetails';
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  autoRenewalOptOut?: Maybe<Scalars['Boolean']>;
  isAccountHolder: Scalars['Boolean'];
  sortCode: Scalars['String'];
};

export type NewEnquiryBankDetailsInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  autoRenewalOptOut?: InputMaybe<Scalars['Boolean']>;
  isAccountHolder: Scalars['Boolean'];
  sortCode: Scalars['String'];
};

export type NewEnquiryFilterScope_AddressFilterInput = {
  and?: InputMaybe<Array<NewEnquiryFilterScope_AddressFilterInput>>;
  isCommercial?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  isGuestHouse?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  isLiving?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  line1?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  line2?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  line3?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  or?: InputMaybe<Array<NewEnquiryFilterScope_AddressFilterInput>>;
  ownership?: InputMaybe<NewEnquiryFilterScope_OwnershipOperationFilterInput>;
  postcode?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
};

export type NewEnquiryFilterScope_AutoSignUpValidationErrorOperationFilterInput = {
  eq?: InputMaybe<AutoSignUpValidationError>;
  in?: InputMaybe<Array<AutoSignUpValidationError>>;
  neq?: InputMaybe<AutoSignUpValidationError>;
  nin?: InputMaybe<Array<AutoSignUpValidationError>>;
};

export type NewEnquiryFilterScope_BankDetailsFilterInput = {
  accountName?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  accountNumber?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  and?: InputMaybe<Array<NewEnquiryFilterScope_BankDetailsFilterInput>>;
  autoRenewalOptOut?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  isAccountHolder?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  or?: InputMaybe<Array<NewEnquiryFilterScope_BankDetailsFilterInput>>;
  sortCode?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
};

export type NewEnquiryFilterScope_BoilerFilterInput = {
  and?: InputMaybe<Array<NewEnquiryFilterScope_BoilerFilterInput>>;
  boilerQuantity?: InputMaybe<NewEnquiryFilterScope_ComparableInt32OperationFilterInput>;
  fuelType?: InputMaybe<NewEnquiryFilterScope_FuelTypeOperationFilterInput>;
  installDate?: InputMaybe<NewEnquiryFilterScope_ComparableNullableOfDateTimeOperationFilterInput>;
  make?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  model?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  needsRepair?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  or?: InputMaybe<Array<NewEnquiryFilterScope_BoilerFilterInput>>;
};

export type NewEnquiryFilterScope_BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type NewEnquiryFilterScope_ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type NewEnquiryFilterScope_ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['Uuid']>;
  gt?: InputMaybe<Scalars['Uuid']>;
  gte?: InputMaybe<Scalars['Uuid']>;
  in?: InputMaybe<Array<Scalars['Uuid']>>;
  lt?: InputMaybe<Scalars['Uuid']>;
  lte?: InputMaybe<Scalars['Uuid']>;
  neq?: InputMaybe<Scalars['Uuid']>;
  ngt?: InputMaybe<Scalars['Uuid']>;
  ngte?: InputMaybe<Scalars['Uuid']>;
  nin?: InputMaybe<Array<Scalars['Uuid']>>;
  nlt?: InputMaybe<Scalars['Uuid']>;
  nlte?: InputMaybe<Scalars['Uuid']>;
};

export type NewEnquiryFilterScope_ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type NewEnquiryFilterScope_ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type NewEnquiryFilterScope_ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type NewEnquiryFilterScope_ContactFilterInput = {
  and?: InputMaybe<Array<NewEnquiryFilterScope_ContactFilterInput>>;
  homePlanContact?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  homePlanOptions?: InputMaybe<NewEnquiryFilterScope_PlanOptionsFilterInput>;
  isMailOnly?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  or?: InputMaybe<Array<NewEnquiryFilterScope_ContactFilterInput>>;
  otherOrgContact?: InputMaybe<NewEnquiryFilterScope_ListOrganizationOperationFilterInput>;
  otherOrgOptions?: InputMaybe<NewEnquiryFilterScope_PlanOptionsFilterInput>;
};

export type NewEnquiryFilterScope_CustomerSignUpFilterInput = {
  activatedAt?: InputMaybe<NewEnquiryFilterScope_ComparableNullableOfDateTimeOperationFilterInput>;
  address?: InputMaybe<NewEnquiryFilterScope_AddressFilterInput>;
  and?: InputMaybe<Array<NewEnquiryFilterScope_CustomerSignUpFilterInput>>;
  authorisedPerson?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  autoSignUpStatus?: InputMaybe<NewEnquiryFilterScope_NullableOfAutoSignUpStatusOperationFilterInput>;
  autoSignUpValidationErrors?: InputMaybe<NewEnquiryFilterScope_ListAutoSignUpValidationErrorOperationFilterInput>;
  bankDetails?: InputMaybe<NewEnquiryFilterScope_BankDetailsFilterInput>;
  boiler?: InputMaybe<NewEnquiryFilterScope_BoilerFilterInput>;
  brandName?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  contact?: InputMaybe<NewEnquiryFilterScope_ContactFilterInput>;
  contractStartDate?: InputMaybe<NewEnquiryFilterScope_ComparableNullableOfDateTimeOperationFilterInput>;
  coverTypeId?: InputMaybe<NewEnquiryFilterScope_ComparableNullableOfInt32OperationFilterInput>;
  dateOfBirth?: InputMaybe<NewEnquiryFilterScope_ComparableNullableOfDateTimeOperationFilterInput>;
  email?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  excess?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  forename?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  hasAgreedContact?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  isOnboarded?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  lastUpdatedAt?: InputMaybe<NewEnquiryFilterScope_ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<NewEnquiryFilterScope_CustomerSignUpFilterInput>>;
  ovoCustomerId?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  paymentDay?: InputMaybe<NewEnquiryFilterScope_ComparableNullableOfInt32OperationFilterInput>;
  phone?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  productName?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  promoCode?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  secondaryPhone?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  signUpId?: InputMaybe<NewEnquiryFilterScope_ComparableGuidOperationFilterInput>;
  signUpType?: InputMaybe<NewEnquiryFilterScope_NullableOfSignUpTypeOperationFilterInput>;
  sourceOfSale?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  surname?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
  title?: InputMaybe<NewEnquiryFilterScope_StringOperationFilterInput>;
};

export type NewEnquiryFilterScope_FuelTypeOperationFilterInput = {
  eq?: InputMaybe<NewEnquiryFuelType>;
  in?: InputMaybe<Array<NewEnquiryFuelType>>;
  neq?: InputMaybe<NewEnquiryFuelType>;
  nin?: InputMaybe<Array<NewEnquiryFuelType>>;
};

export type NewEnquiryFilterScope_ListAutoSignUpValidationErrorOperationFilterInput = {
  all?: InputMaybe<NewEnquiryFilterScope_AutoSignUpValidationErrorOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewEnquiryFilterScope_AutoSignUpValidationErrorOperationFilterInput>;
  some?: InputMaybe<NewEnquiryFilterScope_AutoSignUpValidationErrorOperationFilterInput>;
};

export type NewEnquiryFilterScope_ListOrganizationOperationFilterInput = {
  all?: InputMaybe<NewEnquiryFilterScope_OrganizationOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<NewEnquiryFilterScope_OrganizationOperationFilterInput>;
  some?: InputMaybe<NewEnquiryFilterScope_OrganizationOperationFilterInput>;
};

export type NewEnquiryFilterScope_NullableOfAutoSignUpStatusOperationFilterInput = {
  eq?: InputMaybe<AutoSignUpStatus>;
  in?: InputMaybe<Array<InputMaybe<AutoSignUpStatus>>>;
  neq?: InputMaybe<AutoSignUpStatus>;
  nin?: InputMaybe<Array<InputMaybe<AutoSignUpStatus>>>;
};

export type NewEnquiryFilterScope_NullableOfSignUpTypeOperationFilterInput = {
  eq?: InputMaybe<SignUpType>;
  in?: InputMaybe<Array<InputMaybe<SignUpType>>>;
  neq?: InputMaybe<SignUpType>;
  nin?: InputMaybe<Array<InputMaybe<SignUpType>>>;
};

export type NewEnquiryFilterScope_OrganizationOperationFilterInput = {
  eq?: InputMaybe<Organization>;
  in?: InputMaybe<Array<Organization>>;
  neq?: InputMaybe<Organization>;
  nin?: InputMaybe<Array<Organization>>;
};

export type NewEnquiryFilterScope_OwnershipOperationFilterInput = {
  eq?: InputMaybe<Ownership>;
  in?: InputMaybe<Array<Ownership>>;
  neq?: InputMaybe<Ownership>;
  nin?: InputMaybe<Array<Ownership>>;
};

export type NewEnquiryFilterScope_PlanOptionsFilterInput = {
  and?: InputMaybe<Array<NewEnquiryFilterScope_PlanOptionsFilterInput>>;
  email?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  or?: InputMaybe<Array<NewEnquiryFilterScope_PlanOptionsFilterInput>>;
  phone?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  post?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
  sms?: InputMaybe<NewEnquiryFilterScope_BooleanOperationFilterInput>;
};

export type NewEnquiryFilterScope_StringOperationFilterInput = {
  and?: InputMaybe<Array<NewEnquiryFilterScope_StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<NewEnquiryFilterScope_StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum NewEnquiryFuelType {
  Gas = 'Gas'
}

export enum NoAccessType {
  Company = 'Company',
  Customer = 'Customer'
}

export type NonInsuranceCancellationFeeComponent = {
  __typename?: 'NonInsuranceCancellationFeeComponent';
  adminFee: Scalars['Decimal'];
  annualBoilerServiceFee: Scalars['Decimal'];
  nonInsuranceCancellationCharge: Scalars['Decimal'];
  outstandingNonInsurancePremium: Scalars['Decimal'];
  totalNonInsuranceCancellationAmount: Scalars['Decimal'];
};

export type NotEligibleError = IProductError & {
  __typename?: 'NotEligibleError';
  message: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  isNotified?: Maybe<Scalars['Boolean']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
};

export type NullableOfApplianceTypeOperationFilterInput = {
  eq?: InputMaybe<ApplianceType>;
  in?: InputMaybe<Array<InputMaybe<ApplianceType>>>;
  neq?: InputMaybe<ApplianceType>;
  nin?: InputMaybe<Array<InputMaybe<ApplianceType>>>;
};

export type NullableOfCalloutStatusOperationFilterInput = {
  eq?: InputMaybe<CalloutStatus>;
  in?: InputMaybe<Array<InputMaybe<CalloutStatus>>>;
  neq?: InputMaybe<CalloutStatus>;
  nin?: InputMaybe<Array<InputMaybe<CalloutStatus>>>;
};

export type NullableOfCalloutTypeOperationFilterInput = {
  eq?: InputMaybe<CalloutType>;
  in?: InputMaybe<Array<InputMaybe<CalloutType>>>;
  neq?: InputMaybe<CalloutType>;
  nin?: InputMaybe<Array<InputMaybe<CalloutType>>>;
};

export type NullableOfCompanyCalloutNotifyMethodOperationFilterInput = {
  eq?: InputMaybe<CompanyCalloutNotifyMethod>;
  in?: InputMaybe<Array<InputMaybe<CompanyCalloutNotifyMethod>>>;
  neq?: InputMaybe<CompanyCalloutNotifyMethod>;
  nin?: InputMaybe<Array<InputMaybe<CompanyCalloutNotifyMethod>>>;
};

export type NullableOfCompanyStatusOperationFilterInput = {
  eq?: InputMaybe<CompanyStatus>;
  in?: InputMaybe<Array<InputMaybe<CompanyStatus>>>;
  neq?: InputMaybe<CompanyStatus>;
  nin?: InputMaybe<Array<InputMaybe<CompanyStatus>>>;
};

export type NullableOfContractStatusOperationFilterInput = {
  eq?: InputMaybe<CompanyContractStatus>;
  in?: InputMaybe<Array<InputMaybe<CompanyContractStatus>>>;
  neq?: InputMaybe<CompanyContractStatus>;
  nin?: InputMaybe<Array<InputMaybe<CompanyContractStatus>>>;
};

export type NullableOfEnquiryValidationStatusTypeOperationFilterInput = {
  eq?: InputMaybe<EnquiryValidationStatusType>;
  in?: InputMaybe<Array<InputMaybe<EnquiryValidationStatusType>>>;
  neq?: InputMaybe<EnquiryValidationStatusType>;
  nin?: InputMaybe<Array<InputMaybe<EnquiryValidationStatusType>>>;
};

export type NullableOfGiBillingStatusOperationFilterInput = {
  eq?: InputMaybe<GiBillingStatus>;
  in?: InputMaybe<Array<InputMaybe<GiBillingStatus>>>;
  neq?: InputMaybe<GiBillingStatus>;
  nin?: InputMaybe<Array<InputMaybe<GiBillingStatus>>>;
};

export type NullableOfPriceBasisOperationFilterInput = {
  eq?: InputMaybe<PriceBasis>;
  in?: InputMaybe<Array<InputMaybe<PriceBasis>>>;
  neq?: InputMaybe<PriceBasis>;
  nin?: InputMaybe<Array<InputMaybe<PriceBasis>>>;
};

export type NullableOfProblemTypeOperationFilterInput = {
  eq?: InputMaybe<ProblemType>;
  in?: InputMaybe<Array<InputMaybe<ProblemType>>>;
  neq?: InputMaybe<ProblemType>;
  nin?: InputMaybe<Array<InputMaybe<ProblemType>>>;
};

export type NullableOfRatingOperationFilterInput = {
  eq?: InputMaybe<Rating>;
  in?: InputMaybe<Array<InputMaybe<Rating>>>;
  neq?: InputMaybe<Rating>;
  nin?: InputMaybe<Array<InputMaybe<Rating>>>;
};

export type NullableOfRenewalDeclineReasonOperationFilterInput = {
  eq?: InputMaybe<RenewalDeclineReason>;
  in?: InputMaybe<Array<InputMaybe<RenewalDeclineReason>>>;
  neq?: InputMaybe<RenewalDeclineReason>;
  nin?: InputMaybe<Array<InputMaybe<RenewalDeclineReason>>>;
};

export type NullableOfSelfBillingStatusOperationFilterInput = {
  eq?: InputMaybe<SelfBillingStatus>;
  in?: InputMaybe<Array<InputMaybe<SelfBillingStatus>>>;
  neq?: InputMaybe<SelfBillingStatus>;
  nin?: InputMaybe<Array<InputMaybe<SelfBillingStatus>>>;
};

export type NullableOfServiceCompanyConfirmedOperationFilterInput = {
  eq?: InputMaybe<ServiceCompanyConfirmed>;
  in?: InputMaybe<Array<InputMaybe<ServiceCompanyConfirmed>>>;
  neq?: InputMaybe<ServiceCompanyConfirmed>;
  nin?: InputMaybe<Array<InputMaybe<ServiceCompanyConfirmed>>>;
};

export type NullableOfSludgeWarningOperationFilterInput = {
  eq?: InputMaybe<SludgeWarning>;
  in?: InputMaybe<Array<InputMaybe<SludgeWarning>>>;
  neq?: InputMaybe<SludgeWarning>;
  nin?: InputMaybe<Array<InputMaybe<SludgeWarning>>>;
};

export type NullableOfUnderwriterStatusTypeOperationFilterInput = {
  eq?: InputMaybe<UnderwriterStatusType>;
  in?: InputMaybe<Array<InputMaybe<UnderwriterStatusType>>>;
  neq?: InputMaybe<UnderwriterStatusType>;
  nin?: InputMaybe<Array<InputMaybe<UnderwriterStatusType>>>;
};

export type Offer = {
  __typename?: 'Offer';
  campaignId: Scalars['Uuid'];
  endDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  offerType: OfferType;
  productVersionId: Scalars['Uuid'];
  promotionId: Scalars['Uuid'];
  startDate: Scalars['DateTime'];
};

export type OfferBreakdown = {
  __typename?: 'OfferBreakdown';
  amount?: Maybe<Scalars['Float']>;
  componentId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  offerBreakdownType: OfferBreakdownType;
  offerId: Scalars['Uuid'];
};

export type OfferBreakdownFilterInput = {
  amount?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  and?: InputMaybe<Array<OfferBreakdownFilterInput>>;
  componentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  offerBreakdownType?: InputMaybe<OfferBreakdownTypeOperationFilterInput>;
  offerId?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<OfferBreakdownFilterInput>>;
};

export type OfferBreakdownInput = {
  amount?: InputMaybe<Scalars['Float']>;
  componentId: Scalars['Uuid'];
  offerBreakdownType: OfferBreakdownType;
  offerId: Scalars['Uuid'];
};

export type OfferBreakdownSortInput = {
  amount?: InputMaybe<SortEnumType>;
  componentId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  offerBreakdownType?: InputMaybe<SortEnumType>;
  offerId?: InputMaybe<SortEnumType>;
};

export enum OfferBreakdownType {
  AMOUNT_OFF = 'AMOUNT_OFF',
  OUTSTANDING_AMOUNT = 'OUTSTANDING_AMOUNT',
  PERCENTAGE_OFF = 'PERCENTAGE_OFF'
}

export type OfferBreakdownTypeOperationFilterInput = {
  eq?: InputMaybe<OfferBreakdownType>;
  in?: InputMaybe<Array<OfferBreakdownType>>;
  neq?: InputMaybe<OfferBreakdownType>;
  nin?: InputMaybe<Array<OfferBreakdownType>>;
};

/** A connection to a list of items. */
export type OfferBreakdownsConnection = {
  __typename?: 'OfferBreakdownsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OfferBreakdownsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OfferBreakdown>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OfferBreakdownsEdge = {
  __typename?: 'OfferBreakdownsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OfferBreakdown;
};

export type OfferDetails = {
  __typename?: 'OfferDetails';
  offer: Offer;
  promotion: Promotion;
};

export type OfferFilterInput = {
  and?: InputMaybe<Array<OfferFilterInput>>;
  campaignId?: InputMaybe<ComparableGuidOperationFilterInput>;
  endDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  offerType?: InputMaybe<OfferTypeOperationFilterInput>;
  or?: InputMaybe<Array<OfferFilterInput>>;
  productVersionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  promotionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type OfferInput = {
  campaignId: Scalars['Uuid'];
  endDate: Scalars['DateTime'];
  offerType: OfferType;
  productVersionId: Scalars['Uuid'];
  promotionId: Scalars['Uuid'];
  startDate: Scalars['DateTime'];
};

export type OfferSortInput = {
  campaignId?: InputMaybe<SortEnumType>;
  endDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  offerType?: InputMaybe<SortEnumType>;
  productVersionId?: InputMaybe<SortEnumType>;
  promotionId?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
};

export enum OfferType {
  DIRECT = 'DIRECT',
  INDIRECT = 'INDIRECT',
  STAFF = 'STAFF'
}

export type OfferTypeOperationFilterInput = {
  eq?: InputMaybe<OfferType>;
  in?: InputMaybe<Array<OfferType>>;
  neq?: InputMaybe<OfferType>;
  nin?: InputMaybe<Array<OfferType>>;
};

/** A connection to a list of items. */
export type OffersConnection = {
  __typename?: 'OffersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OffersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Offer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OffersEdge = {
  __typename?: 'OffersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Offer;
};

export enum Organization {
  CorgiHomeHeat = 'CorgiHomeHeat',
  Ovo = 'Ovo',
  Sse = 'Sse'
}

export enum Origin {
  GreenInstaller = 'GreenInstaller',
  LandlordWorker = 'LandlordWorker',
  SelfServe = 'SelfServe'
}

export type OriginOperationFilterInput = {
  eq?: InputMaybe<Origin>;
  in?: InputMaybe<Array<Origin>>;
  neq?: InputMaybe<Origin>;
  nin?: InputMaybe<Array<Origin>>;
};

export type OutOfHours = {
  __typename?: 'OutOfHours';
  action?: Maybe<Scalars['Int']>;
  agent?: Maybe<Scalars['Int']>;
  attendance?: Maybe<Scalars['DateTime']>;
  customerConfirmed?: Maybe<Scalars['DateTime']>;
  dateStamp?: Maybe<Scalars['DateTime']>;
  installerConfirmed?: Maybe<Scalars['Boolean']>;
  isOutOfHours?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['String']>;
  verification?: Maybe<Scalars['Int']>;
  warning?: Maybe<Scalars['Int']>;
};

export type OutOfHoursInput = {
  engineerConfirmed: Scalars['Boolean'];
};

export type Owner = {
  __typename?: 'Owner';
  forename?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type OwnerFilterInput = {
  and?: InputMaybe<Array<OwnerFilterInput>>;
  forename?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OwnerFilterInput>>;
  surname?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type OwnerSortInput = {
  forename?: InputMaybe<SortEnumType>;
  surname?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
};

export enum Ownership {
  Landlord = 'Landlord',
  Owner = 'Owner',
  Tenant = 'Tenant'
}

export enum OwnershipType {
  Landlord = 'Landlord',
  OwnerOccupier = 'OwnerOccupier',
  Tenant = 'Tenant'
}

export type OwnershipTypeOperationFilterInput = {
  eq?: InputMaybe<OwnershipType>;
  in?: InputMaybe<Array<OwnershipType>>;
  neq?: InputMaybe<OwnershipType>;
  nin?: InputMaybe<Array<OwnershipType>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PartUsedInput = {
  cost: Scalars['Decimal'];
  description: Scalars['String'];
};

export type PatchCompanyInput = {
  companyId: Scalars['Int'];
  patch?: InputMaybe<CompanyPatchInput>;
};

export type PatchCompanyPayload = {
  __typename?: 'PatchCompanyPayload';
  company?: Maybe<Company>;
};

export type Payment = {
  __typename?: 'Payment';
  category: PaymentCategory;
  contractId: Scalars['Uuid'];
  dateCreated: Scalars['DateTime'];
  dueAmount: Scalars['Decimal'];
  dueDate: Scalars['DateTime'];
  earliestPaymentDueDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  insuranceAmount: Scalars['Decimal'];
  latestPaymentDueDate: Scalars['DateTime'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentMethod: PaymentMethod;
  productType: ProductType;
  scheduleStatus: ScheduleStatus;
  settlements?: Maybe<Array<Maybe<PaymentSettlement>>>;
  subCategory: PaymentSubCategory;
};

export type PaymentAuthorisation = {
  __typename?: 'PaymentAuthorisation';
  additionalInformation?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['DateTime'];
  customerFriendlyId?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  paymentProposal?: Maybe<PaymentProposal>;
  status: PaymentAuthorisationStatus;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export type PaymentAuthorisationInputModelInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  customerFriendlyId?: InputMaybe<Scalars['String']>;
  paymentProposal?: InputMaybe<PaymentProposalInputModelInput>;
};

export enum PaymentAuthorisationStatus {
  Authorised = 'Authorised',
  Obsolete = 'Obsolete',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type PaymentAuthorisationStatusInputModelInput = {
  paymentAuthorisationId: Scalars['Uuid'];
  status: PaymentAuthorisationStatus;
};

export type PaymentAuthorisationStatusOperationFilterInput = {
  eq?: InputMaybe<PaymentAuthorisationStatus>;
  in?: InputMaybe<Array<PaymentAuthorisationStatus>>;
  neq?: InputMaybe<PaymentAuthorisationStatus>;
  nin?: InputMaybe<Array<PaymentAuthorisationStatus>>;
};

export enum PaymentAuthorisationType {
  BerContribution = 'BerContribution',
  Cancellation = 'Cancellation',
  Goodwill = 'Goodwill',
  NotDefined = 'NotDefined',
  Refund = 'Refund',
  RepairReimbursement = 'RepairReimbursement'
}

export type PaymentAuthorisationTypeOperationFilterInput = {
  eq?: InputMaybe<PaymentAuthorisationType>;
  in?: InputMaybe<Array<PaymentAuthorisationType>>;
  neq?: InputMaybe<PaymentAuthorisationType>;
  nin?: InputMaybe<Array<PaymentAuthorisationType>>;
};

export type PaymentAuthorisationViewModel = {
  __typename?: 'PaymentAuthorisationViewModel';
  additionalInformation?: Maybe<Scalars['String']>;
  complaintId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['DateTime'];
  customerFriendlyId?: Maybe<Scalars['String']>;
  goodwillAmount?: Maybe<Scalars['Decimal']>;
  id: Scalars['Uuid'];
  parentPaymentId?: Maybe<Scalars['Uuid']>;
  paymentProposal?: Maybe<PaymentProposal>;
  refundReason?: Maybe<Scalars['String']>;
  status: PaymentAuthorisationStatus;
  type: PaymentAuthorisationType;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export type PaymentAuthorisationViewModelFilterInput = {
  additionalInformation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PaymentAuthorisationViewModelFilterInput>>;
  complaintId?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  createdBy?: InputMaybe<StringOperationFilterInput>;
  createdDateTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  customerFriendlyId?: InputMaybe<StringOperationFilterInput>;
  goodwillAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<PaymentAuthorisationViewModelFilterInput>>;
  parentPaymentId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  paymentProposal?: InputMaybe<PaymentProposalFilterInput>;
  refundReason?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<PaymentAuthorisationStatusOperationFilterInput>;
  type?: InputMaybe<PaymentAuthorisationTypeOperationFilterInput>;
  updatedBy?: InputMaybe<StringOperationFilterInput>;
  updatedDateTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

/** A connection to a list of items. */
export type PaymentAuthorisationsConnection = {
  __typename?: 'PaymentAuthorisationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PaymentAuthorisationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<PaymentAuthorisationViewModel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PaymentAuthorisationsEdge = {
  __typename?: 'PaymentAuthorisationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PaymentAuthorisationViewModel>;
};

export enum PaymentCategory {
  Callout = 'Callout',
  Complaint = 'Complaint',
  Contract = 'Contract'
}

export type PaymentCategoryOperationFilterInput = {
  eq?: InputMaybe<PaymentCategory>;
  in?: InputMaybe<Array<PaymentCategory>>;
  neq?: InputMaybe<PaymentCategory>;
  nin?: InputMaybe<Array<PaymentCategory>>;
};

export enum PaymentFrequency {
  Annual = 'Annual',
  Monthly = 'Monthly'
}

export enum PaymentMethod {
  Card = 'Card',
  Cheque = 'Cheque',
  DirectCredit = 'DirectCredit',
  DirectDebit = 'DirectDebit'
}

export type PaymentMethodOperationFilterInput = {
  eq?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  neq?: InputMaybe<PaymentMethod>;
  nin?: InputMaybe<Array<PaymentMethod>>;
};

export type PaymentProposal = {
  __typename?: 'PaymentProposal';
  category: PaymentCategory;
  contractId: Scalars['Uuid'];
  insuranceAmount: Scalars['Decimal'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentId: Scalars['Uuid'];
  paymentMethod: PaymentMethod;
  subCategory: PaymentSubCategory;
};

export type PaymentProposalFilterInput = {
  and?: InputMaybe<Array<PaymentProposalFilterInput>>;
  category?: InputMaybe<PaymentCategoryOperationFilterInput>;
  contractId?: InputMaybe<ComparableGuidOperationFilterInput>;
  insuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  nonInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  or?: InputMaybe<Array<PaymentProposalFilterInput>>;
  paymentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  paymentMethod?: InputMaybe<PaymentMethodOperationFilterInput>;
  subCategory?: InputMaybe<PaymentSubCategoryOperationFilterInput>;
};

export type PaymentProposalInputModelInput = {
  category: PaymentCategory;
  contractId: Scalars['Uuid'];
  insuranceAmount: Scalars['Decimal'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentMethod: PaymentMethod;
  subCategory: PaymentSubCategory;
};

export type PaymentScheduleInput = {
  contractDurationMonth: Scalars['Int'];
  contractId: Scalars['Uuid'];
  contractStartDate: Scalars['DateTime'];
  coolingOffEndDate: Scalars['DateTime'];
  paymentDay?: InputMaybe<Scalars['Int']>;
  paymentFrequency: PaymentFrequency;
  promotionDurationMonth: Scalars['Int'];
  promotionInsuranceAmount: Scalars['Decimal'];
  promotionNonInsuranceAmount: Scalars['Decimal'];
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
};

export enum PaymentScheduleType {
  Advanced = 'Advanced',
  Annual = 'Annual',
  Arrears = 'Arrears',
  InLife = 'InLife',
  NotDefined = 'NotDefined'
}

export type PaymentScheduleTypeOperationFilterInput = {
  eq?: InputMaybe<PaymentScheduleType>;
  in?: InputMaybe<Array<PaymentScheduleType>>;
  neq?: InputMaybe<PaymentScheduleType>;
  nin?: InputMaybe<Array<PaymentScheduleType>>;
};

export type PaymentSettlement = {
  __typename?: 'PaymentSettlement';
  amount: Scalars['Decimal'];
  checkoutPaymentId?: Maybe<Scalars['String']>;
  collectionDate?: Maybe<Scalars['DateTime']>;
  contractId?: Maybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  id?: Maybe<Scalars['Uuid']>;
  insuranceAmount: Scalars['Decimal'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentCategory: PaymentCategory;
  paymentId: Scalars['Uuid'];
  paymentMethod: PaymentMethod;
  paymentSubCategory: PaymentSubCategory;
  productType: ProductType;
  reconciliationId?: Maybe<Scalars['String']>;
  reconciliationStatus?: Maybe<Scalars['Boolean']>;
  reconciliationSummaryId?: Maybe<Scalars['Uuid']>;
  retryDate?: Maybe<Scalars['DateTime']>;
  serviceUserNumber?: Maybe<Scalars['String']>;
  settlementType: SettlementType;
};

export type PaymentSettlementEntity = {
  __typename?: 'PaymentSettlementEntity';
  amount: Scalars['Decimal'];
  checkoutPaymentId?: Maybe<Scalars['String']>;
  collectionDate?: Maybe<Scalars['DateTime']>;
  contractId: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  id: Scalars['Uuid'];
  insuranceAmount: Scalars['Decimal'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentCategory: PaymentCategory;
  paymentId: Scalars['Uuid'];
  paymentMethod: PaymentMethod;
  paymentSubCategory: PaymentSubCategory;
  productType: ProductType;
  reconciliationId?: Maybe<Scalars['String']>;
  reconciliationStatus?: Maybe<Scalars['Boolean']>;
  reconciliationSummaryId?: Maybe<Scalars['Uuid']>;
  retryDate?: Maybe<Scalars['DateTime']>;
  serviceUserNumber?: Maybe<Scalars['String']>;
  settlementType: SettlementType;
};

export type PaymentSettlementEntityFilterInput = {
  amount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  and?: InputMaybe<Array<PaymentSettlementEntityFilterInput>>;
  checkoutPaymentId?: InputMaybe<StringOperationFilterInput>;
  collectionDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  contractId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdOn?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  insuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  nonInsuranceAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
  or?: InputMaybe<Array<PaymentSettlementEntityFilterInput>>;
  paymentCategory?: InputMaybe<PaymentCategoryOperationFilterInput>;
  paymentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  paymentMethod?: InputMaybe<PaymentMethodOperationFilterInput>;
  paymentSubCategory?: InputMaybe<PaymentSubCategoryOperationFilterInput>;
  productType?: InputMaybe<ProductTypeOperationFilterInput>;
  reconciliationId?: InputMaybe<StringOperationFilterInput>;
  reconciliationStatus?: InputMaybe<BooleanOperationFilterInput>;
  reconciliationSummaryId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  retryDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  serviceUserNumber?: InputMaybe<StringOperationFilterInput>;
  settlementType?: InputMaybe<SettlementTypeOperationFilterInput>;
};

export type PaymentSettlementEntitySortInput = {
  amount?: InputMaybe<SortEnumType>;
  checkoutPaymentId?: InputMaybe<SortEnumType>;
  collectionDate?: InputMaybe<SortEnumType>;
  contractId?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  insuranceAmount?: InputMaybe<SortEnumType>;
  nonInsuranceAmount?: InputMaybe<SortEnumType>;
  paymentCategory?: InputMaybe<SortEnumType>;
  paymentId?: InputMaybe<SortEnumType>;
  paymentMethod?: InputMaybe<SortEnumType>;
  paymentSubCategory?: InputMaybe<SortEnumType>;
  productType?: InputMaybe<SortEnumType>;
  reconciliationId?: InputMaybe<SortEnumType>;
  reconciliationStatus?: InputMaybe<SortEnumType>;
  reconciliationSummaryId?: InputMaybe<SortEnumType>;
  retryDate?: InputMaybe<SortEnumType>;
  serviceUserNumber?: InputMaybe<SortEnumType>;
  settlementType?: InputMaybe<SortEnumType>;
};

export type PaymentSettlementInput = {
  amount: Scalars['Decimal'];
  checkoutPaymentId?: InputMaybe<Scalars['String']>;
  collectionDate?: InputMaybe<Scalars['DateTime']>;
  contractId?: InputMaybe<Scalars['Uuid']>;
  createdOn: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Uuid']>;
  insuranceAmount: Scalars['Decimal'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentCategory: PaymentCategory;
  paymentId: Scalars['Uuid'];
  paymentMethod: PaymentMethod;
  paymentSubCategory: PaymentSubCategory;
  productType: ProductType;
  reconciliationId?: InputMaybe<Scalars['String']>;
  reconciliationStatus?: InputMaybe<Scalars['Boolean']>;
  reconciliationSummaryId?: InputMaybe<Scalars['Uuid']>;
  retryDate?: InputMaybe<Scalars['DateTime']>;
  serviceUserNumber?: InputMaybe<Scalars['String']>;
  settlementType: SettlementType;
};

export type PaymentSettlementResult = {
  __typename?: 'PaymentSettlementResult';
  amount: Scalars['Decimal'];
  checkoutPaymentId?: Maybe<Scalars['String']>;
  collectionDate?: Maybe<Scalars['DateTime']>;
  contractId?: Maybe<Scalars['Uuid']>;
  createdOn?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Uuid']>;
  insuranceAmount: Scalars['Decimal'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentCategory?: Maybe<PaymentCategory>;
  paymentId?: Maybe<Scalars['Uuid']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentSubCategory?: Maybe<PaymentSubCategory>;
  productType?: Maybe<ProductType>;
  reconciliationId?: Maybe<Scalars['String']>;
  reconciliationStatus?: Maybe<Scalars['Boolean']>;
  reconciliationSummaryId?: Maybe<Scalars['Uuid']>;
  retryDate?: Maybe<Scalars['DateTime']>;
  serviceUserNumber?: Maybe<Scalars['String']>;
  settlementType?: Maybe<SettlementType>;
};

export enum PaymentStatusType {
  AuditDispute = 'AuditDispute',
  Authorised = 'Authorised',
  Clawback = 'Clawback',
  ClawbackRequired = 'ClawbackRequired',
  DisputeClawback = 'DisputeClawback',
  Disputed = 'Disputed',
  Invoiced = 'Invoiced',
  Paid = 'Paid',
  PaymentHeld = 'PaymentHeld',
  Pending = 'Pending',
  Quarantine = 'Quarantine',
  UndoneClawback = 'UndoneClawback'
}

export enum PaymentSubCategory {
  AdvancedPayment = 'AdvancedPayment',
  BerContribution = 'BerContribution',
  Cancellation = 'Cancellation',
  Contribution = 'Contribution',
  Excess = 'Excess',
  Goodwill = 'Goodwill',
  Instalment = 'Instalment',
  Redress = 'Redress',
  Refund = 'Refund',
  RepairContribution = 'RepairContribution',
  RepairReimbursement = 'RepairReimbursement'
}

export type PaymentSubCategoryOperationFilterInput = {
  eq?: InputMaybe<PaymentSubCategory>;
  in?: InputMaybe<Array<PaymentSubCategory>>;
  neq?: InputMaybe<PaymentSubCategory>;
  nin?: InputMaybe<Array<PaymentSubCategory>>;
};

export type PlanOptions = {
  __typename?: 'PlanOptions';
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export type PlanOptionsInput = {
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export type PreferredContactInput = {
  id: Scalars['Int'];
};

export type PresignedUploadUrl = {
  __typename?: 'PresignedUploadUrl';
  objectName: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export enum PriceBasis {
  Fixed = 'Fixed',
  Other = 'Other',
  TimeAndMaterials = 'TimeAndMaterials'
}

export enum ProblemType {
  BlockedDrain = 'BlockedDrain',
  BlockedDrainageExternal = 'BlockedDrainageExternal',
  BlockedDrainageInternal = 'BlockedDrainageInternal',
  BoilerBlowingFuses = 'BoilerBlowingFuses',
  BoilerFaultLights = 'BoilerFaultLights',
  BoilerLockout = 'BoilerLockout',
  BoilerPilotLight = 'BoilerPilotLight',
  BoilerPressureHiLo = 'BoilerPressureHiLo',
  BoilerPumpNoise = 'BoilerPumpNoise',
  BoilerReplacement = 'BoilerReplacement',
  BoilerReplacementATAG = 'BoilerReplacementATAG',
  CO2DetectorFault = 'CO2DetectorFault',
  CircuitBreakerTripping = 'CircuitBreakerTripping',
  DiagnoseOnlyBoiler = 'DiagnoseOnlyBoiler',
  DiagnoseOnlySystem = 'DiagnoseOnlySystem',
  FaultySyphonBallcock = 'FaultySyphonBallcock',
  FixedPriceRepair = 'FixedPriceRepair',
  FrozenCondensate = 'FrozenCondensate',
  GasSupplyPipeLeak = 'GasSupplyPipeLeak',
  InitialInspection = 'InitialInspection',
  LeakBoiler = 'LeakBoiler',
  LeakDrainageExternal = 'LeakDrainageExternal',
  LeakDrainageInternal = 'LeakDrainageInternal',
  LeakEmergency = 'LeakEmergency',
  LeakExternalSupply = 'LeakExternalSupply',
  LeakOverflow = 'LeakOverflow',
  LeakPlumbing = 'LeakPlumbing',
  LeakRadiator = 'LeakRadiator',
  LeakSystemPipework = 'LeakSystemPipework',
  LeakingTap = 'LeakingTap',
  LeakingWC = 'LeakingWC',
  LightswitchFault = 'LightswitchFault',
  MainFuseBlown = 'MainFuseBlown',
  NoHeating = 'NoHeating',
  NoHotWater = 'NoHotWater',
  NoisyPipe = 'NoisyPipe',
  RadiatorNotHeating = 'RadiatorNotHeating',
  RoomStatFault = 'RoomStatFault',
  SeeJobsheet = 'SeeJobsheet',
  Service = 'Service',
  SmartThermostatInstall = 'SmartThermostatInstall',
  SmellGas = 'SmellGas',
  SocketFault = 'SocketFault',
  TRVFault = 'TRVFault',
  TapReWasher = 'TapReWasher',
  TimerFault = 'TimerFault',
  Unknown = 'Unknown'
}

export type ProcessContractCreatedEventInput = {
  contractId: Scalars['Uuid'];
  serviceDueDate?: InputMaybe<Scalars['DateTime']>;
};

export enum ProduceResult {
  NOT_PERSISTED = 'NOT_PERSISTED',
  PERSISTED = 'PERSISTED',
  POSSIBLY_PERSISTED = 'POSSIBLY_PERSISTED'
}

export type ProduceTaskCsatSubmittedEventInput = {
  callCentreScore: Scalars['Int'];
  engineerScore: Scalars['Int'];
  legacyTaskId: Scalars['Int'];
};

export type ProduceTaskCsatSubmittedEventPayload = {
  __typename?: 'ProduceTaskCsatSubmittedEventPayload';
  produceResult: ProduceResult;
};

export type Product = {
  __typename?: 'Product';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
  productCategory: ProductCategory;
};

export enum ProductCategory {
  CORE = 'CORE',
  MIGRATION = 'MIGRATION',
  NON_CORE = 'NON_CORE'
}

export type ProductCategoryOperationFilterInput = {
  eq?: InputMaybe<ProductCategory>;
  in?: InputMaybe<Array<ProductCategory>>;
  neq?: InputMaybe<ProductCategory>;
  nin?: InputMaybe<Array<ProductCategory>>;
};

export type ProductComponent = {
  __typename?: 'ProductComponent';
  id: Scalars['Uuid'];
  name: Scalars['String'];
  productComponentProductComponentItems: Array<ProductComponentProductComponentItems>;
  taxCode: TaxCode;
  taxCodeId: Scalars['Uuid'];
  type: ProductComponentType;
};

export type ProductComponentFilterInput = {
  and?: InputMaybe<Array<ProductComponentFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductComponentFilterInput>>;
  productComponentProductComponentItems?: InputMaybe<ListFilterInputTypeOfProductComponentProductComponentItemsFilterInput>;
  taxCode?: InputMaybe<TaxCodeFilterInput>;
  taxCodeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  type?: InputMaybe<ProductComponentTypeOperationFilterInput>;
};

export type ProductComponentItem = {
  __typename?: 'ProductComponentItem';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
  sectionNumber?: Maybe<Scalars['Int']>;
  sumInsuredTextOverride?: Maybe<Scalars['String']>;
};

export type ProductComponentItemFilterInput = {
  and?: InputMaybe<Array<ProductComponentItemFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductComponentItemFilterInput>>;
  sectionNumber?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  sumInsuredTextOverride?: InputMaybe<StringOperationFilterInput>;
};

export type ProductComponentItemSortInput = {
  code?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  sectionNumber?: InputMaybe<SortEnumType>;
  sumInsuredTextOverride?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ProductComponentItemsConnection = {
  __typename?: 'ProductComponentItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductComponentItemsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductComponentItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductComponentItemsEdge = {
  __typename?: 'ProductComponentItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductComponentItem;
};

export type ProductComponentProductComponentItems = {
  __typename?: 'ProductComponentProductComponentItems';
  covered: Scalars['Boolean'];
  excessAmount?: Maybe<Scalars['Decimal']>;
  id: Scalars['Uuid'];
  productComponent: ProductComponent;
  productComponentId: Scalars['Uuid'];
  productComponentItem: ProductComponentItem;
  productComponentItemId: Scalars['Uuid'];
  /** @deprecated Use SumInsuredGroup.SumInsuredAmount instead */
  repairLimit?: Maybe<Scalars['Decimal']>;
  sumInsuredGroup?: Maybe<SumInsuredGroup>;
  sumInsuredGroupId?: Maybe<Scalars['Uuid']>;
};

export type ProductComponentProductComponentItemsFilterInput = {
  and?: InputMaybe<Array<ProductComponentProductComponentItemsFilterInput>>;
  covered?: InputMaybe<BooleanOperationFilterInput>;
  excessAmount?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductComponentProductComponentItemsFilterInput>>;
  productComponent?: InputMaybe<ProductComponentFilterInput>;
  productComponentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  productComponentItem?: InputMaybe<ProductComponentItemFilterInput>;
  productComponentItemId?: InputMaybe<ComparableGuidOperationFilterInput>;
  repairLimit?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  sumInsuredGroup?: InputMaybe<SumInsuredGroupFilterInput>;
  sumInsuredGroupId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ProductComponentSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  taxCode?: InputMaybe<TaxCodeSortInput>;
  taxCodeId?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum ProductComponentType {
  INSURANCE = 'INSURANCE',
  NON_INSURANCE = 'NON_INSURANCE'
}

export type ProductComponentTypeOperationFilterInput = {
  eq?: InputMaybe<ProductComponentType>;
  in?: InputMaybe<Array<ProductComponentType>>;
  neq?: InputMaybe<ProductComponentType>;
  nin?: InputMaybe<Array<ProductComponentType>>;
};

/** A connection to a list of items. */
export type ProductComponentsConnection = {
  __typename?: 'ProductComponentsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductComponentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductComponent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductComponentsEdge = {
  __typename?: 'ProductComponentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductComponent;
};

export type ProductEligibilityInput = {
  boilerInWorkingCondition: Scalars['Boolean'];
  boilerInstallationDate?: InputMaybe<Scalars['DateTime']>;
  boilerManufacturerId?: InputMaybe<Scalars['Uuid']>;
  boilerModel?: InputMaybe<Scalars['String']>;
  boilerUnder45kw: Scalars['Boolean'];
  boilerUsesNaturalMainsGasBoiler: Scalars['Boolean'];
  brandName: Scalars['String'];
  customerType: CustomerType;
  onlySingleBoiler: Scalars['Boolean'];
  propertyIsNotGuestHouse: Scalars['Boolean'];
};

export type ProductFilterInput = {
  and?: InputMaybe<Array<ProductFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProductFilterInput>>;
  productCategory?: InputMaybe<ProductCategoryOperationFilterInput>;
};

export type ProductSortInput = {
  code?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  productCategory?: InputMaybe<SortEnumType>;
};

export enum ProductType {
  Insurance = 'Insurance',
  NonInsurance = 'NonInsurance'
}

export type ProductTypeOperationFilterInput = {
  eq?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  neq?: InputMaybe<ProductType>;
  nin?: InputMaybe<Array<ProductType>>;
};

export type ProductVersion = {
  __typename?: 'ProductVersion';
  availableFrom: Scalars['DateTime'];
  availableTo?: Maybe<Scalars['DateTime']>;
  brand: Brand;
  brandId: Scalars['Uuid'];
  claimOffPeriodDays?: Maybe<Scalars['Int']>;
  coolingOffDurationDays?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  eligibilityCriteria: EligibilityCriteria;
  eligibilityCriteriaId: Scalars['Uuid'];
  excessType: ExcessType;
  id: Scalars['Uuid'];
  ipiDocumentId?: Maybe<Scalars['Uuid']>;
  name: Scalars['String'];
  nextProductVersion?: Maybe<ProductVersion>;
  nextProductVersionId?: Maybe<Scalars['Uuid']>;
  previousProductVersions: Array<ProductVersion>;
  product: Product;
  productId: Scalars['Uuid'];
  productVersionProductComponents: Array<ProductVersionProductComponents>;
  state: ProductVersionState;
  termsAndConditionsRef: Scalars['String'];
  underwriterId?: Maybe<Scalars['Uuid']>;
};

export type ProductVersionFilterInput = {
  and?: InputMaybe<Array<ProductVersionFilterInput>>;
  availableFrom?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  availableTo?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  brand?: InputMaybe<BrandFilterInput>;
  brandId?: InputMaybe<ComparableGuidOperationFilterInput>;
  claimOffPeriodDays?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  coolingOffDurationDays?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  duration?: InputMaybe<ComparableInt32OperationFilterInput>;
  eligibilityCriteria?: InputMaybe<EligibilityCriteriaFilterInput>;
  eligibilityCriteriaId?: InputMaybe<ComparableGuidOperationFilterInput>;
  excessType?: InputMaybe<ExcessTypeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  ipiDocumentId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  nextProductVersion?: InputMaybe<ProductVersionFilterInput>;
  nextProductVersionId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductVersionFilterInput>>;
  previousProductVersions?: InputMaybe<ListFilterInputTypeOfProductVersionFilterInput>;
  product?: InputMaybe<ProductFilterInput>;
  productId?: InputMaybe<ComparableGuidOperationFilterInput>;
  productVersionProductComponents?: InputMaybe<ListFilterInputTypeOfProductVersionProductComponentsFilterInput>;
  state?: InputMaybe<ProductVersionStateOperationFilterInput>;
  termsAndConditionsRef?: InputMaybe<StringOperationFilterInput>;
  underwriterId?: InputMaybe<ComparableNullableOfGuidOperationFilterInput>;
};

export type ProductVersionProductComponents = {
  __typename?: 'ProductVersionProductComponents';
  commision: Scalars['Decimal'];
  id: Scalars['Uuid'];
  price: Scalars['Decimal'];
  productComponent: ProductComponent;
  productComponentId: Scalars['Uuid'];
  productVersion: ProductVersion;
  productVersionId: Scalars['Uuid'];
};

export type ProductVersionProductComponentsAmoutOffBreakdown = IProductVersionProductComponentsBreakdown & {
  __typename?: 'ProductVersionProductComponentsAmoutOffBreakdown';
  basePrice: Scalars['Decimal'];
  commission: Scalars['Decimal'];
  contractPrice: Scalars['Decimal'];
  id: Scalars['Uuid'];
  productComponent: SaleProductComponent;
  productComponentId: Scalars['Uuid'];
  promocodeId?: Maybe<Scalars['Uuid']>;
  standardPrice: Scalars['Decimal'];
};

export type ProductVersionProductComponentsFilterInput = {
  and?: InputMaybe<Array<ProductVersionProductComponentsFilterInput>>;
  commision?: InputMaybe<ComparableDecimalOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ProductVersionProductComponentsFilterInput>>;
  price?: InputMaybe<ComparableDecimalOperationFilterInput>;
  productComponent?: InputMaybe<ProductComponentFilterInput>;
  productComponentId?: InputMaybe<ComparableGuidOperationFilterInput>;
  productVersion?: InputMaybe<ProductVersionFilterInput>;
  productVersionId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type ProductVersionProductComponentsPercentageOffBreakdown = IProductVersionProductComponentsBreakdown & {
  __typename?: 'ProductVersionProductComponentsPercentageOffBreakdown';
  basePrice: Scalars['Decimal'];
  commission: Scalars['Decimal'];
  contractPrice: Scalars['Decimal'];
  id: Scalars['Uuid'];
  productComponent: SaleProductComponent;
  productComponentId: Scalars['Uuid'];
  promocodeId?: Maybe<Scalars['Uuid']>;
  standardPrice: Scalars['Decimal'];
};

export type ProductVersionProductComponentsWithoutOffer = IProductVersionProductComponentsBreakdown & {
  __typename?: 'ProductVersionProductComponentsWithoutOffer';
  basePrice: Scalars['Decimal'];
  commission: Scalars['Decimal'];
  contractPrice: Scalars['Decimal'];
  id: Scalars['Uuid'];
  productComponent: SaleProductComponent;
  productComponentId: Scalars['Uuid'];
  promocodeId?: Maybe<Scalars['Uuid']>;
  standardPrice: Scalars['Decimal'];
};

export type ProductVersionSortInput = {
  availableFrom?: InputMaybe<SortEnumType>;
  availableTo?: InputMaybe<SortEnumType>;
  brand?: InputMaybe<BrandSortInput>;
  brandId?: InputMaybe<SortEnumType>;
  claimOffPeriodDays?: InputMaybe<SortEnumType>;
  coolingOffDurationDays?: InputMaybe<SortEnumType>;
  duration?: InputMaybe<SortEnumType>;
  eligibilityCriteria?: InputMaybe<EligibilityCriteriaSortInput>;
  eligibilityCriteriaId?: InputMaybe<SortEnumType>;
  excessType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  ipiDocumentId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  nextProductVersion?: InputMaybe<ProductVersionSortInput>;
  nextProductVersionId?: InputMaybe<SortEnumType>;
  product?: InputMaybe<ProductSortInput>;
  productId?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  termsAndConditionsRef?: InputMaybe<SortEnumType>;
  underwriterId?: InputMaybe<SortEnumType>;
};

export enum ProductVersionState {
  GENERALLY_AVAILABLE = 'GENERALLY_AVAILABLE',
  RESTRICTED = 'RESTRICTED'
}

export type ProductVersionStateOperationFilterInput = {
  eq?: InputMaybe<ProductVersionState>;
  in?: InputMaybe<Array<ProductVersionState>>;
  neq?: InputMaybe<ProductVersionState>;
  nin?: InputMaybe<Array<ProductVersionState>>;
};

/** A connection to a list of items. */
export type ProductVersionsConnection = {
  __typename?: 'ProductVersionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductVersionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductVersionsEdge = {
  __typename?: 'ProductVersionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductVersion;
};

/** A connection to a list of items. */
export type ProductsConnection = {
  __typename?: 'ProductsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Product>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProductsEdge = {
  __typename?: 'ProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

export type Promo = {
  __typename?: 'Promo';
  offerId?: Maybe<Scalars['Uuid']>;
  promocodeId?: Maybe<Scalars['Uuid']>;
  promotionDuration?: Maybe<Scalars['Int']>;
  promotionName?: Maybe<Scalars['String']>;
};

export type Promocode = {
  __typename?: 'Promocode';
  code: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  offerIds: Array<Scalars['Uuid']>;
  startDate: Scalars['DateTime'];
};

export type PromocodeInput = {
  code: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  offerIds: Array<Scalars['Uuid']>;
  startDate: Scalars['DateTime'];
};

export type PromocodeValidity = {
  __typename?: 'PromocodeValidity';
  error?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  promocode?: Maybe<Promocode>;
};

export type PromocodeValidityInput = {
  brand?: InputMaybe<Scalars['String']>;
  promocode: Scalars['String'];
};

export type Promotion = {
  __typename?: 'Promotion';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Int']>;
  durationMonths?: Maybe<Scalars['Int']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
  promotionType: PromotionType;
  voucherAmount?: Maybe<Scalars['Float']>;
};

export type PromotionFilterInput = {
  and?: InputMaybe<Array<PromotionFilterInput>>;
  discountAmount?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  discountPercentage?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  durationMonths?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PromotionFilterInput>>;
  promotionType?: InputMaybe<PromotionTypeOperationFilterInput>;
  voucherAmount?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
};

export type PromotionInput = {
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  durationMonths?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  promotionType: PromotionType;
  voucherAmount?: InputMaybe<Scalars['Float']>;
};

export type PromotionSortInput = {
  discountAmount?: InputMaybe<SortEnumType>;
  discountPercentage?: InputMaybe<SortEnumType>;
  durationMonths?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  promotionType?: InputMaybe<SortEnumType>;
  voucherAmount?: InputMaybe<SortEnumType>;
};

export enum PromotionType {
  FIXED_DISCOUNT = 'FIXED_DISCOUNT',
  PERCENTAGE_DISCOUNT = 'PERCENTAGE_DISCOUNT',
  VOUCHER = 'VOUCHER'
}

export type PromotionTypeOperationFilterInput = {
  eq?: InputMaybe<PromotionType>;
  in?: InputMaybe<Array<PromotionType>>;
  neq?: InputMaybe<PromotionType>;
  nin?: InputMaybe<Array<PromotionType>>;
};

/** A connection to a list of items. */
export type PromotionsConnection = {
  __typename?: 'PromotionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PromotionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Promotion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PromotionsEdge = {
  __typename?: 'PromotionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Promotion;
};

export type PublicLiabilityInsurance = {
  __typename?: 'PublicLiabilityInsurance';
  expiryDate?: Maybe<Scalars['DateTime']>;
  hasCover?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<Scalars['String']>;
  policyLimit?: Maybe<Scalars['Decimal']>;
  policyRef?: Maybe<Scalars['String']>;
};

export type PublicLiabilityInsuranceFilterInput = {
  and?: InputMaybe<Array<PublicLiabilityInsuranceFilterInput>>;
  expiryDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  hasCover?: InputMaybe<BooleanOperationFilterInput>;
  insurer?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PublicLiabilityInsuranceFilterInput>>;
  policyLimit?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  policyRef?: InputMaybe<StringOperationFilterInput>;
};

export type PublicLiabilityInsuranceSortInput = {
  expiryDate?: InputMaybe<SortEnumType>;
  hasCover?: InputMaybe<SortEnumType>;
  insurer?: InputMaybe<SortEnumType>;
  policyLimit?: InputMaybe<SortEnumType>;
  policyRef?: InputMaybe<SortEnumType>;
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  actions?: Maybe<ActionsConnection>;
  actionsByDomain?: Maybe<ActionsConnection>;
  actionsByResponsibleEmail?: Maybe<ActionsConnection>;
  activeBankAccounts?: Maybe<Array<BankAccountModel>>;
  activeUnderwriter: UnderwriterModel;
  address: Address;
  addresses?: Maybe<AddressesConnection>;
  audit?: Maybe<AuditLogsConnection>;
  bacsReportHistory: Array<BacsReportHistory>;
  bankAccountDetails?: Maybe<BankAccountResponse>;
  bankDetails?: Maybe<CustomerBankDetails>;
  boilerEligibility: BoilerEligibility;
  callouts?: Maybe<Array<Maybe<Callout>>>;
  calloutsByLegacyLinkId?: Maybe<Array<Maybe<Callout>>>;
  cancellationFeePreviewByContractId?: Maybe<CancellationFeeBreakdown>;
  cardPaymentProcessingUrl: Scalars['String'];
  changePaymentDayPreview: Array<Payment>;
  communication: GetCommunicationResult;
  /** @deprecated Use SearchCommunications */
  communications: CommunicationsSearchResults;
  companies?: Maybe<CompanyCollectionSegment>;
  companiesByPostcode?: Maybe<CompanyByPostcodeDistanceCollectionSegment>;
  companiesBySearchTerm?: Maybe<CompanyCollectionSegment>;
  company?: Maybe<Company>;
  contract: Contract;
  contractBoilers?: Maybe<ContractBoilersConnection>;
  contractById: Contract;
  contractByLegacyLinkId: Contract;
  contractCancelNotifications?: Maybe<ContractCancelNotificationsConnection>;
  contracts?: Maybe<ContractsConnection>;
  contractsForRenewal?: Maybe<ContractsConnection>;
  csvUploadUrl: PresignedUploadUrl;
  customer: Customer;
  customerByFriendlyId: Customer;
  customerMarketingPermissions?: Maybe<MarketingPermissions>;
  customers?: Maybe<CustomersConnection>;
  ddDcPaymentSettlements: Array<PaymentSettlementResult>;
  declinedCalloutsByLegacyLinkId?: Maybe<Array<Maybe<DeclinedCallout>>>;
  directDebitInstructionStatus: InstructionStatus;
  document: GetDocumentResult;
  excessPaymentsQuery: Array<CalloutExcessLinkModel>;
  isNewCalloutRecall: IsNewCalloutRecallDto;
  latestAttachment: DocumentsSearchResult;
  latestRenewalInteraction: ContractRenewalInteraction;
  latestScheduledPayment: Payment;
  legacyActiveComplaintsByCustomerId: Array<Maybe<LegacyComplaint>>;
  legacyCustomer: LegacyCustomer;
  legalEntityByBrandId: LegalEntityModel;
  legalEntityByLegacyBrandId: LegalEntityModel;
  manufacturers?: Maybe<ManufacturersConnection>;
  mtaAudit?: Maybe<MtaInlifeAuditLogsConnection>;
  mtaQuotes: Array<MtaQuote>;
  nearestScheduledPayment: Payment;
  newEnquiries?: Maybe<Array<Maybe<NewEnquiry>>>;
  newEnquiryById: NewEnquiry;
  noAccessScheduledDate?: Maybe<Scalars['DateTime']>;
  offerDetails: OfferDetails;
  paymentAuthorisations?: Maybe<PaymentAuthorisationsConnection>;
  paymentById?: Maybe<Payment>;
  paymentSettlementsByFilter: Array<PaymentSettlementEntity>;
  payments: Array<Maybe<Payment>>;
  paymentsPreview: Array<Maybe<Payment>>;
  productVersionById: ProductVersion;
  promocodeValidity: PromocodeValidity;
  quotes: Array<Quote>;
  reconciliationJournalEntries?: Maybe<ReconciliationJournalEntriesConnection>;
  reconciliationJournalEntry: ReconciliationJournalEntry;
  rejectedPaymentSettlements: Array<PaymentSettlementResult>;
  renewalPriceReductionPreview: Array<Maybe<Payment>>;
  searchCommunications: CommunicationsSearchResults;
  searchDocuments: SearchDocumentPayload;
  serviceAudit: ServiceAuditPage;
  serviceDueDate?: Maybe<Scalars['DateTime']>;
  serviceDueDateForHomeowner?: Maybe<Scalars['DateTime']>;
  serviceMonth?: Maybe<Scalars['Int']>;
  serviceRecords?: Maybe<Array<Maybe<ServiceRecord>>>;
  serviceSchedules?: Maybe<Array<Maybe<ServiceScheduleStatistic>>>;
  templateCollections: Array<TemplateCollection>;
  underwriter: UnderwriterModel;
  underwriters?: Maybe<Array<UnderwriterModel>>;
  workingDayDateGivenNumberOfWorkingDays?: Maybe<WorkingDayDetails>;
};


export type Query_entitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type QueryactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ActionSortInput>>;
  where?: InputMaybe<ActionFilterInput>;
};


export type QueryactionsByDomainArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  domainId: Scalars['Uuid'];
  domainType: DomainType;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ActionSortInput>>;
  where?: InputMaybe<ActionFilterInput>;
};


export type QueryactionsByResponsibleEmailArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ActionSortInput>>;
  responsibleEmail: Scalars['String'];
  where?: InputMaybe<ActionFilterInput>;
};


export type QueryaddressArgs = {
  addressId: Scalars['Uuid'];
};


export type QueryaddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AddressSortInput>>;
  where?: InputMaybe<AddressFilterInput>;
};


export type QueryauditArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AuditLogEntitySortInput>>;
  where?: InputMaybe<AuditLogEntityFilterInput>;
};


export type QuerybacsReportHistoryArgs = {
  contractId: Scalars['Uuid'];
};


export type QuerybankAccountDetailsArgs = {
  bankDetailsInput?: InputMaybe<BankDetailsInput>;
};


export type QuerybankDetailsArgs = {
  contractId: Scalars['Uuid'];
};


export type QueryboilerEligibilityArgs = {
  input: BoilerEligibilityInput;
};


export type QuerycalloutsArgs = {
  order?: InputMaybe<Array<CalloutSortInput>>;
  where?: InputMaybe<CalloutFilterInput>;
};


export type QuerycalloutsByLegacyLinkIdArgs = {
  legacyLinkId: Scalars['Int'];
};


export type QuerycancellationFeePreviewByContractIdArgs = {
  contractId: Scalars['Uuid'];
};


export type QuerycardPaymentProcessingUrlArgs = {
  input: CardProcessingInput;
};


export type QuerychangePaymentDayPreviewArgs = {
  input: GetChangePaymentDayPreviewQueryInput;
};


export type QuerycommunicationArgs = {
  id: Scalars['Uuid'];
};


export type QuerycommunicationsArgs = {
  where: CommunicationsQueryInput;
};


export type QuerycompaniesArgs = {
  order?: InputMaybe<Array<CompanySortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyFilterInput>;
};


export type QuerycompaniesByPostcodeArgs = {
  order?: InputMaybe<Array<CompanyByPostcodeDistanceSortInput>>;
  postcode?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyByPostcodeDistanceFilterInput>;
};


export type QuerycompaniesBySearchTermArgs = {
  order?: InputMaybe<Array<CompanySortInput>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CompanyFilterInput>;
};


export type QuerycompanyArgs = {
  companyId: Scalars['Int'];
};


export type QuerycontractArgs = {
  id: Scalars['String'];
};


export type QuerycontractBoilersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ContractBoilerSortInput>>;
  where?: InputMaybe<ContractBoilerFilterInput>;
};


export type QuerycontractByIdArgs = {
  contractId: Scalars['Uuid'];
};


export type QuerycontractByLegacyLinkIdArgs = {
  legacyLinkId: Scalars['Int'];
};


export type QuerycontractCancelNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ContractCancelNotificationSortInput>>;
  where?: InputMaybe<ContractCancelNotificationFilterInput>;
};


export type QuerycontractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ContractSortInput>>;
  where?: InputMaybe<ContractFilterInput>;
};


export type QuerycontractsForRenewalArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QuerycsvUploadUrlArgs = {
  serviceUserNumber: Scalars['String'];
};


export type QuerycustomerArgs = {
  customerId: Scalars['Uuid'];
};


export type QuerycustomerByFriendlyIdArgs = {
  friendlyCustomerId: Scalars['String'];
};


export type QuerycustomerMarketingPermissionsArgs = {
  customerId: Scalars['Uuid'];
};


export type QuerycustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<CustomerSortInput>>;
  where?: InputMaybe<CustomerFilterInput>;
};


export type QueryddDcPaymentSettlementsArgs = {
  reconciliationSummaryId: Scalars['Uuid'];
};


export type QuerydeclinedCalloutsByLegacyLinkIdArgs = {
  legacyLinkId: Scalars['Int'];
};


export type QuerydirectDebitInstructionStatusArgs = {
  contractId: Scalars['Uuid'];
};


export type QuerydocumentArgs = {
  id: Scalars['UUID'];
};


export type QueryexcessPaymentsQueryArgs = {
  contractId: Scalars['String'];
};


export type QueryisNewCalloutRecallArgs = {
  input: IsNewCalloutRecallInput;
};


export type QuerylatestAttachmentArgs = {
  input: LatestAttachmentQueryInput;
};


export type QuerylatestRenewalInteractionArgs = {
  contractId: Scalars['Uuid'];
};


export type QuerylatestScheduledPaymentArgs = {
  input: GetLatestPaymentQueryInput;
};


export type QuerylegacyActiveComplaintsByCustomerIdArgs = {
  customerId?: InputMaybe<Scalars['Int']>;
};


export type QuerylegacyCustomerArgs = {
  customerId: Scalars['String'];
};


export type QuerylegalEntityByBrandIdArgs = {
  brandId: Scalars['Uuid'];
};


export type QuerylegalEntityByLegacyBrandIdArgs = {
  legacyId: Scalars['Int'];
};


export type QuerymanufacturersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<AggregatedManufacturerFilterInput>>;
  where?: InputMaybe<AggregatedManufacturerFilterInput>;
};


export type QuerymtaAuditArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<MtaInlifeAuditLogEntitySortInput>>;
  where?: InputMaybe<MtaInlifeAuditLogEntityFilterInput>;
};


export type QuerymtaQuotesArgs = {
  input: GetMtaQuotesInput;
};


export type QuerynearestScheduledPaymentArgs = {
  input: GetNearestPaymentQueryInput;
};


export type QuerynewEnquiryByIdArgs = {
  newEnquiryId: Scalars['Uuid'];
};


export type QuerynoAccessScheduledDateArgs = {
  input: GetNoAccessScheduledDateInput;
};


export type QueryofferDetailsArgs = {
  offerId: Scalars['Uuid'];
};


export type QuerypaymentAuthorisationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentAuthorisationViewModelFilterInput>;
};


export type QuerypaymentByIdArgs = {
  paymentId: Scalars['Uuid'];
};


export type QuerypaymentSettlementsByFilterArgs = {
  order?: InputMaybe<Array<PaymentSettlementEntitySortInput>>;
  where?: InputMaybe<PaymentSettlementEntityFilterInput>;
};


export type QuerypaymentsArgs = {
  contractId: Scalars['Uuid'];
};


export type QuerypaymentsPreviewArgs = {
  input: PaymentScheduleInput;
};


export type QueryproductVersionByIdArgs = {
  id: Scalars['Uuid'];
};


export type QuerypromocodeValidityArgs = {
  brand?: InputMaybe<Scalars['String']>;
  promocode?: InputMaybe<Scalars['String']>;
};


export type QueryquotesArgs = {
  input: GetQuotesInput;
};


export type QueryreconciliationJournalEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryreconciliationJournalEntryArgs = {
  id: Scalars['Uuid'];
};


export type QueryrejectedPaymentSettlementsArgs = {
  reconciliationSummaryId: Scalars['Uuid'];
};


export type QueryrenewalPriceReductionPreviewArgs = {
  input: PaymentScheduleInput;
};


export type QuerysearchCommunicationsArgs = {
  input: SearchCommunicationQueryInput;
};


export type QuerysearchDocumentsArgs = {
  input: SearchDocumentQueryInput;
};


export type QueryserviceAuditArgs = {
  input: GetAuditInput;
};


export type QueryserviceDueDateArgs = {
  contractId: Scalars['Uuid'];
};


export type QueryserviceDueDateForHomeownerArgs = {
  contractId: Scalars['Uuid'];
};


export type QueryserviceMonthArgs = {
  legacyLinkId: Scalars['Int'];
};


export type QueryserviceRecordsArgs = {
  activeOnly?: Scalars['Boolean'];
  month: Month;
  year: Scalars['Int'];
};


export type QueryunderwriterArgs = {
  id: Scalars['Uuid'];
};


export type QueryworkingDayDateGivenNumberOfWorkingDaysArgs = {
  queryInput: GetWorkingDayDateGivenNumberOfWorkingDaysQueryInput;
};

export type Quote = {
  __typename?: 'Quote';
  brand: SaleBrand;
  brandId: Scalars['Uuid'];
  claimOffPeriodDays?: Maybe<Scalars['Int']>;
  coolingOffDurationDays?: Maybe<Scalars['Int']>;
  duration: Scalars['Int'];
  excessType: ExcessType;
  product: SaleProduct;
  productId: Scalars['Uuid'];
  productVersionId: Scalars['Uuid'];
  productVersionName: Scalars['String'];
  productVersionProductComponents: Array<IProductVersionProductComponentsBreakdown>;
  promo?: Maybe<Promo>;
  salePrice: SalePrice;
  termsAndConditionsRef: Scalars['String'];
};

export type RaiseCalloutInput = {
  autoAllocate: Scalars['Boolean'];
  brandId: Scalars['Int'];
  calloutFlags?: InputMaybe<CalloutFlagsInput>;
  calloutType: CalloutType;
  contractStartDate: Scalars['DateTime'];
  decisionId?: InputMaybe<Scalars['Uuid']>;
  details?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  excess?: InputMaybe<Scalars['Int']>;
  landlord?: InputMaybe<Scalars['Boolean']>;
  legacyBilling?: InputMaybe<Scalars['Boolean']>;
  legacyLinkId: Scalars['Int'];
  origin?: InputMaybe<Origin>;
  previousLegacyLinkId?: InputMaybe<Scalars['Int']>;
  problemType: ProblemType;
  scheduledDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<CalloutStatus>;
};

export type RaiseCalloutPayload = {
  __typename?: 'RaiseCalloutPayload';
  calloutId: Scalars['Int'];
};

export type Rates = {
  __typename?: 'Rates';
  elecCharge?: Maybe<Scalars['Decimal']>;
  elecChargeOoh?: Maybe<Scalars['Decimal']>;
  elecRate?: Maybe<Scalars['Decimal']>;
  elecRateOoh?: Maybe<Scalars['Decimal']>;
  fixedRepairCharge?: Maybe<Scalars['Decimal']>;
  hourlyRate?: Maybe<Scalars['Decimal']>;
  hourlyRateOoh?: Maybe<Scalars['Decimal']>;
  plumbCharge?: Maybe<Scalars['Decimal']>;
  plumbChargeOoh?: Maybe<Scalars['Decimal']>;
  plumbRate?: Maybe<Scalars['Decimal']>;
  plumbRateOoh?: Maybe<Scalars['Decimal']>;
  repairChargeOoh?: Maybe<Scalars['Decimal']>;
  serviceCharge?: Maybe<Scalars['Decimal']>;
  serviceRate?: Maybe<Scalars['Decimal']>;
  sparesMarkup?: Maybe<Scalars['Float']>;
};

export type RatesFilterInput = {
  and?: InputMaybe<Array<RatesFilterInput>>;
  elecCharge?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  elecChargeOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  elecRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  elecRateOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  fixedRepairCharge?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  hourlyRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  hourlyRateOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  or?: InputMaybe<Array<RatesFilterInput>>;
  plumbCharge?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  plumbChargeOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  plumbRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  plumbRateOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  repairChargeOoh?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  serviceCharge?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  serviceRate?: InputMaybe<ComparableNullableOfDecimalOperationFilterInput>;
  sparesMarkup?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
};

export type RatesSortInput = {
  elecCharge?: InputMaybe<SortEnumType>;
  elecChargeOoh?: InputMaybe<SortEnumType>;
  elecRate?: InputMaybe<SortEnumType>;
  elecRateOoh?: InputMaybe<SortEnumType>;
  fixedRepairCharge?: InputMaybe<SortEnumType>;
  hourlyRate?: InputMaybe<SortEnumType>;
  hourlyRateOoh?: InputMaybe<SortEnumType>;
  plumbCharge?: InputMaybe<SortEnumType>;
  plumbChargeOoh?: InputMaybe<SortEnumType>;
  plumbRate?: InputMaybe<SortEnumType>;
  plumbRateOoh?: InputMaybe<SortEnumType>;
  repairChargeOoh?: InputMaybe<SortEnumType>;
  serviceCharge?: InputMaybe<SortEnumType>;
  serviceRate?: InputMaybe<SortEnumType>;
  sparesMarkup?: InputMaybe<SortEnumType>;
};

export enum Rating {
  Good = 'Good',
  Ok = 'Ok',
  Poor = 'Poor',
  Select = 'Select',
  VeryGood = 'VeryGood',
  VeryPoor = 'VeryPoor'
}

/** A connection to a list of items. */
export type ReconciliationJournalEntriesConnection = {
  __typename?: 'ReconciliationJournalEntriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReconciliationJournalEntriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ReconciliationJournalEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ReconciliationJournalEntriesEdge = {
  __typename?: 'ReconciliationJournalEntriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ReconciliationJournalEntry;
};

export type ReconciliationJournalEntry = {
  __typename?: 'ReconciliationJournalEntry';
  cardPaymentDate: Scalars['DateTime'];
  collectionsAmount: Scalars['Decimal'];
  collectionsSuspense: Scalars['Decimal'];
  differenceToReconcile: Scalars['Decimal'];
  directDebitDate: Scalars['DateTime'];
  entryDate: Scalars['DateTime'];
  friendlyId: Scalars['Int'];
  id?: Maybe<Scalars['Uuid']>;
  otherAmount: Scalars['Decimal'];
  otherToReconcile: Scalars['Decimal'];
  refundsAmount: Scalars['Decimal'];
  refundsSuspense: Scalars['Decimal'];
  rejectionsAmount: Scalars['Decimal'];
  rejectionsSuspense: Scalars['Decimal'];
  settlementsSummary: ReconciliationSettlementsSummary;
  sun: Scalars['Long'];
  totalAmount: Scalars['Decimal'];
  uploadDate: Scalars['DateTime'];
};

export type ReconciliationResult = {
  __typename?: 'ReconciliationResult';
  alreadyExist: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  reconciliationSaved: Scalars['Boolean'];
};

export type ReconciliationSettlementsSummary = {
  __typename?: 'ReconciliationSettlementsSummary';
  cardCollectionsCleared: Scalars['Decimal'];
  cardRefundsCleared: Scalars['Decimal'];
  directCreditsCleared: Scalars['Decimal'];
  directDebitsCleared: Scalars['Decimal'];
  otherCleared: Scalars['Decimal'];
  reconciledCardCollections: Scalars['Decimal'];
  reconciledCardRefunds: Scalars['Decimal'];
  reconciledDirectCredits: Scalars['Decimal'];
  reconciledDirectDebits: Scalars['Decimal'];
  reconciledOther: Scalars['Decimal'];
  reconciledRejected: Scalars['Decimal'];
  totalCleared: Scalars['Decimal'];
  totalReconciled: Scalars['Decimal'];
  totalRejected: Scalars['Decimal'];
};

export type RecreateDocumentInput = {
  documentId: Scalars['UUID'];
};

export type RefundAuthorisationInputModelInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  complaintId?: InputMaybe<Scalars['Int']>;
  customerFriendlyId?: InputMaybe<Scalars['String']>;
  parentPaymentId: Scalars['Uuid'];
  paymentProposal?: InputMaybe<PaymentProposalInputModelInput>;
  refundReason?: InputMaybe<Scalars['String']>;
};

export type RefundPaymentAuthorisation = {
  __typename?: 'RefundPaymentAuthorisation';
  additionalInformation?: Maybe<Scalars['String']>;
  complaintId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['DateTime'];
  customerFriendlyId?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  parentPaymentId: Scalars['Uuid'];
  paymentProposal?: Maybe<PaymentProposal>;
  refundReason?: Maybe<Scalars['String']>;
  status: PaymentAuthorisationStatus;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export enum RenewalDeclineReason {
  CanNoLongerAfford = 'CanNoLongerAfford',
  ContractTooExpensive = 'ContractTooExpensive',
  DissatisfiedWithAbs = 'DissatisfiedWithAbs',
  DissatisfiedWithCustomerExperience = 'DissatisfiedWithCustomerExperience',
  HasMadeComplaint = 'HasMadeComplaint',
  HasNewBoiler = 'HasNewBoiler',
  LookingToUpgradeDowngradeLevelOfCover = 'LookingToUpgradeDowngradeLevelOfCover',
  MovingToAnotherProvider = 'MovingToAnotherProvider',
  UnhappyWithPriceIncrease = 'UnhappyWithPriceIncrease',
  UnhappyWithTermsAndConditions = 'UnhappyWithTermsAndConditions',
  UnhappyWithUpgradeDowngradeOptions = 'UnhappyWithUpgradeDowngradeOptions'
}

export type RenewalOffersInput = {
  offerIds: Array<Scalars['Uuid']>;
};

export type RenewalQuote = {
  __typename?: 'RenewalQuote';
  additionalPremiumInsuranceAmount: Scalars['Decimal'];
  contractInsuranceAmount: Scalars['Decimal'];
  contractNonInsuranceAmount: Scalars['Decimal'];
  contractYear: Scalars['Int'];
  offerId?: Maybe<Scalars['Uuid']>;
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promoDuration?: Maybe<Scalars['Int']>;
  promotionInsuranceAmount: Scalars['Decimal'];
  promotionNonInsuranceAmount: Scalars['Decimal'];
};

export type RenewalQuoteInput = {
  additionalPremiumInsuranceAmount: Scalars['Decimal'];
  boilerInstallationDate?: InputMaybe<Scalars['DateTime']>;
  boilerManufactureId?: InputMaybe<Scalars['Uuid']>;
  boilerModel?: InputMaybe<Scalars['String']>;
  brandName: Scalars['String'];
  claimsCost12Months: Scalars['Decimal'];
  contractId: Scalars['Uuid'];
  contractInsuranceAmount: Scalars['Decimal'];
  contractNonInsuranceAmount: Scalars['Decimal'];
  contractYear: Scalars['Int'];
  offerId?: InputMaybe<Scalars['Uuid']>;
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  promotionInsuranceAmount: Scalars['Decimal'];
  promotionNonInsuranceAmount: Scalars['Decimal'];
};

export enum RenewalStatus {
  Accept = 'Accept',
  Decline = 'Decline'
}

export type RenewalStatusOperationFilterInput = {
  eq?: InputMaybe<RenewalStatus>;
  in?: InputMaybe<Array<RenewalStatus>>;
  neq?: InputMaybe<RenewalStatus>;
  nin?: InputMaybe<Array<RenewalStatus>>;
};

export type RepairNotCovered = {
  __typename?: 'RepairNotCovered';
  by?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  notCovered?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export type RepairReimbursementAuthorisationInputModelInput = {
  additionalInformation?: InputMaybe<Scalars['String']>;
  calloutId: Scalars['Int'];
  complaintId?: InputMaybe<Scalars['Int']>;
  customerFriendlyId?: InputMaybe<Scalars['String']>;
  paymentProposal?: InputMaybe<PaymentProposalInputModelInput>;
  repairReimbursementAmount: Scalars['Decimal'];
};

export type RepairReimbursementPaymentAuthorisation = {
  __typename?: 'RepairReimbursementPaymentAuthorisation';
  additionalInformation?: Maybe<Scalars['String']>;
  calloutId: Scalars['Int'];
  complaintId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  createdDateTime: Scalars['DateTime'];
  customerFriendlyId?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  paymentProposal?: Maybe<PaymentProposal>;
  repairReimbursementAmount: Scalars['Decimal'];
  status: PaymentAuthorisationStatus;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export enum ReportType {
  AddacsAmendments = 'AddacsAmendments',
  AddacsCancellations = 'AddacsCancellations',
  Arucs = 'Arucs',
  Arudd = 'Arudd',
  Auddis = 'Auddis',
  Ddic = 'Ddic'
}

export type RequestDocumentCommandInput = {
  templateCollectionName?: InputMaybe<Scalars['String']>;
  templateInputJson: Scalars['String'];
  templateKey?: InputMaybe<TemplateKeyInput>;
  templateName?: InputMaybe<Scalars['String']>;
};

export type ResendCommunicationInput = {
  commId: Scalars['Uuid'];
  deliverTo: Scalars['String'];
  type: CommunicationRequestType;
};

export type ResolveCalloutInput = {
  calloutId: Scalars['Int'];
  invoiceFlags?: InputMaybe<InvoiceFlagsInput>;
  jobsheetOriginator: Scalars['String'];
  netAmt: Scalars['Decimal'];
  outOfHours?: InputMaybe<OutOfHoursInput>;
  partsUsed?: InputMaybe<Array<InputMaybe<PartUsedInput>>>;
  paymentAmt: Scalars['Decimal'];
  paymentStatus: PaymentStatusType;
  taxAmt: Scalars['Decimal'];
  workDone?: InputMaybe<Array<InputMaybe<WorkDoneInput>>>;
};

export type ResolveCalloutPayload = {
  __typename?: 'ResolveCalloutPayload';
  calloutId: Scalars['Int'];
};

export type Responsibility = {
  __typename?: 'Responsibility';
  action: Action;
  createdAt: Scalars['DateTime'];
  isTeam: Scalars['Boolean'];
  modifiedAt: Scalars['DateTime'];
  read: Scalars['Boolean'];
  responsibilityId: Scalars['Uuid'];
  responsible: Scalars['String'];
  team?: Maybe<Team>;
};

export type ResponsibilityFilterInput = {
  action?: InputMaybe<ActionFilterInput>;
  and?: InputMaybe<Array<ResponsibilityFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  isTeam?: InputMaybe<BooleanOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ResponsibilityFilterInput>>;
  read?: InputMaybe<BooleanOperationFilterInput>;
  responsibilityId?: InputMaybe<ComparableGuidOperationFilterInput>;
  responsible?: InputMaybe<StringOperationFilterInput>;
  team?: InputMaybe<TeamFilterInput>;
};

export type RetryBulkDirectDebitCollectionResponse = {
  __typename?: 'RetryBulkDirectDebitCollectionResponse';
  data: RetryBulkDirectDebitCollectionResponseData;
};

export type RetryBulkDirectDebitCollectionResponseData = {
  __typename?: 'RetryBulkDirectDebitCollectionResponseData';
  insuranceClientCollectionId?: Maybe<Scalars['String']>;
  nonInsuranceClientCollectionId?: Maybe<Scalars['String']>;
};

export type RetryDirectDebitCollectionCommandInput = {
  paymentSettlements: Array<PaymentSettlementInput>;
};

export type SaleBrand = {
  __typename?: 'SaleBrand';
  id: Scalars['Uuid'];
  name: Scalars['String'];
};

export type SaleOffer = {
  __typename?: 'SaleOffer';
  campaign: Campaign;
  offer: Offer;
  offerBreakdowns: Array<OfferBreakdown>;
  promocodeId?: Maybe<Scalars['Uuid']>;
  promotion: Promotion;
};

export type SaleOffersInput = {
  brand?: InputMaybe<Scalars['String']>;
  offerSaleDate?: InputMaybe<Scalars['DateTime']>;
  productVersionIds: Array<Scalars['Uuid']>;
  promocode?: InputMaybe<Scalars['String']>;
};

export type SalePrice = {
  __typename?: 'SalePrice';
  additionalContractPremiumAdded: Scalars['Boolean'];
  contractFirstMonthPrice: Scalars['Decimal'];
  contractPrice: Scalars['Decimal'];
  offerId?: Maybe<Scalars['Uuid']>;
  standardMonthlyPrice: Scalars['Decimal'];
  standardPrice: Scalars['Decimal'];
};

export type SaleProduct = {
  __typename?: 'SaleProduct';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
  productCategory: ProductCategory;
};

export type SaleProductComponent = {
  __typename?: 'SaleProductComponent';
  id: Scalars['Uuid'];
  name: Scalars['String'];
  productComponentProductComponentItems: Array<SaleProductComponentProductComponentItems>;
  taxCode: SaleTaxCode;
  taxCodeId: Scalars['Uuid'];
  type: ProductComponentType;
};

export type SaleProductComponentItem = {
  __typename?: 'SaleProductComponentItem';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
  sectionNumber?: Maybe<Scalars['Int']>;
  sumInsuredTextOverride?: Maybe<Scalars['String']>;
};

export type SaleProductComponentProductComponentItems = {
  __typename?: 'SaleProductComponentProductComponentItems';
  covered?: Maybe<Scalars['Boolean']>;
  excessAmount?: Maybe<Scalars['Decimal']>;
  id: Scalars['Uuid'];
  productComponentItem: SaleProductComponentItem;
  productComponentItemId: Scalars['Uuid'];
  /** @deprecated Use SumInsuredGroup.SumInsuredAmount instead */
  repairLimit?: Maybe<Scalars['Decimal']>;
  sumInsuredGroup?: Maybe<SaleSumInsuredGroup>;
  sumInsuredGroupId?: Maybe<Scalars['Uuid']>;
};

export type SaleSumInsuredGroup = {
  __typename?: 'SaleSumInsuredGroup';
  id: Scalars['Uuid'];
  sumInsuredAmount: Scalars['Decimal'];
};

export type SaleTaxCode = {
  __typename?: 'SaleTaxCode';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
};

export enum ScheduleStatus {
  Active = 'Active',
  AdHoc = 'AdHoc',
  Cancelled = 'Cancelled',
  Uncollectable = 'Uncollectable'
}

export type ScheduledNoAccess = {
  __typename?: 'ScheduledNoAccess';
  date: Scalars['DateTime'];
  id: Scalars['Uuid'];
  processed: Scalars['Boolean'];
  serviceMonth: Month;
  serviceYear: Scalars['Int'];
  type: NoAccessType;
};

export type SearchCommunicationQueryInput = {
  tags?: InputMaybe<Array<SearchTagInput>>;
};

export type SearchDocumentPayload = {
  __typename?: 'SearchDocumentPayload';
  documents?: Maybe<Array<DocumentWithPreSignedURL>>;
  error?: Maybe<GetDocumentError>;
};

export type SearchDocumentQueryInput = {
  documentCollection?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<SearchTagInput>>;
};

export type SearchTagInput = {
  key: Scalars['String'];
  searchTagType: SearchTagType;
  value: Scalars['String'];
};

export enum SearchTagType {
  CONTAINS = 'CONTAINS',
  EXACT_MATCH = 'EXACT_MATCH'
}

export type SelectionCriteria = {
  __typename?: 'SelectionCriteria';
  distance: Scalars['Int'];
  includeFFASHigh: Scalars['Boolean'];
  maxNumberOfServicesPerEngineer: Scalars['Int'];
  minRating: Scalars['Int'];
  potentialCompanies?: Maybe<Array<Maybe<ServiceCompany>>>;
};

export enum SelfBillingStatus {
  Expired = 'Expired',
  Live = 'Live',
  None = 'None',
  Prepared = 'Prepared',
  Submitted = 'Submitted'
}

export type SendCommunicationInput = {
  deliverTo: Scalars['String'];
  template: Scalars['String'];
  templateCollection: Scalars['String'];
  templateInputJson: Scalars['String'];
  templateKey?: InputMaybe<TemplateKeyInput>;
  type: CommunicationRequestType;
};

export type SendPayload = {
  __typename?: 'SendPayload';
  commId?: Maybe<Scalars['Uuid']>;
  error?: Maybe<CreateCommunicationError>;
};

export type SendServiceNotificationsInput = {
  serviceMonth: Month;
};

export type ServiceAudit = {
  __typename?: 'ServiceAudit';
  createdDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  emailSent: Scalars['Int'];
  lettersSent: Scalars['Int'];
  serviceMonth: Month;
  userName?: Maybe<Scalars['String']>;
};

export type ServiceAuditPage = {
  __typename?: 'ServiceAuditPage';
  serviceAudits?: Maybe<Array<Maybe<ServiceAudit>>>;
  totalPages: Scalars['Int'];
};

export type ServiceCompany = {
  __typename?: 'ServiceCompany';
  companyId: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  distance: Scalars['Float'];
  fFASHigh?: Maybe<Scalars['Int']>;
  rating: Scalars['Int'];
  serviceCharge: Scalars['Int'];
};

export enum ServiceCompanyConfirmed {
  No = 'No',
  None = 'None',
  Yes = 'Yes'
}

export type ServiceDetailsDto = {
  __typename?: 'ServiceDetailsDto';
  calloutId: Scalars['Int'];
  companyId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
};

export type ServiceRecord = {
  __typename?: 'ServiceRecord';
  active: Scalars['Boolean'];
  brand: Scalars['Int'];
  contractId: Scalars['Uuid'];
  contractStartDate: Scalars['DateTime'];
  customerId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  lastUpdatedAt: Scalars['DateTime'];
  latitude: Scalars['Float'];
  legacyLinkId: Scalars['Int'];
  longitude: Scalars['Float'];
  processedDate?: Maybe<Scalars['DateTime']>;
  processedManually?: Maybe<Scalars['Boolean']>;
  serviceMonth: Scalars['Int'];
  serviceYear: Scalars['Int'];
};

export type ServiceSchedule = {
  __typename?: 'ServiceSchedule';
  admin?: Maybe<Scalars['Int']>;
  agent1?: Maybe<Scalars['Int']>;
  agent2?: Maybe<Scalars['Int']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  serviceMonth: Month;
  status?: Maybe<ServiceScheduleStatus>;
};

export type ServiceScheduleStatistic = {
  __typename?: 'ServiceScheduleStatistic';
  admin?: Maybe<Scalars['Int']>;
  agent1?: Maybe<Scalars['Int']>;
  agent2?: Maybe<Scalars['Int']>;
  allocated?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Int']>;
  due?: Maybe<Scalars['Int']>;
  noAccess?: Maybe<Scalars['Int']>;
  pendingNoAccess?: Maybe<Scalars['Int']>;
  scheduled?: Maybe<Scalars['Int']>;
  scheduledDate?: Maybe<Scalars['DateTime']>;
  scheduledPast?: Maybe<Scalars['Int']>;
  serviceMonth: Month;
  status?: Maybe<ServiceScheduleStatus>;
  unscheduled?: Maybe<Scalars['Int']>;
};

export enum ServiceScheduleStatus {
  NotProcessed = 'NotProcessed',
  Processed = 'Processed'
}

export type Servicing = {
  __typename?: 'Servicing';
  electrical?: Maybe<Scalars['Boolean']>;
  plumbing?: Maybe<Scalars['Boolean']>;
  powerflush?: Maybe<Scalars['Boolean']>;
  serviceAndRepair?: Maybe<Scalars['Boolean']>;
  serviceOnly?: Maybe<Scalars['Boolean']>;
  tapsAndToilets?: Maybe<Scalars['Boolean']>;
};

export type ServicingFilterInput = {
  and?: InputMaybe<Array<ServicingFilterInput>>;
  electrical?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ServicingFilterInput>>;
  plumbing?: InputMaybe<BooleanOperationFilterInput>;
  powerflush?: InputMaybe<BooleanOperationFilterInput>;
  serviceAndRepair?: InputMaybe<BooleanOperationFilterInput>;
  serviceOnly?: InputMaybe<BooleanOperationFilterInput>;
  tapsAndToilets?: InputMaybe<BooleanOperationFilterInput>;
};

export type ServicingSortInput = {
  electrical?: InputMaybe<SortEnumType>;
  plumbing?: InputMaybe<SortEnumType>;
  powerflush?: InputMaybe<SortEnumType>;
  serviceAndRepair?: InputMaybe<SortEnumType>;
  serviceOnly?: InputMaybe<SortEnumType>;
  tapsAndToilets?: InputMaybe<SortEnumType>;
};

export type SetCalloutToNoAccessInput = {
  calloutId: Scalars['Int'];
};

export type SetCalloutToNoAccessPayload = {
  __typename?: 'SetCalloutToNoAccessPayload';
  calloutIdSetToNoAccess: Scalars['Int'];
};

export type SetNoAccessDateInput = {
  date: Scalars['DateTime'];
  serviceMonth: Month;
  type: NoAccessType;
};

export type SetReconciliationStatusCommandInput = {
  reconciliationStatus: Scalars['Boolean'];
  settlementIds: Array<InputMaybe<Scalars['Uuid']>>;
};

export type SetReconciliationStatusPayload = {
  __typename?: 'SetReconciliationStatusPayload';
  settlements: Array<PaymentSettlement>;
};

export enum SettlementType {
  AutoRetryScheduled = 'AutoRetryScheduled',
  CancelledDuePayment = 'CancelledDuePayment',
  CancelledNotDebt = 'CancelledNotDebt',
  Collected = 'Collected',
  IndemnityClaim = 'IndemnityClaim',
  ManualRetryScheduled = 'ManualRetryScheduled',
  NotSubmitted = 'NotSubmitted',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
  Submitted = 'Submitted'
}

export type SettlementTypeOperationFilterInput = {
  eq?: InputMaybe<SettlementType>;
  in?: InputMaybe<Array<SettlementType>>;
  neq?: InputMaybe<SettlementType>;
  nin?: InputMaybe<Array<SettlementType>>;
};

export enum Severity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export enum SignUpType {
  TeleCan = 'TeleCan',
  Website = 'Website'
}

export enum SludgeWarning {
  PowerFlushDone = 'PowerFlushDone',
  WarningSent = 'WarningSent'
}

export enum SortEnumType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SplitAmounts = {
  __typename?: 'SplitAmounts';
  insurance: Scalars['Decimal'];
  nonInsurance: Scalars['Decimal'];
};

export type Status = {
  __typename?: 'Status';
  contractStatus?: Maybe<CompanyContractStatus>;
  rating?: Maybe<Rating>;
  scStatus?: Maybe<CompanyStatus>;
};

export type StatusFilterInput = {
  and?: InputMaybe<Array<StatusFilterInput>>;
  contractStatus?: InputMaybe<NullableOfContractStatusOperationFilterInput>;
  or?: InputMaybe<Array<StatusFilterInput>>;
  rating?: InputMaybe<NullableOfRatingOperationFilterInput>;
  scStatus?: InputMaybe<NullableOfCompanyStatusOperationFilterInput>;
};

export type StatusOperationFilterInput = {
  eq?: InputMaybe<ActionsStatus>;
  in?: InputMaybe<Array<ActionsStatus>>;
  neq?: InputMaybe<ActionsStatus>;
  nin?: InputMaybe<Array<ActionsStatus>>;
};

export type StatusSortInput = {
  contractStatus?: InputMaybe<SortEnumType>;
  rating?: InputMaybe<SortEnumType>;
  scStatus?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SumInsuredGroup = {
  __typename?: 'SumInsuredGroup';
  id: Scalars['Uuid'];
  productComponentProductComponentItems: Array<ProductComponentProductComponentItems>;
  sumInsuredAmount: Scalars['Decimal'];
};

export type SumInsuredGroupFilterInput = {
  and?: InputMaybe<Array<SumInsuredGroupFilterInput>>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<SumInsuredGroupFilterInput>>;
  productComponentProductComponentItems?: InputMaybe<ListFilterInputTypeOfProductComponentProductComponentItemsFilterInput>;
  sumInsuredAmount?: InputMaybe<ComparableDecimalOperationFilterInput>;
};

export type Tag = {
  __typename?: 'Tag';
  action: Action;
  createdAt: Scalars['DateTime'];
  domainId: Scalars['Uuid'];
  domainType: ActionsDomainType;
  id: Scalars['Uuid'];
  modifiedAt: Scalars['DateTime'];
  preferredContactTimeEnd?: Maybe<Scalars['DateTime']>;
  preferredContactTimeStart?: Maybe<Scalars['DateTime']>;
};

export type TagFilterInput = {
  action?: InputMaybe<ActionFilterInput>;
  and?: InputMaybe<Array<TagFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  domainId?: InputMaybe<ComparableGuidOperationFilterInput>;
  domainType?: InputMaybe<DomainTypeOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TagFilterInput>>;
  preferredContactTimeEnd?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  preferredContactTimeStart?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
};

export type TaxCode = {
  __typename?: 'TaxCode';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  name: Scalars['String'];
};

export type TaxCodeFilterInput = {
  and?: InputMaybe<Array<TaxCodeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TaxCodeFilterInput>>;
};

export type TaxCodeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type TaxCodesConnection = {
  __typename?: 'TaxCodesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TaxCodesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TaxCode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TaxCodesEdge = {
  __typename?: 'TaxCodesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TaxCode;
};

export type TaxRate = {
  __typename?: 'TaxRate';
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  startDate: Scalars['DateTime'];
  taxCode: TaxCode;
  taxCodeId: Scalars['Uuid'];
  value: Scalars['Int'];
};

export type TaxRateFilterInput = {
  and?: InputMaybe<Array<TaxRateFilterInput>>;
  endDate?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<TaxRateFilterInput>>;
  startDate?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  taxCode?: InputMaybe<TaxCodeFilterInput>;
  taxCodeId?: InputMaybe<ComparableGuidOperationFilterInput>;
  value?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type TaxRateSortInput = {
  endDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
  taxCode?: InputMaybe<TaxCodeSortInput>;
  taxCodeId?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type TaxRatesConnection = {
  __typename?: 'TaxRatesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TaxRatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TaxRate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TaxRatesEdge = {
  __typename?: 'TaxRatesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TaxRate;
};

export type Team = {
  __typename?: 'Team';
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  modifiedAt: Scalars['DateTime'];
  teamEmail: Scalars['String'];
  teamId: Scalars['Uuid'];
};

export type TeamFilterInput = {
  and?: InputMaybe<Array<TeamFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  modifiedAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TeamFilterInput>>;
  teamEmail?: InputMaybe<StringOperationFilterInput>;
  teamId?: InputMaybe<ComparableGuidOperationFilterInput>;
};

export type Template = {
  __typename?: 'Template';
  inputSchema?: Maybe<Scalars['String']>;
  metadataTagPointersJson?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  properties: TemplateProperties;
};

export type TemplateCollection = {
  __typename?: 'TemplateCollection';
  name: Scalars['String'];
  templates: Array<Template>;
};

export type TemplateKey = {
  __typename?: 'TemplateKey';
  brand: Scalars['String'];
  name: Scalars['String'];
  nameInOldFormat: Scalars['String'];
  toPath: Scalars['String'];
  variant: Scalars['String'];
  version: Scalars['Int'];
};

export type TemplateKeyInput = {
  brand: Scalars['String'];
  name: Scalars['String'];
  variant?: Scalars['String'];
  version?: Scalars['Int'];
};

export type TemplateNotFoundError = {
  __typename?: 'TemplateNotFoundError';
  templateCollection: Scalars['String'];
  templateKey: TemplateKey;
  templateName: Scalars['String'];
};

export type TemplateProperties = {
  __typename?: 'TemplateProperties';
  requiredPagesCount: Scalars['Int'];
};

export type TotalFeeIncludingOtherPaymentsToBeCollectedModel = {
  __typename?: 'TotalFeeIncludingOtherPaymentsToBeCollectedModel';
  insuranceTotalFeeIncludingOtherPaymentsToBeCollected: Scalars['Decimal'];
  nonInsuranceTotalFeeIncludingOtherPaymentsToBeCollected: Scalars['Decimal'];
  outstandingAdhocPaymentsInsuranceTotal: Scalars['Decimal'];
  outstandingAdhocPaymentsNonInsuranceTotal: Scalars['Decimal'];
  outstandingAdhocPaymentsTotal: Scalars['Decimal'];
  totalFeeIncludingOtherPaymentsToBeCollected: Scalars['Decimal'];
};

export type UnderwriterModel = {
  __typename?: 'UnderwriterModel';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Uuid'];
  legalEntity?: Maybe<LegalEntityModel>;
  name?: Maybe<Scalars['String']>;
};

export enum UnderwriterStatusType {
  AwaitingUnderwriterApproval = 'AwaitingUnderwriterApproval',
  NotRequired = 'NotRequired',
  UnderwriterApproved = 'UnderwriterApproved',
  UnderwriterRejected = 'UnderwriterRejected'
}

export type UndoContractCancelNotificationInput = {
  contractCancelNotificationId: Scalars['Uuid'];
  contractId: Scalars['Uuid'];
};

export type UpdateAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  latitude?: InputMaybe<Scalars['Decimal']>;
  longitude?: InputMaybe<Scalars['Decimal']>;
  postcode?: InputMaybe<Scalars['String']>;
};

export type UpdateCampaignInput = {
  brand?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateComplaintPaymentLinkCommandInput = {
  complaintId: Scalars['Int'];
  linkId: Scalars['Uuid'];
  paymentId: Scalars['Uuid'];
};

export type UpdateContractBoilerInput = {
  id: Scalars['Uuid'];
  installedOn?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<Scalars['String']>;
  sludge?: InputMaybe<SludgeWarning>;
  sludgeWarning?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateContractCommandInput = {
  addressId?: InputMaybe<Scalars['Uuid']>;
  brandId?: InputMaybe<Scalars['Int']>;
  claimOffDate?: InputMaybe<Scalars['DateTime']>;
  contractBoilerId?: InputMaybe<Scalars['Uuid']>;
  contractDurationMonth?: InputMaybe<Scalars['Int']>;
  contractId: Scalars['Uuid'];
  contractPremiumInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  contractPremiumNonInsurance?: InputMaybe<Scalars['Decimal']>;
  contractStatus?: InputMaybe<ContractStatus>;
  contractYear?: InputMaybe<Scalars['Int']>;
  coolingOffEndDate?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['Uuid']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  enquiryValidationStatus?: InputMaybe<EnquiryValidationStatusType>;
  flowType?: InputMaybe<FlowType>;
  initialProductVersionId?: InputMaybe<Scalars['String']>;
  legacyLinkId?: InputMaybe<Scalars['Int']>;
  offerId?: InputMaybe<Scalars['Uuid']>;
  ownershipType?: InputMaybe<OwnershipType>;
  previousContractId?: InputMaybe<Scalars['Uuid']>;
  previousProvider?: InputMaybe<Scalars['String']>;
  productInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  productNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  productVersionId?: InputMaybe<Scalars['Uuid']>;
  promocodeId?: InputMaybe<Scalars['Uuid']>;
  promotionDurationMonth?: InputMaybe<Scalars['Int']>;
  promotionInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  promotionNonInsuranceAmount?: InputMaybe<Scalars['Decimal']>;
  signUpId?: InputMaybe<Scalars['Uuid']>;
  signUpMethod?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subBrand?: InputMaybe<Scalars['String']>;
  totalInsuranceCost?: InputMaybe<Scalars['Decimal']>;
  totalNonInsuranceCost?: InputMaybe<Scalars['Decimal']>;
  underwriterStatus?: InputMaybe<UnderwriterStatusType>;
  wasRenewalDeclined?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateContractPayload = {
  __typename?: 'UpdateContractPayload';
  contractId: Scalars['Uuid'];
  /** @deprecated Is not used and should be removed */
  message?: Maybe<Scalars['String']>;
};

export type UpdateContractPreferenceCommandInput = {
  auroraBilling?: InputMaybe<Scalars['Boolean']>;
  contractId: Scalars['Uuid'];
  paymentDay?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<Scalars['Int']>;
  paymentSchedule?: InputMaybe<PaymentScheduleType>;
  toRenew?: InputMaybe<Scalars['Int']>;
};

export type UpdateCustomerInput = {
  authorisedPersonFullName?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  forename?: InputMaybe<Scalars['String']>;
  id: Scalars['Uuid'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  ovoCustomerId?: InputMaybe<Scalars['String']>;
  primaryPhone?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  thirdPhone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateDocumentTagsInput = {
  documentId: Scalars['UUID'];
  tags: Array<KeyValuePairOfStringAndStringInput>;
};

export type UpdateDocumentTagsPayload = {
  __typename?: 'UpdateDocumentTagsPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<CreateDocumentError>>;
};

export type UpdateMtaInlifeContractInput = {
  contractId: Scalars['Uuid'];
  contractPremiumInsuranceAmount: Scalars['Decimal'];
  contractPremiumNonInsurance: Scalars['Decimal'];
  productInsuranceAmount: Scalars['Decimal'];
  productNonInsuranceAmount: Scalars['Decimal'];
  productVersionId: Scalars['Uuid'];
  totalInsuranceCost: Scalars['Decimal'];
  totalNonInsuranceCost: Scalars['Decimal'];
};

export type UpdateNewEnquiryCommandInput = {
  activatedAt?: InputMaybe<Scalars['DateTime']>;
  address?: InputMaybe<AddressInput>;
  authorisedPerson?: InputMaybe<Scalars['String']>;
  autoSignUpStatus?: InputMaybe<AutoSignUpStatus>;
  autoSignUpValidationErrors?: InputMaybe<Array<AutoSignUpValidationError>>;
  bankDetails?: InputMaybe<NewEnquiryBankDetailsInput>;
  boiler?: InputMaybe<newEnquiries_BoilerInput>;
  brandName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<ContactInput>;
  contractStartDate?: InputMaybe<Scalars['DateTime']>;
  coverTypeId?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  excess?: InputMaybe<Scalars['String']>;
  forename?: InputMaybe<Scalars['String']>;
  hasAgreedContact?: InputMaybe<Scalars['Boolean']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']>;
  newEnquiryId: Scalars['Uuid'];
  ovoCustomerId?: InputMaybe<Scalars['String']>;
  paymentDay?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  signUpType?: InputMaybe<SignUpType>;
  sourceOfSale?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateOfferBreakdownInput = {
  amount?: InputMaybe<Scalars['Float']>;
  componentId?: InputMaybe<Scalars['Uuid']>;
  id: Scalars['Uuid'];
  offerBreakdownType?: InputMaybe<OfferBreakdownType>;
  offerId?: InputMaybe<Scalars['Uuid']>;
};

export type UpdateOfferInput = {
  campaignId?: InputMaybe<Scalars['Uuid']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  offerType?: InputMaybe<OfferType>;
  productVersionId?: InputMaybe<Scalars['Uuid']>;
  promotionId?: InputMaybe<Scalars['Uuid']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateOptimalEngineersInput = {
  contractId: Scalars['Uuid'];
};

export type UpdateOptimalEngineersPayload = {
  __typename?: 'UpdateOptimalEngineersPayload';
  contractId: Scalars['Uuid'];
  id: Scalars['Uuid'];
  lastUpdated: Scalars['DateTime'];
  selectionCriteria?: Maybe<Array<Maybe<SelectionCriteria>>>;
  serviceMonth: Scalars['Int'];
};

export type UpdatePaymentMethodInput = {
  paymentId: Scalars['Uuid'];
  paymentMethod: CustomerPaymentMethod;
};

export type UpdatePromocodeInput = {
  code?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Uuid'];
  offerIds?: InputMaybe<Array<Scalars['Uuid']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdatePromotionInput = {
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  durationMonths?: InputMaybe<Scalars['Int']>;
  id: Scalars['Uuid'];
  name?: InputMaybe<Scalars['String']>;
  promotionType?: InputMaybe<PromotionType>;
  voucherAmount?: InputMaybe<Scalars['Float']>;
};

export type UpdateScheduledDateInput = {
  scheduledDate: Scalars['DateTime'];
  serviceMonth: Month;
};

export type UpdateServiceMonthInput = {
  contractId?: InputMaybe<Scalars['Uuid']>;
  legacyLinkId: Scalars['Int'];
  serviceMonth: Scalars['Int'];
};

export type UpdateServiceMonthPayload = {
  __typename?: 'UpdateServiceMonthPayload';
  contractId?: Maybe<Scalars['Uuid']>;
  legacyLinkId: Scalars['Int'];
  serviceMonth: Scalars['Int'];
};

export type UpdateWorkingDayEventCommandInput = {
  eventDescription?: InputMaybe<Scalars['String']>;
  eventEndDateTime: Scalars['DateTime'];
  eventStartDateTime: Scalars['DateTime'];
  eventTitle?: InputMaybe<Scalars['String']>;
  eventType: EventType;
  id: Scalars['Uuid'];
};

export type UpdatedUserInput = {
  companyId: Scalars['Int'];
  password?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UploadDocumentInput = {
  documentCollectionName: Scalars['String'];
  documentType: Scalars['String'];
  file: Scalars['Upload'];
  tags: Array<KeyValuePairOfStringAndStringInput>;
};

export type UploadDocumentPayload = {
  __typename?: 'UploadDocumentPayload';
  documentId?: Maybe<Scalars['UUID']>;
  error?: Maybe<CreateDocumentError>;
  filePath?: Maybe<Scalars['String']>;
};

export type UpsertCustomerDefaultPreferenceCommandInput = {
  customerId: Scalars['Uuid'];
  documentCommunicationPreference?: InputMaybe<DocumentCommunicationPreference>;
  paymentDay?: InputMaybe<Scalars['Int']>;
  paymentMethod?: InputMaybe<Scalars['Int']>;
  paymentSchedule?: InputMaybe<PaymentScheduleType>;
  toRenew?: InputMaybe<Scalars['Int']>;
};

export type UpsertMarketingPermissionsInput = {
  customerId: Scalars['Uuid'];
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export type UpsertMarketingPermissionsPayload = {
  __typename?: 'UpsertMarketingPermissionsPayload';
  customerId: Scalars['Uuid'];
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
  post: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  screenName?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  companyId?: InputMaybe<ComparableInt32OperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  password?: InputMaybe<StringOperationFilterInput>;
  userId?: InputMaybe<ComparableInt32OperationFilterInput>;
  username?: InputMaybe<StringOperationFilterInput>;
};

export type ValidationFailedError = {
  __typename?: 'ValidationFailedError';
  validationFailures: Array<ValidationFailure>;
};

export type ValidationFailure = {
  __typename?: 'ValidationFailure';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  formattedMessagePlaceholderValues?: Maybe<Array<KeyValuePairOfStringAndObject>>;
  location: Scalars['String'];
  message: Scalars['String'];
  /** @deprecated Use Location */
  pointer: Scalars['String'];
  propertyName?: Maybe<Scalars['String']>;
  severity: Severity;
};

export type VatStatus = {
  __typename?: 'VatStatus';
  isRegistered?: Maybe<Scalars['Boolean']>;
  registrationNumber?: Maybe<Scalars['String']>;
};

export type VatStatusFilterInput = {
  and?: InputMaybe<Array<VatStatusFilterInput>>;
  isRegistered?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<VatStatusFilterInput>>;
  registrationNumber?: InputMaybe<StringOperationFilterInput>;
};

export type VatStatusSortInput = {
  isRegistered?: InputMaybe<SortEnumType>;
  registrationNumber?: InputMaybe<SortEnumType>;
};

export type WorkDoneInput = {
  description: Scalars['String'];
  hours: Scalars['Decimal'];
};

export type WorkingDayDetails = {
  __typename?: 'WorkingDayDetails';
  endTime: Scalars['DateTime'];
  isAdHoc: Scalars['Boolean'];
  isPublicHoliday: Scalars['Boolean'];
  isWeekend: Scalars['Boolean'];
  isWorkingDay: Scalars['Boolean'];
  publicHolidayTitle?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
};

/** Union of all types that key directive applied. This information is needed by the Apollo federation gateway. */
export type _Entity = AuditLogEntity | Contract | Customer | FakeEntity | Manufacturer | MtaInlifeAuditLogEntity;

export type _SchemaDefinition = {
  __typename?: '_SchemaDefinition';
  document: Scalars['String'];
  extensionDocuments: Array<Scalars['String']>;
  name: Scalars['String'];
};

/** This type provides a field named sdl: String! which exposes the SDL of the service's schema. This SDL (schema definition language) is a printed version of the service's schema including the annotations of federation directives. This SDL does not include the additions of the federation spec. */
export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type audit_DomainTypeOperationFilterInput = {
  eq?: InputMaybe<DomainType>;
  in?: InputMaybe<Array<DomainType>>;
  neq?: InputMaybe<DomainType>;
  nin?: InputMaybe<Array<DomainType>>;
};

export enum audit_EventType {
  BUSINESS_EVENT = 'BUSINESS_EVENT',
  ERROR = 'ERROR',
  TECHNICAL = 'TECHNICAL',
  UI_EVENT = 'UI_EVENT'
}

export enum callout_CalloutSourceType {
  External = 'External',
  Horizon = 'Horizon',
  Internal = 'Internal',
  OVOBoilerRepair = 'OVOBoilerRepair',
  OVOEnergy = 'OVOEnergy',
  OVOHomePlan = 'OVOHomePlan'
}

export type company_Address = {
  __typename?: 'company_Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type company_AddressFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  address3?: InputMaybe<StringOperationFilterInput>;
  address4?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<company_AddressFilterInput>>;
  or?: InputMaybe<Array<company_AddressFilterInput>>;
  postcode?: InputMaybe<StringOperationFilterInput>;
};

export type company_AddressSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  address3?: InputMaybe<SortEnumType>;
  address4?: InputMaybe<SortEnumType>;
  postcode?: InputMaybe<SortEnumType>;
};

export type customerPayments_Payment = {
  __typename?: 'customerPayments_Payment';
  category: PaymentCategory;
  contractId: Scalars['Uuid'];
  dateCreated: Scalars['DateTime'];
  dueAmount: Scalars['Decimal'];
  dueDate: Scalars['DateTime'];
  earliestPaymentDueDate: Scalars['DateTime'];
  id: Scalars['Uuid'];
  insuranceAmount: Scalars['Decimal'];
  latestPaymentDueDate: Scalars['DateTime'];
  nonInsuranceAmount: Scalars['Decimal'];
  paymentMethod: CustomerPaymentMethod;
  productType: ProductType;
  scheduleStatus: ScheduleStatus;
  subCategory: PaymentSubCategory;
};

export type legacyCustomer_Address = {
  __typename?: 'legacyCustomer_Address';
  addressId: Scalars['Uuid'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  modifiedAt: Scalars['DateTime'];
  postcode: Scalars['String'];
};

export type newEnquiries_BoilerInput = {
  boilerQuantity: Scalars['Int'];
  fuelType: NewEnquiryFuelType;
  installDate?: InputMaybe<Scalars['DateTime']>;
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  needsRepair: Scalars['Boolean'];
};
