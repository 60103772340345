export const EMAIL_AND_POSTCODE_NOT_EXIST = 'Sorry, there’s a problem';

export const MULTI_ACTIVE_POLICY_HOLDER_ERROR_TITLE =
  'Sorry, there’s a problem';

export const ERROR_CODES = {
  INVALID_METHOD: 'InvalidMethod',
  USER_EXISTS_ERROR_CODE: 'UsernameExistsException',
  USER_NOT_FOUND_ERROR_CODE: 'UserNotFoundException',
  EMAIL_OR_POSTCODE_MISSING: 'EmailOrPostcodeMissing',
  NO_CUSTOMER_FOUND: 'NoCustomerFound',
  MULTI_ACTIVE_POLICY_HOLDER: 'MultiActivePolicyHolder',
  SIGNUP_FAILED: 'SignupFailed',
};
export const SIGNUP_MIXPANEL_EVENTS = {
  FAILED_SIGNUP: 'login.page.login.signup.failed',
  SUCCESS_SIGNUP: 'login.page.login.signup.success',
};

export const LOGIN_MIXPANEL_EVENTS = {
  SUBMIT_BUTTON_CLICK: 'login.page.login.btn.click',
  VALID_FORM_SUBMISSION: 'login.page.login.successful.submission',
  INVALID_FORM_SUBMISSION: 'login.page.login.unsuccessful.submission',
  EMAIL_RESENT_SUBMISSION: 'emailsent.page.resend.btn.click',
  ENTER_NEW_EMAIL_REDIRECT: 'emailsent.page.reEnterEmail.link.click',
  INVALID_CODE: 'emailSent.page.login.submission.error',
  VALID_CODE: 'emailSent.page.login.submission.success',
  LOGIN_ERROR: 'login.page.error',
  VIEW: 'login.page.view',
  TROUBLE_LOGGING_IN_BUTTON_CLICK: 'login.page.trouble.logging.in.btn.click',
};

export const LOGIN_ERROR_REASONS = {
  EMAIL_POSTCODE_DONT_MATCH: 'Email and Postcode dont match',
  EMAIL_INVALID: 'Email is invalid',
  POSTCODE_INVALID: 'Postcode is invalid',
  MULTI_ACTIVE_POLICY_HOLDER: 'Multi active policy holder',
};

export const LOGIN_ERRORS_KEYS = {
  SIGNUP_FAILED: 'SIGNUP_FAILED',
  INVALID_CONFIRMATION_CODE: 'INVALID_CONFIRMATION_CODE',
};

export const MAX_CODE_ATTEMPTS = 2;

export const INVALID_CODE_MESSAGES = {
  INVALID_CODE: 'Please check you have entered the correct access code.',
  TOO_MANY_ATTEMPTS:
    'You have entered an incorrect access code too many times, please press the resend button below to be given a new code',
};

export const ACCESS_CODE_LENGTH = 6;

export const LOGIN_SESSION_STORAGE_KEYS = {
  EMAIL: 'email',
  POSTCODE: 'postcode',
  BRAND: 'brand',
};
