import React from 'react';

const NotResolvedIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle cx="10" cy="10.5" r="10" fill="#D7D7D7" />
    <rect x="3.5" y="8.5" width="13" height="4" fill="white" />
  </svg>
);

export { NotResolvedIcon };
