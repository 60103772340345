import { Margin } from '@ovotech/element';
import React from 'react';

import { ITopNavbarLinkProps } from '../interfaces';
import { StyledLinkTag, StyledTextLink } from '../TopNavbar/styles';

import { StyledIconLink, StyledMenuListItem } from './styles';

export const MobileMenuItem: React.FC<ITopNavbarLinkProps> = (props) => {
  const { name } = props;
  return (
    <StyledMenuListItem>
      {props.icon && (
        <Margin right={2} left={2}>
          <StyledIconLink {...props}>
            <props.icon />
          </StyledIconLink>
        </Margin>
      )}
      <StyledTextLink {...props}>
        <StyledLinkTag>{name}</StyledLinkTag>
      </StyledTextLink>
    </StyledMenuListItem>
  );
};
