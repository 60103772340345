import React from 'react';

import { IMobileMenuListProps } from '../interfaces';

import { MobileMenuItem } from './MobileMenuItem';
import { StyledMenuList } from './styles';

export const MobileMenuList: React.FC<IMobileMenuListProps> = ({ links }) => {
  return (
    <div>
      <StyledMenuList showBullets={false}>
        {links.map((props, idx) => (
          <MobileMenuItem key={`mobile-navbar-menu-link-${idx}`} {...props} />
        ))}
      </StyledMenuList>
    </div>
  );
};
