import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OptimizelyProvider } from '@optimizely/react-sdk';

import { useAppSelector } from '../../hooks/useAppSelector';
import { selectLoginEmail } from '../../redux/auth/selectors';

import { initOptimizely } from './utils';

const optimizelyInstance = initOptimizely();
const QA_AUTOMATION_EMAIL = 'qa.automation';
const OptimizelyProviderWrapper: React.FC = ({ children }) => {
  const currentUserEmail = useAppSelector(selectLoginEmail);
  const [userEmail, setUserEmail] = useState<string>(currentUserEmail);

  useEffect(() => {
    const sessionEmail = sessionStorage.getItem('email');

    if (sessionEmail) {
      setUserEmail(sessionEmail);
    }
  }, [currentUserEmail]);

  const isTestUser = useCallback(
    (email: string) => (email?.includes(QA_AUTOMATION_EMAIL) ? true : false),
    [],
  );

  const user = useMemo(
    () => ({
      id: userEmail !== '' ? userEmail : 'Unknown',
      attributes: {
        isTestUser: isTestUser(userEmail),
      },
    }),
    [isTestUser, userEmail],
  );

  return (
    <OptimizelyProvider
      optimizely={optimizelyInstance}
      user={user}
      timeout={30}
    >
      {children}
    </OptimizelyProvider>
  );
};

export default OptimizelyProviderWrapper;
