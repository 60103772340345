import { createTheme, theme } from '@ovotech/element-core';
import { DefaultTheme } from 'styled-components';

export const CorgiSelfServeTheme: DefaultTheme = createTheme(theme, {
  core: {
    color: {
      brand: {
        midnight: '#444444',
        midnightTint: '#444444',
        forest: '#736862',
        forestTint: '#736862',
        ovo: '#EE6F2E',
        ovoTint: '#EE6F2E',
        leaf: '#F7C343',
        leafTint: '#F7C343',
        energised: '#F3901D',
        energisedTint: '#F3901D',
      },
    },
  },
  semantic: {
    message: {
      branded: '#EE6F2E',
      link: '#EE6F2E',
    },
    data: {
      branded: {
        '1': '#EE6F2E',
        '2': '#EE6F2E',
        '3': '#EE6F2E',
        '4': '#EE6F2E',
      },
    },
  },
  component: {
    cta: {
      primary: {
        surface: '#F3901D',
        surfaceFocused: '#F3901D',
        surfaceHover: '#F7C343',
        backgroundFocused: '#F3901D',
      },
      secondary: {
        surface: '#444444',
        surfaceFocused: '#444444',
        surfaceHover: '#000000',
        backgroundFocused: '#444444',
      },
    },
  },
});
