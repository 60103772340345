import { useRouter } from 'next/router';

import { InfoNotification } from '@ovotech/element';

import Link from 'next/link';

import { CommonRoutes } from '../../constants/routes';

import { useMixpanel } from '../UserAnalytics/hooks';

import { LastDayOfCover, StyledTextLink } from './styles';

import {
  PROFILE_MIXPANEL_EVENTS,
  PROFILE_MIXPANEL_EVENT_PROPERTIES,
} from './constants';

import { IRenewPlanCTAProps } from './interfaces';

const RenewPlanCTA: React.FC<IRenewPlanCTAProps> = ({
  customerForename,
  renewalDate,
  isRenewalDeclined,
}) => {
  const mixpanel = useMixpanel();
  const { getRenewPlanRoute } = CommonRoutes;
  const router = useRouter();

  const handleRenewMyPlanClick = () => {
    mixpanel?.track(PROFILE_MIXPANEL_EVENTS.BUTTON_CLICK, {
      buttonId: PROFILE_MIXPANEL_EVENT_PROPERTIES.RENEW_PLAN_BUTTON,
      pageName: router.pathname,
      mixpanelData: {
        location: isRenewalDeclined
          ? 'header-renewal declined'
          : 'header-renewal original',
      },
    });
  };

  return (
    <InfoNotification
      id="renew-plan-cta-card"
      title={
        isRenewalDeclined
          ? "You've chosen not to renew your plan"
          : `Ready to renew your plan ${customerForename}?`
      }
      className="mb-4 md:mb-12"
    >
      <div className="mb-4">
        {isRenewalDeclined
          ? 'You can change your mind until'
          : 'Your last day of cover is'}{' '}
        <LastDayOfCover>{renewalDate}</LastDayOfCover>
      </div>
      <StyledTextLink
        data-testid="renew-plan-button"
        onClick={handleRenewMyPlanClick}
        href={getRenewPlanRoute()}
        as={Link}
      >
        Renew my plan
      </StyledTextLink>
    </InfoNotification>
  );
};

export { RenewPlanCTA };
