import React from 'react';

const BulbIcon = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_6603_8910)">
      <path
        opacity="0.3"
        d="M14 19.25C18.8325 19.25 22.75 15.3325 22.75 10.5C22.75 5.66751 18.8325 1.75 14 1.75C9.16751 1.75 5.25 5.66751 5.25 10.5C5.25 15.3325 9.16751 19.25 14 19.25Z"
        fill="#0F9C9E"
      />
      <g opacity="0.3">
        <path
          d="M8.9248 10.5L11.1415 18.0833H13.5915L12.0748 12.8333H15.9248L14.4081 18.0833H16.8581L19.0748 10.5H8.9248Z"
          fill="#0F9C9E"
        />
      </g>
      <path
        d="M16.9167 23.3333H11.0833L9.625 18.0833H18.375L16.9167 23.3333Z"
        fill="#0F9C9E"
      />
      <path
        opacity="0.3"
        d="M11.083 23.3333H16.9163C16.9163 24.9666 15.633 26.2499 13.9997 26.2499C12.3663 26.2499 11.083 24.9666 11.083 23.3333Z"
        fill="#0F9C9E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6603_8910">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { BulbIcon };
