import { ErrorSummaryNotification } from '@ovotech/element';
import { ErrorSummaryList } from '@ovotech/element/dist/components/ErrorSummaryNotification/ErrorSummaryNotification';
import { map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

interface IFormErrorSummaryProps {
  id: string;
  errors: {
    [key: string]:
      | {
          [key: string]: FieldError | undefined;
        }
      | FieldError
      | undefined;
  };
}

const FormErrorSummary: FC<IFormErrorSummaryProps> = ({ id, errors }) => {
  const formattedErrors: ErrorSummaryList = useMemo(
    () =>
      map(errors, (error, key) => {
        return {
          id: String(key),
          message: error?.message,
        };
      }),
    [errors],
  );

  return (
    <ErrorSummaryNotification
      id={id}
      errors={formattedErrors}
      title="Sorry, there’s a problem"
    />
  );
};

export { FormErrorSummary };
