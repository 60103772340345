import React from 'react';

const PaymentDetailsIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="36"
    height="28"
    viewBox="0 0 36 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M0 0V28H36V0H0ZM11.4 13.8H4.3V8.1H11.4V13.8ZM25.9 13.8L24.1 12C24.4 11.7 24.6 11.3 24.6 10.9C24.6 10.5 24.4 10.1 24.1 9.8L25.9 8C26.7 8.8 27.1 9.8 27.1 10.9C27.1 12 26.7 13 25.9 13.8ZM29.1 17L27.3 15.2C28.5 14 29.1 12.5 29.1 10.9C29.1 9.3 28.5 7.7 27.3 6.6L29.1 4.8C30.7 6.4 31.6 8.6 31.6 10.9C31.6 13.2 30.7 15.4 29.1 17Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M24.0996 9.8C24.3996 10.1 24.5996 10.5 24.5996 10.9C24.5996 11.3 24.3996 11.7 24.0996 12L25.8996 13.8C26.6996 13 27.0996 12 27.0996 10.9C27.0996 9.8 26.6996 8.8 25.8996 8L24.0996 9.8Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M27.3008 6.60005C28.5008 7.80005 29.1008 9.30005 29.1008 10.9C29.1008 12.5 28.5008 14.1 27.3008 15.2L29.1008 17C30.7008 15.4 31.6008 13.2 31.6008 10.9C31.6008 8.60005 30.7008 6.40005 29.1008 4.80005L27.3008 6.60005Z"
      fill="currentColor"
    />
  </svg>
);

export { PaymentDetailsIcon };
