import Auth from '@aws-amplify/auth';
import { SignOutOpts } from '@aws-amplify/auth/lib/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { SelfServeCognitoUser } from '../../modules/Login/interfaces';

import { restoreAuthenticatedUser } from '../../utils/authStorage';

export const signIn = createAsyncThunk('auth/signIn', async (email: string) => {
  return await Auth.signIn(email);
});

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (options?: SignOutOpts) => {
    await Auth.signOut(options);
  },
);

export const getCurrentAuthenticatedUser = createAsyncThunk(
  'auth/getCurrentAuthenticatedUser',
  async () => {
    return await restoreAuthenticatedUser({ bypassCache: true });
  },
);

export const sendCustomChallengeAnswer = createAsyncThunk(
  'auth/sendCustomChallengeAnswer',
  async ({
    session,
    code,
  }: {
    session: SelfServeCognitoUser | null;
    code: string;
  }) => {
    return await Auth.sendCustomChallengeAnswer(session, code);
  },
);
