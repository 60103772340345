import React from 'react';

const OvoEnergyRectangularLogo = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 811" {...rest}>
    <defs>
      <style></style>
    </defs>
    <g id="BASE">
      <path
        fill="#0a9828"
        d="M971.68,301.93c-60.11,0-96.44,51.64-96.44,109.73,0,56.88,29.86,109.73,98.46,109.73,42,0,85.94-26.63,85.94-44,0-10.09-6.45-15.74-12.91-15.74-13.32,0-25.42,29.05-72.62,29.05-40,0-63.36-28.65-63.36-65.35h133.57c15.32,0,19.76-6.47,19.76-19C1064.08,354.79,1030.19,301.93,971.68,301.93Zm-60.93,96.82c1.22-32.68,17-67.77,60.93-66.15,39.14,0,56.89,35.5,56.89,66.15Z"
      />
      <path
        fill="#0a9828"
        d="M684.94,573.24c-89.16,0-163.3-50.56-177.35-136.59L456.68,568.06h-100L306,437.42C291.67,523,217.73,573.24,128.81,573.24c-7,0-13.87-.34-20.65-1L172,810.52l638.17-171-24.57-91.69C756.85,564.42,722.22,573.24,684.94,573.24Z"
      />
      <path
        fill="#0a9828"
        d="M624.16,408.13c0,37.07,25,62.51,60.78,62.51s60.8-25.44,60.8-62.51a72,72,0,0,0-1.54-14.73l-2.13-8c-7.92-23.56-28.29-40.66-57.13-40.66C647.44,344.75,624.16,373.64,624.16,408.13Z"
      />
      <path
        fill="#0a9828"
        d="M189.61,408.13c0-34.49-23.29-63.38-60.8-63.38S68,373.64,68,408.13c0,37.07,25,62.51,60.8,62.51S189.61,445.2,189.61,408.13Z"
      />
      <path
        fill="#0a9828"
        d="M242.35,273l-11.61-30H354.06l43.11,147.88,9.42,33.33s4.83-17.38,9.55-33.33L459.27,243H582.59l-11.76,30.38c31.11-22.85,70.92-35.55,114.11-35.55,6,0,11.93.26,17.76.73l-63.54-237L1,172.47,26,265.8c29.2-18,64.67-28,102.79-28C171.74,237.84,211.3,250.4,242.35,273Z"
      />
      <path
        fill="#0a9828"
        d="M1982.86,301.93c-8.88,0-14.53,5.65-16.94,13.31l-48.43,147.25h-.8l-49.22-147.25c-2.42-7.66-8.88-13.31-17.76-13.31-8.06,0-16.14,6.05-16.14,14.93,0,4,.81,7.66,4.85,19.76L1899,511.3l-22.61,60.12c-4.44,12.1-5.25,15.72-5.25,19.76,0,8.88,8.08,14.93,16.14,14.93,8.88,0,14.53-5.66,17.34-13.32l89.59-256.17c4-12.1,4.84-15.73,4.84-19.76C1999,308,1990.94,301.93,1982.86,301.93Z"
      />
      <path
        fill="#0a9828"
        d="M1188.37,301.93c-21.38,0-43.59,8.08-59.32,28.24l-.81-.81v-8.47c0-11.7-6.85-19-17.74-19s-17.76,7.26-17.76,19V502.42c0,11.7,6.87,19,17.76,19s17.74-7.27,17.74-19v-117c0-37.52,26.23-52.84,49.64-52.84s49.62,15.32,49.62,52.84v117c0,11.7,6.87,19,17.76,19s17.75-7.27,17.75-19v-119C1263,322.91,1221.45,301.93,1188.37,301.93Z"
      />
      <path
        fill="#0a9828"
        d="M1790.38,301.93c-9.68,0-19,4.85-19,17.35V329h-.82c-9.27-12.9-31.47-27-54.46-27-60.53,0-94.43,52.86-94.43,109.73s33.9,109.73,94.43,109.73c24.2,0,43.57-12.11,54.46-30.66h.82v18.55c0,30.67-13.32,66.16-58.51,66.16-38.33,0-54.08-25-70.22-25-11.29,0-14.93,9.27-14.93,16.54,0,18.15,45.6,39.13,82.31,39.13,58.11,0,96.84-38.73,96.84-102.48V319.28C1806.92,306.78,1797.24,301.93,1790.38,301.93Zm-74.23,188.8c-43.59,0-58.92-42.76-58.92-79.07s15.33-79.06,58.92-79.06,58.92,42.75,58.92,79.06S1759.72,490.73,1716.15,490.73Z"
      />
      <path
        fill="#0a9828"
        d="M1388.1,301.93c-60.12,0-96.44,51.64-96.44,109.73,0,56.88,29.86,109.73,98.46,109.73,42,0,85.94-26.63,85.94-44,0-10.09-6.45-15.74-12.9-15.74-13.34,0-25.42,29.05-72.64,29.05-40,0-63.35-28.65-63.35-65.35h133.56c15.33,0,19.77-6.47,19.77-19C1480.5,354.79,1446.61,301.93,1388.1,301.93Zm-60.93,96.82c1.22-32.68,16.95-67.77,60.93-66.15,39.15,0,56.89,35.5,56.89,66.15Z"
      />
      <path
        fill="#0a9828"
        d="M1593.89,301.93c-20.58,0-40.35,20.58-48.43,37.12h-.79V320.89c0-11.7-6.87-19-17.76-19s-17.75,7.26-17.75,19V502.42c0,11.7,6.86,19,17.75,19s17.76-7.27,17.76-19V400.36c0-41.55,25.81-57.28,46.79-60.91,15-2.83,22.2-9.28,22.2-19.35S1606.41,301.93,1593.89,301.93Z"
      />
    </g>
  </svg>
);

export { OvoEnergyRectangularLogo };
