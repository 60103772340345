import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div(
  ({ theme: { core, semantic } }) => css`
    padding: ${core.space[4]};
    background-color: ${semantic.surface.elevated};
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid ${semantic.border.functional};
    margin-bottom: ${core.space[4]};
    position: fixed;
    z-index: 100;
  `,
);
