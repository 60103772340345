import { ErrorSummaryNotification } from '@ovotech/element';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { useToast } from '../../modules/Toasts/hooks';

const CustomErrorBoundary: React.FC = ({ children }): JSX.Element => {
  function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    const { showError } = useToast();
    const router = useRouter();

    const handleRouteChange = useCallback(() => {
      resetErrorBoundary();
    }, [resetErrorBoundary]);

    useEffect(() => {
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }, [handleRouteChange, router]);

    useEffect(() => {
      showError(error.message);
    }, [error, showError]);

    return (
      <>
        {error ? (
          <ErrorSummaryNotification
            title={undefined}
            id="error-summary"
            errors={[
              { id: 'error-name', message: 'Try again later' },
              { id: 'error', message: JSON.stringify(error) },
            ]}
          />
        ) : (
          children
        )}
      </>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
};

export { CustomErrorBoundary };
