import React, { useEffect, useState } from 'react';

import { SuccessNotification } from '@ovotech/element';

import { ProfileCalloutCTA } from '@client/modules/Profile/ProfileCalloutCTA';

import { RenewalStatus } from '@client/types/__generated__/globalTypes';

import { ProfileSideNav } from './ProfileSideNav';

import { RenewPlanCTA } from './RenewPlanCTA';
import { useRenewalNotifications } from './hooks';
import { IProfilePageProps } from './interfaces';

const ProfileLayout: React.FC<IProfilePageProps> = ({
  customer,
  children,
  isDirectDebitActive,
  planDetails,
  lastRenewalInteraction,
  pendingRenewalContractProductName,
  pendingRenewalContractProductId,
  isRenewalDiscountApplied,
  isAbsOnlyCustomer,
  isBookEngineerMobileCTAVisible = true,
}) => {
  const {
    renewalDate,
    displayRenewPlanCta,
    displayRenewalInteractionNotification,
    renewalInteractionNotificationText,
  } = useRenewalNotifications({
    customer,
    isDirectDebitActive,
    planDetails,
    lastRenewalInteraction,
    pendingRenewalContractProductName,
    pendingRenewalContractProductId,
    isRenewalDiscountApplied,
  });

  const [shouldDisplayRenewPlanCTA, setShouldDisplayRenewPlanCTA] =
    useState(false);

  useEffect(() => {
    setShouldDisplayRenewPlanCTA(displayRenewPlanCta);
  }, [displayRenewPlanCta]);

  return (
    <>
      <div className="hidden md:block md:mr-12 max-w-[196px]" id="sidebar">
        <ProfileSideNav isAbsOnly={isAbsOnlyCustomer} />
      </div>
      <div id="content Container" className="flex flex-col w-full md:w-4/5">
        <div>
          {shouldDisplayRenewPlanCTA && (
            <RenewPlanCTA
              customerForename={customer?.forename}
              renewalDate={renewalDate}
              isRenewalDeclined={
                lastRenewalInteraction?.renewalStatus == RenewalStatus.Decline
              }
            />
          )}
          {displayRenewalInteractionNotification && (
            <SuccessNotification
              id="success-renewal-notification"
              data-testid="plan-renewal-notification"
              title={'Success'}
            >
              {renewalInteractionNotificationText}
            </SuccessNotification>
          )}
        </div>
        {isBookEngineerMobileCTAVisible && (
          <div className="block md:hidden">
            <ProfileCalloutCTA isAbsOnly={isAbsOnlyCustomer} />
          </div>
        )}
        <div>{children}</div>
      </div>
    </>
  );
};

export { ProfileLayout };
