import Head from 'next/head';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import IconProvider from '../../components/Icons/IconProvider';

import { BRAND } from '../../constants/general';

import { useBrand } from '../../hooks/useBrand';

import { favicons, themes } from './constants';

export const BrandThemeProvider: React.FC = ({ children }) => {
  const { id, brand, isActualBrandUploaded } = useBrand();

  const brandName = BRAND[id];
  const brandTheme = isActualBrandUploaded && themes[brandName];
  const brandFavicon = favicons[brandName];

  const title = `Request an engineer - ${brand?.name}`;

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={brandFavicon}></link>
        <title>{title}</title>
      </Head>
      {brandTheme && (
        <ThemeProvider theme={brandTheme}>
          <IconProvider>{children}</IconProvider>
        </ThemeProvider>
      )}
    </>
  );
};
