import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export const initDatadog = (dataDogVersion: string) => {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'self-serve-ui',
    version: dataDogVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    silentMultipleInit: true,
    env: process.env.NEXT_PUBLIC_DD_ENV as string,
  });

  if (!datadogLogs.getInitConfiguration()) {
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
      site: 'datadoghq.com',
      service: 'self-serve-ui',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: process.env.NEXT_PUBLIC_DD_ENV as string,
      forwardConsoleLogs: 'all',
    });
  }
};
