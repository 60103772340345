import React from 'react';

const EditIcon = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M0.956055 11.6429V13.5H2.81319L10.2417 6.07149L8.38459 4.21436L0.956055 11.6429Z"
      stroke="currentColor"
      fill="currentColor"
    />
    <rect
      x="11.3738"
      y="1.20711"
      width="2.71427"
      height="1.47618"
      transform="rotate(45 11.3738 1.20711)"
      stroke="currentColor"
      fill="currentColor"
    />
  </svg>
);

export { EditIcon };
