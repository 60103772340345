import { Li, TextLink, Ul } from '@ovotech/element';
import styled, { css } from 'styled-components';

import { IMobileMenuButton, IMobileMenuContainer } from '../interfaces';

export const StyledMobileMenuScroll = styled.div(
  ({ theme: { core } }) => css`
    padding-top: ${core.space[4]};
    padding-bottom: ${core.space[4]};
    overflow: auto;
    flex: 1;
  `,
);

export const StyledMobileMenuContent = styled.div<IMobileMenuContainer>(
  ({ theme: { core } }) => css`
    margin: 0 auto;
    max-width: 100%;
    width: ${core.breakpoint.large};
    padding-left: ${core.space[4]};
    padding-right: ${core.space[4]};
    ${core.mediaQuery.large} {
      padding-left: ${core.space[8]};
      padding-right: ${core.space[8]};
    }
  `,
);

export const StyledMobileMenuContainer = styled(StyledMobileMenuContent)(
  ({ isOpen, theme: { semantic } }) => css`
    background: ${semantic.surface.elevated};
    position: fixed;
    z-index: 100;
    top: 78px;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    -webkit-transform-origin: 100% 50%;
    -webkit-transform: ${isOpen ? `translateX(0%)` : `translateX(-100%)`};
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -moz-transform-origin: 100% 50%;
    -moz-transform: ${isOpen ? `translateX(0%)` : `translateX(-100%)`};
    -moz-transition: -webkit-transform 0.2s ease-in-out;
    transform-origin: 100% 50%;
    transform: ${isOpen ? `translateX(0%)` : `translateX(-100%)`};
    transition: -webkit-transform 0.2s ease-in-out;
  `,
);

export const StyledMobileMenuBottomBarSection = styled.div(
  ({ theme: { core } }) => css`
    display: block;
    justify-content: space-between;
    padding: ${core.space[4]} 0;
    > * > * + * {
      margin-left: ${core.space[6]};
    }
  `,
);

export const StyledMenuList = styled(Ul)`
  list-style: none;
  margin: 0;
`;

export const StyledMenuListItem = styled(Li)`
  display: flex;
  align-items: center;
`;

export const StyledIconLink = styled(TextLink)`
  height: 31.67px;
  width: 20px;
  display: flex;
  svg {
    height: 31.67px;
    width: 20px;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledMobileMenuButtonWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: fixed;
  left: 20px;
  top: 20px;
`;

export const StyledMobileMenuButtonLine = styled.div<IMobileMenuButton>(
  ({ isOpen, theme: { core } }) => css`
    width: 32px;
    height: 3px;
    background-color: ${isOpen
      ? core.color.brand.midnight
      : core.color.neutral.base};
    margin: 8px 0;
    transition: 0.4s;
    border-radius: 100px;
  `,
);

export const StyledMobileMenuButtonTop = styled(StyledMobileMenuButtonLine)(
  ({ isOpen }) => css`
    -webkit-transform: ${isOpen ? `translate(0, 11px) rotate(-45deg)` : ``};
    -moz-transform: ${isOpen ? `translate(0, 11px) rotate(-45deg)` : ``};
    transform: ${isOpen ? `translate(0, 11px) rotate(-45deg)` : ``};
  `,
);

export const StyledMobileMenuButtonMiddle = styled(StyledMobileMenuButtonLine)(
  ({ isOpen }) => css`
    opacity: ${isOpen ? `0` : ``};
  `,
);

export const StyledMobileMenuButtonBottom = styled(StyledMobileMenuButtonLine)(
  ({ isOpen }) => css`
    -webkit-transform: ${isOpen ? `translate(0, -11px) rotate(45deg)` : ``};
    -moz-transform: ${isOpen ? `translate(0, -11px) rotate(45deg)` : ``};
    transform: ${isOpen ? `translate(0, -11px) rotate(45deg)` : ``};
  `,
);
