import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAppSelector } from '@client/hooks/useAppSelector';
import { selectIsLoading } from '@client/redux/common/selectors';
import {
  selectCurrentUser,
  selectIsUserConfirmed,
} from '@client/redux/auth/selectors';

import { useOptimizely } from '@client/modules/UserAnalytics/hooks';

import { setIsLoading } from '@client/redux/common';

import { useAppDispatch } from '@client/hooks/useAppDispatch';
import { useToast } from '@client/modules/Toasts/hooks';

import { LOGIN_TOAST_DISPLAYED_KEY } from '@client/modules/Welcome/constants';

export const useLayout = () => {
  const isLoading = useAppSelector(selectIsLoading);
  const currentUser = useAppSelector(selectCurrentUser);
  const enableAccountDashboardDecision = useOptimizely(
    'enable-account-dashboard',
    true,
  );
  const isLoggedIn = useAppSelector(selectIsUserConfirmed);
  const isEnableAccountDashboard =
    enableAccountDashboardDecision.enabled && isLoggedIn;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();

  useEffect(() => {
    const toastDisplayed = sessionStorage.getItem(
      LOGIN_TOAST_DISPLAYED_KEY,
    ) as string;

    if (!toastDisplayed && currentUser) {
      showSuccess('Login successful');
      sessionStorage.setItem(LOGIN_TOAST_DISPLAYED_KEY, String(true));
    }
  }, [showSuccess, currentUser]);

  useEffect(() => {
    const handleRouteChangeStart = async () => {
      await dispatch(setIsLoading(true));
    };

    const handleRouteChangeComplete = async () => {
      await dispatch(setIsLoading(false));
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeStart', handleRouteChangeComplete);
    };
  }, [dispatch, router.events]);

  return {
    isLoading,
    currentUser,
    isEnableAccountDashboard,
  };
};
