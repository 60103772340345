import React from 'react';

import { ITopNavbarLinkProps } from '../interfaces';

import { StyledLinkTag, StyledTextLink } from './styles';

const TopNavbarLink: React.FC<ITopNavbarLinkProps> = ({ name, ...rest }) => {
  return (
    <StyledTextLink {...rest}>
      <StyledLinkTag>{name}</StyledLinkTag>
    </StyledTextLink>
  );
};

export default TopNavbarLink;
