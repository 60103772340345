import { Icon } from '@ovotech/element';
import Link from 'next/link';

import { StyledLogoLink } from './TopNavbar/styles';

export const Logo = () => (
  <Link href="/" passHref legacyBehavior>
    <StyledLogoLink data-testid="logo-link">
      <Icon name={'logo'} width={132} height={52} />
    </StyledLogoLink>
  </Link>
);
