import { RootState } from '../../hooks/useAppSelector';

export const selectIsAuthLoading = (state: RootState) =>
  state.auth.loading ||
  state.auth.isChallengeAnswerLoading ||
  state.auth.signupLoading;
export const selectIsUserLoaded = (state: RootState) => state.auth.isUserLoaded;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectLoginEmail = (state: RootState) => state.auth.email;
export const selectIsUserConfirmed = (state: RootState) =>
  Boolean(state.auth.user?.getSignInUserSession()?.isValid());
export const selectSession = (state: RootState) => state.auth.session;
