import { FieldError } from 'react-hook-form';

import { ContractsDetails } from '../Customer/gql/fragments/__generated__/ContractDetails';

import { GetCustomerByIdDetails } from '../Customer/gql/fragments/__generated__/GetCustomerByIdDetails';
import {
  BoilerSubProblemsType,
  ElectricsSubProblemsType,
  ExternalDrainsSubProblemsType,
  InternalDrainsSubProblemsType,
  WaterLeakSubProblemsType,
} from '../TellUsMore/interfaces';

export interface IProblemProps {
  customer: GetCustomerByIdDetails | null | undefined;
}

export enum GeneralProblemsType {
  BOILER = 'BOILER',
  WATER_LEAKING = 'WATER_LEAKING',
  INTERNAL_DRAINS = 'INTERNAL_DRAINS',
  EXTERNAL_DRAINS = 'EXTERNAL_DRAINS',
  ELECTRICS = 'ELECTRICS',
  TAPS_AND_TOILETS = 'TAPS_AND_TOILETS',
  SOMETHING_ELSE = 'SOMETHING_ELSE',
}

export interface IGeneralProblem {
  icon: string;
  title: string;
  coveredBySections?: Array<number>;
  key: GeneralProblemsType;
  excessAmount: number;
  emailLabel?: string;
  subProblem?:
    | BoilerSubProblemsType
    | WaterLeakSubProblemsType
    | ElectricsSubProblemsType
    | InternalDrainsSubProblemsType
    | ExternalDrainsSubProblemsType;
}

export interface IGeneralProblemsListProps {
  problems: Array<IGeneralProblem>;
  onProblemClick: (key: GeneralProblemsType) => () => void;
}

export interface IGeneralProblemsFormInputs {
  generalProblem: GeneralProblemsType;
  additionalInformation?: string;
}

export interface IUseProblemData {
  customer?: GetCustomerByIdDetails;
  handleNextClick: () => void;
  formattedGeneralProblems: Array<IGeneralProblem>;
  title: string;
  activeContract: ContractsDetails | null | undefined;
  isCustomerHasExcess: boolean;
  isValid: boolean;
  errors: {
    generalProblem?: FieldError | undefined;
    additionalInformation?: FieldError | undefined;
  };
  handleProblemClick: (key: GeneralProblemsType) => () => void;
  isGiveUsCallModal: boolean;
  isLoading: boolean;
  isSomethingElse: boolean;
  setIsGiveUsCallModal: (value: boolean) => void;
  isWaterLeakingModalOpen: boolean;
  setIsWaterLeakingModalOpen: (value: boolean) => void;
  leakVisibleValue: string | undefined;
  setLeakVisibleValue: (value: string | undefined) => void;
  handlePhoneNumberClick: (isFromWaterLeakingModal?: boolean) => void;
  handleWaterLeakRadioClick: (value: string | undefined) => void;
}
