import { TextLink } from '@ovotech/element';
import React, { FC } from 'react';

import { useBrand } from '../../hooks/useBrand';

import { PROBLEM_MODALS_EVENTS } from '../../modules/Problem/constants';
import { useMixpanel } from '../../modules/UserAnalytics/hooks';

interface ICustomerServicePhoneProps {
  mixpanelEventName?: string;
}
const CustomerServicePhone: FC<ICustomerServicePhoneProps> = ({
  mixpanelEventName,
}) => {
  const mixpanel = useMixpanel();
  const { brand } = useBrand();

  const handlePhoneNumberClick = () => {
    if (mixpanelEventName) {
      mixpanel?.track(mixpanelEventName);
      return;
    }
    mixpanel?.track(PROBLEM_MODALS_EVENTS.MODAL_PHONE_NUMBER_CLICK_EVENT);
  };

  return (
    <TextLink
      data-testid="brand-phone-number"
      onClick={handlePhoneNumberClick}
      href={`tel:${brand?.phone}`}
    >
      {brand?.phone}
    </TextLink>
  );
};

export { CustomerServicePhone };
