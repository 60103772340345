import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import useAuroraBilling from '@client/hooks/useAuroraBilling';

import { useMixpanel } from '../UserAnalytics/hooks';

import { ProfileMenuList } from './ProfileMenuList';

import { ProfileCardLayout } from './ProfileCardLayout';

import { ProfileCalloutCTA } from './ProfileCalloutCTA';

import {
  PROFILE_MIXPANEL_EVENTS,
  PROFILE_MIXPANEL_EVENT_PROPERTIES,
  PROFILE_PATHNAME,
} from './constants';

const ProfileSideNav: React.FC<{ isAbsOnly?: boolean }> = ({ isAbsOnly }) => {
  const { isAuroraBilling } = useAuroraBilling();
  const [hasAuroraBilling, setHasAuroraBilling] = useState(true);
  const mixpanel = useMixpanel();
  const router = useRouter();

  useEffect(() => {
    if (sessionStorage.getItem('hasAuroraBilling') === 'true') {
      setHasAuroraBilling(true);
    } else {
      sessionStorage.setItem('hasAuroraBilling', isAuroraBilling.toString());
      setHasAuroraBilling(isAuroraBilling);
    }
  }, [isAuroraBilling, hasAuroraBilling, setHasAuroraBilling]);

  const handleMenuLinkClick = useCallback(
    (menuLinkSelected: string) => () => {
      mixpanel?.track(PROFILE_MIXPANEL_EVENTS.MENU_SELECT, {
        menuSelection: menuLinkSelected,
        pageName: router.pathname,
      });
    },
    [mixpanel, router.pathname],
  );

  const profileSideNavLinks = useMemo(() => {
    const profileDefaultSideNavLinks = [
      {
        href: PROFILE_PATHNAME.PROFILE,
        name: 'Profile',
        order: 1,
        onClick: handleMenuLinkClick(
          PROFILE_MIXPANEL_EVENT_PROPERTIES.PROFILE_LINK,
        ),
      },
      {
        href: PROFILE_PATHNAME.PLAN,
        name: 'Plan',
        order: 2,
        onClick: handleMenuLinkClick(
          PROFILE_MIXPANEL_EVENT_PROPERTIES.PLAN_LINK,
        ),
      },
    ];

    const profileAuroraBillingSideNavLinks = [
      {
        href: PROFILE_PATHNAME.PAYMENT_DETAILS,
        name: 'Payment details',
        order: 3,
        onClick: handleMenuLinkClick(
          PROFILE_MIXPANEL_EVENT_PROPERTIES.PAYMENT_DETAILS_LINK,
        ),
      },
      {
        href: PROFILE_PATHNAME.BILLING_HISTORY,
        name: 'Billing history',
        order: 4,
        onClick: handleMenuLinkClick(
          PROFILE_MIXPANEL_EVENT_PROPERTIES.BILLING_HISTORY_LINK,
        ),
      },
    ];

    return hasAuroraBilling
      ? [...profileDefaultSideNavLinks, ...profileAuroraBillingSideNavLinks]
      : profileDefaultSideNavLinks;
  }, [hasAuroraBilling, handleMenuLinkClick]);

  return (
    <div>
      <ProfileCardLayout className="py-12 px-10">
        <ProfileMenuList
          data-test-id="mobile-navbar-top-content"
          links={profileSideNavLinks}
          handleMenuLinkClick={handleMenuLinkClick}
        />
      </ProfileCardLayout>
      <ProfileCalloutCTA isAbsOnly={isAbsOnly} />
    </div>
  );
};

export { ProfileSideNav };
