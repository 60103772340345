import React from 'react';

const AutomationIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_5541_206314)">
      <path
        opacity="0.3"
        d="M24 4.6001C19.2 4.6001 14.6 6.3001 11 9.4001V6.6001H7V16.6001H17V12.6001H13.4C16.3 10.0001 20 8.6001 24 8.6001C32.8 8.6001 40 15.8001 40 24.6001C40 33.4001 32.8 40.6001 24 40.6001C15.2 40.6001 8 33.4001 8 24.6001H4C4 35.6001 13 44.6001 24 44.6001C35 44.6001 44 35.6001 44 24.6001C44 13.6001 35 4.6001 24 4.6001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1 29.5V19.7L24 15L15.9 19.7V29.5L24 34.1L32.1 29.5ZM20 24.6C20 22.4 21.8 20.6 24 20.6C26.2 20.6 28 22.4 28 24.6C28 26.8 26.2 28.6 24 28.6C21.8 28.6 20 26.8 20 24.6Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5541_206314">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="matrix(-1 0 0 1 48 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { AutomationIcon };
