import React from 'react';

import { Logo } from '../Logo';

import MobileMenu from '../MobileMenu';

import { sortLinksByOrder } from '../constant';

import TopNavbarLink from './TopNavbarLink';
import { TopNavbarContainer } from './TopNavbarContainer';
import { TopNavbarContent } from './TopNavbarContent';
import { SmallTopNavbarSection } from './SmallTopNavbarSection';
import { TopNavbarSection } from './TopNavbarSection';
import { useTopNavbar } from './hooks/useTopNavbar';

export const TopNavbar: React.FC = () => {
  const { topNavLinks, mobileMenuLinks } = useTopNavbar();

  const orderedTopNavLinks = sortLinksByOrder(topNavLinks);

  const orderedMobileMenuLinks = sortLinksByOrder(mobileMenuLinks);

  const topNavbarLinksComponents = orderedTopNavLinks.map((props, idx) => {
    return <TopNavbarLink key={`top-navbar-link-${idx}`} {...props} />;
  });

  return (
    <TopNavbarContainer data-testid="top-navbar">
      <TopNavbarContent>
        <SmallTopNavbarSection>
          <MobileMenu links={orderedMobileMenuLinks} />
          <Logo />
        </SmallTopNavbarSection>
        <TopNavbarSection as="nav" aria-label="site">
          <Logo />
        </TopNavbarSection>
        <TopNavbarSection displayMode="block">
          {topNavbarLinksComponents}
        </TopNavbarSection>
      </TopNavbarContent>
    </TopNavbarContainer>
  );
};
