import { ParsedUrlQuery } from 'querystring';

import { Dictionary } from '@reduxjs/toolkit';

const commonRoutesPath = {
  LOGIN: '/login',
  WELCOME: '/welcome',
  PROFILE: '/profile',
  PROBLEM: '/problem',
  TELL_US_MORE: '/tell-us-more',
  ENGINEER_ACCESS: '/engineer-access',
  ADDITIONAL_NEEDS: '/additional-needs',
  CONTACT_INFORMATION: '/contact-information',
  PROVIDED_INFORMATION: '/provided-information',
  SUMMARY: '/summary',
  CONTACT_US: '/contactUs',
  MORE_DETAILS_NEEDED: '/login/more-details-needed',
  EMAIL_HINT: '/login/email-hint',
  UPDATE_EMAIL: '/login/reset-email',
  EMAIL_SENT: '/login/email-sent',
  PAYMENT_DETAILS: '/profile/payment-details',
  COMMUNICATION: '/profile/plan/communication',
  PLAN: '/profile/plan',
  UPDATE_PHONE_NUMBER: '/profile/edit/update-phone-number',
  UPDATE_PAYMENT_DAY: '/profile/edit/update-payment-day',
  EDIT_BANK_DETAILS: '/profile/payment-details/edit-bank-details',
  RENEW_PLAN: '/profile/renew-plan',
  REVIEW_RENEWAL: '/profile/review-renewal',
  FIND_A_PLAN: '/profile/find-a-plan',
  RENEWAL_CONFIRMATION: '/profile/renewal-confirmation',
  CANCEL_PLAN: '/profile/cancel-plan',
  BEFORE_LEAVING: '/profile/before-leaving',
  FIND_RENEWAL_DETAILS: '/profile/find-renewal-details',
  SAD_TO_SEE_YOU_GO: '/profile/sad-to-see-you-go',
  RENEWAL_PREFERENCES: '/profile/renewal-preferences',
};

type CommonRoutesGetterType = Record<
  | 'getLoginRoute'
  | `getWelcomeRoute`
  | `getProfileRoute`
  | 'getProblemRoute'
  | 'getTellUsMoreRoute'
  | 'getEngineerAccessRoute'
  | 'getAdditionalNeedsRoute'
  | 'getContactInformationRoute'
  | 'getProvidedInformationRoute'
  | 'getSummaryRoute'
  | 'getContactUsRoute'
  | 'getEmailSent'
  | 'getMoreDetailsNeededRoute'
  | 'getEmailHintRoute'
  | 'getResetEmailRoute'
  | 'getPaymentDetailsRoute'
  | 'getPlanRoute'
  | 'getCommunicationRoute'
  | 'getUpdatePhoneNumberRoute'
  | 'getUpdatePaymentDayRoute'
  | 'getEditBankDetailsRoute'
  | 'getCancelPlanRoute'
  | 'getReviewRenewalRoute'
  | 'getRenewPlanRoute'
  | 'getFindAPlanRoute'
  | 'getBeforeLeavingRoute'
  | 'getFindRenewalDetailsRoute'
  | 'getRenewalConfirmationRoute'
  | 'getSadToSeeYouGoRoute'
  | 'getRenewalPreferencesRoute',
  (params?: RouteParams) => string
>;

type RouteParams = Dictionary<string | string[]>;

const appendParamsToQueryString = (params?: ParsedUrlQuery) => {
  if (!params || !Object.keys(params).length) return '';

  let qs = '';
  for (const [key, value] of Object.entries(params)) {
    qs += `&${key}=${value}`;
  }

  // adding '?' at the beginning and trimming the first '&' symbol
  return '?' + qs.substr(1);
};

export const AllRoutes: string[] = [
  commonRoutesPath.WELCOME,
  commonRoutesPath.PROFILE,
  commonRoutesPath.PROBLEM,
  commonRoutesPath.TELL_US_MORE,
  commonRoutesPath.ENGINEER_ACCESS,
  commonRoutesPath.ADDITIONAL_NEEDS,
  commonRoutesPath.CONTACT_INFORMATION,
  commonRoutesPath.PROVIDED_INFORMATION,
  commonRoutesPath.SUMMARY,
  commonRoutesPath.EMAIL_HINT,
  commonRoutesPath.PAYMENT_DETAILS,
  commonRoutesPath.COMMUNICATION,
  commonRoutesPath.PLAN,
  commonRoutesPath.UPDATE_PHONE_NUMBER,
  commonRoutesPath.UPDATE_PAYMENT_DAY,
  commonRoutesPath.EDIT_BANK_DETAILS,
  commonRoutesPath.RENEW_PLAN,
  commonRoutesPath.REVIEW_RENEWAL,
  commonRoutesPath.FIND_A_PLAN,
  commonRoutesPath.RENEWAL_CONFIRMATION,
  commonRoutesPath.CANCEL_PLAN,
  commonRoutesPath.BEFORE_LEAVING,
  commonRoutesPath.FIND_RENEWAL_DETAILS,
  commonRoutesPath.SAD_TO_SEE_YOU_GO,
  commonRoutesPath.RENEWAL_PREFERENCES,
];

export const CommonRoutes: CommonRoutesGetterType = {
  getLoginRoute: (params) => {
    return `${commonRoutesPath.LOGIN}${appendParamsToQueryString(params)}`;
  },
  getWelcomeRoute: () => commonRoutesPath.WELCOME,
  getProfileRoute: () => commonRoutesPath.PROFILE,
  getProblemRoute: () => commonRoutesPath.PROBLEM,
  getTellUsMoreRoute: () => commonRoutesPath.TELL_US_MORE,
  getEngineerAccessRoute: () => commonRoutesPath.ENGINEER_ACCESS,
  getAdditionalNeedsRoute: () => commonRoutesPath.ADDITIONAL_NEEDS,
  getContactInformationRoute: () => commonRoutesPath.CONTACT_INFORMATION,
  getProvidedInformationRoute: () => commonRoutesPath.PROVIDED_INFORMATION,
  getSummaryRoute: () => commonRoutesPath.SUMMARY,
  getContactUsRoute: (params) =>
    `${commonRoutesPath.CONTACT_US}${appendParamsToQueryString(params)}`,
  getEmailSent: (params) => {
    return `${commonRoutesPath.EMAIL_SENT}${appendParamsToQueryString(params)}`;
  },
  getMoreDetailsNeededRoute: () => commonRoutesPath.MORE_DETAILS_NEEDED,
  getEmailHintRoute: () => commonRoutesPath.EMAIL_HINT,
  getResetEmailRoute: () => commonRoutesPath.UPDATE_EMAIL,
  getPaymentDetailsRoute: () => commonRoutesPath.PAYMENT_DETAILS,
  getCommunicationRoute: () => commonRoutesPath.COMMUNICATION,
  getPlanRoute: () => commonRoutesPath.PLAN,
  getUpdatePhoneNumberRoute: () => commonRoutesPath.UPDATE_PHONE_NUMBER,
  getUpdatePaymentDayRoute: () => commonRoutesPath.UPDATE_PAYMENT_DAY,
  getEditBankDetailsRoute: () => commonRoutesPath.EDIT_BANK_DETAILS,
  getRenewPlanRoute: () => commonRoutesPath.RENEW_PLAN,
  getCancelPlanRoute: () => commonRoutesPath.CANCEL_PLAN,
  getReviewRenewalRoute: () => commonRoutesPath.REVIEW_RENEWAL,
  getFindAPlanRoute: () => commonRoutesPath.FIND_A_PLAN,
  getRenewalConfirmationRoute: () => commonRoutesPath.RENEWAL_CONFIRMATION,
  getBeforeLeavingRoute: (params) =>
    `${commonRoutesPath.BEFORE_LEAVING}/${params?.discountCode}`,
  getFindRenewalDetailsRoute: (params) =>
    `${commonRoutesPath.FIND_RENEWAL_DETAILS}/${params?.discountCode}`,
  getSadToSeeYouGoRoute: () => commonRoutesPath.SAD_TO_SEE_YOU_GO,
  getRenewalPreferencesRoute: () => commonRoutesPath.RENEWAL_PREFERENCES,
};
