import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query';

import { selfServeApi } from '../services/self-serve';

import auth from './auth';
import common from './common';
import profile from './profile';

export const store = configureStore({
  reducer: {
    [selfServeApi.reducerPath]: selfServeApi.reducer,
    auth,
    common,
    profile,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      selfServeApi.middleware,
    ),
  devTools: true,
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
