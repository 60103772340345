import { createSlice } from '@reduxjs/toolkit';
import React from 'react';

interface CommonState {
  isLoading: boolean;
  errors: Array<{
    key: string;
    title: string;
    message: React.ReactNode;
  }>;
}

const commonInitialState = {
  isLoading: false,
  errors: [],
} as CommonState;

const commonSlice = createSlice({
  name: 'common',
  initialState: commonInitialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setErrors: (state, { payload }) => {
      console.log(payload, 'payload');
      state.errors = payload;
    },
  },
});

export const { setIsLoading, setErrors } = commonSlice.actions;
export default commonSlice.reducer;
