import React from 'react';

const AddressIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M18 0L0 12V36H36V12L18 0ZM13.8 32L10.8 20.6L22.2 17.6L25.2 29L13.8 32Z"
      fill="currentColor"
    />
  </svg>
);

export { AddressIcon };
