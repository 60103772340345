import React from 'react';

import {
  StyledLinkTag,
  StyledTextLink,
} from '../../components/Navbar/TopNavbar/styles';
import { StyledMenuListItem } from '../../components/Navbar/MobileMenu/styles';

import { IProfileMenuLinkProps } from './interfaces';

export const ProfileMenuItem: React.FC<IProfileMenuLinkProps> = (props) => {
  const { name } = props;
  return (
    <StyledMenuListItem data-testid={`profile-menu-item-${name}`}>
      <StyledTextLink {...props}>
        <StyledLinkTag>{name}</StyledLinkTag>
      </StyledTextLink>
    </StyledMenuListItem>
  );
};
