import { ITopNavbarSectionProps } from '../interfaces';

import { StyledSmallTopNavbarSection } from './styles';

export const SmallTopNavbarSection: React.FC<ITopNavbarSectionProps> = ({
  children,
  ...rest
}) => {
  return (
    <StyledSmallTopNavbarSection {...rest}>
      {children}
    </StyledSmallTopNavbarSection>
  );
};
