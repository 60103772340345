import {
  IMobileMenuLinkProps,
  ITopNavbarLinkProps,
  RouteParams,
} from './interfaces';

export const SetRouteParams = (link: string, params: RouteParams): string => {
  Object.entries(params).forEach(([key, value]) => {
    if (Object.prototype.hasOwnProperty.call(params, key) && value) {
      link = link.replace(
        `[${key}]`,
        Array.isArray(value) ? value.join(',') : value,
      );
    }
  });
  return link;
};

export const sortLinksByOrder = (
  links: ITopNavbarLinkProps[] | IMobileMenuLinkProps[],
) => links.sort((a, b) => a.order - b.order);

export const filterLinksByPosition = (links: IMobileMenuLinkProps[]) => {
  return links.reduce(
    (acc, link) => {
      const isTopLink = !link.isBottom;
      return isTopLink
        ? { ...acc, topLinks: [...acc.topLinks, link] }
        : { ...acc, bottomLinks: [...acc.bottomLinks, link] };
    },
    { topLinks: [], bottomLinks: [] } as {
      topLinks: IMobileMenuLinkProps[];
      bottomLinks: IMobileMenuLinkProps[];
    },
  );
};
