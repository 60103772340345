import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';

export type DataDogUser = {
  customerId: string;
  email: string;
};

export interface DataDogRum {
  addError: (errorMessage: string, data?: Record<string, unknown>) => void;
  addAction: (actionName: string, data?: Record<string, unknown>) => void;
  setUser: (user: Partial<DataDogUser>) => void;
}

export const useDataDog = (): DataDogRum => {
  const handleAddError = useCallback(
    (errorMessage: string, data?: Record<string, unknown>) => {
      datadogRum.addError(new Error(errorMessage), data);
    },
    [],
  );

  const handleAddAction = useCallback(
    (actionName: string, data?: Record<string, unknown>) => {
      datadogRum.addAction(actionName, data);
    },
    [],
  );

  const handleSetUser = useCallback((user: Partial<DataDogUser>) => {
    datadogRum.setUser(user);
  }, []);

  return {
    addError: handleAddError,
    addAction: handleAddAction,
    setUser: handleSetUser,
  };
};
