import React from 'react';

const AccountDetailsIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="32"
    height="37"
    viewBox="0 0 32 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      opacity="0.3"
      d="M32 11.9004H0V36.9004H32V11.9004Z"
      fill="currentColor"
    />
    <path
      d="M27.9001 11.9H23.9001C23.9001 7.6 20.4001 4 16.0001 4C11.6001 4 8.1001 7.5 8.1001 11.9H4.1001C4.1001 5.4 9.4001 0 16.0001 0C22.6001 0 27.9001 5.4 27.9001 11.9Z"
      fill="currentColor"
    />
    <path
      d="M19.4001 22.3004C19.4001 20.4004 17.9001 18.9004 16.0001 18.9004C14.1001 18.9004 12.6001 20.4004 12.6001 22.3004C12.6001 23.4004 13.1001 24.4004 14.0001 25.0004V30.4004H18.0001V25.0004C18.8001 24.3004 19.4001 23.4004 19.4001 22.3004Z"
      fill="currentColor"
    />
  </svg>
);

export { AccountDetailsIcon };
