import { Heading2 } from '@ovotech/element';

import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'OVOCircular',Futura,'Century Gothic',sans-serif;
    height: 100%;
    background-color: #F9F8F6;
    color: #444444;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  * {
    box-sizing: border-box;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

export const StyledCenterHeading1 = styled(Heading2)`
  text-align: center;
`;
export const StyledCenterHeading2 = styled(Heading2)`
  text-align: center;
`;

export const StyledCenterImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;
