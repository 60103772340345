import { createSlice } from '@reduxjs/toolkit';

interface profileState {
  isBankDetailsAmended: boolean;
  isContractPreferencesAmended: boolean;
  isDirectDebitAmended: boolean;
  isPhoneNumberAmended: boolean;
}

const profileInitialState: profileState = {
  isBankDetailsAmended: false,
  isContractPreferencesAmended: false,
  isPhoneNumberAmended: false,
  isDirectDebitAmended: false,
} as profileState;

const profileSlice = createSlice({
  name: 'profile',
  initialState: profileInitialState,
  reducers: {
    setIsBankDetailsAmended: (state, { payload }) => {
      state.isBankDetailsAmended = payload;
    },
    setIsContractPreferencesAmended: (state, { payload }) => {
      state.isContractPreferencesAmended = payload;
    },
    setIsDirectDebitAmended: (state, { payload }) => {
      state.isDirectDebitAmended = payload;
    },
    setIsPhoneNumberAmended: (state, { payload }) => {
      state.isPhoneNumberAmended = payload;
    },
  },
});

export const {
  setIsBankDetailsAmended,
  setIsContractPreferencesAmended,
  setIsDirectDebitAmended,
  setIsPhoneNumberAmended,
} = profileSlice.actions;

export default profileSlice.reducer;
