import { PaymentScheduleType } from '@client/types/__generated__/globalTypes';

import { brandInfo } from '@client/hooks/useBrand';

import { ProductIds } from '@client/modules/Profile/constants';

import { Brand } from './interfaces';

export const SELECTED_RENEWAL_PRODUCT_STORAGE_KEY = 'selectedRenewalProduct';

export const BRANDS: Brand[] = [
  {
    id: 1,
    longName: brandInfo.CORGI.brand.name,
    shortName: 'CORGI',
  },
  {
    id: 3,
    longName: brandInfo.OVO.brand.name,
    shortName: 'OVO',
  },
  {
    id: 4,
    longName: brandInfo.OVOEnergy.brand.name,
    shortName: 'OVO',
  },
];

export const PAYMENT_SCHEDULE = [
  PaymentScheduleType.InLife,
  PaymentScheduleType.NotDefined,
  PaymentScheduleType.Arrears,
  null,
];

export const ProductRanking = {
  [ProductIds.Essentials]: 0,
  [ProductIds.Starter]: 1,
  [ProductIds.Advanced]: 2,
  [ProductIds.Complete]: 3,
} as const;

export enum PaymentFrequency {
  Annual = 'Annual',
  Monthly = 'Monthly',
}

export enum RadioGuestHouseValues {
  yes = 'Yes',
  no = 'No',
}

export enum RadioBoiler {
  yes = 'Yes',
  no = 'No',
}
