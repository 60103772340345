import React from 'react';

const WarningIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.9999 0.5L0.333252 21.5H23.6666L11.9999 0.5ZM13.4583 19.8083H10.5416V17.65H13.4583V19.8083ZM10.5416 15.4333V14.7333V8.2H13.4583V15.4917H10.5416V15.4333Z"
      fill="#D50C3C"
    />
  </svg>
);

export { WarningIcon };
