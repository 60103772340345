import { TextLink } from '@ovotech/element';
import styled, { css } from 'styled-components';

import { NavbarLink } from '../NavbarLink';

import { ITopNavbarSectionProps } from '../interfaces';

export const StyledLogoLink = styled(TextLink)(
  ({ theme: { semantic } }) => css`
    &:focus {
      outline: ${semantic.focus.outline};
      overflow: hidden;
    }
    height: 4.8rem;
    display: flex;
    svg {
      height: 4.8rem;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
);

export const StyledSmallTopNavbarSection = styled.div<ITopNavbarSectionProps>(
  ({ theme: { core } }, displayMode = 'flex') => css`
    display: ${displayMode};
    width: 100%;
    justify-content: center;
    ${core.mediaQuery.medium} {
      display: none;
    }
  `,
);

export const StyledTopNavbarContainer = styled.div(
  ({ theme: { semantic, core } }) => css`
    position: fixed;
    height: 78px;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 100;
    display: flex;
    background: ${semantic.surface.elevated};
    border-bottom: 1px solid ${semantic.border.functional};
    padding: ${core.space[4]};
    justify-content: center;
    align-items: center;
    ${core.mediaQuery.medium} {
      padding: ${core.space[6]};
    }
  `,
);

export const StyledTopNavbarContent = styled.div(
  ({ theme: { core } }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    ${core.mediaQuery.medium} {
      padding: ${core.space[8]};
    }
  `,
);

export const StyledTopNavbarSection = styled.div<ITopNavbarSectionProps>(
  ({ theme: { core }, displayMode = 'flex' }) => css`
    display: none;
    justify-items: center;
    align-items: center;
    ${core.mediaQuery.medium} {
      display: ${displayMode};
    }
    > * + * {
      margin-left: ${core.space[8]};
    }
  `,
);

export const StyledLinkTag = styled.a`
  cursor: pointer;
`;

export const StyledTextLink = styled(NavbarLink)(
  ({ theme: { core } }) => css`
    color: ${core.color.brand.ovo};
    width: 120px;
    text-align: left;
    cursor: pointer;
    &.active {
      text-decoration-line: underline;
      cursor: unset;
      pointer-events: none;
    }
  `,
);
