import React from 'react';

import { Loading } from '../../components';

import { useAuth } from './hooks';

const Auth: React.FC = ({ children }) => {
  const { isAuthLoading } = useAuth();

  return (
    <>
      {children}
      {isAuthLoading && <Loading />}
    </>
  );
};

export { Auth };
