import React from 'react';

import mixpanel from 'mixpanel-browser';

import { useAppSelector } from '../../hooks/useAppSelector';
import { selectCurrentUser } from '../../redux/auth/selectors';

import { MixPanelContext } from './constants';

const MixpanelProvider: React.FC = ({ children }) => {
  const currentUser = useAppSelector(selectCurrentUser);

  mixpanel.identify(currentUser?.getUsername());

  return (
    <MixPanelContext.Provider value={mixpanel}>
      {children}
    </MixPanelContext.Provider>
  );
};

export default MixpanelProvider;
