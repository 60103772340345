import { OptimizelyDecision, useDecision } from '@optimizely/react-sdk';

type FeatureFlag =
  | 'allow-additional-problems'
  | 'enable-account-dashboard'
  | 'tell-us-more-things-not-covered-position'
  | 'claims-provided-information-cta'
  | 'claims-provided-information-copy'
  | 'allow-renewals'
  | 'renewal-discount';

const useOptimizely = (
  featureFlag: FeatureFlag,
  autoUpdate: boolean,
): OptimizelyDecision => {
  const [featureFlagDecision] = useDecision(featureFlag, { autoUpdate });

  return featureFlagDecision;
};

export { useOptimizely };
