import React from 'react';
import { AppProps } from 'next/app';

import Application from '../client/modules/Application';
import { initAmplify } from '../client/utils';

import '../client/styles/themes/font.css';
import '../global.css';

initAmplify();

const App: React.FC<AppProps> = ({ Component, pageProps, router }) => {
  return (
    <Application Component={Component} pageProps={pageProps} router={router} />
  );
};

export default App;
