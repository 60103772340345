import {
  BRANDS,
  ProductRanking,
} from '@client/modules/Profile/FindAPlan/constants';
import { Brand } from '@client/modules/Profile/FindAPlan/interfaces';
import {
  CustomerType,
  MtaQuote,
  OwnershipType,
  ProductVersion,
} from '@client/types/__generated__/globalTypes';

export const getBrandByProperty = (
  propertyName: keyof Brand,
  value: string | number | undefined,
): Brand | undefined =>
  BRANDS.find((brand) => brand[propertyName as keyof Brand] === value);

export const getBrandNameById = (
  brandId: number | undefined,
  isLong = false,
): string => {
  const nameProperty = isLong ? 'longName' : 'shortName';
  return getBrandByProperty('id', brandId)?.[nameProperty] || 'Unknown';
};

export const getCustomerType = (
  ownershipType: OwnershipType | undefined,
): CustomerType => {
  let customerType;
  switch (ownershipType) {
    case OwnershipType.Landlord:
      customerType = CustomerType.LANDLORD;
      break;
    default:
      customerType = CustomerType.HOMEOWNER;
      break;
  }
  return customerType;
};

export const getValueByKeyForNumberRanking = (
  value: string | null | undefined,
) => {
  return Object.entries(ProductRanking).find(([key]) => key === value)?.[1];
};

export const getPlanCardCtaButtonText = (
  selectedPlan: MtaQuote | ProductVersion,
  product: MtaQuote | ProductVersion,
  isSelectedPlan: boolean,
) => {
  const selectedPlanRankingValue =
    getValueByKeyForNumberRanking(
      selectedPlan?.productId || selectedPlan?.product?.id,
    ) ?? 0;

  const productRankingValue =
    getValueByKeyForNumberRanking(product?.productId || product?.product?.id) ??
    0;

  return isSelectedPlan
    ? 'Renew'
    : selectedPlanRankingValue < productRankingValue
    ? 'Upgrade'
    : 'Switch';
};
