import React from 'react';

const CorgiShield = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="38"
    height="41"
    viewBox="0 0 38 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_1553_211041)">
      <path
        d="M18.9951 0C25.1221 0.0315117 31.1871 1.21395 36.8661 3.48415C37.1205 3.56136 37.3451 3.71331 37.5097 3.91973C37.6744 4.12615 37.7714 4.37722 37.7878 4.63946C38.5931 11.5021 37.4807 18.4513 34.5712 24.7346C31.4274 31.4791 26.2349 37.0907 19.714 40.7908C19.507 40.9284 19.2631 41.002 19.0136 41.002C18.764 41.002 18.5201 40.9284 18.3131 40.7908C14.0647 38.3812 10.3557 35.1459 7.41068 31.2806C4.46567 27.4153 2.34584 23.0004 1.17947 18.3031C0.143282 14.2486 -0.208599 10.0532 0.137995 5.88573C0.137995 5.42179 0.220948 4.97603 0.285464 4.48479C0.313439 4.27886 0.398962 4.08468 0.532445 3.92401C0.665928 3.76334 0.842077 3.64252 1.0412 3.5751C4.47212 2.15162 8.06513 1.14363 11.7417 0.573113C12.9398 0.391174 14.1472 0.254727 15.3546 0.154661C16.5619 0.054594 17.7785 0.0545818 18.9951 0ZM18.9951 2.37432C17.5113 2.47439 16.0274 2.52895 14.5435 2.6654C10.6548 3.04036 6.83176 3.91186 3.17026 5.25804C2.73708 5.42179 2.46978 5.60372 2.54351 6.10405C2.55213 6.22823 2.55213 6.35285 2.54351 6.47703C2.31175 9.85779 2.56305 13.2539 3.29005 16.5656C4.23022 21.042 6.09429 25.2794 8.76614 29.0137C11.438 32.748 14.8604 35.8992 18.82 38.2709C18.8825 38.3212 18.9606 38.3486 19.0412 38.3486C19.1218 38.3486 19.1999 38.3212 19.2624 38.2709C25.9668 34.2415 31.0407 28.0338 33.6127 20.7138C35.2892 15.9097 35.9174 10.8099 35.456 5.74929C35.456 5.52186 35.3085 5.44908 35.1242 5.37631C29.9682 3.45061 24.5077 2.44044 18.9951 2.39251V2.37432Z"
        fill="#EE6F2E"
      />
      <path
        d="M11.9908 18.6671H9.78809C9.66587 18.7166 9.52874 18.7161 9.40688 18.6658C9.28503 18.6154 9.18841 18.5194 9.1383 18.3988C9.08819 18.2781 9.08869 18.1428 9.13968 18.0225C9.19067 17.9022 9.28798 17.8069 9.4102 17.7574C11.2535 15.938 17.3365 9.95222 18.5162 8.76052C18.6438 8.65047 18.8076 8.58984 18.977 8.58984C19.1465 8.58984 19.3102 8.65047 19.4379 8.76052C22.4732 11.7928 25.5116 14.7948 28.553 17.7665C28.6386 17.8323 28.6996 17.9244 28.7263 18.0281C28.753 18.1319 28.7439 18.2415 28.7005 18.3396C28.658 18.4373 28.585 18.5191 28.4922 18.573C28.3994 18.627 28.2916 18.6504 28.1844 18.6398H25.9908V26.5269C26.0046 26.6152 25.9973 26.7055 25.9695 26.7906C25.9416 26.8756 25.894 26.9531 25.8304 27.0167C25.7668 27.0804 25.6891 27.1284 25.6033 27.1571C25.5175 27.1858 25.4261 27.1943 25.3365 27.1819H21.6498C21.505 27.1944 21.3592 27.1754 21.2226 27.1263C21.0861 27.0773 20.962 26.9993 20.8592 26.8978C20.7565 26.7964 20.6774 26.674 20.6277 26.5392C20.578 26.4043 20.5588 26.2604 20.5715 26.1175V20.4046H17.2535V20.5866C17.2535 22.406 17.2535 24.2799 17.2535 26.1266C17.2601 26.2693 17.2364 26.4118 17.1841 26.5449C17.1317 26.6781 17.0519 26.799 16.9495 26.9C16.8472 27.001 16.7247 27.0799 16.5898 27.1315C16.4548 27.1832 16.3105 27.2065 16.1659 27.2001C14.6729 27.2001 14.0553 27.2001 12.5623 27.2001C12.4719 27.214 12.3795 27.2068 12.2925 27.1788C12.2055 27.1508 12.1264 27.103 12.0617 27.0391C11.9971 26.9753 11.9485 26.8972 11.9202 26.8114C11.8919 26.7255 11.8845 26.6343 11.8987 26.5451V18.658L11.9908 18.6671Z"
        fill="#EE6F2E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1553_211041">
        <rect width="38" height="41" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { CorgiShield };
