import styled, { css } from 'styled-components';

export const FooterContainer = styled.div(
  ({ theme: { semantic } }) => css`
    left: 0;
    bottom: 0;
    width: 100%;
    height: 78px;
    background-color: ${semantic.surface.elevated};
    border-top: 1px solid ${semantic.border.functional};
    margin-top: 14px;
  `,
);
