import React from 'react';

const BoilerIcon = ({ ...rest }: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.3871 101.532V100.032H45.3871V101.532V108.468V109.968H45.394C45.3894 110.016 45.3871 110.064 45.3871 110.113C45.3871 110.941 46.0587 111.613 46.8871 111.613H54.7258C55.5542 111.613 56.2258 110.941 56.2258 110.113C56.2258 109.869 56.1677 109.639 56.0645 109.436V108.468V101.532V100.032H53.0645V101.532V108.468V108.613H48.3871V108.468V101.532ZM70.9677 101.532V100.032H67.9677V101.532V108.468V109.968H67.9747C67.9701 110.016 67.9677 110.064 67.9677 110.113C67.9677 110.941 68.6393 111.613 69.4677 111.613H77.3064C78.1349 111.613 78.8064 110.941 78.8064 110.113C78.8064 109.869 78.7483 109.639 78.6452 109.436V108.468V101.532V100.032H75.6452V101.532V108.468V108.613H70.9677V108.468V101.532Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.8408 0.0806451C47.7368 0.0806451 46.7634 0.804815 46.4462 1.86228L44.3365 8.89453C44.3147 8.96735 44.2963 9.04019 44.2813 9.1129H39.9032C36.8656 9.1129 34.4032 11.5753 34.4032 14.6129V93.2796C34.4032 94.4481 34.7931 95.7976 35.9481 96.6999C38.5321 98.7182 45.5414 102.435 62.0968 102.435C78.6521 102.435 85.6614 98.7182 88.2454 96.6999C89.4004 95.7976 89.7903 94.448 89.7903 93.2796V14.6129C89.7903 11.5753 87.3279 9.1129 84.2903 9.1129H79.9122C79.8972 9.04019 79.8788 8.96735 79.857 8.89454L77.7473 1.86228C77.43 0.804815 76.4567 0.0806451 75.3527 0.0806451H48.8408ZM76.7904 9.1129L74.9807 3.08065H49.2128L47.4031 9.1129H76.7904ZM46.7311 12.1129H39.9032C38.5225 12.1129 37.4032 13.2322 37.4032 14.6129V93.2796C37.4032 93.842 37.5858 94.1723 37.7949 94.3356C39.6862 95.8129 45.9655 99.4355 62.0968 99.4355C78.228 99.4355 84.5073 95.8129 86.3986 94.3356C86.6077 94.1723 86.7903 93.842 86.7903 93.2796V14.6129C86.7903 13.2322 85.671 12.1129 84.2903 12.1129H77.4624H46.7311Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2581 0.0806451C24.0865 0.0806451 24.7581 0.752218 24.7581 1.58065L24.7581 82.7258H48.4032V79.9032C48.4032 77.9702 49.9702 76.4032 51.9032 76.4032H58.7419C60.6749 76.4032 62.2419 77.9702 62.2419 79.9032V87.6452C62.2419 89.5782 60.6749 91.1452 58.7419 91.1452H51.9032C49.9702 91.1452 48.4032 89.5782 48.4032 87.6452V85.7258H23.2581C22.4296 85.7258 21.7581 85.0542 21.7581 84.2258L21.7581 1.58065C21.7581 0.752218 22.4296 0.0806451 23.2581 0.0806451ZM51.9032 79.4032C51.6271 79.4032 51.4032 79.6271 51.4032 79.9032V87.6452C51.4032 87.9213 51.6271 88.1452 51.9032 88.1452H58.7419C59.0181 88.1452 59.2419 87.9213 59.2419 87.6452V79.9032C59.2419 79.6271 59.0181 79.4032 58.7419 79.4032H51.9032Z"
      fill="currentColor"
    />
    <rect
      x="46.2903"
      y="21.9032"
      width="31.6129"
      height="15.3548"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
    />
  </svg>
);

export { BoilerIcon };
