import { Icon } from '@ovotech/element';
import React from 'react';

import { HeaderContainer } from './styles';

const Header = () => {
  return (
    <HeaderContainer data-testid="top-header">
      <Icon name={'logo'} width={132} height={52} />
    </HeaderContainer>
  );
};

export { Header };
