import { Hub } from 'aws-amplify';
import { useEffect } from 'react';

import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectIsAuthLoading } from '../../../redux/auth/selectors';

import { getCurrentAuthenticatedUser } from '../../../redux/auth/thunks';

import { useAppDispatch } from '../../../hooks/useAppDispatch';

interface AuthHookReturnData {
  isAuthLoading: boolean;
}

type HubPayload = {
  event: string;
  data?: unknown;
  message?: string;
};

type HubCapsule = {
  channel: string;
  payload: HubPayload;
  source: string;
  patternInfo?: string[];
};

const useAuth = (): AuthHookReturnData => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const updateUser = async (data: HubCapsule | null) => {
      if (data) {
        console.log('A new auth event has happened: ', data.payload?.event);
        console.dir(data);
      }

      if (!data || data?.payload?.event === 'signIn') {
        try {
          await dispatch(getCurrentAuthenticatedUser()).unwrap();
        } catch (e) {
          return;
        }
      }
    };
    Hub.listen('auth', updateUser); // listen for Login/Logout events
    updateUser(null); // check manually the first time because we won't get a Hub event
    return () => Hub.remove('auth', updateUser);
  }, [dispatch]);

  const isAuthLoading = useAppSelector(selectIsAuthLoading);

  return {
    isAuthLoading,
  };
};

export { useAuth };
