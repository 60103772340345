import React from 'react';

const BillingDetailsIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="38"
    height="37"
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      opacity="0.3"
      d="M18.9998 0.216797C23.3998 0.216797 27.6165 1.77513 30.9165 4.6168V2.05013H34.5832V11.2168H25.4165V7.55013H28.7165C26.0582 5.1668 22.6665 3.88346 18.9998 3.88346C10.9332 3.88346 4.33317 10.4835 4.33317 18.5501C4.33317 26.6168 10.9332 33.2168 18.9998 33.2168C27.0665 33.2168 33.6665 26.6168 33.6665 18.5501H37.3332C37.3332 28.6335 29.0832 36.8835 18.9998 36.8835C8.9165 36.8835 0.666504 28.6335 0.666504 18.5501C0.666504 8.4668 8.9165 0.216797 18.9998 0.216797Z"
      fill="currentColor"
    />
    <path
      d="M18.4634 21.7565H23.8259V24.281H13.6289V21.7565H15.1964V19.4465H13.6289V17.5655H15.1964V17.4665C15.1964 15.8935 15.6859 14.6725 16.6649 13.8035C17.6549 12.9345 19.0409 12.5 20.8229 12.5C22.1759 12.5 23.2979 12.7475 24.1889 13.2425L23.2319 15.7505C22.6159 15.3875 21.8679 15.206 20.9879 15.206C20.1739 15.206 19.5469 15.393 19.1069 15.767C18.6779 16.13 18.4634 16.6745 18.4634 17.4005V17.5655H22.1924V19.4465H18.4634V21.7565Z"
      fill="currentColor"
    />
  </svg>
);

export { BillingDetailsIcon };
