import React from 'react';

export const ExitIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="29"
    height="32"
    viewBox="0 0 29 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      opacity="0.3"
      d="M20.5 31.8327H0.5V0.166016H20.5V5.99935H17.1667V3.49935H3.83333V28.4993H17.1667V25.9993H20.5V31.8327Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      d="M25.5 14.332H8.83337V17.6654H25.5V14.332Z"
      fill="currentColor"
    />
    <path
      d="M21 23.8327L18.6666 21.4993L24.0833 15.9993L18.6666 10.4993L21 8.16602L28.8333 15.9993L21 23.8327Z"
      fill="currentColor"
    />
  </svg>
);
