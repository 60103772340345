import React from 'react';

const ContactUsIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    color="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M24.9369 44.959C27.3669 44.959 29.3369 42.989 29.3369 40.559C29.3369 38.1289 27.3669 36.159 24.9369 36.159C22.5068 36.159 20.5369 38.1289 20.5369 40.559C20.5369 42.989 22.5068 44.959 24.9369 44.959Z"
      fill="currentColor"
    />
    <path
      d="M9.73682 20.259C10.3368 20.059 11.0368 19.959 11.7368 19.959C15.0368 19.959 17.7368 22.659 17.7368 25.959C17.7368 29.259 15.0368 31.959 11.7368 31.959C11.0368 31.959 10.3368 31.859 9.73682 31.659V20.259Z"
      fill="currentColor"
    />
    <path
      d="M39.7368 31.659C39.1368 31.859 38.4368 31.959 37.7368 31.959C34.4368 31.959 31.7368 29.259 31.7368 25.959C31.7368 22.659 34.4368 19.959 37.7368 19.959C38.4368 19.959 39.1368 20.059 39.7368 20.259V31.659Z"
      fill="currentColor"
    />
    <g opacity="0.3">
      <path
        d="M22.7368 43.259L21.6368 39.459L35.7368 35.459V19.959C35.7368 13.859 30.8368 8.95898 24.7368 8.95898C18.6368 8.95898 13.7368 13.859 13.7368 19.959V30.959H9.73682V19.959C9.73682 11.659 16.4368 4.95898 24.7368 4.95898C33.0368 4.95898 39.7368 11.659 39.7368 19.959V38.359L22.7368 43.259Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export { ContactUsIcon };
