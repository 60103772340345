import React from 'react';

const QuestionMarkIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M47.5 38H31.5C31.5 38 29.5 16.5 55.5 16C74 16 79 29.5 79 38C79 57.5 56.5 58 56.5 58L56 65H41L40.5 46C40.5 46 62.5 49.5 62 38.5C61.772 33.4829 60.5 31 55.5 30.5C46 30.5 47.5 38 47.5 38Z"
      stroke="#303030"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <ellipse
      cx="48.5"
      cy="82"
      rx="10.5"
      ry="9"
      stroke="#303030"
      strokeWidth="3"
    />
  </svg>
);

export { QuestionMarkIcon };
