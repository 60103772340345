import { differenceInDays } from 'date-fns';

import {
  IGetContractContract,
  IGetPendingContract,
} from '@server/services/BillingHistory/Interfaces';

export const FIFTY_CODE = 'igcAKd';
export const HUNDRED_CODE = 'cAumeI';
export const renewalDiscountCodes: Record<string, number> = {
  [FIFTY_CODE]: 50,
  [HUNDRED_CODE]: 100,
};
export const getDaysUntilRenewal = (contractEndDate: Date) => {
  const now = new Date();
  const contractEndTime = new Date(contractEndDate);

  return differenceInDays(contractEndTime, now);
};

export const getIsInRenewalPeriod = (contractEndDate: Date) => {
  const daysUntilRenewal = getDaysUntilRenewal(contractEndDate);

  if (daysUntilRenewal == undefined) return false;

  return daysUntilRenewal >= 0 && daysUntilRenewal <= 45;
};

export const getMaxDiscount = ({
  previousContract,
  newContract,
}: {
  previousContract: IGetContractContract;
  newContract: IGetContractContract;
}) => {
  return Math.max(
    0,
    Math.max(newContract.contractPremiumInsuranceAmount ?? 0, 0) -
      Math.max(0, previousContract.contractPremiumInsuranceAmount ?? 0),
  );
};

export const getDiscountFromCode = (
  code: string | undefined | null,
  maxDiscount: number,
) => {
  if (!code) return 0;
  if (code in renewalDiscountCodes) {
    const discountPercentage = renewalDiscountCodes[code];
    return maxDiscount * (discountPercentage / 100);
  }
  return 0;
};

export const getRenewalPriceWithDiscountAnnual = ({
  previousContract,
  newContract,
  discountCode,
}: {
  previousContract: IGetContractContract;
  newContract: IGetContractContract;
  discountCode?: string;
}) => {
  const maxAllowedDiscount = getMaxDiscount({ previousContract, newContract });
  const discountFromCode = getDiscountFromCode(
    discountCode,
    maxAllowedDiscount,
  );
  const newPrice = newContract.totalCost - discountFromCode;

  return newPrice;
};

// The renewal descount calculation can be found https://ovotech.atlassian.net/wiki/spaces/CORGIProd/pages/3941237001/Renewal+discounts#UPDATE-FOR-MTA%3A

export const getRenewalDiscount = ({
  previousContract,
  newContract,
}: {
  previousContract: IGetContractContract;
  newContract: IGetContractContract;
}) => {
  const maxAllowedDiscount = getMaxDiscount({ previousContract, newContract });

  const discountFromCode50 = getDiscountFromCode(
    FIFTY_CODE,
    maxAllowedDiscount,
  );
  const discountFromCode100 = getDiscountFromCode(
    HUNDRED_CODE,
    maxAllowedDiscount,
  );
  const priceWithDiscount50 = newContract.totalCost - discountFromCode50;
  const priceWithDiscount100 = newContract.totalCost - discountFromCode100;
  const isDiscountAvailable = maxAllowedDiscount > 0;

  return {
    discountFromCode50,
    discountFromCode100,
    priceWithDiscount50,
    priceWithDiscount100,
    isDiscountAvailable,
  };
};

export const getCodeByVariation = (
  variation: string | null,
  previousCode?: string | null,
): string => {
  switch (variation) {
    case '50':
      return FIFTY_CODE;
    case '100':
      return HUNDRED_CODE;
    case 'multi': {
      if (previousCode === FIFTY_CODE || previousCode === HUNDRED_CODE) {
        return HUNDRED_CODE;
      }
      return FIFTY_CODE;
    }
    default:
      return FIFTY_CODE;
  }
};

export const getIsRenewalDiscountApplied = (
  pendingRenewalContract: IGetPendingContract,
) => {
  const renewalInteractionWithDiscount =
    pendingRenewalContract?.renewalInteractions?.find(
      ({ renewalDiscount }) => renewalDiscount > 0,
    );

  return !!renewalInteractionWithDiscount;
};
