import { Card, P } from '@ovotech/element';
import styled, { DefaultTheme, css, keyframes } from 'styled-components';

const rotationAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledLoading = styled.div(
  ({ theme: { core } }) => css`
    position: relative;

    text-indent: -9999em;

    border: 1.1em solid ${core.color.brand.ovo};

    transform: translateZ(0);

    animation: ${rotationAnimation} 1.1s infinite linear;

    border-radius: 50%;

    &,
    &:after {
      width: 5rem;
      height: 5rem;
      font-size: 5px;
    }
  `,
);

export const StyledLoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;

  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.7);
`;

export const ProgressBar = styled.div`
  height: 16px;
  width: 204px;
  border-radius: 7px;
  background: #f9f7f6;
`;

export const Progress = styled.div`
  ${({ theme, width }: { theme: DefaultTheme; width: number }) =>
    `background-color: ${theme.core.color.brand.ovo};
     border-radius: 7px;
     height: 100%;
     transition: width 0.5s ease-in-out;
     transition: width 0.5s ease-in-out;
     width: ${width}%;
    `}
`;

export const StyledCard = styled(Card)`
  ${({ theme }) =>
    ` width: 100%;
      height: max-content;
      padding: 20px;
      ${theme.core.mediaQuery.large}{
        padding: 40px;
      }
    `}
`;

export const SubTitle = styled(P)`
  color: #736862;
`;
