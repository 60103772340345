import { useCallback } from 'react';
import { useRouter } from 'next/router';

const useNavigate = () => {
  const router = useRouter();
  const query = router.query;
  const pathname = router.pathname;
  const navigate = useCallback(
    async (path: string) => {
      if (path === 'back') {
        await router.back();
      } else {
        await router.push(`${path}`);
      }
    },
    [router],
  );

  return { navigate, pathname, query };
};

export default useNavigate;
