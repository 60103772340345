import styled from 'styled-components';
import {
  Card,
  Col,
  Heading1,
  Heading2,
  Heading3,
  Icon,
  Row,
  TextLink,
} from '@ovotech/element';

export const StyledHeading1 = styled(Heading1)`
  font-size: 16px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  ${({ theme: { core } }) => `
    ${core.mediaQuery.medium} {
      font-size: 24px;
      line-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  `}
`;

export const StyledHeading2 = styled(Heading2)`
  font-size: 16px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  ${({ theme: { core } }) => `
    ${core.mediaQuery.large} {
      font-size: 20px;
    }
  `}
`;

export const StyledHeading3 = styled(Heading3)`
  ${({ theme: { core } }) => `
    font-size: 14px;
    margin: 0;
    ${core.mediaQuery.large} {
      font-size: 16px;
    }
  `}
`;

export const StyledFieldValue = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 14px;
  margin: 4px 0 16px;
  word-break: break-all;
`;

export const StyledCol = styled(Col)`
  ${({ theme: { core } }) => `
    margin: 0 0 20px 0;
    padding: 0;
    height: initial;
    ${core.mediaQuery.medium} {
      margin: 0 0 24px 0;
      height: 100%;
    }
    ${core.mediaQuery.large} {
      margin: 0 0 30px 0;
    }
  `}
`;

export const StyledProfileLayoutCol = styled(Col)`
  ${({ theme: { core } }) => `
    margin: 10px;
    padding: 0;
    ${core.mediaQuery.medium} {
      margin: 12px;
    }
    ${core.mediaQuery.large} {
      margin: -46px 15px 15px;
    }
  `}
`;

export const StyledNestedCol = styled(Col)`
  margin: 0;
  padding: 0;
`;

export const StyledProfileRow = styled(Row)`
  ${({ theme: { core } }) => `
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;  
    ${core.mediaQuery.medium} {
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-column-gap: 30px;
      grid-template-columns: minmax(0px, 330px) minmax(0px, 360px);
    }

    &:last-child {
      margin-top: 0;
      ${core.mediaQuery.medium} {
        margin-top: 30px;
      }
    }
  `}
`;

export const StyledRow = styled(Row)`
  ${({ theme: { core } }) => `
    margin: 0;
    padding: 0; 
    ${core.mediaQuery.large} {
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-column-gap: 30px;
    }
  `}
`;

export const StyledCtaRow = styled.div`
  ${({ theme: { core } }) => `
    ${core.mediaQuery.large} {
      display: none;
    }
  `}
`;

export const StyledCard = styled(Card)`
  border-radius: 0;
  padding: 20px;
  height: 90%;
`;

export const StyledCtaCard = styled(Card)`
  ${({ theme: { core } }) => `
    border: 2px solid ${core.color.brand.ovo};
  `}
`;

export const StyledProfileSideNav = styled(StyledCol)`
  height: max-content;
  ${({ theme: { core } }) => `
    display: none;
    ${core.mediaQuery.large} {
      display: block;
      max-width: 248px;
      margin-right: 30px;
    }
  `}
`;

export const StyledIcon = styled(Icon)`
  ${({ theme: { core } }) => `
    color: ${core.color.brand.ovo};
    margin-right: 12px;
  `}
`;

export const EnergisedIcon = styled(Icon)`
  ${({ theme: { core } }) => `
    color: ${core.color.brand.energised};
    transform: rotate(15deg);
  `}
`;

export const EnergisedIconBackground = styled.div`
  ${({ theme: { core } }) => `
    background: ${core.color.brand.midnight};
    position: relative;
    top: -50px;
    right: -50px;
    box-sizing: border-box;
    transform: rotate(-15deg);
    height: 120px;
    width: 120px;
    padding-left: 20px;
    padding-top: 45px;
    float: right;
  `}
`;

export const StyledEditIcon = styled(Icon)`
  ${({ theme: { core } }) => `
    display: none;

    ${core.mediaQuery.large} {
      display: flex;
      margin: 4px 0 0 8px;
    }
  `}
`;

export const EditableFieldRow = styled(Row)`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: space-between;
`;

export const EditableFieldValue = styled.div`
  content: '';
`;

export const EditableFieldLinkMobile = styled.div`
  ${({ theme: { core } }) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4px;
    font-size: initial;

    ${core.mediaQuery.large} {
      display: none;
    }
  `}
`;

export const EditableFieldLinkDesktop = styled.div`
  ${({ theme: { core } }) => `
    display: none;
    font-size: initial;

    ${core.mediaQuery.large} {
      display: flex;

      span {
        display: flex;
        flex-direction: row;
      }
    }
  `}
`;

export const ProfileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProfileCardFieldName = styled.div`
  ${({ theme: { core } }) => `
    color: ${core.color.brand.ovo};
    font-weight: 700;
  `}
`;

export const StyledTextLink = styled(TextLink)<{ as: React.ElementType }>`
  ${({ theme: { core } }) => `
    font-size: initial;
    color: ${core.color.brand.forest} !important;
  `}
`;

export const LastDayOfCover = styled.span`
  ${({ theme: { core } }) => `
    color: ${core.color.brand.forest};
    font-weight: 700;
  `}
`;
