import React from 'react';

import { IProfilePageProps } from '@client/modules/Profile/interfaces';

import CustomErrorBoundary from '../CustomErrorBoundary';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Loading } from '../Loading';
import { TopNavbar } from '../Navbar';

import { Wrapper } from './Wrapper';
import { useLayout } from './hooks/useLayout';

const Layout: React.FC<IProfilePageProps> = ({ children, ...pageProps }) => {
  const { isLoading, currentUser, isEnableAccountDashboard } = useLayout();

  return (
    <>
      {isLoading && <Loading />}
      {isEnableAccountDashboard && currentUser ? <TopNavbar /> : <Header />}
      <div
        id="main-container"
        className="flex justify-center mt-48 min-h-[calc(100vh-60px)]"
      >
        <CustomErrorBoundary>
          <Wrapper {...pageProps}>{children}</Wrapper>
        </CustomErrorBoundary>
      </div>
      <Footer />
    </>
  );
};

export { Layout };
