import React from 'react';

const SuccessIcon = ({
  ...rest
}: JSX.IntrinsicElements['svg']): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.0002 0.333313C5.55279 0.333313 0.333496 5.55261 0.333496 12C0.333496 18.4473 5.55279 23.6666 12.0002 23.6666C18.4475 23.6666 23.6668 18.4473 23.6668 12C23.6668 5.55261 18.4475 0.333313 12.0002 0.333313ZM9.237 20.1666L2.91244 13.0438L6.04402 10.1579L9.237 13.7193L16.6668 5.307L19.7984 8.13156L9.237 20.1666Z"
      fill="#0A9828"
    />
  </svg>
);

export { SuccessIcon };
