import { DefaultTheme } from 'styled-components';

import { CorgiSelfServeTheme } from '@client/styles/themes/corgi';
import { OvoSelfServeTheme } from '@client/styles/themes/ovo';

export const themes: Record<string, DefaultTheme> = {
  OVO: OvoSelfServeTheme,
  OVOEnergy: OvoSelfServeTheme,
  CORGI: CorgiSelfServeTheme,
};

export const favicons: Record<string, string> = {
  OVO: '/favicon-ovo.ico',
  OVOEnergy: '/favicon-ovo.ico',
  CORGI: '/favicon.ico',
};
