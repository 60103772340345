import { useMemo } from 'react';

import { ContractStatus } from '@client/types/__generated__/globalTypes';

import { useGetCustomerQuery } from '../services/self-serve';

const useAuroraBilling = () => {
  const { data } = useGetCustomerQuery();
  const activeContract = useMemo(
    () =>
      data?.customer?.contracts?.find(
        (contract) => contract?.contractStatus === ContractStatus.Active,
      ),
    [data?.customer?.contracts],
  );

  const isAuroraBilling =
    activeContract?.contractPreference?.auroraBilling ?? false;

  return {
    isAuroraBilling,
  };
};

export default useAuroraBilling;
