import * as optimizelyReactSDK from '@optimizely/react-sdk';
// Instantiate an Optimizely client

export const initOptimizely = () => {
  return optimizelyReactSDK.createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
    logLevel: 'none',
    datafileOptions: {
      autoUpdate: true,
      updateInterval: 600000, // 10 minutes in milliseconds
    },
  });
};
