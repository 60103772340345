import React from 'react';

import { IErrorIconNotificationProps } from './interfaces';

import { ErrorText, StyledErrorNotification, WarningIcon } from './styles';

const ErrorIconNotification: React.FC<IErrorIconNotificationProps> = ({
  errorText,
}) => {
  return (
    <StyledErrorNotification
      variant="error"
      id="error-notification"
      title={undefined}
    >
      <WarningIcon name="warning" color="#EE482E" size={24} />
      <ErrorText>{errorText}</ErrorText>
    </StyledErrorNotification>
  );
};

export { ErrorIconNotification };
