import { IconsProvider as OvotechIconProvider } from '@ovotech/element';

import React from 'react';

import { useBrand } from '../../hooks/useBrand';

import { BRAND } from '../../constants/general';

import {
  AccountDetailsIcon,
  AddressIcon,
  AutomationIcon,
  BackChevronIcon,
  BillingDetailsIcon,
  BillingHistoryIcon,
  BoilerIcon,
  BulbIcon,
  CalendarIcon,
  ContactUsIcon,
  CorgiLogo,
  CorgiShield,
  DrainsIcon,
  EditIcon,
  ElectricsIcon,
  HeaderTickIcon,
  InfoIcon,
  InternalDrainsIcon,
  NotResolvedIcon,
  OvoEnergyRectangularLogo,
  OvoLogo,
  PaymentDetailsIcon,
  PhoneIcon,
  PlanIcon,
  PoundIcon,
  ProfileIcon,
  QuestionMarkIcon,
  RadiatorIcon,
  ResolvedIcon,
  SuccessIcon,
  SummaryCheckIcon,
  TapsAndToiletsIcon,
  TickIcon,
  TimerIcon,
  WarningIcon,
  WaterLeakingIcon,
} from './svg';

const icons = {
  backChevron: BackChevronIcon,
  boiler: BoilerIcon,
  waterLeaking: WaterLeakingIcon,
  internalDrains: InternalDrainsIcon,
  externalDrains: DrainsIcon,
  drains: DrainsIcon,
  edit: EditIcon,
  electrics: ElectricsIcon,
  tapsAndToilets: TapsAndToiletsIcon,
  questionMark: QuestionMarkIcon,
  warning: WarningIcon,
  summaryCheck: SummaryCheckIcon,
  calendar: CalendarIcon,
  timer: TimerIcon,
  phone: PhoneIcon,
  radiator: RadiatorIcon,
  profile: ProfileIcon,
  plan: PlanIcon,
  address: AddressIcon,
  accountDetails: AccountDetailsIcon,
  paymentDetails: PaymentDetailsIcon,
  tick: TickIcon,
  automation: AutomationIcon,
  resolved: ResolvedIcon,
  notResolved: NotResolvedIcon,
  billingHistory: BillingHistoryIcon,
  bulb: BulbIcon,
  billingDetails: BillingDetailsIcon,
  info: InfoIcon,
  success: SuccessIcon,
  headerTick: HeaderTickIcon,
  corgiShield: CorgiShield,
  ovoEnergyRectangularLogo: OvoEnergyRectangularLogo,
  pound: PoundIcon,
  contactUs: ContactUsIcon,
};

const logos: Record<
  string,
  ({ ...rest }: JSX.IntrinsicElements['svg']) => JSX.Element
> = {
  CORGI: CorgiLogo,
  OVO: OvoLogo,
  OVOEnergy: OvoLogo,
};

const IconProvider: React.FC = ({ children }) => {
  const { id, isActualBrandUploaded } = useBrand();
  const brandName = BRAND[id];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logo: any = isActualBrandUploaded && logos[brandName];

  return (
    <OvotechIconProvider icons={{ ...icons, logo }}>
      {children}
    </OvotechIconProvider>
  );
};

export default IconProvider;
