import { Col, Row } from '@ovotech/element';

import styled from 'styled-components';

export const StyledMainContainer = styled(Row)(
  ({ theme: { core } }) => `
    padding: ${core.space[6]};
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 60px);
  `,
);

export const StyledWrapper = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const RaiseClaimWrapper = styled(StyledWrapper)`
  align-items: center;
  padding: 20px;
`;

export const StyledClaimsJourneyWrapper = styled(Col)`
  ${({ theme: { core } }) => `
    ${core.mediaQuery.small} {
      box-sizing: content-box;
      max-width: 416px;
    }
  `}
`;

export const LayoutAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;
